import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import { SearchOutlined } from "@mui/icons-material";
import associateStyle from "./../css/associateConcept.module.css";
import { blue } from "@mui/material/colors";
import BlueButton from "../../components/common/BlueButton";
import SuccessModal from "../../components/common/SuccessModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

import {
  Checkbox,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  TextField,
} from "@mui/material";

const AssociateCptCreated = () => {
  const assoIniVal = {
    conceptId: "",
    subConcept: [
      {
        subConceptId: "",
        rootConceptIds: [],
      },
    ],
  };
  const token = sessionStorage.getItem("token");
  const subjectId = sessionStorage.getItem("subjectId");
  const boardId = sessionStorage.getItem("boardId");
  const gradeId = sessionStorage.getItem("gradeId");
  const chapterId = sessionStorage.getItem("chapterId");
  const [cptmapping, setCptmapping] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [conceptAll, setConceptAll] = useState([]);
  const [totalConcept, setTotalConcept] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [subconcept, setSubconcept] = useState([]);
  const [rootconcept, setRootconcept] = useState([]);
  const [search, setSearch] = useState({
    search: "",
  });
  const [association, setAssociation] = useState([]);

  const [selectedCpt, setSelectedCpt] = useState([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  const closeSuccessModal = () => {
    setSuccessModal(false);
  };

  const createAssociation = () => {
    const associationData = handlePostData();
    let data = {
      boardId: boardId,
      gradeId: gradeId,
      subjectId: subjectId,
      // "subTopicId": "402880e88092a7ad018092f47ef13000",
      chapterId: chapterId,
      association: associationData,
    };

    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setSuccessModal(true);
      })
      .catch((err) => console.log(err));
  };

  const checboxOnchange = (e, id) => {
    // var tempCpt = [] ;

    if (e.target.checked) {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedSearch: true };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
    } else {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedSearch: false };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      subconcept?.length > 0 && handleRemoveSubcomceptState(id);
    }
  };

  const handlePostData = () => {
    var temp = [];
    var tempsub = [];
    var rtempoot = [];
    conceptAll.forEach((con) => {
      if (con.isSelectedConcept) {
        subconcept.forEach((sub) => {
          if (
            sub.isSelectedSubconcept &&
            sub.concept_id.toString() === con.id.toString()
          ) {
            rootconcept?.forEach((root) => {
              if (
                root.isSelectRootConcept &&
                root.concept_id.toString() === con.id.toString() &&
                root.sub_concept_id.toString() === sub.id.toString()
              ) {
                rtempoot.push(root.id);
              }
            });
            tempsub.push({ subConceptId: sub.id, rootConceptIds: rtempoot });
            rtempoot = [];
          }
        });
        temp.push({
          conceptId: con.id,
          subConcept: tempsub,
        });
        tempsub = [];
      }
    });
    return temp;
  };

  const handleSearch = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const hanleSelctConcepts = (e, id) => {
    if (e.target.checked) {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedConcept: true };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      getSubConcept(id);
      const tempAssociat = association;
      tempAssociat.push({
        conceptId: id,
        subConcept: [],
      });
      setAssociation(tempAssociat);
    } else {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedConcept: false };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      handleRemoveSubcomceptState(id);
      const tempAssociat = association.filter(
        (data) => data.conceptId.toString() !== id.toString()
      );
      setAssociation(tempAssociat);
    }
  };
  const handleRemoveSubcomceptState = (id) => {
    if (subconcept && subconcept.length > 0) {
      const tempSubConcept = subconcept.filter(
        (data) => data.concept_id.toString() !== id.toString()
      );
      setSubconcept((prev) =>
        prev?.lenght > 0 ? [...prev, tempSubConcept] : tempSubConcept
      );
      const tempAssociat = association.map((data) => {
        if (data.subConcept && data.conceptId === id.toString()) {
          return {
            ...data,
            subConcept: data.subConcept.filter(
              (sub_data) =>
                !tempSubConcept
                  .map((d) => d.id.toString())
                  .includes(sub_data.subConceptId.toString())
            ),
          };
        } else return data;
      });
      setAssociation(tempAssociat);
      if (rootconcept && rootconcept.length > 0) {
        const te = subconcept.map(
          (data) =>
            data.concept_id.toString() === id.toString() && data.id.toString()
        );
        const tempSubConcept = [];
        rootconcept.forEach(
          (data) =>
            !te.includes(data.sub_concept_id.toString()) &&
            tempSubConcept.push(data)
        );
        setRootconcept(tempSubConcept);
      }
    }
  };
  const haldeRemoveRoootConcepts = async (id) => {
    if (rootconcept && rootconcept.length > 0) {
      const tempSubConcept = await rootconcept.filter(
        (data) => data.sub_concept_id.toString() !== id.toString()
      );
      await setRootconcept(tempSubConcept);
    }
  };

  const handleSelectSubconcept = (e, id) => {
    const tempSubId = [];
    if (e.target.checked) {
      const subTemp = subconcept.map((d) => {
        if (d.id.toString() === id.toString()) {
          tempSubId.push(d.concept_id.toString());
          return { ...d, isSelectedSubconcept: true };
        } else {
          return d;
        }
      });

      setSubconcept(subTemp);
      getRootConcept(id, tempSubId[0]);
      const tempAssociat = association.map((data) => {
        if (tempSubId.includes(data.conceptId.toString())) {
          return {
            ...data,
            subConcept: [
              ...data.subConcept,
              { subConceptId: id, rootConceptIds: [] },
            ],
          };
        } else return data;
      });
      setAssociation(tempAssociat);
    } else {
      const subTemp = subconcept.map((d) => {
        if (d.id.toString() === id.toString()) {
          tempSubId.push(d.concept_id.toString());
          return { ...d, isSelectRootConcept: false };
        } else {
          return d;
        }
      });
      setSubconcept(subTemp);
      haldeRemoveRoootConcepts(id);
      const tempAssociat = association.map((data) => {
        if (tempSubId.includes(data.conceptId.toString())) {
          return {
            ...data,
            subConcept: data.subConcept.filter(
              (d) => d.subConceptId.toString() !== id.toString()
            ),
          };
        } else return data;
      });
      setAssociation(tempAssociat);
      // getRootConcept(id)
    }
  };
  const handleSelectRootconcept = (e, id) => {
    const tempSubId = [];
    const tempConId = [];

    if (e.target.checked) {
      const subTemp = rootconcept.map((d) => {
        if (d.id.toString() === id.toString()) {
          tempConId.push(d.concept_id.toString());
          tempSubId.push(d.sub_concept_id.toString());

          return { ...d, isSelectRootConcept: true };
        } else {
          return d;
        }
      });
      setRootconcept(subTemp);
      const tempAssociat = association.map((data) => {
        if (tempConId.includes(data.conceptId.toString()) && data.subconcept) {
          const tempSub = data.subConcept?.map((sub) => {
            if (tempSubId.includes(sub.subConceptId.toString())) {
              return { ...sub, rootConceptIds: sub.rootConceptIds.push(id) };
            } else return sub;
          });
          return {
            ...data,
            subConcept: tempSub,
          };
        } else return data;
      });
      setAssociation(tempAssociat);
    } else {
      const subTemp = rootconcept.map((d) => {
        if (d.id.toString() === id.toString()) {
          tempConId.push(d.concept_id.toString());
          tempSubId.push(d.sub_concept_id.toString());
          return { ...d, isSelectRootConcept: false };
        } else {
          return d;
        }
      });
      setRootconcept(subTemp);
      // getRootConcept(id)
    }
  };

  const getAssociateion = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association?boardId=${boardId}&subjectId=${subjectId}&gradeId=${gradeId}&chapterId=${chapterId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCptmapping(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getSubConcept = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?pageSize=10&conceptId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSubconcept((prev) =>
          prev?.length > 0
            ? [
                ...prev,
                ...res.data.data.data.map((data) => ({
                  ...data,
                  concept_id: id,
                  isSelectRootConcept: false,
                })),
              ]
            : res.data.data.data.map((data) => ({
                ...data,
                concept_id: id,
                isSelectRootConcept: false,
              }))
        );
      })
      .catch((err) => console.log(err));
  };

  const getConcept = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?search=${search.search}&subjectId=${subjectId}&pageSize=2500`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalConcept(res.data.data.totalElements);
        setConceptAll(
          res.data.data.data.map((data) => ({
            ...data,
            isSelectedSearch: false,
            isSelectedConcept: false,
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const getRootConcept = (id, concept_id) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/all?pageSize=2500&subConceptId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data.data,"fffffffff");
        console.log((prev) =>
          prev?.length > 0
            ? [
                ...prev,
                ...res.data.data.data.map((data) => ({
                  ...data,
                  concept_id,
                  sub_concept_id: id,
                  isSelectRootConcept: false,
                })),
              ]
            : res.data.data.data.map((data) => ({
                ...data,
                concept_id,
                sub_concept_id: id,
                isSelectRootConcept: false,
              }))
        );

        // setRootconcept(res.data.data.data);
        setRootconcept((prev) =>
          prev?.length > 0
            ? [
                ...prev,
                ...res.data.data.data.map((data) => ({
                  ...data,
                  concept_id,
                  sub_concept_id: id,
                  isSelectRootConcept: false,
                })),
              ]
            : res.data.data.data.map((data) => ({
                ...data,
                concept_id,
                sub_concept_id: id,
                isSelectRootConcept: false,
              }))
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getConcept(search);
    getAssociateion();
    console.log(rootconcept, "dddddd");
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <Link to="/dashboard/associate%20concept">
            <span className={dashboardContentStyles.link_text}>
              Associate concepts
            </span>
          </Link>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Details</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>
            Last Update:
            {/* {lastModifiedDate.data} */}
          </p>
        </div>
      </article>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "0.5vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <LibraryBooksRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Associate Concepts
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: "1rem",
            }}
          >
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  font: "normal normal normal 13px/20px Poppins !important",
                  opacity: 1,
                },
              }}
              type={"text"}
              placeholder={"Search...."}
              // onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            />
            {/* {!addChapterVisible && (
                <GreenAddButton onClick={() => setAddChapterVisible(true)}>
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add Chapter
                </GreenAddButton>
              )} */}
          </div>
        </div>
        <Paper>
          <div>
            <div>
              <div className={associateStyle.container}>
                <div className={associateStyle.box}>
                  <h3>*Boards</h3>
                  <p>{sessionStorage.getItem("board")}</p>
                </div>
                <div className={associateStyle.box}>
                  <h3>*Subject</h3>
                  <p>{sessionStorage.getItem("subject")}</p>
                </div>
                <div className={associateStyle.box}>
                  <h3>*Grade</h3>
                  <p>{sessionStorage.getItem("grade")}</p>
                </div>
                <div className={associateStyle.box}>
                  <h3>*Chapter</h3>
                  <p>{sessionStorage.getItem("chapter")}</p>
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <div
                style={{ margin: "50px 50px 0px -124px" }}
                className={associateStyle.flex_center}
              >
                <p>Concept search</p>
                <TextField
                  type={"text"}
                  name="search"
                  autoComplete="off"
                  // onFocus={()=> setShowSearch(true)}
                  value={search.search}
                  onChange={handleSearch}
                  placeholder={"Type concept name to find"}
                  sx={{
                    width: { sm: 200, md: 300 },
                    "& .MuiInputBase-root": {
                      width: "247px !important",
                      height: "32px !important",
                      borderRadius: "50px !important",
                      background: "#fff !important",
                      lineHeight: "21px !important",
                      font: "normal normal normal 13px/20px Poppins !important",
                      opacity: 1,
                    },
                  }}
                  // onChange={onSearch}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </IconButton>
                    ),
                  }}
                />

                <BlueButton
                  onClick={() => {
                    navigate("/dashboard/AssociateCptDetails");
                  }}
                >
                  CREATE ASSOCIATION
                </BlueButton>
                {/* <div style={{position:"relative"}}>
              <div style={{position:"absolute" , right:"8px" , margin:'10px'}}>
              <BlueButton onClick={()=>{
                navigate('/dashboard/AssociateCptDetails')
              }}>ASSOCIATE MAPPING</BlueButton>
              </div>
            </div>
                 */}
              </div>
              <div
                style={
                  showSearch
                    ? { display: "block", height: "230px", overflowY: "scroll" }
                    : { display: "none" }
                }
                className={associateStyle.searchOptBox}
              >
                <div style={{ width: "100%" }}>
                  <ul>
                    {conceptAll.length > 0
                      ? conceptAll
                          ?.filter((d) =>
                            search?.search && search?.search.length > 0
                              ? d.concept
                                  .toLowerCase()
                                  .includes(search?.search.toLowerCase())
                              : d
                          )
                          .map((elem) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  borderBottom: "1px solid grey",
                                }}
                              >
                                <Checkbox
                                  {...label}
                                  sx={{
                                    color: `#111fee !important`,
                                    "&.Mui-checked": {
                                      color: blue[900],
                                    },
                                  }}
                                  // value={elem.concept}
                                  onChange={(e) => checboxOnchange(e, elem.id)}
                                  checked={elem.isSelectedSearch}
                                />
                                <li
                                  style={{
                                    listStyleType: "none",
                                    padding: "8px",
                                  }}
                                >
                                  {elem.concept}
                                </li>
                              </div>
                            );
                          })
                      : ""}
                    <li
                      style={{
                        position: "relative",
                        listStyleType: "none",
                        padding: "8px",
                        color: "blue",
                      }}
                    >
                      <span style={{ position: "absolute", left: "5px" }}>
                        {totalConcept} Results
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          right: "5px",
                          color: "blue",
                        }}
                      >
                        1 2 3 4 5
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginTop: "50px" }}>
              <div
                style={{
                  borderBottom: "2px solid lightgrey",
                  borderTop: "2px solid lightgrey",
                  width: "100%",
                }}
                className={associateStyle.d_flex}
              >
                <p
                  style={{
                    color: "blue",
                    padding: "8px",
                    textAlign: "center",
                    borderRight: "2px solid lightgrey",
                    width: "33%",
                  }}
                >
                  Concepts
                </p>
                <p
                  style={{
                    color: "blue",
                    padding: "8px",
                    textAlign: "center",
                    borderRight: "2px solid lightgrey",
                    width: "33%",
                  }}
                >
                  Sub-Concepts
                </p>
                <p
                  style={{
                    color: "blue",
                    padding: "8px",
                    textAlign: "center",
                    width: "33%",
                  }}
                >
                  Root-Concepts
                </p>
              </div>
              {cptmapping && cptmapping.length > 0
                ? cptmapping.map((elem) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <div
                          style={{
                            borderRight: "2px solid lightgrey",
                            textAlign: "left",
                            padding: "8px",
                            width: "33%",
                          }}
                        >
                          {" "}
                          <Checkbox
                            {...label}
                            sx={{
                              color: `#111fee !important`,
                              "&.Mui-checked": {
                                color: blue[900],
                              },
                            }}
                            // defaultChecked
                            // value={elem.concept}
                            //   onChange={(e)=> hanleSelctConcepts(e,elem.id)}
                            //   checked={elem.isSelectedConcept}
                          />
                          {elem.concept}
                        </div>
                        <div style={{ width: "33%" }}>
                          <ul
                            style={{
                              display: "flex",
                              borderRight: "2px solid lightgrey",
                              flexDirection: "column",
                            }}
                          >
                            {elem.subConcept && elem.subConcept.length > 0
                              ? elem.subConcept.map((elemm) => (
                                  <li
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      listStyleType: "none",
                                    }}
                                  >
                                    {" "}
                                    <Checkbox
                                      {...label}
                                      sx={{
                                        color: `#111fee !important`,
                                        "&.Mui-checked": {
                                          color: blue[900],
                                        },
                                      }}
                                      // defaultChecked
                                      // value={elem.concept}
                                      // onChange={(e)=> hanleSelctConcepts(e,elem.id)}
                                      // checked={elem.isSelectedConcept}
                                    />
                                    {elemm.subConcept}
                                  </li>
                                ))
                              : ""}
                          </ul>
                        </div>
                        <div style={{ width: "33%" }}>
                          <ul
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {elem.subConcept && elem.subConcept.length > 0
                              ? elem.subConcept.map((elemm) =>
                                  elemm.rootConceptResponses.length > 0
                                    ? elemm.rootConceptResponses.map((elx) => {
                                        return (
                                          <li
                                            style={{
                                              textAlign: "left",
                                              listStyleType: "none",
                                              padding: "8px",
                                            }}
                                          >
                                            {" "}
                                            <Checkbox
                                              {...label}
                                              sx={{
                                                color: `#111fee !important`,
                                                "&.Mui-checked": {
                                                  color: blue[900],
                                                },
                                              }}
                                              // defaultChecked
                                              // value={elem.concept}
                                              // onChange={(e)=> hanleSelctConcepts(e,elem.id)}
                                              // checked={elem.isSelectedConcept}
                                            />
                                            {elx.rootConcept}
                                          </li>
                                        );
                                      })
                                    : ""
                                )
                              : ""}
                            {/* <li>Root-co-1</li>
                            <li>Root-co-1</li>
                            <li>Root-co-1</li>
                            <li>Root-co-1</li> */}
                          </ul>
                        </div>
                      </div>
                    );
                  })
                : ""}

              <div></div>
            </div>
            {/* <div style={{position:"relative"}}>
              <div style={{position:"absolute" , right:"8px" , margin:'10px'}}>
              <BlueButton onClick={createAssociation}>ASSOCIATE</BlueButton>
              </div>
            </div> */}
          </div>
        </Paper>
      </div>
      <SuccessModal
        userType={sessionStorage.getItem("chapter")}
        open={successModal}
        close={closeSuccessModal}
        handleOk={closeSuccessModal}
      />
    </div>
  );
};

export default AssociateCptCreated;
