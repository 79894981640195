import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import TrainingStyles from "./../css/createTraining.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import Pagination1 from "./pagination1";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import training_category_controller_getall from "../API_CONTROLLER/training-category-controller/training_category_controller_getall";
import training_subcategory_controller_getall from "../API_CONTROLLER/training-category-controller/training_subcategory_controller_getall";
import training_subtraining_table_get from "../API_CONTROLLER/training-category-controller/training_subtraining_table_get";
import training_subtraining_table_post from "../API_CONTROLLER/training-category-controller/training_subtraining_table_post";
import training_table_post from "../API_CONTROLLER/training-controller/training_table_post";
import sortIcon from "../../../src/pages/img/sort_1.png";
import training_table_get from "../API_CONTROLLER/training-controller/training_table_get";
import training_get_modifieddate from "../API_CONTROLLER/training-controller/training_modifiedDate_get";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

const GlobalTraining = () => {
  const [modifiedDate, setModifiedDate] = useState({});

  const [traininglist, setTraininglist] = useState({});
  const [subtraininglist, setSubtraininglist] = useState({});
  const [trainingtableData, setTrainingtableData] = useState({});
  const [trainingnameField, setTrainingnameField] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [selectedSubCat, setSelectedsubcat] = useState({});
  const [name, setName] = useState("");
  const [trainingInputs, setTrainingInputs] = useState({
    categoryId: "",
    subCategoryId: "",
    reviewStatus: "PENDING",
    name: "",
  });
  // search section start
  const [search, setSearch] = useState({
    search: "",
  });

  const navigate = useNavigate();
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    training_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search
    );
  };

  const handleTrainingInputs = (e) => {
    e.preventDefault();
    setTrainingInputs({
      ...trainingInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateTraining = () => {
    training_table_post(trainingInputs);
    setTrainingInputs("");
    window.alert("success");
  };

  useEffect(() => {
    if (search.search === "") {
      training_table_get(
        setTrainingtableData,
        rowsInput,
        page,
        setPageDetail,
        search
      );
    }
    return () => { };
  }, [rowsInput, search]);

  useEffect(() => {
    training_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search
    );
    return () => { };
  }, [rowsInput, page]);

  useEffect(() => {
    training_category_controller_getall(setTraininglist);
    training_subcategory_controller_getall(setSubtraininglist);
    GetLastModifiedAt(setModifiedDate);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
             <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p className="az_navigation_text"><span>Content Admin</span></p>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <a>Create Training </a>
          </div>  */}
          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/template")
              }}

            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate("/dashboard/upload content")
                sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
                sessionStorage.removeItem("sideNavSubMenuNames");
              }}
            >
              Content Admin
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span onClick={() => navigate("/dashboard/upload content")}
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Create Training 
            </span>
          </div>

          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {modifiedDate.data}
            </p>
          </div>
        </article>
        {/* create box */}

        <div
          className={TrainingStyles.flex}
          style={{ position: "absolute", marginTop: "56px", right: "15px" }}
        >
          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                font: "normal normal normal 13px/20px Poppins !important",
                opacity: 1,
              },
            }}
            type={"text"}
            placeholder={"Search...."}
            name="search"
            Value={search.search}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
          <div style={{ marginLeft: "18px" }}>
            <Link
              style={{ textDecoration: "none" }}
              to="/dashboard/CreateTraining"
            >
              <button
              id={`az-content-addtraining-button`}   
               className={TrainingStyles.green_btn}>
                + ADD TRAINING
              </button>
            </Link>
          </div>
        </div>

        <div style={{ paddingRight: "33px" }}>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              borderBottom: "30px solid white",
              marginTop: "126px",
              marginLeft: "15px",
            }}
          >
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={TrainingStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingStyles.table_head}
                    >
                      Category Name
                      <img
                        style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                      ></img>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Sub-category Name
                      <img
                        style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                      ></img>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Training Name
                      <img
                        style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                      ></img>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Review-status
                      <img
                        style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                      ></img>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingStyles.table_head}
                      style={{ paddingRight: "57px", textAlign: "right" }}
                    >
                      Operation
                      <img
                        style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                      ></img>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingtableData.length > 0 ? (
                    trainingtableData.map((elem, i) => {
                      return (
                        <TableRow
                        style={{ backgroundColor: (i % 2 === 0) ? "white" : "#f5f5f8" }}>
                          <TableCell className={TrainingStyles.table_cell}>
                            {i + 1}
                          </TableCell>
                          <TableCell className={TrainingStyles.table_cell}>
                            {elem.categoryName}
                          </TableCell>
                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.subCategoryName}
                          </TableCell>

                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.name}
                          </TableCell>
                          {/* active btn */}
                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.reviewStatus == "PENDING" ? (
                              <button className={TrainingStyles.red_btn}>
                                PENDING
                              </button>
                            ) : (
                              <button className={TrainingStyles.green_btn}>
                                ACTIVE
                              </button>
                            )}

                            <i
                              className="bi bi-pencil"
                              onClick={(e) => {
                                e.preventDefault();
                                var trainingId = elem.id;
                                navigate("/dashboard/CreateTraining", {
                                  state: "edit",
                                });
                                sessionStorage.setItem(
                                  "trainingId",
                                  trainingId
                                );
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "orange",
                                padding: "6px",
                                borderRadius: "50%",
                                marginLeft: "7px",
                              }}
                            ></i>
                          </TableCell>
                          {/* index */}
                          <TableCell
                            align="right"
                            className={TrainingStyles.table_cell}
                            style={{
                              display: "tableCell",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/dashboard/TrainingAids"
                            >
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  color: "black",
                                  border: "1px solid",
                                }}
                                className={TrainingStyles.white_btn}
                              >
                                <AddCircleOutlineOutlinedIcon
                                  style={{
                                    marginRight: "6px",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />{" "}
                                Training Aids
                              </button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <td
                      colSpan="10"
                      style={{ height: "25rem", textAlign: "center" }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        {/* pagination container - Start */}
        <div
          className={TrainingStyles.pagination}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "100%",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={TrainingStyles.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
      </div>
    </>
  );
};

export default GlobalTraining;
