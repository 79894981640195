import React, { useEffect, useState } from "react";
import Acadmicidstyles from "../css/acadmicStaff.module.css";
import { useNavigate } from "react-router-dom";
import tickImage from "./../img/tick-inside-a-circle.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import studentmodal from "./../css/StudentModal.module.css";
import changepasswordstyle from "../css/changePassword.module.css";
import studentedit from "./../css/TeacherEditModal.module.css"; //Edit model css file
import sharestyle from "../css/shareModal.module.css"; //share model css file
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import Coordinator_Type_Get from "../API/misc-controller/coordinator_type_get";
import Coordinator_type_get_All from "../API_CONTROLLER/coordinator-type-controller/coordinator_type_get_All";
import Gender_Controller_Get from "../API/misc-controller/gender_controller_get";
import TeacherControllerGet from "../API/teacher-controller/User_Teacher_Get";
import Pagination1 from "./pagination1";
import TeacherIDControllerGet from "../API/teacher-controller/User_TeacherId_Get";
import TeacherIDControllerPut from "../API/teacher-controller/User_TeacherId_put";
import School_name from "./school_name";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import moment from 'moment';
//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";

import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import { Tooltip } from "@mui/material";
import searchIcn from "../../../src/img/search.svg";
import share from "../Share";
import changePasswordPut from "../ChangePassword";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";

const CoordinatorInput = (props) => {
  return (
    <>
      <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
        <select
          className={Acadmicidstyles.form_input_element_teacher_Acadmicid}
          name="coordinatorTypeId"
          id="coordinatorTypeId"
          // value={props.TeacherInputs.designation}
          value={props.teacherIDData.coordinatorTypeId}
          onChange={props.onChangeHandler}
        >
          <option value="">Select</option>
          {props.subCoordinatorType.length > 0 ? (
            props.subCoordinatorType.map((subCoordinatorType) => {
              return (
                <option value={subCoordinatorType.id}>
                  {subCoordinatorType.coordinatorType}
                </option>
              );
            })
          ) : (
            <option value=""></option>
          )}
        </select>
        <label for="coordinatorType">*Coordinator Type</label>
      </div>
    </>
  );
};

const TeacherDesignationInput = (props) => {
  return (
    <>
      <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
        <input
          className={Acadmicidstyles.form_input_element_teacher_Acadmicid}
          type="text"
          name="designation"
          id="designation"
          // value={props.TeacherInputs.designation}
          value={props.teacherIDData.designation}
          onChange={props.onChangeHandler}
          placeholder=" "
        />
        <label for="designation">*Designation</label>
      </div>
    </>
  );
};

const PrincipalInput = (props) => {
  return (
    <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
      <input
        className={Acadmicidstyles.form_input_element_teacher_Acadmicid}
        type="text"
        name="designation"
        id="designation"
        // value={props.TeacherInputs.designation}
        value={props.teacherIDData.designation}
        onChange={props.onChangeHandler}
        placeholder=" "
      />
      <label for="principalType">*Principal Type</label>
    </div>
  );
};

const Acadmicid = (props) => {
  const navigate = useNavigate();
  const [TeacherInputs, setTeacherInputs] = useState({
    address: "",
    // branch: "4028818380665dd20180667ee7ee0003",
    academicStaffProfile: "",
    designation: "",
    dob: "",
    email: "",
    firstName: "",
    gender: "",
    joinDate: "",
    lastName: "",
    mobile: "",
    previousWorkExp: "",
    token: "770-1798-MMARS",
  });

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Backspace') {
  //       const selectionStart = e.target.selectionStart;
  //       const selectionEnd = e.target.selectionEnd;
  //       const inputValue = e.target.value;
  //       console.log("Selection Start:", selectionStart);
  //       console.log("Selection End:", selectionEnd);
  //       console.log("Input Value:", inputValue);
  //       // Check if all text is selected
  //       if (e.keyCode === 8 && (selectionStart === 0 || selectionStart === null) && (selectionEnd === null || selectionEnd === inputValue.length)) {
  //         console.log("Backspace key pressed with all text selected.");
  //           setTeacherIDData({
  //               ...teacherIDData,
  //               [e.target.name]: "",
  //               designation: null,
  //           });
  //       }
  //   }
  // };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setTimeout(() => {
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;
        const inputValue = e.target.value;
        const selectedText = inputValue.substring(selectionStart, selectionEnd);

        if (selectedText.trim().length === inputValue.trim().length) {
          console.log("Backspace key pressed with all text selected.");
          setTeacherIDData({
            ...teacherIDData,
            [e.target.name]: "",
          });
        } else {
          console.log("Backspace key pressed normally.");
        }
      }, 0);
    }
  };

  function validates(dobValue, admissionDateValue) {
    const [dobYear, dobMonth, dobDay] = dobValue?.split("-")?.map(Number);
    const [admissionYear, admissionMonth, admissionDay] = admissionDateValue?.split("-")?.map(Number);

    if (dobYear > admissionYear) return false;
    if (dobYear < admissionYear) return true;

    if (dobMonth > admissionMonth) return false;
    if (dobMonth < admissionMonth) return true;

    return dobDay < admissionDay;
  }
  
  
  


  const handlerTeacherInputs = (e) => {
    e.preventDefault();
    if (TeacherInputs.academicStaffProfile === "COORDINATOR") {
      setTeacherIDData({
        ...teacherIDData,
        [e.target.name]: e.target.value,
        designation: null,
      });
    }
    if (TeacherInputs.academicStaffProfile !== "COORDINATOR") {
      setTeacherIDData({
        ...teacherIDData,
        [e.target.name]: e.target.value,
      });
    } else {
      setTeacherIDData({
        ...teacherIDData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [isError, setIsError] = useState("");
  const [showError, setShowError] = useState("");
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [shareFile, setShareFile] = useState("");
  const [success, setSuccess] = useState("");
  const [shareFirstName, setShareFirstName] = useState("");
  const [shareLastName, setShareLastName] = useState("");

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }
  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }

  function validateWorkExperience(yearsValue) {
    if (yearsValue.trim() === '') {
      return true;
    }
    const regex = /^(?:0|[1-9]\d*)(\.\d+)?$/;
    return regex.test(yearsValue);
  }

  const [showModalUpdated, setShowModalUpdated] = useState(false);

  const handleCreateTeacher = (e) => {
    // e.preventDefault();
    if (teacherIDData.firstName === "" ||
    teacherIDData.lastName === "" ||
    teacherIDData.email === "" ||
    teacherIDData.mobile === "" ||
    teacherIDData.gender === "") {
    setIsError(true);
    setShowError("Please enter all the required values");
    return;
  }

    // if(teacherIDData.firstName === "" ||
    // teacherIDData.lastName === "" ||
    //   teacherIDData.email === "" ||
    //   teacherIDData.mobile === "" ||
    //   teacherIDData.gender === "" ||
    //   teacherIDData.joinDate === "" ||
    //   teacherIDData.previousWorkExp === "" ||
    //   teacherIDData.address === "" ||
    //   teacherIDData.designatio === '')
    //   {
    //     setIsError(true);
    //     setShowError("Please enter all the required values@@@@@");
    //     return;
    //   }

    // other validation condtions
    if (!validateMobile(teacherIDData.mobile)) {
      setIsError(true);
      setShowError("Phone number invalid");
      return false
    }

    if(teacherIDData.dob !== '' && teacherIDData.dob !== null && teacherIDData.joinDate !== '' && teacherIDData.joinDate !== null){
      if (!validates(teacherIDData.dob, teacherIDData.joinDate)) {
        setIsError(true);
        setShowError("The DOB must be before the joining date.");
        return;
      }
    }

    if (teacherIDData.academicStaffProfile === "") {
      setIsError(true);
      setShowError("Please select profile type");
      return false
    }

    if (teacherIDData.academicStaffProfile === "COORDINATOR") {
      if (!teacherIDData.coordinatorTypeId) {
        setIsError(true);
        setShowError("Please select coordinator type");
        return false
      }
    }
    
    if (!validateEmail(teacherIDData.email)) {
      setIsError(true);
      setShowError("Please enter a valid email address");
    }

    // if (!validateWorkExperience(TeacherInputs.previousWorkExp)) {
    //   setIsError(true);
    //   setShowError("Please enter a valid value for work experience");
    // }

    async function teacherIDPutMethod(teacherIDData, setSuccess) {
      let token = sessionStorage.getItem("token");
      let teacherid = sessionStorage.getItem("teacherid");
    
      if (teacherIDData.academicStaffProfile === "COORDINATOR") {
      var item = {
        lmsEnv: process.env.REACT_APP_LMS_ENV,
        address: teacherIDData.address,
        branch: sessionStorage.getItem("branchid"),
        academicStaffProfile: teacherIDData.academicStaffProfile,
        coordinatorTypeId: teacherIDData.coordinatorTypeId,
        designation: teacherIDData.designation,
        dob: teacherIDData.dob,
        email: teacherIDData.email.toLowerCase(),
        firstName: teacherIDData.firstName,
        gender: teacherIDData.gender,
        joinDate: teacherIDData.joinDate,
        lastName: teacherIDData.lastName,
        mobile: teacherIDData.mobile,
        previousWorkExp: teacherIDData.previousWorkExp,
        roleId: "ff8081818041dfd1018041e36f980000",
        school: sessionStorage.getItem("schoolid"),
        token: "770-1798-MMARS",
      }
    }else{
      var item = {
        lmsEnv: process.env.REACT_APP_LMS_ENV,
        address: teacherIDData.address,
        branch: sessionStorage.getItem("branchid"),
        academicStaffProfile: teacherIDData.academicStaffProfile,
        coordinatorTypeId: null,
        designation: teacherIDData.designation,
        dob: teacherIDData.dob,
        email: teacherIDData.email.toLowerCase(),
        firstName: teacherIDData.firstName,
        gender: teacherIDData.gender,
        joinDate: teacherIDData.joinDate,
        lastName: teacherIDData.lastName,
        mobile: teacherIDData.mobile,
        previousWorkExp: teacherIDData.previousWorkExp,
        roleId: "ff8081818041dfd1018041e36f980000",
        school: sessionStorage.getItem("schoolid"),
        token: "770-1798-MMARS",
      }
    }
     
    
        let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${sessionStorage.getItem("teacherid")}`,item,
        {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((json)=>{
          setSuccess(json.data)
        })
        .catch(err=>{
          if (err.message.includes("409")) {
            setShowError(
              "Academic staff already exists with this same email or mobile."
            );
            setIsError(true);
          } else if (err.message.includes("400")) {
            setIsError(true);
            setShowError(
              "Please enter a valid data."
            );
          }
          else if (err.message.includes("500")) {
            setIsError(true);
            setShowError(
              "failed to update Teacher."
            );
          } else {
            setIsError(true);
            setShowError(err.message);
          }
        });
    }

    setIsError(false);
    teacherIDPutMethod(teacherIDData, setSuccess);
    setSuccess(true);
    // navigate("/dashboard/academicid");
  };

  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => {};
    }
    if (success.success === true) {
      setShowModalUpdated(true);
      // navigate("/dashboard/teacherId");
      return () => {};
    }
  }, [success]);

  const handleUploadModal = (e) => {
    if (e.target.parentNode.children[0].files[0]) {
      setShareFile(e.target.parentNode.children[0].files[0].name);
      setShowModalUpload(true);
      setIsError(false);
    } else {
      setIsError(true);
      setShowError("Please choose a file to submit");
    }
  };

  const handleUploadModalOk = (e) => {
    setShowModalUpload(false);
  };

  const [active, setActive] = useState("FirstInputBox");
  const [teacherInputaActive, setTeacherInputaActive] = useState("TEACHER");

  const ChangeButtonHandler = (e) => {
    if (e.target.value === "TEACHER") {
      setActive("FirstInputBox");
    }
    if (e.target.value === "COORDINATOR") {
      setActive("SecondInputBox");
    }
    if (e.target.value === "PRINCIPAL") {
      setActive("3rdInputBox");
    }
  };

  // search starts
  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearchbtn = (e) => {
    e.preventDefault();
    TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search
    );
  };

  // useEffect(() => {
  //   if (search.search === "") {
  //     console.log("useEffect");
  //     TeacherControllerGet(
  //       setTeacherData,
  //       setPageDetail,
  //       page,
  //       rowsInput,
  //       search
  //     );

  //     return () => {};
  //   }
  // }, [rowsInput, page, search]);
  // search ends

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends
  useEffect(() => {
    // getPaginationGroup();
  });
  const [pageDetail, setPageDetail] = useState({});
  const [teacherData, setTeacherData] = useState({});
  const [coordinatorType, setCoordinatorType] = useState({});
  const [subCoordinatorType, setSubCoordinatorType] = useState({});
  const [genderType, setGenderType] = useState({});
  const [teacherIDData, setTeacherIDData] = useState({});
  useEffect(() => {
    Coordinator_Type_Get(setCoordinatorType);
    Gender_Controller_Get(setGenderType);
    TeacherIDControllerGet(setTeacherIDData);
    Coordinator_type_get_All(setSubCoordinatorType);

    return () => {};
  }, []);
  // useEffect(() => {
  //   TeacherIDControllerGet(setTeacherIDData);

  //   return () => {};
  // }, [schoolDetails]);

  useEffect(() => {
    if (TeacherInputs.coordinatorType === "TEACHER") {
      setTeacherInputaActive("TEACHER");
    }
    if (TeacherInputs.coordinatorType === "COORDINATOR") {
      setTeacherInputaActive("COORDINATOR");
    }
    if (TeacherInputs.coordinatorType === "PRINCIPAL") {
      setTeacherInputaActive("PRINCIPAL");
    }
    return () => {};
  }, [TeacherInputs]);

  useEffect(() => {
    TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search
    );
    return () => {};
  }, [page, rowsInput, sessionStorage.getItem("branchid")]);

  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    // sessionStorage.removeItem("teacherid");
    Branches_Changer_School_Id(setBranchList);

    return () => {};
  }, [schoolDetails]);

  //BranchController ends
  // popup modal starts
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleChangePassword = (e) => {
    setShowModalChangePassword(true);
  };
  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };

  const handleSubmit = (e) => {
    changePasswordPut(userName, userId, password, persona);
    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
  };

  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/academicid");
  };

  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
    // navigate("/dashboard/studentTable");
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  // popup modal starts
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "ACADEMIC_STAFF";
  const [regexPass, setRegexPass] = useState(true);

  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }

  useEffect(() => {
    share(userName, userId, persona);
  }, [userName, userId]);

  const handleOkEdit = () => {
    navigate("/dashboard/teacherId");
  };

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Resigtered Schools
          </span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Add New School</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      {/* <h1 className={Acadmicidstyles.h1}> hello</h1> */}
      <article className={Acadmicidstyles.article_Acadmicid}>
        <div className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={Acadmicidstyles.grid_container_header_plan_Acadmicid}>
          <div
            className={Acadmicidstyles.item_plan1_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={Acadmicidstyles.item_plan2_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={Acadmicidstyles.item_plan3_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/ AddAcademicStaff");
            }}
          >
            Academic Staff
          </div>
          <div
            className={Acadmicidstyles.item_plan4_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={Acadmicidstyles.item_plan5_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={Acadmicidstyles.item_plan6_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={Acadmicidstyles.item_plan7_Acadmicid}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={Acadmicidstyles.create_teacher_Acadmicid}>
            <div className={Acadmicidstyles.input_form_teacher_Acadmicid}>
              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder=""
                  // value={TeacherInputs.firstName}
                  value={teacherIDData.firstName}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="Name">*First Name</label>
              </div>
              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder=" "
                  value={teacherIDData.lastName}
                  // value={TeacherInputs.lastName}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="Name">*Last Name</label>
              </div>
              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  // value={TeacherInputs.email}
                  Value={teacherIDData.email}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="email">*Primary Email</label>
              </div>
              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="tel"
                  name="mobile"
                  id="mobile"
                  placeholder=" "
                  // value={TeacherInputs.mobile}
                  Value={teacherIDData.mobile}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                  maxLength={10}
                />
                <label for="phoneno">*Primary Phone no.</label>
              </div>

              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder=" "
                  // value={TeacherInputs.dob}
                  Value={teacherIDData.dob}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="dateOfBirth">Date of Birth</label>
              </div>
              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <select
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  name="gender"
                  id="gender"
                  // value={TeacherInputs.gender}
                  value={teacherIDData.gender}
                  onChange={handlerTeacherInputs}
                >
                  <option value="">Select</option>
                  {genderType.length > 0 ? (
                    genderType.map((genderType) => {
                      return (
                        <option value={genderType.code}>
                          {genderType.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">Loading....</option>
                  )}
                </select>
                <label for="gender">*Gender</label>
              </div>

              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="date"
                  name="joinDate"
                  id="joinDate"
                  placeholder=" "
                  Value={teacherIDData.joinDate}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="dateofjoining">Date of Joining</label>
              </div>

              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                {/* <select
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  name="previousWorkExp"
                  id="previousWorkExp"
                  // value={TeacherInputs.previousWorkExp}
                  value={teacherIDData.previousWorkExp}
                  onChange={handlerTeacherInputs}
                >
                  <option value="select">Select</option>
                  <option value="1 yrs">1 Year</option>
                  <option value="2 yrs">2 Year</option>
                  <option value="3 yrs">3 Year</option>
                  <option value="4 yrs">4 Year</option>
                  <option value="5 yrs">5 Year</option>
                  <option value="6 yrs">6 Year</option>
                </select> */}
                 <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="text"
                  name="previousWorkExp"
                  id="previousWorkExp"
                  placeholder=" "
                  // value={TeacherInputs.address}
                  Value={teacherIDData.previousWorkExp}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="experience">Previous work experience</label>
              </div>

              <div className={Acadmicidstyles.input_element_teacher_Acadmicid}>
                <input
                  className={
                    Acadmicidstyles.form_input_element_teacher_Acadmicid
                  }
                  type="text"
                  name="address"
                  id="address"
                  placeholder=" "
                  // value={TeacherInputs.address}
                  Value={teacherIDData.address}
                  onChange={handlerTeacherInputs}
                  onKeyDown={handleKeyDown}
                />
                <label for="address">Address</label>
              </div>

              {teacherIDData.academicStaffProfile !== "COORDINATOR" && (
                <div
                  className={Acadmicidstyles.input_element_teacher_Acadmicid}
                >
                  <input
                    className={
                      Acadmicidstyles.form_input_element_teacher_Acadmicid
                    }
                    type="text"
                    name="designation"
                    id="designation"
                    value={teacherIDData.designation}
                    onChange={handlerTeacherInputs}
                    onKeyDown={handleKeyDown}
                    placeholder=" "
                  />
                  <label for="designation">Designation</label>
                </div>
              )}
              {teacherIDData.academicStaffProfile === "COORDINATOR" && (
                <div
                  className={Acadmicidstyles.input_element_teacher_Acadmicid}
                >
                  <select
                    className={
                      Acadmicidstyles.form_input_element_teacher_Acadmicid
                    }
                    name="coordinatorTypeId"
                    id="coordinatorTypeId"
                    value={teacherIDData.coordinatorTypeId}
                    onChange={handlerTeacherInputs}
                  >
                    <option value="">Select</option>
                    {subCoordinatorType.data.length > 0 ? (
                      subCoordinatorType.data.map((subCoordinatorType) => {
                        return (
                          <option
                            value={subCoordinatorType.id}
                            name="coordinatorTypeId"
                          >
                            {subCoordinatorType.coordinatorType}
                          </option>
                        );
                      })
                    ) : (
                      <option value=""></option>
                    )}
                  </select>
                  <label for="coordinatorType">*Coordinator Type</label>
                </div>
              )}

              <div
                className={
                  Acadmicidstyles.input_block_element_teacher_Acadmicid
                }
              >
                <div
                  className={Acadmicidstyles.error_box}
                  style={isError ? { display: "block" } : { display: "none" }}
                >
                  {showError}
                </div>
              </div>
            </div>
          </div>

          <section
            className={Acadmicidstyles.create_teacher_Acadmicid_sectiondiv1}
          >
            <div
              className={
                Acadmicidstyles.input_form_teacher_Acadmicid_sectiondiv1
              }
            >
              <div>
                <div className={Acadmicidstyles.teacher1_Acadmicid_sectiondiv1}>
                  <div
                    className={
                      Acadmicidstyles.input_element_teacher_Acadmicid_sectiondiv1
                    }
                  >
                    <select
                      className={
                        Acadmicidstyles.form_input_element_teacher_Acadmicid_sectiondiv1
                      }
                      name="academicStaffProfile"
                      id={coordinatorType.code}
                      placeholder=""
                      // value={TeacherInputs.coordinatorType}
                      value={teacherIDData.academicStaffProfile}
                      onChange={handlerTeacherInputs}
                      // onClick={ChangeButtonHandler}
                    >
                      <option value="">Select</option>
                      {coordinatorType.length > 0 ? (
                        coordinatorType.map((coordinatorType) => {
                          return (
                            <option value={coordinatorType.code}>
                              {coordinatorType.name}
                            </option>
                          );
                        })
                      ) : (
                        <div></div>
                      )}
                    </select>
                    <label for="Change Profile">*Change Profile</label>
                  </div>

                  {/* <div
                    className={
                      Acadmicidstyles.input_element_teacher_Acadmicid_sectiondiv1
                    }
                  >
                    {active === "FirstInputBox" && (
                      <TeacherDesignationInput
                        onClickHandler={ChangeButtonHandler}
                        onChangeHandler={handlerTeacherInputs}
                        // TeacherInputs={TeacherInputs}
                        teacherIDData={teacherIDData}
                      />
                    )}
                    {active === "SecondInputBox" && (
                      <CoordinatorInput
                        onClickHandler={ChangeButtonHandler}
                        onChangeHandler={handlerTeacherInputs}
                        // TeacherInputs={TeacherInputs}
                        teacherIDData={teacherIDData}
                        subCoordinatorType={subCoordinatorType}
                      />
                    )}
                    {active === "3rdInputBox" && (
                      <PrincipalInput
                        onChangeHandler={handlerTeacherInputs}
                        // TeacherInputs={TeacherInputs}
                        teacherIDData={teacherIDData}
                      />
                    )}
                    
                  </div> */}

                  {teacherIDData.academicStaffProfile !== "COORDINATOR" ? (
                    <div
                      className={
                        Acadmicidstyles.input_element_teacher_Acadmicid_sectiondiv1
                      }
                    >
                      {/* <input
                        className={
                          Acadmicidstyles.form_input_element_teacher_Acadmicid
                        }
                        type="text"
                        name="designation"
                        id="designation"
                        // value={props.TeacherInputs.designation}
                        value={teacherIDData.designation}
                        onChange={handlerTeacherInputs}
                        placeholder=" "
                      />
                      <label for="designation">*Designation</label> */}
                    </div>
                  ) : (
                    <div
                      className={
                        Acadmicidstyles.input_element_teacher_Acadmicid_sectiondiv1
                      }
                    >
                      {/* <select
                        className={
                          Acadmicidstyles.form_input_element_teacher_Acadmicid
                        }
                        name="coordinatorTypeId"
                        id="coordinatorTypeId"
                        // value={props.TeacherInputs.designation}
                        value={teacherIDData.coordinatorTypeId}
                        onChange={handlerTeacherInputs}
                      >
                        <option value="select">Select</option>
                        {subCoordinatorType.data.length > 0 ? (
                          subCoordinatorType.data.map((subCoordinatorType) => {
                            return (
                              <option
                                value={subCoordinatorType.id}
                                name="coordinatorTypeId"
                              >
                                {subCoordinatorType.coordinatorType}
                              </option>
                            );
                          })
                        ) : (
                          <option value=""></option>
                        )}
                      </select>
                      <label for="coordinatorType">*Coordinator Type</label> */}
                    </div>
                  )}
                </div>
                <div
                  className={Acadmicidstyles.teacher1_Acadmicid_1_sectiondiv1}
                >
                  <div
                    className={
                      Acadmicidstyles.teacher1_Acadmicid_button_sectiondiv1
                    }
                  >
                    <button
                      className={
                        Acadmicidstyles.create_button_teacher1_Acadmicid_sectiondiv1
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboard/teacherId");
                      }}
                    >
                      CANCEL
                    </button>
                  </div>

                  <div
                    className={Acadmicidstyles.teacher2_Acadmicid_sectiondiv1}
                  >
                    <button
                      className={
                        Acadmicidstyles.create_button_teacher2_Acadmicid_sectiondiv1
                      }
                      onClick={handleCreateTeacher}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
        <div className={Acadmicidstyles.teacher_list_Acadmicid}>
          <div className={Acadmicidstyles.list1_Acadmicid}>
            <i class="fa-solid fa-chalkboard-user" aria-hidden="true"></i>
            <span className={Acadmicidstyles.logo_space_1}>
              Academic Staff List
            </span>
          </div>
          <div className={Acadmicidstyles.school_list_search_container}>
            <input
              className={Acadmicidstyles.input_list2_Acadmicid}
              type="search"
              name="search"
              id="search"
              placeholder="Name/Email/Mobile..."
              Value={search.search}
              onChange={handleSearch}
            />
            <span>
              <img
                src={searchIcn}
                style={{
                  verticalAlign: "top",
                  fontSize: "large",
                }}
                onClick={handleSearchbtn}
              />
            </span>
          </div>
        </div>

        <div
          className={studentmodal.flex_container}
          id={studentmodal.modal}
          style={showModalUpdated ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={studentmodal.main_content}
            id={studentmodal.modal_content}
          >
            <span style={{ textAlign: "center" }}>
              <i
                style={{ color: "orange", fontSize: "50px" }}
                class="fa-regular fa-circle-check"
              ></i>
              {/* <img src={tickImage} alt="red checkmark" /> */}
            </span>

            <h5>You have successfully Updated Academic Staff</h5>
            {/* <h6>
            "{shareFirstName} {shareLastName} "
          </h6> */}
            <div className={studentmodal.modal_buttons}>
              <button
                className={studentmodal.choosefile_button_1}
                id={studentmodal.choose_button_id_1}
                onClick={handleOkEdit}
              >
                ok
              </button>
            </div>
          </div>
        </div>

        {/* <!-- ===========TABLE STARTS FROM HERE=========== --> */}

        <div className={Acadmicidstyles.list_table_teacher_div_Acadmicid}>
          <table
            className={Acadmicidstyles.table_section_teacher_list_Acadmicid}
          >
            <thead>
              <tr
                className={
                  Acadmicidstyles.table_head_section_teacher_list_Acadmicid
                }
              >
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  {" "}
                  # <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  <span class={Acadmicidstyles.email_alignment}>
                    {" "}
                    <div class={Acadmicidstyles.name}>
                      Email <i class="fa-solid fa-arrows-up-down"></i>
                    </div>
                  </span>
                </th>
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  Password <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  Role <i class="fa-solid fa-arrows-up-down"></i>{" "}
                </th>
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  {" "}
                  User Name <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                {/* <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  User Id <i class="fa-solid fa-arrows-up-down"></i>
                </th> */}
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                >
                  Login Time <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th
                  className={Acadmicidstyles.list_table_teacher_list_Acadmicid}
                  style={{ paddingRight: "20px" }}
                >
                  Action <i class="fa-solid fa-arrows-up-down"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {teacherData.length > 0 ? (
                teacherData.map((teacherData, index) => {
                  return (
                    <tr className={Acadmicidstyles.data_row} key={index}>
                      <td>{index + 1}</td>
                      <td
                        id={Acadmicidstyles.email}
                        onClick={(e) => {
                          e.preventDefault();
                          var teacherid = teacherData.id;
                          sessionStorage.setItem("teacherid", teacherid);
                          TeacherIDControllerGet(setTeacherIDData);
                          setShowModalEdit(true);
                        }}
                      >
                        {" "}
                        {teacherData.email}
                      </td>
                      <td className={Acadmicidstyles.type_of_school}>
                        {teacherData.password}
                      </td>
                      <td className={Acadmicidstyles.list_role}>
                        {teacherData.academicStaffProfile}
                      </td>
                      <td>{teacherData.userName}</td>
                      {/* <td>
                        <div className={Acadmicidstyles.name}>
                          {teacherData.userId}
                        </div>
                      </td> */}
                      <td>{teacherData.lastLoginTime}</td>
                      <td
                        className={
                          Acadmicidstyles.list_body_table_teache_list_teacherid
                        }
                      >
                        <div className={Acadmicidstyles.wrapper_Acadmicid}>
                          <div
                            // className={Acadmicidstyles.icon}
                            onClick={handleChangePassword}
                          >
                            {/* <div className={Acadmicidstyles.tooltip}>
                              Change Password
                            </div> */}
                            <span
                              id={Acadmicidstyles.change_password_teacherid}
                            >
                              {/* <i class="fa-solid fa-lock"></i> */}
                              <LockIconButton>
                                <Tooltip
                                  title="Change Password"
                                  placement={"top"}
                                >
                                  {/* <LockOutlinedIcon style={{ fontSize: "1.5vw" }} /> */}
                                  <img
                                    onClick={() => {
                                      setShowModalChangePassword(true);
                                      setUserId(teacherData.userId);
                                      setUserName(teacherData.userName);
                                      setLoginPassword("");
                                      setConfirmLoginPassword("");
                                      setRegexPass(true);
                                    }}
                                    className={
                                      dashboardContentStyles.btnHoverEffect
                                    }
                                    src={action_sa_Lock}
                                    alt="Lock-btn"
                                  />
                                </Tooltip>
                              </LockIconButton>
                            </span>
                          </div>
                          <div
                          // className={Acadmicidstyles.icon}
                          >
                            {/* <div className={Acadmicidstyles.tooltip}>Edit</div> */}
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                var teacherid = teacherData.id;
                                sessionStorage.setItem("teacherid", teacherid);
                                TeacherIDControllerGet(setTeacherIDData);
                                // navigate("/dashboard/academicid");
                                // window.location.reload();
                                // window.location = "/dashboard/academicid";
                              }}
                            >
                              {/* <i class="fa-light fa-pen"></i> */}
                              <EditIconButton>
                                <Tooltip title="Edit" placement={"top"}>
                                  {/* <EditOutlinedIcon style={{ fontSize: "1.5vw" }} /> */}
                                  <img
                                    className={
                                      dashboardContentStyles.btnHoverEffect
                                    }
                                    src={action_sa_Edit}
                                    alt="Edit-btn"
                                  />
                                </Tooltip>
                              </EditIconButton>
                            </span>
                          </div>
                          <div
                            // className={Acadmicidstyles.icon}
                            // onClick={handleShareModal}
                            onClick={(e) => {
                              // e.preventDefault();
                              var teacherid = teacherData.id;
                              sessionStorage.setItem("teacherid", teacherid);
                              TeacherIDControllerGet(setTeacherIDData);

                              setShowModalShare(true);
                            }}
                          >
                            {/* <div className={Acadmicidstyles.tooltip}>Share</div> */}
                            <span>
                              {/* <i class="fa fa-share-alt" aria-hidden="true"></i> */}
                              <ShareIconButton>
                                <Tooltip title="Share " placement={"top"}>
                                  {/* <ShareOutlinedIconstyle={{ fontSize: "1.5vw" }}/> */}
                                  <img
                                    onClick={() => {
                                      setUserName(teacherData.userName);
                                      setUserId(teacherData.userId);
                                      share(userName, userId, persona);
                                      setShowModalShare(true);
                                    }}
                                    className={
                                      dashboardContentStyles.btnHoverEffect
                                    }
                                    src={action_sa_Share}
                                    alt="Share-btn"
                                  />
                                </Tooltip>
                              </ShareIconButton>
                            </span>
                          </div>
                          <div
                          // className={Acadmicidstyles.icon}
                          >
                            {/* <div className={Acadmicidstyles.tooltip}>
                              Remove
                            </div> */}
                            <span>
                              {/* <i class="fa-solid fa-trash-can"></i> */}
                              <DeleteIconButton>
                                <Tooltip title="Remove" placement={"top"}>
                                  {/* <DeleteOutlinedIcon style={{ fontSize: "1.5vw" }}/> */}
                                  <img
                                    className={
                                      dashboardContentStyles.btnHoverEffect
                                    }
                                    src={action_sa_Delete}
                                    alt="Dlt-btn"
                                  />
                                </Tooltip>
                              </DeleteIconButton>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>
        {/* <!-- =================================TABLE ENDS HERE============= --> */}
        {/* <div className={Acadmicidstyles.data_available_Acadmicid}>
            <p className={Acadmicidstyles.data_placeholder_Acadmicid}>NO DATA AVAILABLE</p>
          </div> */}
        {/* pagination container - Start */}
        <div className={Acadmicidstyles.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Acadmicidstyles.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}

        {/* upload modal starts  */}

        <div
          className={Acadmicidstyles.flex_container_Acadmicid}
          id={Acadmicidstyles.modal_Acadmicid}
          style={showModalUpload ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={Acadmicidstyles.main_content_Acadmicid}
            id={Acadmicidstyles.modal_content_Acadmicid}
          >
            <span className={Acadmicidstyles.img}>
              <img src={tickImage} alt="red checkmark" />
            </span>

            <h5 className={Acadmicidstyles.head}>
              You have successfully uploaded CSV file
            </h5>

            <p className={Acadmicidstyles.para}> {shareFile}</p>
            <div className={Acadmicidstyles.modal_buttons_Acadmicid}>
              <button
                className={Acadmicidstyles.ok_btn}
                id={Acadmicidstyles.ok_btn_1_Acadmicid}
                onClick={handleUploadModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        {/* upload modal ends */}

        {/* change password modal starts  */}
        <div
          className={changepasswordstyle.flex_container_teacherid}
          id={changepasswordstyle.modal_teacherid}
          style={
            showModalChangePassword ? { display: "flex" } : { display: "none" }
          }
        >
          <form class={changepasswordstyle.setPassword_login_form}>
            <div class={changepasswordstyle.login_form_title}>
              <h5>Change Password</h5>
              <span>
                <i
                  class="fa-solid fa-xmark"
                  onClick={handleUpdatedPassword}
                ></i>
              </span>
            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>New Password</legend>
                <input
                  type={passwordShown ? "text" : "password"}
                  name="userPassword"
                  id={changepasswordstyle.password}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <i
                  className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"} ${
                    changepasswordstyle.bi_eye_slash
                  }`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleLoginPasswordVisibility("show");
                  }}
                  onMouseLeave={() => {
                    toggleLoginPasswordVisibility("dontShow");
                  }}
                ></i>
              </fieldset>
              <span
                className={changepasswordstyle.error_span}
                style={
                  isPassEmpty ? { display: "inline" } : { display: "none" }
                }
              >
                Password cannot be empty
              </span>
            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>Confirm Password</legend>
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  name="confirmPassword"
                  id={changepasswordstyle.password}
                  value={confirmLoginPassword}
                  onChange={(e) => setConfirmLoginPassword(e.target.value)}
                />
                <i
                  className={`bi ${
                    confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                  } ${changepasswordstyle.bi_eye_slash}`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleconfirmLoginPasswordVisibility("Confirmshow");
                  }}
                  onMouseLeave={() => {
                    toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                  }}
                ></i>
              </fieldset>
              <span
                class={changepasswordstyle.error_span}
                style={
                  passwordMatch ? { display: "none" } : { display: "inline" }
                }
              >
                Passwords do not match
              </span>

              <span
                class={changepasswordstyle.error_span}
                style={regexPass ? { display: "none" } : { display: "inline" }}
              >
                Password length must be greater than or equal to 8, must contain
                atleast one numeric, alphanumeric, upper-case and lower-case
                alphabet
              </span>
            </div>
            <div class={changepasswordstyle.login_container}>
              <button
                class={changepasswordstyle.sp_login_btn}
                onClick={handleSubmit}
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
        {/* change password modal ends  */}
        {/* Edit modal starts  */}
        <div
          className={studentedit.flex_container_teacherid}
          id={studentedit.modal_teacherid}
          style={showModalEdit ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={studentedit.main_content_teacherid}
            id={studentedit.modal_content_teacherid}
          >
            <div className={studentedit.EditHeadContainer}>
              <h5 className={studentedit.head}>Academic Staff Details</h5>
              <i onClick={handleEditModalcross} class="fa-solid fa-xmark"></i>
            </div>
            <div className={studentedit.EditBox}>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> First Name</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.firstName}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Last Name</label>
                <label className={studentedit.Input}>
                  {teacherIDData.lastName}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Primary Phone no.{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.mobile}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Primary Email</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.email}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Date of Birth</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.dob}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Gender </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.gender}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Date of Joining
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.joinDate}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Previous work experience{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.previousWorkExp}{" "}
                </label>
              </div>
              {teacherIDData.academicStaffProfile === "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Coordinator Type{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.coordinatorType}
                  </label>
                </div>
              )}
              {teacherIDData.academicStaffProfile !== "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Designation{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.designation}
                  </label>
                </div>
              )}

              <div className={studentedit.Edithead} id={studentedit.Addressbox}>
                <label className={studentedit.Input_head}> Address </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.address}{" "}
                </label>
              </div>
            </div>

            <div className={studentedit.modal_buttons_teacherid}>
              <button
                className={studentedit.Edit_btn}
                id={studentedit.Edit_btn_1_teacherid}
                onClick={handleEditModalEdit}
              >
                <i class="fa-solid fa-pen"></i> Edit
              </button>
            </div>
          </div>
        </div>
        {/* Edit modal ends  */}
        {/* share modal starts  */}
        <div
          className={sharestyle.flex_container_teacherid}
          id={sharestyle.modal_teacherid}
          style={showModalShare ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={sharestyle.main_content_teacherid}
            id={sharestyle.modal_content_teacherid}
          >
            <span className={sharestyle.img}>
              <CheckCircleOutlineIcon />
            </span>

            <h5 className={sharestyle.head}>Successfully shared User Id</h5>
            <p className={sharestyle.para}> "{teacherIDData.email}" </p>
            <p
              className={sharestyle.para}
              style={{ marginTop: "8px", fontWeight: "bold" }}
            >
              {" "}
              "{teacherIDData.mobile}"{" "}
            </p>

            <div className={sharestyle.modal_buttons_teacherid}>
              <button
                className={sharestyle.ok_btn}
                id={sharestyle.ok_btn_1_teacherid}
                onClick={handleShareModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        {/* share modal ends */}
      </article>
    </>
  );
};
export default Acadmicid;
