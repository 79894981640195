import React, { useEffect, useState } from "react";
import SchoolDashCss from "./SchoolAdminDash.module.css";
import dashHome from "../../../img/dashHome.svg";
import vector from "../../../img/Vector.png";
import boss from "../../../img/boss 1.png";
import coordination from "../../../img/coordination 1.png";
import teacher from "../../../img/teacher 1.png";
import reading from "../../../img/reading 1.png";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import { useNavigate } from "react-router-dom";

const SchoolAdminDash = () => {
  const token = sessionStorage.getItem("token");
  const [countData, setCountData] = useState({});
  const [overviewData, setOverviewData] = useState([]);
  const [pageSize, setPageSize] = useState("5");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  
  useEffect(() => {
    schoolBranchOverview();
  }, []);

  const schoolId = sessionStorage.getItem("schoolid");
  const userName = sessionStorage.getItem("userName");
  const schoolBranchCount = async () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/counts?schoolId=${schoolId}&administrator=true&userName=${userName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCountData(res?.data.data);
      });
  };

  const schoolBranchOverview = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/schools/over-view/schools-branches?pageNumber=${
          page - 1
        }&pageSize=${pageSize}&sortBy=createdAt&order=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOverviewData(res?.data.data.data);
      });
  };

  useEffect(() => {
    schoolBranchCount();
  }, [schoolId]);

  const useStyles = makeStyles({
    table: {
      "&:last-child th, &:last-child td": {
        borderBottom: 0,
      },
    },
    tableRow: {
      "&:hover": {
        backgroundColor: "#FFF7E5", // Change the background color on hover
      },
    },
  });

  const classes = useStyles();
  return (
    <>
      <div className={SchoolDashCss.mainContainer}>
        {/* <div style={{ paddingBottom: "7px" }}>
          <span>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{
                verticalAlign: "middle",
                marginLeft: "6px",
                color: "rgb(122, 122, 122)",
              }}
            />
          </span>{" "}
          <span className={SchoolDashCss.link_text}>Home</span>{" "}
          <span>
            <i
              style={{ fontSize: "8px", color: "rgb(122, 122, 122)" }}
              className="fa-solid fa-angle-right"
            ></i>
          </span>
          <span>
            <i
              style={{ fontSize: "8px", color: "rgb(122, 122, 122)" }}
              className="fa-solid fa-angle-right"
            ></i>
          </span>{" "}
          
          <a
            style={{ fontSize: "12px", color: "blue", textDecoration: "none" }}
            href=""
          >
            DashBoard
          </a>
         
        </div> */}

<article>
      <div style={{ display: 'flex', position: 'relative',justifyContent:"space-between"  }}>
        <div style={{ display: 'flex', position: 'relative', }}  >
          <div >
            <span
              className={dashboardContentStyles.link_icon}
              style={{ cursor: 'pointer' }}             
            >
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle", marginRight: '5px' }}
              />
            </span>
          </div>
          <div>
            <span
              id="az-small"
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/dashboard/template`)
                
              }}
            >
              Home
            </span>
           
              <span>
                <i className="fa-solid fa-angle-right" />
              </span>
           
           
              <span>
                <i className="fa-solid fa-angle-right" />
              </span>          

           
              <span                
                className={dashboardContentStyles.az_selected_menu_text}              
              >
                DashBoard
              </span>
         
          </div>
        </div>
       
      </div>
    </article >



        <div style={{ display: "flex" }}>
          <h3 style={{ padding: "2px 7px 7px 9px", color: "#2D3962" }}>
            Overview
          </h3>
          <h3
            style={{
              padding: "2px 7px 7px 9px",
              color: "#2D3962",
              paddingLeft: "36%",
            }}
          >
            Graphs
          </h3>
        </div>

        <div className={SchoolDashCss.boardsContainer}>
          <div className={SchoolDashCss.boardBox}>
            <Table size="small" aria-label="a dense table">
              <TableBody>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0",
                      padding: "10px 16px",
                      " @media (max-width: 1440px)": {
                        padding: "6px 16px",
                        borderBottom: "0",
                      },
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#E1E5FF",
                        height: "30px",
                        width: "30px",
                        marginRight: "35px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        style={{ paddingTop: "6px", paddingLeft: "6px" }}
                        src={vector}
                        alt="no img"
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {" "}
                    Number of Branches
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0" }}>
                    -
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {countData.branchCount}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0",
                      padding: "10px 16px",
                      " @media (max-width: 1440px)": {
                        padding: "6px 16px",
                        borderBottom: "0",
                      },
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFE1F6",
                        height: "30px",
                        width: "30px",
                        marginRight: "35px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        style={{ paddingTop: "4px" }}
                        src={boss}
                        alt="no img"
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {" "}
                    Number of Principals
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0" }}>
                    -
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {countData.principalCount}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0",
                      padding: "10px 16px",
                      " @media (max-width: 1440px)": {
                        padding: "6px 16px",
                        borderBottom: "0",
                      },
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFF6E1",
                        height: "30px",
                        width: "30px",
                        marginRight: "35px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        style={{ paddingTop: "4px" }}
                        src={coordination}
                        alt="no img"
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {" "}
                    Number of Coordinators
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0" }}>
                    -
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {countData.coordinatorCount}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0",
                      padding: "10px 16px",
                      " @media (max-width: 1440px)": {
                        padding: "6px 16px",
                        borderBottom: "0",
                      },
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#E7FFE1",
                        height: "30px",
                        width: "30px",
                        marginRight: "35px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        style={{ paddingTop: "4px" }}
                        src={teacher}
                        alt="no img"
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {" "}
                    Number of Teachers
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0" }}>
                    -
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {countData.teacherCount}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0",
                      padding: "10px 16px",
                      " @media (max-width: 1440px)": {
                        padding: "6px 16px",
                        borderBottom: "0",
                      },
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFE1E1",
                        height: "30px",
                        width: "30px",
                        marginRight: "35px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        style={{ paddingTop: "3px" }}
                        src={reading}
                        alt="no img"
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {" "}
                    Number of Students
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0" }}>
                    -
                  </TableCell>
                  <TableCell sx={{ borderBottom: "0" }}>
                    {countData.studentCount}
                  </TableCell>
                </TableRow>
                
                <TableRow sx={{ borderBottom: "0" }}></TableRow>
                {/* Add more rows as needed */}
              </TableBody>
            </Table>
          </div>

          <div className={SchoolDashCss.applicationBox1}>
            <div style={{ color: "#3268E7", padding: "5px" }}>
              {" "}
              Web Application
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "15px" }}>50%</p>{" "}
                <div
                  style={{
                    backgroundColor: "blue",
                    height: "95px",
                    width: "35px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                student
              </p>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "15px" }}>50%</p>{" "}
                <div
                  style={{
                    backgroundColor: "#FA9825",
                    height: "95px",
                    width: "35px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                teacher
              </p>
            </div>
          </div>
          <div className={SchoolDashCss.applicationBox2}>
            <p style={{ color: "#3268E7", padding: "5px" }}>
              {" "}
              Mobile Application
            </p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "15px" }}>50%</p>{" "}
                <div
                  style={{
                    backgroundColor: "blue",
                    height: "95px",
                    width: "35px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                student
              </p>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "15px" }}>50%</p>{" "}
                <div
                  style={{
                    backgroundColor: "#FA9825",
                    height: "95px",
                    width: "35px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                teacher
              </p>
            </div>
          </div>
        </div>

        <h3 style={{ padding: "8px", color: "#2D3962" }}>Branches Overview</h3>
        
        <TableContainer component={Paper} sx={{ height: "191px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  backgroundColor: "#403e75",
                  top: "0",
                 // color: "white"
                }}
              >
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Name of the Branch
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Location of the Branch
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Number of Grades
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Total Students
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Board
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", borderRight: " 1px solid darkgray !important" }}
                >
                  Plan
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overviewData?.map((item) =>
                item.branches?.map((branch,index) => (
                  <TableRow
                    className={classes.tableRow}
                    style={{ backgroundColor: (index % 2 === 0) ? "white" : "#f5f5f8",
                 }}
                    key={branch?.id}
                    sx={{
                      "&:nth-child(even)": {
                        background: "#F5F5F8"
                      }, "&:hover": {
                        background: "#fff !important",
                        border: "2px solid orange"
                      }
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ fontSize: "14px" }}
                    >
                      {branch?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{  fontSize: "14px" }}
                    >
                      {branch?.locality}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{  fontSize: "14px" }}
                    >
                      {branch?.gradeCount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{  fontSize: "14px" }}
                    >
                      {branch?.numberOfStudents}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{  fontSize: "14px" }}
                    >
                      {branch?.board}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{  fontSize: "14px" }}
                    >
                      {branch?.plan}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default SchoolAdminDash;
