import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  fetchPlanDetails,
  fetchEnableAssignBtn,
} from "../../js/Cordinator/assignQuiz/api/fetchCalls";
import {
  isNOTNullOrUndefined,
  isNullOrUndefined,
} from "../../js/TeachValidate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AvidReader = (props) => {
  // const [sortOrder, setSortOrder] = useState(false);
  // const [sortBy, setSortBy] = useState("id");
  const currentYear = new Date().getFullYear();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [avidList, setAvidList] = useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [gradeSection, setGradeSection] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedMonthYr, setSelectedMonthYr] = useState(`JAN-${currentYear}`);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const student_grade = sessionStorage.getItem("gradeName");
  var _month = 3;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder;
    setSortBy(field);
    setSortOrder(!isAsc);
  };

  const handleChangeMonth = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);

    console.log("month >>> ", event.target.value, currentYear);

    switch (month) {
      case 1:
        _month = `JAN-${currentYear}`;
        console.log(">>>>>>>>>>>>>>.", _month);
        setSelectedMonthYr(`JAN-${currentYear}`);

        break;
      case 2:
        _month = `FEB-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 3:
        _month = `MAR-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 4:
        _month = `APR-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 5:
        _month = `MAY-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 6:
        _month = `JUN-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 7:
        _month = `JUL-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 8:
        _month = `AUG-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 9:
        _month = `SEP-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 10:
        _month = `OCT-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 11:
        _month = `NOV-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 12:
        _month = `DEC-${currentYear}`;
        setSelectedMonthYr(_month);

        break;

      default:
        console.log("Unknown month selected");
      // if(_month !=='' && _month.isNOTNullOrUndefined){
      //     fetchAvidList(_month);
      // }
    }
    console.log("selecc >>> ", selectedMonthYr);
  };

  useEffect(() => {
    fetchAvidList();
  }, [
    selectedMonthYr,
    selectedSection,
    selectedGrade,
    selectedMonth,
    sortOrder,
    sortBy,
  ]);

  //   const fetchAvidList = async () => {

  //     //  if (selectedMonth.isNOTNullOrUndefined) {
  //           publishPassportCDN();
  //       //}

  //       setTimeout(() => {

  //       }, 1000);
  //       const response = await axios.get(
  //           `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/getAvidReaderList?gradeId=${selectedGrade}&sectionId=${selectedSection}&month=${selectedMonthYr}`,
  //           {
  //               headers: {
  //                   "Content-Type": "application/json",
  //                   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //               },
  //           }
  //       );
  //       console.log('fetchAvidList >>>>', response?.data?.data.data);
  //       setAvidList(response?.data?.data.data);
  //       //setPassbokkList(response?.data?.data.data);
  //   };

  const fetchAvidList = async () => {
    publishPassportCDN();
    // setTimeout(() => { }, 1000);
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/getAvidReaderList?gradeId=${selectedGrade}&sectionId=${selectedSection}&month=${selectedMonthYr}&branchId=${sessionStorage.getItem("branchId")}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        // console.log("fetchAvidList >>>>", response?.data?.data.data,'  branch',sessionStorage.getItem("branchId"));
        // var currbranch=response?.data?.data?.data?.filter((v) =>v.branchId === sessionStorage.getItem("branchId"))
        let data = response?.data?.data?.data || [];

        const maxSubmissions = Math.max(...data.map(item => item.noOfSubmissions));

        data = data.filter(item => item.noOfSubmissions === maxSubmissions);

        if (sortBy) {
          const isAscending = sortOrder;
          data.sort((a, b) => {
            let valueA = a[sortBy];
            let valueB = b[sortBy];
            if (typeof valueA === "string") {
              valueA = valueA.toLowerCase();
              valueB = valueB.toLowerCase();
            }

            if (valueA < valueB) return isAscending ? -1 : 1;
            if (valueA > valueB) return isAscending ? 1 : -1;
            return 0;
          });
        }
        setAvidList(data);
      } catch (error) {
        console.error("Error fetching Avid Reader List:", error);

      }
    }, 1000);
  };

  /// CDN publish call
  const publishPassportCDN = async () => {
    const payload = {
      month: selectedMonth,
      year: currentYear,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/saveAvidReader`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    // alert(response);
    //console.log("publishSubjectCDN >>> ", response);
  };

  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  //auto populate filters start scripts
  const [isAutoPopulating, setIsAutoPopulating] = useState(false);
  useEffect(() => {
    if (isNOTNullOrUndefined(gradeSection) && gradeSection?.length) {
      startAutoPopulate();
    }
  }, [gradeSection]);

  const startAutoPopulate = async () => {
    fetchPlanDetails();
    fetchEnableAssignBtn();
    setIsAutoPopulating(true);
    setSelectedGrade(gradeSection[0]?.gradeId);
    setSelectedSection(gradeSection[0]?.sections[0]?.sectionId);
    console.log(
      "initial grade & section >>> ",
      gradeSection[0]?.gradeId,
      gradeSection[0]?.sections[0]?.sectionId
    );

    // alert("teach ");
    sessionStorage.setItem("gradeId", gradeSection[0]?.gradeId);
    sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0]?.sectionId);

    setIsAutoPopulating(false);
    // setTimeout(() => {
    //   setIsAutoPopulating(false);
    // }, 1000);
  };

  const fetchGrade = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    var id = sessionStorage.getItem("teacherId");
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/school-strength/${id}`
    if (localStorage.getItem("role") === "TEACHER") {
      id = sessionStorage.getItem("userId");
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/${id}`
    }
    // console.log("URLURL", URL, id)
    if (id && URL) {
      await axios
        .get(
          // `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
          URL,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response?.data?.data[0]?.grades)
          var sectionAvailable = response?.data?.data[0]?.grades;

          if (localStorage.getItem("role") === "TEACHER") {
            var sectionAvailable = response?.data?.data?.access?.grades;
            sectionAvailable = response?.data?.data?.access?.grades?.filter((v) => v.sections?.length > 0)
          }
          setGradeSection(sectionAvailable || []);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    // fetchReadingBookData();
    GetLastModifiedAt(setModifiedDate);
  }, []);

  useEffect(async () => {
    const grade = await fetchGrade();
    const section = await fetchGrade();
    setSectionList(section);
    setGradeList(grade);
  }, []);

  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    sessionStorage.setItem("gradeId", e.target.value);
    setSelectedSection("");
    console.log("grade >>> ", e.target.value);
  };

  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGrade)) {
      const filtered = gradeSection?.find(
        (i) => i.gradeId === selectedGrade
      )?.sections;
      setSectionFilteredList(filtered);
    }
  }, [selectedGrade]);

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
    sessionStorage.setItem("sectionId", event.target.value);
    console.log("section >>> ", event.target.value);
    console.log("selecc >>> ", selectedMonthYr);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div style={{ position: "relative", margin: "14px" }}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              id={`az-breadscrum-home`}
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/TeacherDashboard");
              }}
            >
              Home
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onClosePage();
                // props.pageRefresh();
                // props.useEffect();
              }}
            >
              Reading Passport
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span className={dashboardContentStyles.az_selected_menu_text}>
              Monthly - Avid Reader
            </span>
          </div>

          {/* <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div> */}
        </article>

        <div
          style={{
            marginTop: "30px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedGrade && (
                    <InputLabel
                      id="az-teacher-gardes-select-label"
                      shrink={false}
                    >
                      Grades
                    </InputLabel>
                  )}

                  <Select
                    labelId="az-teacher-gardes-select-label"
                    id="az-teacher-gardes-select"
                    className={
                      selectedGrade
                        ? dashboardContentStyles.az_select_menu_selected
                        : dashboardContentStyles.az_select_menu
                    }
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedGrade ? "orange" : "black",
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "orange",
                        color: "#FFFFFF",
                        // border: "2px solid orange"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                        // border: "2px solid orange"
                      },
                    }}
                    size={"small"}
                    value={selectedGrade}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          // className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.grade
                            .toLowerCase()
                            .replace(/ /g, "_")}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "orange",
                              color: "#FFFFFF",
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: "#fcedc2",
                              color: "#000000",
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: "#fcedc2",
                              color: "#000000",
                            },
                          }}
                        >
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }}
                    className='dropDownLabel'
                    id="az-teacher-gardes-select-label"
                  >Grade
                  </InputLabel>

                  <Select
                    labelId="az-teacher-gardes-select-label"
                    id="az-teacher-gardes-select"
                    input={<OutlinedInput label='Grade' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedGrade}

                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.gradeId}
                          key={item?.gradeId}
                          // className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.grade
                            .toLowerCase()
                            .replace(/ /g, "_")}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          {item.grade}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedSection && (
                    <InputLabel
                      id="az-teacher-section-select-label"
                      shrink={false}
                    >
                      Section
                    </InputLabel>
                  )}
                  <Select
                    labelId="az-teacher-section-select-label"
                    id="az-teacher-section-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "orange",
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                    }}
                    className={
                      selectedSection
                        ? dashboardContentStyles.az_select_menu_selected
                        : dashboardContentStyles.az_select_menu
                    }
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedSection ? "#ffa500" : "#000000",
                    }}
                    size={"small"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec.id}
                            key={sec.id}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.section
                              .toLowerCase()
                              .replace(/ /g, "_")}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "orange",
                                color: "#FFFFFF",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#fcedc2",
                                color: "#000000",
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: "#fcedc2",
                                color: "#000000",
                              },
                            }}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }}
                    className='dropDownLabel'
                    id="az-teacher-section-select-label"
                  >
                    Section
                  </InputLabel>
                  <Select
                    labelId="az-teacher-section-select-label"
                    id="az-teacher-section-select"
                    input={<OutlinedInput label='Section' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon
                        {...props}
                        sx={{ fontSize: "1.5vw" }}
                      />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec?.sectionId}
                            key={sec?.sectionId}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.section
                              .toLowerCase()
                              .replace(/ /g, "_")}`}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            {sec.section}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedMonth && (
                    <InputLabel id="month-select-label" shrink={false}>
                      Months
                    </InputLabel>
                  )}
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedMonth ? "#ffa500" : "#000000",
                    }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                  >
                    {months.map((month) => (
                      <MenuItem
                        key={month.id}
                        value={month.id}
                        id={`az-teacher-monthdropdown-${month.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "orange",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#fcedc2",
                            color: "#000000",
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: "#fcedc2",
                            color: "#000000",
                          },
                        }}
                      >
                        <ListItemText primary={month.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }} className='dropDownLabel'
                    id="month-select-label" >
                    Month
                  </InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    input={<OutlinedInput label='Month ' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                          maxHeight: '190px',
                          '&::-webkit-scrollbar': {
                            width: '2px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px',
                          overflowY: 'auto',
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedMonth}
                    onChange={handleChangeMonth}

                  >
                    {months.map((month) => (
                      <MenuItem
                        key={month.id}
                        value={month.id}
                        id={`az-teacher-monthdropdown-${month.id}`}
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}
                      >
                        {month.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>

        <Paper
          style={{
            marginTop: "105px",
            width: "100%",
            padding: "10px",
          }}
        >
          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
              style={{ marginBottom: "50px" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade

                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Section

                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Student Name
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("studentName")}
                      />
                      {/* <img
                                                style={{
                                                    verticalAlign: "middle",
                                                    marginLeft: "5px",
                                                    paddingLeft: "3px",
                                                    marginBottom: "2px",
                                                }}
                                                className="cursor-pointer"
                                                width="14"
                                                height="15"
                                               // src={sortIcon}
                                                onClick={async () => {
                                                    await setSortOrder(!sortOrder);
                                                    await setSortBy("Review");
                                                    // await initData(!sortOrder, "id");
                                                }}
                                            /> */}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      No Of Submission
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("noOfSubmissions")}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{}}>
                  {avidList && avidList?.length > 0 ? (
                    avidList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((elem, i) => (
                        <TableRow
                          // sx={{
                          //   "&:nth-child(even)": {
                          //     background: "#F5F5F8",
                          //   },
                          //   "&:hover": {
                          //     border: "10px solid red !important",
                          //     background: "#fff !important",
                          //   },
                          // }}
                          sx={{
                            // "&:nth-child(even)": {
                            //     background: "#F5F5F8"
                            // },
                            backgroundColor: i % 2 === 0 ? '#ffffff' : '#f5f5f8',
                            "&:last-child td, &:last-child th": {
                              borderBottom: "none !important",
                            },

                            "&:hover": {

                              "& td, & th": {
                                borderTop: "1px solid #ff9702",
                                borderBottom: "1px solid #ff9702",
                              },
                              " & th:first-of-type": {
                                borderLeft: "1px solid #ff9702",
                              },

                              "& td:first-of-type": {

                                borderLeft: "1px solid #ff9702",

                              },

                              "&:last-child td, &:last-child th": {
                                borderBottom: "1px solid #ff9702 !important",
                              },

                              "& td:last-of-type": {
                                borderRight: "1px solid #ff9702",

                              },

                              "& th:last-of-type": {
                                borderRight: "0",
                              },
                            },

                            "& td, & th": {
                              border: "1px solid transparent",
                              borderTop: "1px solid transparent",
                              borderBottom: "1px solid transparent",
                              "&:not(:last-child)": {
                                borderRight: "1px solid transparent",
                              },
                            },


                          }}

                        >
                          {/* index */}
                          <TableCell className={dashboardContentStyles.table_cell}>
                            {i + 1}
                          </TableCell>
                          {/* index */}
                          <TableCell className={dashboardContentStyles.table_cell}>
                            <Tooltip title={elem.gradeName} arrow>
                              <span>{elem.gradeName}</span>
                            </Tooltip>
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={dashboardContentStyles.table_cell}
                            align="center"
                          >
                            {elem.sectionName}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={dashboardContentStyles.table_cell}
                            align="center"
                          >
                            {elem.studentName}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={dashboardContentStyles.table_cell}
                            align="center"
                          >
                            {elem.noOfSubmissions}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={avidList && avidList?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </Paper>
      </div>
    </>
  );
};

export default AvidReader;