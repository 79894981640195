import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { selectedDashboardData } from "../../../actions/dashboardTableAction";
import report from "../../../img/file.png";
import sortIcon from "../../img/sort_1.png";
import { useNavigate } from "react-router";
import chapterStyles from "../../css/createChapter.module.css";


const ChapterWiseTable = ({userType, tableData, selectedGrade, selectedSubject }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPageOptions] = useState([10, 15, 20]);
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function formatDateToDMY(dateString) {
        const date = new Date(dateString);
    
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
    
        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
    
        return dateString != null && dateString != "" ? formattedDate : '';
    }

    const sortByField = (array, field, order) => {
        if (array?.length > 0) {
            var nullsFirst = false;
            if (order == "asc") {
                nullsFirst = true
            }
            return array.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null) return nullsFirst ? -1 : 1;
                if (b[field] === null) return nullsFirst ? 1 : -1;
    
                if (typeof a[field] === 'string') {
                    if (order === 'asc') {
                        return a[field].localeCompare(b[field]);
                    } else {
                        return b[field].localeCompare(a[field]);
                    }
                } else if (typeof a[field] === 'number' || a[field] instanceof Date) {
                    if (order === 'asc') {
                        return a[field] - b[field];
                    } else {
                        return b[field] - a[field];
                    }
                }
            });
        } else {
            return []
        }
    };


    const isNavigateChapterReport = (item) => {
        console.log("isNavigateChapterReport",item,selectedGrade,selectedSubject)
        navigate("/dashboard/Chapter%20Performance");
        let data = {
            selectedTableGrade: selectedGrade,
            selectedTableSubject: selectedSubject,
            ...item
        }
        dispatch(selectedDashboardData(data))
        sessionStorage.setItem("sideNavMainMenuNames", "Reports");
        sessionStorage.setItem("sideNavSubMenuNames", "Chapter Performance");
    }

    const handleRowsPerPageChange = (e) => {
        setSelectedRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // Sorting Table Functionality Start 

    const requestSort = (key) => {
        console.log("requestsort", key)
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else {
            console.log("need to see here")
        }
        setSortConfig({ key, direction });
    };

    const sortedDataArray = sortByField(userType === "Teacher" ? tableData?.chapters : tableData, sortConfig?.key, sortConfig?.direction);
    console.log("sortedDataArray", sortedDataArray, tableData?.chapters, tableData, sortConfig?.direction)

    // Sorting Table Functionality End 

    const indexOfLastItem = currentPage * selectedRowsPerPage;
    const indexOfFirstItem = indexOfLastItem - selectedRowsPerPage;
    const currentItems = sortedDataArray?.slice(indexOfFirstItem, indexOfLastItem);
    console.log("currentItems",currentItems)
    const nPages = Math.ceil(sortedDataArray?.length / selectedRowsPerPage) || [];

    return <div className="dashoard-table-content">
        {userType && userType === "Teacher" ? <table class="dashboard-table">
            <thead>
                {userType === "Principal" && <th style={{ textAlign: "start" }} onClick={() => requestSort('teacherName')}>Teacher <span><img src={sortIcon} /></span></th>}
                <th style={{ textAlign: "start" }} onClick={() => requestSort('section')}>Section <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('chapter')}>Chapter Name <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('startDate')}>Start Date <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('endDate')}>End Date <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('quizRelease')}>Quiz Release <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('quizAverage')}>Avg Quiz Score <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('attendance')}>Attendance <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('section')}>Reports <span><img src={sortIcon} /></span></th>
            </thead>
            {currentItems && currentItems?.length > 0 ?
                <tbody>
                    {currentItems && currentItems?.map((o, i) => {
                        return (
                            <tr key={i}>
                                {userType === "Principal" && <td style={{ textAlign: "start" }}>{o?.teacherName}</td>}
                                <td style={{ textAlign: "start" }}>{o?.section}</td>
                                <td style={{ textAlign: "start" }}>{o?.chapter}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.startDate)}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.endDate)}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.quizRelease)}</td>
                                <td>{o?.quizRelease != "" && o?.quizRelease != null ? `${o?.quizAverage}%` : "NA"}</td>
                                <td>{o?.quizRelease != "" && o?.quizRelease != null ? `${o?.attendance}%` : "NA"}</td>
                                <td>
                                    <button disabled={o?.quizRelease != "" && o?.quizRelease != null ? false : true} style={{ border: "none", cursor: o?.quizRelease != "" && o?.quizRelease != null ? "pointer" : "no-drop" }} onClick={() => isNavigateChapterReport(o)}>
                                        <img style={{ opacity: o?.quizRelease != "" && o?.quizRelease != null ? "" : "0.3" }} src={report} alt="report-icon" />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody> :
                <tbody>
                    <tr><td colspan="8">No data</td></tr>
                </tbody>}
        </table> : <table class="dashboard-table">
            <thead>
                {
                    console.log("currentItems", currentItems)
                }
                {userType === "Principal" && <th style={{ textAlign: "start" }} onClick={() => requestSort('teacherName')}>Teacher <span><img src={sortIcon} /></span></th>}
                <th style={{ textAlign: "start" }} onClick={() => requestSort('section')}>Section <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('chapter')}>Chapter Name <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('startDate')}>Start Date <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('endDate')}>End Date <span><img src={sortIcon} /></span></th>
                <th style={{ textAlign: "start" }} onClick={() => requestSort('releaseDate')}>Quiz Release <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('avgQuizScore')}>Avg Quiz Score <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('attendance')}>Attendance <span><img src={sortIcon} /></span></th>
                <th onClick={() => requestSort('section')}>Reports <span><img src={sortIcon} /></span></th>
            </thead>
            {
                console.log("currentItems",currentItems)
            }
            {currentItems && currentItems?.length > 0 ?
                <tbody>
                    {currentItems && currentItems?.map((o, i) => {
                        console.log(o, "obj")
                        return (
                            <tr key={i}>
                                {userType === "Principal" && <td style={{ textAlign: "start" }}>{o?.teacherName}</td>}
                                <td style={{ textAlign: "start" }}>{o?.section}</td>
                                <td style={{ textAlign: "start" }}>{o?.chapter}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.startDate)}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.endDate)}</td>
                                <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.releaseDate != null && o?.releaseDate != "" ? o?.releaseDate : '')}</td>
                                <td>{(o?.releaseDate != "" && o?.releaseDate != null) ? `${o?.avgQuizScore}%` : "NA"}</td>
                                <td>{(o?.releaseDate != "" && o?.releaseDate != null) ? `${o?.attendance}%` : "NA"}</td>
                                <td>
                                    <button disabled={o?.releaseDate != "" && o?.releaseDate != null ? false : true} style={{ border: "none", cursor: o?.releaseDate != "" && o?.releaseDate != null ? "pointer" : "no-drop" }} onClick={() => isNavigateChapterReport(o)}>
                                        <img style={{ opacity: o?.releaseDate != "" && o?.releaseDate != null ? "" : "0.3" }} src={report} alt="report-icon" />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody> :
                <tbody>
                    <tr><td colspan="8">No data</td></tr>
                </tbody>}
        </table>}
        {/* pagination container - Start */}
        {currentItems && currentItems.length > 0 && <div className={chapterStyles.pagination} style={{ width: "100%", margin: 0 }}>
            <form>
                <span className={chapterStyles.rows}>
                    <label for="rows">Rows per page</label>
                    <select
                        name="rows"
                        id="rows"
                        onChange={handleRowsPerPageChange}
                        value={selectedRowsPerPage}
                    >
                        {rowsPerPageOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </span>
            </form>
            <span>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </span>
        </div>}
        {/* pagination container - End */}
    </div>
}

export default ChapterWiseTable;



export const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const goToNextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    return (
        <div className="dashboard-pagination">
            <span onClick={goToPrevPage} >&laquo;</span>
            {pageNumbers.map(pgNumber => (<span className={currentPage == pgNumber ? 'active' : ''} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</span>))}
            <span onClick={goToNextPage}>&raquo;</span>
        </div>
    )
}