import React, { useEffect, useState } from "react";
import RevisionAdminBlueprintLayer from "../RevisionAdminBlueprintLayer/RevisionAdminBlueprintLayer";
import AssignAssessmentPaper from "./AssignAssessmentPaper";
import {
  bluePrintsAllGrades,
  bluePrintsAllSubjects,
  bluePrintsBoards,
  bluePrintsGrades,
  bluePrintsSubjects,
  fetchBluePrintLevels,
} from "../../../BluePrint/Apis/BluePrintApis";
import {
  bluePrintAcademicYear,
  bluePrintsPaperList,
  bluePrintsSchools,
  fetchUploadAssessmentForListView,
} from "../Apis/RevisionAdminBluePrintApis";
import "./assignassessment.css";
import BluePrintFilter from "../../../../../components/BluePrintCommonTable/BluePrintFilter";
import { UploadAssessmentTableBody } from "../UploadAssessment/UploadAssessmentTableBody";
import UploadAssessmentTableHead from "../UploadAssessment/UploadAssessmentTableHead";
import { tableHeadAssign } from "../Schema/schema";
import { TablePagination } from "@mui/material";
import AssignAssessmentModal from "./AssignAssessmentModal";
import ConfigurationSuccessModal from "../../../../../components/common/ConfigurationSuccessModal";

export default function AssessmentAssign() {
  const [boardList, setBoardList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paperList, setPaperList] = useState([]);

  const [allGradeList, setAllGradeList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [uploadData, setUploadData] = useState([]);

  const [formSelectedGrade, setFormSelectedGrade] = useState("");
  const [formSelectedBoard, setFormSelectedBoard] = useState("");
  const [formSelectedSubject, setFormSelectedSubject] = useState("");
  const [formSelectedLevel, setFormSelectedLevel] = useState("");
  const [formSelectedAcademicYear, setFormSelectedAcademicYear] = useState("");

  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");

  const [pageSize, setpageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [selected, setSelected] = useState([]);

  const [showAssignModal, setShowAssignModal] = useState(false);

  let initialData = {
    boardId: "",
    gradeId: "",
    subjectId: "",
    blueprintLevelId: "",
    academicYearId: "",
    selectedSchools: [],
  };

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    fetchBoardList();
    fetchLevelList();
    fetchAcademicYearList();
    fetchAllGradeList();
    fetchAllSubjectList();
  }, []);

  useEffect(() => {
    if (formSelectedBoard) fetchGradeList();
  }, [formSelectedBoard]);

  useEffect(() => {
    if (formSelectedBoard && formSelectedGrade) {
      fetchSubjectList();
    }
  }, [formSelectedBoard, formSelectedGrade]);

  useEffect(() => {
    if (
      formSelectedBoard &&
      formSelectedGrade &&
      formSelectedSubject &&
      formSelectedLevel
    ) {
      fetchSchoolList();
    }
  }, [
    formSelectedBoard,
    formSelectedGrade,
    formSelectedSubject,
    formSelectedLevel,
  ]);

  useEffect(() => {
    if (
      formSelectedBoard &&
      formSelectedGrade &&
      formSelectedSubject &&
      formSelectedLevel &&
      formSelectedAcademicYear
    ) {
      fetchPaperList();
    }
  }, [
    formSelectedBoard,
    formSelectedGrade,
    formSelectedSubject,
    formSelectedLevel,
    formSelectedAcademicYear,
  ]);

  const fetchBoardList = async () => {
    const response = await bluePrintsBoards();
    setBoardList(response);
  };

  const fetchGradeList = async () => {
    const response = await bluePrintsGrades(formSelectedBoard);
    setGradeList(response);
  };

  const fetchSubjectList = async () => {
    const response = await bluePrintsSubjects(
      formSelectedBoard,
      formSelectedGrade
    );
    setSubjectList(response);
  };

  const fetchPaperList = async () => {
    const response = await bluePrintsPaperList(
      formSelectedBoard,
      formSelectedGrade,
      formSelectedSubject,
      formSelectedLevel,
      formSelectedAcademicYear
    );
    setPaperList(response);
  };

  const fetchSchoolList = async () => {
    setSelected([]);
    setFormData({
      ...formData,
      selectedSchools: [],
    });
    const response = await bluePrintsSchools(
      formSelectedBoard,
      formSelectedGrade,
      formSelectedSubject,
      formSelectedLevel
    );
    setSchoolList(response);
  };

  const fetchLevelList = async () => {
    const response = await fetchBluePrintLevels();
    setLevelList(response);
  };

  const fetchAcademicYearList = async () => {
    const response = await bluePrintAcademicYear();
    setAcademicYearList(response);
  };

  const fetchAllGradeList = async () => {
    const response = await bluePrintsAllGrades();
    setAllGradeList(response);
  };

  const fetchAllSubjectList = async () => {
    const response = await bluePrintsAllSubjects();
    setAllSubjectList(response);
  };

  const fetchUploadAssessments = async () => {
    let pageNumber = page;
    const response = await fetchUploadAssessmentForListView(
      pageNumber,
      pageSize,
      selectedBoard,
      selectedGrade,
      selectedSubject,
      search
    );
    setUploadData(response?.data);
    setTotalPage(response?.totalPages);
    setTotalElements(response?.totalElements);
    setCurrentPage(response?.pageNumber);
  };

  useEffect(() => {
    fetchUploadAssessments();
  }, [selectedBoard, selectedGrade, selectedSubject, search, pageSize, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowAssignModal(false);
  };
  const onSubmitAssignSuccessfull = () => {
    setShowAssignModal(false);
    setModalMessage("You have successfully Assigned. ");
    setSuccessCreateModal(true);
    setTimeout(() => {
      setSuccessCreateModal(false)
    }, 2000);
  };

  const [modalMessage, setModalMessage] = useState("");
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
    setFormData(initialData);
  };

  return (
    <RevisionAdminBlueprintLayer>
      <div
        className="filter-wrapper"
        style={{ marginTop: "30px", marginBottom: "20px" }}
      >
        <AssignAssessmentPaper
          boardList={boardList}
          gradeList={gradeList}
          subjectList={subjectList}
          levelList={levelList}
          academicYearList={academicYearList}
          schoolList={schoolList}
          paperList={paperList}
          formSelectedBoard={formSelectedBoard}
          setFormSelectedBoard={setFormSelectedBoard}
          formSelectedGrade={formSelectedGrade}
          setFormSelectedGrade={setFormSelectedGrade}
          formSelectedSubject={formSelectedSubject}
          setFormSelectedSubject={setFormSelectedSubject}
          formSelectedLevel={formSelectedLevel}
          setFormSelectedAcademicYear={setFormSelectedAcademicYear}
          setFormSelectedLevel={setFormSelectedLevel}
          setShowAssignModal={setShowAssignModal}
          setSelectedSchool={setSelectedSchool}
          formData={formData}
          setFormData={setFormData}
          setSelected={setSelected}
          selected={selected}
        />
        <BluePrintFilter
          title="Assessment paper"
          selectedBoard={selectedBoard}
          setSelectedBoard={(i) => {
            setPage(0);
            setSelectedBoard(i);
          }}
          selectedGrade={selectedGrade}
          setSelectedGrade={(i) => {
            setPage(0);
            setSelectedGrade(i);
          }}
          selectedSubject={selectedSubject}
          setSelectedSubject={(i) => {
            setPage(0);
            setSelectedSubject(i);
          }}
          search={search}
          setSearch={(i) => {
            setPage(0);
            setSearch(i);
          }}
          gradeList={allGradeList}
          subjectList={allSubjectList}
          boardList={boardList}
        />
        <table className="table-wrapper" style={{ marginTop: "15px" }}>
          <UploadAssessmentTableHead tableHeadData={tableHeadAssign} />
          <UploadAssessmentTableBody
            blueprintsData={uploadData}
            pageSize={pageSize}
            totalElements={totalElements}
            currentPage={currentPage}
            page={"assign_assessment"}
          />
        </table>
        {uploadData?.length > 0 && (
          <div style={{ marginTop: "5px" }}>
            <span>
              <TablePagination
                style={{
                  bottom: "0",
                  width: "100%",
                  backgroundColor: "white",
                }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </span>
          </div>
        )}
        <AssignAssessmentModal
          show={showAssignModal}
          close={handleCloseModal}
          schoolData={selectedSchool}
          paperData={paperList}
          onSubmitAssignSuccessfull={onSubmitAssignSuccessfull}
        />
        <ConfigurationSuccessModal
          userType={modalMessage}
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
      </div>
    </RevisionAdminBlueprintLayer>
  );
}
