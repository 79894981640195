import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import createschool from "./../css/CreateSchool.module.css";
import createBranchModal from "./../css/createBranchModal.module.css";
import tickImage from "./../img/tick-inside-a-circle.svg";
import file_upload_controller_post from "../API_CONTROLLER_3/file_upload_controller_post";
import SchoolControllerGet from "../API/school-controller/User_Schools";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import User_Schools_Addget from "../API/school-controller/User_Schools_addget";
import SchoolController_post from "../API/school-controller/User_School_post";
import City_Controller_getall from "../API_CONTROLLER/City_Controller/City_Controller_getall_school";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import SchoolControllerIdPut from "../API/school-controller/User_School_Id_put";
import Pagination1 from "./pagination1";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import SuccessModal from "../../components/common/SuccessModal";
import sortIcon from "../../../src/pages/img/sort_1.png";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import {
  IconButton,
  TextField,
  Button,
} from "@mui/material";
// input choose file label image
import Cf_img from "../../../src/img/image_icon.png";
import axios from "axios";
import ConfigurationSuccessModal from "../../components/common/ConfigurationSuccessModal";

const CreateSchool = () => {
  // const [phoneNumber, setphoneNumber] = useState("");
  const [boardId, setBoardId] = useState("");
  const navigate = useNavigate();
  const prevLocation = useLocation();
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdatedModal, setIsUpdateModal] = useState(false);
  const [num, setNum] = useState("")
  const [schoolInputs, setSchoolInputs] = useState({
    cityId: "",
    code: "",
    hasBranch: true,
    name: "",
    logoUrl: "",
    pocEmail: "",
    signatoryName: "",
    signatoryRole: "",
    website: "",
  });

  const inititalState = () => {
    setSchoolInputs({
    cityId: "",
    code: "",
    hasBranch: true,
    name: "",
    logoUrl: "",
    pocEmail: "",
    signatoryName: "",
    signatoryRole: "",
    website: "",
    })
  }

  const [selectedImage, setSelectedImage] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const subFolders = "SCHOOL"
  const imageChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    file_upload_controller_post(
      setSelectedImage,
      formData,
      setSchoolInputs,
      schoolInputs, subFolders
    );
  };

  useEffect(() => {
    setSchoolInputs({
      ...schoolInputs,
      logoUrl: sessionStorage.getItem("logoUrl"),
    });

    return () => { };
  }, [sessionStorage.getItem("logoUrl")]);

  const removeSelectedImage = () => {
    setSchoolInputs({
      ...schoolInputs,
      logoUrl: null,
    });
  };
  const [totalele, setTotalelements] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [urlValue, seturlValue] = useState("");
  const [isAddSchool, setIsAddSchool] = useState(true);
  const [search, setSearch] = useState({
    search: "",
  });

  
  useEffect(() => {
    if (prevLocation.state === "edit") {
      setIsAddSchool(false);
  
      setSchoolInputs({
        ...schoolInputs,
        logoUrl: sessionStorage.getItem("logoUrl"),
      });
      prevLocation.state = null;
    }else if (prevLocation.state !== "edit") {
      sessionStorage.removeItem("logoUrl");
      prevLocation.state = null;
    }
  }, []);

  const handlerSchoolInputs = (e) => {
    e.preventDefault();
    setSchoolInputs({
      ...schoolInputs,
      [e.target.name]: e.target.value,
    });
    // }
  };
  const handleKeyPress = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setNum(result);
  };

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }
  function validateUrl(urlValue) {
    const regex = /\S+\.\S+/;
    return regex.test(urlValue);
  }
  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }
  const handleCreateSchool = (e) => {
    e.preventDefault();
    for (const input in schoolInputs) {
      if (schoolInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all the required values");
        return;
      }
    }

    // if (!validateEmail(schoolInputs.pocEmail)) {
    //   setIsError("true");
    //   setShowError("Please enter a valid email address");
    //   return;
    // }

    // other validation condtions
    if (!validateMobile(num)) {
      setIsError(true);
      setShowError("Phone number invalid");
      return;
    }
    if (!validateEmail(schoolInputs.pocEmail)) {
      setIsError("true");
      setShowError("Please enter a valid email address");
      return;
    }
    if (!validateUrl(schoolInputs.website)) {
      setIsError("true");
      setShowError("Please enter a valid url");
      return;
    }

    if (!schoolInputs.logoUrl) {
      setIsError("true");
      setShowError("Please upload Logo Image");
      return;
    }
    setIsError(false);
    // setShowModal(true);

    SchoolController_post(schoolInputs, num).then((response) => {

      if (response.status == 200) {
        setSuccessModal(true);
        sessionStorage.setItem("schoolid", response.data.data.id)
        inititalState()
      } else if (response.status != 200) {
        setIsError("true");
        setShowError(
          response?.data?.message
            ? response.data.message
            : "Failed To Add a School"
        );
        return false;
      }
    });
  };
  const closeSuccessModal = () => {
    setSuccessModal(false);
    navigate("/dashboard/Registered%20Schools");
  };

  const closeSuccessModalBranch = () => {
    setSuccessModal(false);
    navigate("/dashboard/addbranch");
  };
  const [isSearch, setisSearch] = useState(true);
  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools?pageNumber=0&pageSize=100&sortBy=${sortBy || "id"}&order=${sortOrder || false}&search=${search.search}&boardId=${boardId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools?pageNumber=0&pageSize=${totlaele}&sortBy=${sortBy || "id"}&order=${sortOrder || false}&search=${search.search}&boardId=${boardId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data
    setTotalelements(totalData);
  }

  const handleCreateBranch = (e) => {
    navigate("/dashboard/addbranch");
    var schoolid = success.data.id;
    sessionStorage.setItem("schoolid", schoolid);
  };
  const handleCreateBranchNotNow = (e) => {
    // updateSchoolList(e, schoolInputs);
    setShowModal(false);
    navigate("/dashboard/Registered Schools");
  };

  // search starts
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");

  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    User_Schools_Addget(
      rowsInput,
      setSchoolListData,
      setPageDetail,
      page,
      search
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.name?.toLowerCase().includes(inputValue);
      });
      setSchoolListData(result)
    } else {
      User_Schools_Addget(
        rowsInput,
        setSchoolListData,
        setPageDetail,
        page,
        search
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };

  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const [pageDetail, setPageDetail] = useState({});
  const [schoolListData, setSchoolListData] = useState({});
  const [success, setSuccess] = useState({});
  const [cityName, setCityName] = useState({});

  useEffect(() => {
    City_Controller_getall(setCityName);
    if (isAddSchool === true) {
      SchoolControllerIdGet(setSchoolInputs);
    }
    return () => { };
  }, []);

  useEffect(() => {
    User_Schools_Addget(
      rowsInput,
      setSchoolListData,
      setPageDetail,
      page,
      search
    );

    return () => { };
  }, [rowsInput, page]);

  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const [selectedCode, setSelectedCode] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const onCloseUpdateModal = () => {
    setIsUpdateModal(false)
    navigate("/dashboard/addbranch");
  }

  const callback = (res) => {
    if(res.status === 200)
      setIsUpdateModal(true)
  }

  return (
    <>
      {/* <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>{" "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>{" "}
          <span className={dashboardContentStyles.link_text}>
            Registered Schools
          </span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>{" "}
          <a>Add New School</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article> */}
      <div className="az_home_navigation_section">
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <p className="az_navigation_text"><span style={{ position: "relative" }}>
              <img style={{ position: "absolute", top: "1px" }}
                src={dashHome}
                alt="no img"
                width="18px"
                height="18px"

              /> </span>
              <span className="az_navigation_content" style={{ marginLeft: "20px" }}>Home</span>
            </p>
          </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p className="az_navigation_text"><span>Registered Schools</span></p>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p><span style={{ color: "#ffa500" }}>Add New School</span></p>
        </div>
        <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
      </div>

      <article style={{ paddingTop: "10px" }} className={createschool.add_schools}>
        <div className={createschool.add_school_title}>
          <span className={createschool.link_icon}>
            <i class="fas fa-university"></i>
          </span>
          <span> Add New School</span>
        </div>
        <form
          className={createschool.add_schools_form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={createschool.input_grid}>
            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="text"
                name="name"
                id="az-super-admin-add-school-text-input-schoolName"
                Value={schoolInputs.name}
                onChange={handlerSchoolInputs}
                placeholder=" "
                autocomplete="none"
              />
              {/* <label style={{ fontWeight: '300' }} for="name">*School Name</label> */}
              <label for="az-super-admin-add-school-text-input-schoolName">*School Name</label>
            </div>

            <div style={{ width: "100% !important" }} className={createschool.input_element}>
              <select
                className={createschool.form_select_element + ' ' + 'az_select'}
                name="cityId"
                id="cityName"
                value={schoolInputs.cityId}
                onChange={handlerSchoolInputs}
                placeholder=" "
              >
                <option className={createschool.option} value="">
                  Select
                </option>
                {cityName.length > 0 ? (
                  cityName.map((cityName, index) => {
                    return (
                      <option
                        className={createschool.option}
                        Value={cityName.id}
                        id={cityName.id}
                        placeholder=" "
                      >
                        {cityName.city}
                      </option>
                    );
                  })
                ) : (
                  <option className={createschool.option} value="">
                    Select
                  </option>
                )}
              </select>

              {/* <label style={{ fontWeight: '300' }} for="cityName">*City Name</label> */}
              <label
                for="cityName"
                className={
                  schoolInputs.cityId === "" ? createschool.std_cat : "NONE"
                }
              >
                *City Name
              </label>
              <label
                for="cityName"
                className={
                  schoolInputs.cityId !== "" ? createschool.std_cat : "NONE"
                }
              >
                *City Name
              </label>
            </div>
            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="email"
                name="pocEmail"
                id="az-super-admin-add-school-text-input-pocEmail"
                Value={schoolInputs.pocEmail}
                onChange={handlerSchoolInputs}
                placeholder=" "
                autocomplete="none"
              />
              {/* <label style={{ fontWeight: '300' }} for="pocEmail">*POC Email</label> */}
              <label for="az-super-admin-add-school-text-input-pocEmail">*POC Email</label>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <select
                style={{ width: "60px", height: "48px", borderRadius: "8px" }}
                value={selectedCode}
                onChange={handleCodeChange}
                className="az_phone_code"
              >
                <option value="+1">+91</option>
                <option value="+44">
                  +44
                </option>
                <option value="+91">+1</option>
              </select>

            <div className={createschool.input_element}>
            {/* <span  style={{position:"absolute",top:"10px",left:"-32px"}}>+91</span> */}
            <input
												style={{ width: "210px" }}
												className={
													createschool.form_input_element + ' ' + 'az_text_input'
												}
												type="text"
												name="name"
												id="name"
												value={num}
                        maxLength={10}
												placeholder=" "
												onChange={(e) =>
                          handleKeyPress(e)
												}
											/>

                <label htmlFor="name">
                  *Phone-Number
                </label>

              </div></div>

            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="url"
                name="website"
                id="az-super-admin-add-school-text-input-website"
                Value={schoolInputs.website}
                placeholder=" "
                autocomplete="none"
                onChange={handlerSchoolInputs}
              />
              {/* <label style={{ fontWeight: '300' }} for="website">*Website</label> */}
              <label for="az-super-admin-add-school-text-input-website">*Website</label>
            </div>

            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="text"
                name="signatoryName"
                id="az-super-admin-add-school-text-input-signatoryName"
                Value={schoolInputs.signatoryName}
                placeholder=" "
                autocomplete="none"
                onChange={handlerSchoolInputs}
              />
              {/* <label style={{ fontWeight: '300' }} for="signatureName">*Signature Name</label> */}
              <label for="az-super-admin-add-school-text-input-signatureName">*Signature Name</label>
            </div>

            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="text"
                name="signatoryRole"
                id="az-super-admin-add-school-text-input-signatoryRole"
                Value={schoolInputs.signatoryRole}
                onChange={handlerSchoolInputs}
                placeholder=" "
                autocomplete="none"
              />
              {/* <label style={{ fontWeight: '300' }} for="signatureRole">*Signature Role</label> */}
              <label for="az-super-admin-add-school-text-input-signatureRole">*Signature Role</label>
            </div>

            <div className={createschool.input_element}>
              <input
                className={createschool.form_input_element + ' ' + 'az_text_input'}
                type="text"
                name="code"
                id="az-super-admin-add-school-text-input-code"
                Value={schoolInputs.code}
                onChange={handlerSchoolInputs}
                placeholder=" "
                autocomplete="none"
              />
              {/* <label style={{ fontWeight: '300' }} for="code">*code</label> */}
              <label for="az-super-admin-add-school-text-input-code">*code</label>
            </div>
          </div>

          <div className={createschool.add_schools_btn}>
            <div className={createschool.upload_logo_container}>
              {/* <span style={{ marginLeft: "40px" }}>*Upload Logo</span> */}
              <span>*Upload Logo</span>
              <span className={createschool.custom_file_container}>
                <input
                  type="file"
                  name="logoUrl"
                  // id={sessionStorage.getItem("logoUrl")}
                  // filename={sessionStorage.getItem("logoUrl")}
                  Value={schoolInputs.logoUrl}
                  // onError={setIsError(true)}
                  onChange={imageChange}
                  className={createschool.custom_file_input + ' ' + 'az_text_input'}
                  style={{ display: "none" }}
                  id="az-super-admin-add-school-text-input-logoUrl"
                />

                <div className={createschool.chooseFile_btn_wrapper}>
                  <img
                    src={Cf_img}
                    style={{ filter: "brightness(0) invert(1)" }}
                    alt="img"
                  />
                  <label
                    htmlFor="az-super-admin-add-school-text-input-logoUrl"
                    className={createschool.chooseFile_btn}
                  >
                    Choose File
                  </label>
                </div>

                {schoolInputs.logoUrl && (
                  <span>
                    <img
                      src={schoolInputs.logoUrl}
                      alt="Thumb"
                      width="67"
                      height="67"
                    />
                    <span>
                      <a>
                        {schoolInputs.logoUrl.substring(
                          schoolInputs.logoUrl.lastIndexOf("_") + 1,
                          schoolInputs.logoUrl.length
                        )}
                      </a>
                      <br />
                      <p className="az_cursor_pointer"
                        onClick={removeSelectedImage}
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          paddingTop: "10px",
                        }}
                      >
                        Remove
                      </p>
                    </span>
                  </span>
                )}
              </span>
            </div>

            <div
              className={createschool.error_box}
              style={
                isError ? { visibility: "visible" } : { visibility: "hidden" }
              }
            >
              {showError}
            </div>
            <div className="az_create_school_button_container" style={{ display: "flex !important", alignItems: "center !important", gap: "20px" }}>
              {isAddSchool ? (
                <HoverGreyCancelButton
                  variant="outlined"
                  className={createschool.artical_button_cancel}
                  id={createschool.button_id_cancel}
                  onClick={(e) => {
                    navigate("/dashboard/addbranch");
                  }}
                >
                  CANCEL
                </HoverGreyCancelButton>
              ) : (
                <HoverGreyCancelButton
                  variant="outlined"
                  className={createschool.artical_button_cancel}
                  id={createschool.button_id_cancel}
                  onClick={(e) => {
                    navigate("/dashboard/Registered%20Schools");
                  }}
                >
                  CANCEL
                </HoverGreyCancelButton>
              )}
              {
                console.log("isAddSchool", isAddSchool)
              }
              {isAddSchool ? (
                <Button
                  sx={{
                    backgroundColor: "#403E75 !important",
                    color: "#FFFFFF",
                    width: "150px",
                    fontSize: "14px",
                    borderRadius: "150px",
                    "&:hover": {
                      backgroundColor: "#2A2859 !important",
                    }
                  }}
                  id="az-super-admin-add-school-save-btn"
                  onClick={(e) => {
                    SchoolControllerIdPut(schoolInputs, num, callback);
                  }}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  sx={{
                    backgroundColor: "#403E75 !important",
                    color: "#FFFFFF",
                    width: "150px",
                    fontSize: "14px",
                    borderRadius: "150px",
                    "&:hover": {
                      backgroundColor: "#2A2859 !important",
                    }
                  }}
                  id="az-super-admin-add-school-create-btn"
                  onClick={handleCreateSchool}
                >
                  CREATE SCHOOL
                </Button>
              )}
            </div>
          </div>
        </form>

        <div className={createschool.school_list_container}>
          <span className={createschool.school_list}>
            <i class="fas fa-school"></i>
            <span> School List</span>
          </span>

          <div>
            <TextField
              onChange={handleInputChange}
              type={"text"}
              value={query}
              placeholder={"Search anything..."}
              sx={{
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  fontFamily: "Poppins !important",
                  fontSize: "13px",
                  marginLeft: "30px !important",
                  background: "#FFFFFF",
                  opacity: 1,
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {showClear && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon
                          style={{ color: "#F05262", fontSize: "1vw" }}
                        />
                      </IconButton>
                    )}
                    {!showClear && (
                      <IconButton>
                        <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          </div>
        </div>
        <div className={createschool.school_list_table}>
          <table className={createschool.table + ' ' + 'az_table'}>
            <thead className="az_table_head">
              <tr>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  #
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  <div >
                    Schools Name{" "}
                    <img
                      style={{ verticalAlign: "middle", marginBottom: "1px" }}
                      width="12"
                      height="15"
                      className="cursor-pointer"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("name");
                        await SchoolControllerGet(
                          rowsInput,
                          setSchoolListData,
                          setPageDetail,
                          page,
                          boardId,
                          search,
                          "name",
                          !sortOrder
                        );
                      }}
                    />
                  </div>
                </th>

                <th scope="col" className={dashboardContentStyles.table_head}>
                  Board{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("boardList");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "boardList",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branches{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfBranches");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfBranches",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Active
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfCoordinators");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "active",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Coordinator{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfCoordinators");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfCoordinators",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Teachers{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  principals{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfStudents");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Student{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    width="12"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfTeachers");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfTeachers",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Action
                </th>
              </tr>
            </thead>

            <tbody className={createschool.tbody + ' ' + "az_table_body"}>
              {schoolListData.length > 0 ? (
                schoolListData.map((schoolListData, index) => {
                  return (
                    <tr className={createschool.data_row} key={index}>
                      <td className={dashboardContentStyles.table_cell}>
                        {index + 1}
                      </td>
                      <td
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/dashboard/addBranch");

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                        }}
                      >
                        <div className={dashboardContentStyles.table_celll}>
                          {schoolListData.name}
                        </div>
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.board}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.numberOfBranches}
                      </td>
                      <td>
                        <label className={createschool.active_switch}>
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            value=""
                            checked={schoolListData.active}
                          />
                          <span className={createschool.active_slider}></span>
                        </label>
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.numberOfCoordinators}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.numberOfTeachers}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.numberOfPrincipals}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {schoolListData.numberOfStudents}
                      </td>
                      <td className={createschool.create_branch}>
                        <input
                          type="submit"
                          value="Create Branch"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/dashboard/addBranch");
                            var schoolid = schoolListData.id;
                            sessionStorage.setItem("schoolid", schoolid);
                          }}
                          className={createschool.create_branch_btn}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{
                      height: "23rem",
                    }}
                  >
                    NO DATA AVAILABLE
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* pagination container - Start */}
        <div className={createschool.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={createschool.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>

        {/* pagination container - End */}
      </article>
      {/* modal Begin */}
      <div
        className={createBranchModal.flex_container}
        id={createBranchModal.modal}
        style={showModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={createBranchModal.main_content}
          id={createBranchModal.modal_content}
        >
          <span>
            <img src={tickImage} alt="red checkmark" />
          </span>
          <p>School Successfully added</p>
          <h5>Do You Want to Create School Branch?</h5>
          <div className={createBranchModal.modal_buttons}>
            <button
              className={createBranchModal.choosefile_button_1}
              id={createBranchModal.choose_button_id_1}
              onClick={handleCreateBranchNotNow}
            >
              NOT NOW
            </button>
            <button
              className={createBranchModal.choosefile_button_2}
              id={createBranchModal.choose_button_id_2}
              onClick={handleCreateBranch}
            >
              CREATE BRANCH
            </button>
          </div>
        </div>
      </div>
      {/* modal End */}
      <SuccessModal
        userType="School"
        open={successModal}
        close={closeSuccessModal}
        handleOk={closeSuccessModalBranch}
      />
      <ConfigurationSuccessModal 
        userType={"School Successfully Updated"}
        open={isUpdatedModal}
        handleOk={onCloseUpdateModal}/>
    </>
  );
};

export default CreateSchool;
