import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TextField,
  TablePagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UpdatedModal from "./UpdatedModal";
import { SearchOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import "../css/switch.css";
import axios from "axios";
import DeleteModal from "./DeleteModal";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import sortIcon from "../../../src/pages/img/sort_1.png";
import dashHome from "../../../src/img/dashHome.svg";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";

const AdminRole = () => {
  const token = sessionStorage.getItem("token");
  const [addAdminVisible, setAddAdminVisible] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [title, setTitle] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modifiedDate, setModifiedDate] = useState({});
  const [item, setItem] = useState();
  const [roleDetail, setRoleDetail] = useState({
    role: "",
    hasRoleError: false,
    roleErrorMessage: "",
  });
  const [updatedRoleId, setUpdatedRoleID] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate();

  // Mui TextField Hover Style
  const [aRLabelHovered, setARLabelHovered] = useState(false);

  const handleLabelMouseEnterAr = () => {
    setARLabelHovered(true);
  };
  const handleLabelMouseLeaveAr = () => {
    setARLabelHovered(false);
  };

  const handleOpen = async (row) => {
    setOpen(true);
    setItem(row);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/confirmation-api?id=${row.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage({ discription: response?.data?.data?.message });
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const changeHandler = (e) => {
    let value = e.target.value;
    let hasRoleError = false;
    let roleErrorMessage = "";

    if (/^[A-Z]+(?:_[A-Z]+)*$/.test(e.target.value)) {
      value = e.target.value;
    } else {
      hasRoleError = true;
      roleErrorMessage = "Please Enter Valid Value For example ASDF_SFGSFGS";
    }
    setRoleDetail({ role: value, hasRoleError, roleErrorMessage });
  };
  const updatedRoleHandler = (item) => {
    setIsEdit(true);
    setUpdatedRoleID(item?.id);
    setRoleDetail({ role: item?.role });
    setAddAdminVisible(true);
  };
  const fetchData = async (sortOrder, sortBy) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/page?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUpdatedData(response?.data?.data?.data);
  };
  useEffect(() => {
    fetchData(sortOrder);
  }, []);
  const [updatedName, setUpdatedName] = useState("")
  const addAdminRoleHandler = async () => {
    if (!roleDetail.role) {
      setRoleDetail({
        role: "",
        hasRoleError: true,
        roleErrorMessage: "Role is Required",
      });
      return;
    }

    const newValue = {
      discription: roleDetail.role,
      role: roleDetail.role.toUpperCase(),
    };
    setUpdatedName(roleDetail.role.toUpperCase())
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
          setOpenModal(true)
          console.log(response.data.success, "success")
        }
      })
      .catch(function (error) {
        setRoleDetail({
          role: "",
          hasRoleError: true,
          roleErrorMessage: error?.response?.data?.message
            ? error.response.data.message
            : "Role is Required",
        });
      });
    setAddAdminVisible(false);
    setRoleDetail("");
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.role?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
    setPage(0);
  };

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
        }
      })
      .catch((err) => console.log(err));
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setFilterValue(clone);
            handleConfirmationClose();
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/${item?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== item?.id);
          setFilterValue(find);
        }
        setOpen(false);
      })
      .catch(function (response) { });
  };
  const editHandler = (id) => {
    if (!roleDetail.role) {
      setRoleDetail({
        role: "",
        hasRoleError: true,
        roleErrorMessage: "Role is Required",
      });
      return;
    }
    const updatedValue = {
      discription: roleDetail.role,
      role: roleDetail.role,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          find.role = roleDetail?.role;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          setAddAdminVisible(false);
          setIsEdit(false);
          setSuccesUpdatesModal(true);
          setUpdatedName(roleDetail.role.toUpperCase())
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };


  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const closeUpdateModal = () => {
    setSuccesUpdatesModal(false)
  }

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          {/* <a>Admin Role</a> */}
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Admin Role
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addAdminVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Admin Role" : "Add Admin Role"}
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "270px",
                      height: "48px",
                    }}
                  >
                    <TextField
                      // Hover effect
                      onMouseEnter={handleLabelMouseEnterAr}
                      onMouseLeave={handleLabelMouseLeaveAr}
                      InputLabelProps={{ shrink: aRLabelHovered || Boolean.value }}
                      //-----------------------------------------------------------
                      sx={aRLabelHovered === false ? {
                        "& .MuiInputLabel-root": {
                          color: "#B5B6B6 !important",
                          // fontSize: "14px/21px !important",
                          fontSize: "14px !important",
                        },
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "150px !important",



                        },
                      } : {
                        "& .MuiInputLabel-root": {
                          color: "#FD8C00 !important",
                          // fontSize: "14px/21px !important",
                          fontSize: "19px !important",
                          fontWeight: "bolder"
                        },
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "150px !important",
                        },

                        "& .MuiInputBase-input": {
                          color: "#ffa500",

                        }
                      }}
                      id="demo-helper-text-aligned-no-helper"
                      label="*Admin Role"
                      autoComplete="off"
                      value={roleDetail.role}
                      onChange={(e) => changeHandler(e)}

                    />
                    {roleDetail?.hasRoleError && (
                      <span style={{ color: "red" }}>
                        {roleDetail.roleErrorMessage}
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        setAddAdminVisible(false);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton onClick={() => editHandler(updatedRoleId)}>
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton
                        onClick={() => addAdminRoleHandler()}
                        disabled={roleDetail.hasRoleError}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ManageAccountsOutlinedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.2vw",
                  fontWeight: "600",
                }}
              >
                Admin Role
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                  },
                }}
                onChange={onSearch}
                style={{ fontSize: "1vw" }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addAdminVisible && (
                <GreenAddButton
                  onClick={() => {
                    setRoleDetail("");
                    setAddAdminVisible(true);
                  }}
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      marginRight: "10px",
                      marginBottom: "2px",
                      fontSize: "medium",
                    }}
                  />
                  Add Admin Role
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper sx={{ width: "81vw", overflow: "hidden" }}>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        #
                        {/* <img
                        className="cursor-pointer"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("id")
                          await fetchData(!sortOrder,"id");
                        }}
                        /> */}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ alignItems: "center", display: "flex" }}>
                        Admin Role
                        <img
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          style={{ margin: "0 0 1px 3px" }}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("role");
                            await fetchData(!sortOrder, "role");
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{ paddingRight: "56px" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}
                        className={dashboardContentStyles.table_rw}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={dashboardContentStyles.table_cell}
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cellll}
                        >
                          {row?.role}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <Switch
                              checked={row?.active}
                              onChange={(e) => switchHandler(row?.id, e)}
                            />
                          </div>
                        </TableCell>
                        <TableCell

                          align="right"
                          sx={{ paddingRight: '60px' }}
                        // sx={{display:"flex",justifyContent:"flex-end",marginRight:"50px"}}

                        >
                          <EditIconButton
                            onClick={() => updatedRoleHandler(row)}
                          >
                            <Tooltip title="Edit" placement={"top"}
                             
                                  arrow
                                 
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: 'arrow',
                                        options: {
                                          element: '[data-popper-arrow]',
                                        },
                                      },
                                      {
                                        name: 'offset',
                                        options: {
                                          offset: [0, -16],
                                        },
                                      }
                                    ],
                                  }}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: '#ffa500', 
                                        color: 'white',
                                        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)', 
                                        fontSize: '10px', 
                                        fontWeight: 500,
                                        padding: '9px 20px', 
                                        borderRadius: '5px', 
                                      },
                                    },
                                    arrow: {
                                      sx: {
                                        color: '#ffa500', 
                                      },
                                    },
                                  }}
                            >
                              {/* <EditOutlinedIcon style={{ fontSize: "1.7vw" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Edit}
                                alt="Edit-Btn"
                              />
                            </Tooltip>
                          </EditIconButton>
                          {/* <DeleteIconButton onClick={() => handleOpen(row)}>
                            <Tooltip title="Remove" placement={"top"}> */}
                          {/* <DeleteOutlinedIcon style={{ fontSize: "1.7vw" }}/> */}
                          {/* <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Delete}
                                alt="Dlt-Btn"
                              />
                            </Tooltip>
                          </DeleteIconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "81vw",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <DeleteModal
          open={open}
          close={handleClose}
          title={message}
          deleteTitle={"Admin Role"}
          deleteHandler={deleteHandler}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />

        <Success_OK_Modal
          userType={"Admin Role"}
          userName_Modal={
            updatedName}
          open={openModal}
          // close={closeSuccessModal}
          // addedItem={}
          handleOk={closeModal}
        />

        <UpdatedModal
          userType={"Admin Role"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeUpdateModal}
          close={closeUpdateModal}
        />

      </div>
    </>
  );
};
export default AdminRole;
