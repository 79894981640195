import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

// Css
import "../../css/Student/Student_MC_TopBar.css";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
//Icons & Images Used
import hand_icn from "../../../../src/img/hand.png";
import bell_icn from "../../../../src/img/bell.svg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import NotificationList from "../NotificationList";
import { Popover } from 'antd'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import mainContainerStyles from '../../css/mainContainer.module.css'

// const styles = StyleSheet.create({
//   list: {
//     marginTop: 20,
//   },
//   friend: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//   },
//   avatar: {
//     margin: 10,
//     width: 50,
//     height: 50,
//     borderRadius: 25,
//   },
//   name: {
//     fontSize: 18,
//     color: '#000',
//   }
// });

function Student_MC_TopBar({ anchorEl, firstName, lastName }) {

  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openNotification, setOpenNotification] = useState(false)

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const Role = localStorage.getItem("role");
  console.log('>>', Role);

  const [items, setItems] = useState([
    {
      "id": 1,
      "name": "user 1",
      "message": "user one details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {
      "id": 2,
      "name": "user 2",
      "message": "user two details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {
      "id": 3,
      "name": "user 3",
      "message": "user three details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {
      "id": 4,
      "name": "user 4",
      "message": "user four details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {

      "id": 5,
      "name": "user 5",
      "message": "user five details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {

      "id": 6,
      "name": "user 6",
      "message": "user six details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {

      "id": 6,
      "name": "user 6",
      "message": "user six details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {

      "id": 6,
      "name": "user 6",
      "message": "user six details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    },
    {

      "id": 6,
      "name": "user 6",
      "message": "user six details",
      "image": "https://picsum.photos/seed/picsum/200/300",
      "created_at": new Date()
    }
  ]);

  const getTextShortForm = (text) => {
    const words = text.split("_");
    if (words.length === 1) {
      return words[0][0];
    } else {
      return words[0][0] + words[1][0];
    }
  };

  const handleClick2 = () => {
    // Handle click logic here
    // alert("Badge clicked!");
    setShowNotificationDialog(true);
    setOpenModel(true);
  };

  const handleClose = () => {
    setShowNotificationDialog(false);
    setOpenModel(false);
  };


  const handleNotification = () => {
    console.log("notification click");
    setOpenNotification(!openNotification)
  }

  return (
    <>
      <h3 className="head_title">

        {/* Welcome to {firstName} {lastName}
        <img className="handWaveImg" src={hand_icn} />
        <p className="subHead_title">
          Here's what happened with your learning system
        </p> */}
      </h3>
      <div className="profileSection"
        sx={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <div className="std_badge" onClick={handleClick2}>
          <img src={bell_icn} />
        </div> */}

        <Popover
              content={<NotificationList/>}
              placement="bottom"
              title=""
              trigger="click"
              open={openNotification}
              onOpenChange={handleNotification}
            >

            <div className={mainContainerStyles.teacher_badge}>
              <Badge
                variant='dot'
                sx={{ color: '#403b97', fontSize: '1.5vw' }}
                color={'secondary'}
                id="notification-badge"
              >
                <NotificationsNoneOutlinedIcon style={{ fontSize: '1.5vw' }} />
              </Badge>
            </div>
            </Popover>

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={() => {
            navigate("/dashboard/Student_StudentProfile");
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: "10px",
            padding: "10px",
          }}
        >
          <Avatar
            sx={{
              background: "#fd8c00",
              width: "2.8vw",
              height: "2.8vw",
              fontSize: "1.4vw",
            }}
          >
            {firstName[0]?.toUpperCase()}
          </Avatar>
          <div className="profile_Namee">
            <div className="role_std">
              Welcome
            </div>
            <div className="std_name">
              {firstName} {lastName} {"(" + getTextShortForm(Role) + ")"}
            </div>

          </div>

          <div className="down_Arrow">
            <div class="triangle-bottom"></div>
            {/* <i className="fas fa-chevron-down"  style={{ fontSize: "1.5vw", color: "white" }}></i> */}
            {/* <div>
              <KeyboardArrowDownOutlinedIcon
                style={{ fontSize: "1.5vw", color: "black" }}
              />
            </div> */}
            <div />
          </div>
        </Button>
      </div>
      {
        showNotificationDialog &&
        (


          <Dialog
            open={openModel}
            onClose={handleClose}
            fullWidth={true}
            Width="400px"
            style={{ height: '70vh', marginTop: '80px', }}
            PaperProps={{
              sx: {
                borderRadius: '10px', // Set curved border
                // width: '1200px', // Set width
                // maxWidth: 'none', // Prevent dialog from being stretched to fit content
              }
            }}
          >
            <DialogTitle>Dialog Title</DialogTitle>
            {items.map((item) => (
            <DialogContent key={item.id} style={{width:'100%'}}>
              <div className="dropdown-menu" style={{ width: '100%' }}>
                
                  <ul style={{ listStyle: 'none' }}>
                    <li key={item.id} style={{ margin: '10px 10px', display:'flex',justifyContent:'space-between'}}>
                    <img className="avatar" style={{ width: '50px', height: '50px', margin: '10px', borderRadius: '150px' }} src={item.image} alt={`${item.name}`} />
                      <div style={{ marginBottom: '10px', width:'100%' }}>
                        {/* Assuming item.message contains different content */}
                        <span>{`${item.name} `}</span>
                        <span style={{display:'block'}}>{`${item.message} `}</span>
                      </div>
                      {/* Uncomment the following if you want to include the image */}
                      {/*  */}
                    </li>
                    {/* <div key={item.id} className='drop-item'>
                    <img className='icon' src={item.image} alt="" />
                    <div className='text'>
                      <p>{item.name}</p>
                      <div className='small'>{item.message}</div>
                    </div>
                    <p className='time'>March 2024</p>
                  </div> */}
                  </ul>
                
              </div>
            </DialogContent>
            ))}
            <DialogActions>
              {/* Add any actions here */}
            </DialogActions>
          </Dialog>

        )
      }
    </>
  );
}

export default Student_MC_TopBar;
