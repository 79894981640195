import React, { useContext, useEffect, useState } from "react";
import mainContainerStyles from "./../css/mainContainer.module.css";
import navStyles from "./../css/navbar.module.css";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import searchIcn from "../../../src/img/Search.png";
import hand_icn from "../../../src/img/hand.png";
import bell_icn from "../../../src/img/bell.svg";
import Student_MC_TopBar from "./Student/Student_MC_TopBar";
import { Popover } from "antd";
import NotificationList from "./NotificationList";
import { NotificationContext } from "../../context/notification/NotificationContext";

const MainContainer = (props) => {
  const location = useLocation();
  const hideNavBarRoutes = [
    "dashboard/quiz",
    "enrich/quiz",
    "enrich/quiz/resume",
    "enrich/quiz/analysis",
    "enrich/quiz/resume/analysis",
    "Student_Enrich_Quiz/quiz",
    "Student_Enrich_Quiz/quiz/resume",
    "Student_Enrich_Quiz/quiz/analysis",
    "Student_Enrich_Quiz/quiz/resume/analysis",
    "formal%20assessment%20quiz/quiz",
    "formal%20assessment%20quiz/quiz/resume",
    "formal%20assessment%20quiz/quiz/analysis",
    "formal20assessment%20quiz/quiz/resume/analysis"
  ]

  const isHiddenRoute = hideNavBarRoutes.some(route => location.pathname.includes(route));
  const [contentDevOrAdmin, setContentDevOrAdmin] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [topTitle, setTopTitle] = useState("Dashboard");

  const notificationList = useContext(NotificationContext)
  const { notifications } = notificationList || {};
  const { data } = notifications || {};
  
  const [openNotification, setOpenNotification] = useState(false)
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.split("/");
      const title = path.includes("ConceptTreeView")
        ? path[path.length - 2].replaceAll("%20", " ")
        : path[path.length - 1].replaceAll("%20", " ");
      setTopTitle(
        title === "template"
          ? "Dashboard"
          : title === "content approval"
            ? "Content Approval"
            : title === "template"
              ? "Dashboard"
              : title === "global training approval"
                ? "Global Training Approval"
                : title === "template"
                  ? "Dashboard"
                  : title === "concept tree approval"
                    ? "Concept Tree Approval"
                    : title === "template"
                      ? "Dashboard"
                      : title === "view concept tree"
                        ? "View Concept Tree"
                        : title === "template"
                          ? "Dashboard"
                          : title === "csp"
                            ? "Change Student Profile"
                            : title === "template"
                              ? "Dashboard"
                              : title === "branchplan"
                                ? "Plan"
                                : title === "template"
                                  ? "Dashboard"
                                  : title === "branchsection"
                                    ? "Sections"
                                    : title === "template"
                                      ? "Dashboard"
                                      : title === "teacherId"
                                        ? "Academic Staff"
                                        : title === "template"
                                          ? "Dashboard"
                                          : title === "student"
                                            ? "Students"
                                            : title === "template"
                                              ? "Dashboard"
                                              : title === "tokenList"
                                                ? "Tokens"
                                                : title === "template"
                                                  ? "Dashboard"
                                                  : title === "logo"
                                                    ? "Branch Logo"
                                                    : title === "template"
                                                      ? "Dashboard"
                                                      : title === "TokenList"
                                                        ? "Tokens"
                                                        : title === "template"
                                                          ? "Dashboard"
                                                          : title === "admin users"
                                                            ? "Admin Users"
                                                            : title === "template"
                                                              ? "Dashboard"
                                                              : title === "upload content"
                                                                ? "Upload Content"
                                                                : title === "template"
                                                                  ? "Dashboard"
                                                                  : title === "global training"
                                                                    ? "Global Training"
                                                                    : title === "template"
                                                                      ? "Dashboard"
                                                                      : title === "associate concepts"
                                                                        ? "Associate Concepts"
                                                                        : title === "template"
                                                                          ? "Dashboard"
                                                                          : title === "blue print"
                                                                            ? "Blue Print"
                                                                            : title === "template"
                                                                              ? "Dashboard"
                                                                              : title === "CreateManagement"
                                                                                ? "Create Management"
                                                                                : title === "template"
                                                                                  ? "Dashboard"
                                                                                  : title === "add Management"
                                                                                    ? "Add Management"
                                                                                    : title === "template"
                                                                                      ? "Dashboard"
                                                                                      : title === "academicid"
                                                                                        ? "Academic Id"
                                                                                        : title === "template"
                                                                                          ? "Dashboard"
                                                                                          : title === "createschool"
                                                                                            ? "Create School"
                                                                                            : title === "template"
                                                                                              ? "Dashboard"
                                                                                              : title === "CreateChapter"
                                                                                                ? "Create Chapter"
                                                                                                : title === "template"
                                                                                                  ? "Dashboard"
                                                                                                  : title === "AddTeacherFiles"
                                                                                                    ? "Add Teacher Files"
                                                                                                    : title === "template"
                                                                                                      ? "Dashboard"
                                                                                                      : title === "CreateTrainingCategory"
                                                                                                        ? "Create Training Category"
                                                                                                        : title === "template"
                                                                                                          ? "Dashboard"
                                                                                                          : title === "CreateTraining"
                                                                                                            ? "Create Training"
                                                                                                            : title === "template"
                                                                                                              ? "Dashboard"
                                                                                                              : title === "addBranch"
                                                                                                                ? "Branches"
                                                                                                                : title === "template"
                                                                                                                  ? "Dashboard"
                                                                                                                  : title === "addbranch"
                                                                                                                    ? "Branches"
                                                                                                                    : title === "template"
                                                                                                                      ? "Dashboard"
                                                                                                                      : title === "GlobalTrainingApprovalApproved"
                                                                                                                        ? "Global Training Approval "
                                                                                                                        : title === "template"
                                                                                                                          ? "Dashboard"
                                                                                                                          : title === "Edit Concept"
                                                                                                                            ? "Create/Edit Concept"
                                                                                                                            : title === "template"
                                                                                                                              ? "Dashboard"
                                                                                                                              : title === "StudentFileApproval"
                                                                                                                                ? "Student File Approval"
                                                                                                                                : title === "template"
                                                                                                                                  ? "Dashboard"
                                                                                                                                  : title === "TeacherFileApproval"
                                                                                                                                    ? "Teacher File Approval"
                                                                                                                                    : title === "template"
                                                                                                                                      ? "Dashboard"
                                                                                                                                      : title === "Content Approval"
                                                                                                                                        ? "Content Approval"
                                                                                                                                        : title === "template"
                                                                                                                                          ? "Dashboard"
                                                                                                                                          : title === "GlobalTrainingApprovalRejected"
                                                                                                                                            ? "Global Training Approval "
                                                                                                                                            : title === "template"
                                                                                                                                              ? "Dashboard"
                                                                                                                                              : title === "ConceptTreeApprovalRejected"
                                                                                                                                                ? "Concept Tree Approval "
                                                                                                                                                : title === "template"
                                                                                                                                                  ? "Dashboard"
                                                                                                                                                  : title === "ConceptTreeApprovalPending"
                                                                                                                                                    ? "Concept Tree Approval "
                                                                                                                                                    : title === "template"
                                                                                                                                                      ? "Dashboard"
                                                                                                                                                      : title === "GlobalTrainingApprovalApproved"
                                                                                                                                                        ? "Global Training Approval "
                                                                                                                                                        : title === "template"
                                                                                                                                                          ? "Dashboard"
                                                                                                                                                          : title === "ContentApprovalRejected"
                                                                                                                                                            ? "Content Approval "
                                                                                                                                                            : title === "template"
                                                                                                                                                              ? "Dashboard"
                                                                                                                                                              : title === "ContentApprovalApproved"
                                                                                                                                                                ? "Content Approval "
                                                                                                                                                                : title === "template"
                                                                                                                                                                  ? "Dashboard"
                                                                                                                                                                  : title === "CreateTeacher"
                                                                                                                                                                    ? "Add Academic Staff"
                                                                                                                                                                    : title === "template"
                                                                                                                                                                      ? "Dashboard"
                                                                                                                                                                      : title === "Edit Sub & Root Concepts"
                                                                                                                                                                        ? "Create/Edit Sub & Root Concepts"
                                                                                                                                                                        : title === "template"
                                                                                                                                                                          ? "Dashboard"
                                                                                                                                                                          : title === "contentapprovalviewfile"
                                                                                                                                                                            ? "Content Approval"
                                                                                                                                                                            : title === "template"
                                                                                                                                                                              ? "Dashboard"
                                                                                                                                                                              : title === "ContentApprovalTeacherViewFile"
                                                                                                                                                                                ? "Content Approval"
                                                                                                                                                                                : title === "template"
                                                                                                                                                                                  ? "Dashboard"
                                                                                                                                                                                  : title === "study"
                                                                                                                                                                                    ? "Study"
                                                                                                                                                                                    : title === "template"
                                                                                                                                                                                      ? "Dashboard"
                                                                                                                                                                                      : title === "enrich"
                                                                                                                                                                                        ? "Enrich"
                                                                                                                                                                                        : title === "template"
                                                                                                                                                                                          ? "Dashboard"
                                                                                                                                                                                          : title === "teacherid"
                                                                                                                                                                                            ? "Academic Staff"
                                                                                                                                                                                            : title
      );
    }
  }, [location.pathname]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, iconValue) => {
    //PrincipalProfile
    const value = e.target.innerText;
    if (localStorage.getItem("role") === "TEACHER") {
      if (value || iconValue) {
        if (value === "Profile" || iconValue === "Profile") {
          navigate("/dashboard/TeacherProfile", {
            state: { id: 1, value: "profile" },
          });
        } else if (
          value === "Current Access" ||
          iconValue === "Current Access"
        ) {
          navigate("/dashboard/TeacherProfile", {
            state: { id: 2, value: "currentAccess" },
          });
        }
      }
    } else if (localStorage.getItem("role") == "PRINCIPAL") {
      if (value === "Profile" || iconValue === "Profile") {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 1, value: "profile" },
        });
      } else if (value === "School Strength" || iconValue === "School Strength") {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 3, value: "School Strength" },
        });
      } else if (value === "Teacher Access" || iconValue === "Teacher Access") {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 2, value: "Teacher Access" },
        });
      } else if (value === "Current Access" || iconValue === "Current Access") {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 4, value: "Current Access" },
        });
      }
    } else if (localStorage.getItem("role") === "COORDINATOR") {
      if (value === "Profile" || iconValue === "Profile") {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 1, value: "profile" },
        });
      }
      else if (
        value === "Current Access" ||
        iconValue === "Current Access"
      ) {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 4, value: "Current Access" },
        });
      } else if (
        value === "Teacher Access" ||
        iconValue === "Teacher Access"
      ) {
        navigate("/dashboard/PrincipalProfile", {
          state: { id: 2, value: "Teacher Access" },
        });
      }
    }
    setAnchorEl(null);
  };
  const isTeacher = localStorage.getItem("role") === "TEACHER";
  const isPrincipal = localStorage.getItem("role") == "PRINCIPAL";
  const isStudent = localStorage.getItem("role") == "STUDENT";
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const handleToggle = () => {
    setContentDevOrAdmin(contentDevOrAdmin ? false : true);
  };

  const navigate = useNavigate();
  const tempMenu = localStorage.getItem("menus");
  const Navmenu = JSON.parse(tempMenu);

  const getTextShortForm = (text) => {
    const words = text.split("_");
    if (words.length === 1) {
      return words[0][0];
    } else {
      return words[0][0] + words[1][0];
    }
  };
  const handleNotification = () => {
    console.log("notification click");
    setOpenNotification(!openNotification)
  }
  return (
    // header loading here
    <div className={mainContainerStyles.main_container}
    >
      {!isHiddenRoute && ( 
      <header
        style={{ marginLeft: "-15px", paddingLeft: "15px", backgroundColor: "#403e75" }}
        className={mainContainerStyles.header}
        id="top-header"
      >

        {/*  added by sg */}
        {/* <h3 className={mainContainerStyles.header_title}>{props.headerTitle ? props.headerTitle : 'Registered Schools'}</h3> */}

        {isTeacher ? (
          <>
            <h3 className={mainContainerStyles.header_title}>
              {/* Welcome to {firstName} {lastName} */}
            </h3>
            <div className={mainContainerStyles.header_teacher}>
              <TextField
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    left: "-48px",
                    width: "342px",
                    height: "44px",
                    background: " #F3F2FF",
                    borderRadius: "10px",
                    opacity: 1,
                  },
                }}
                type={"text"}
                placeholder={"Search anything...."}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                          color: "#27334E",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
                size={"small"}
              />    
               <Popover
              content={<NotificationList/>}
              placement="bottom"
              title=""
              trigger="click"
              open={openNotification}
              onOpenChange={handleNotification}
            >
              <div className={mainContainerStyles.teacher_badge}>
                <Badge
                  variant="dot"
                  sx={{ color: "#403b97", fontSize: "1.5vw" }}
                  color={"secondary"}
                  id="notification-badge"
                >
                  <NotificationsNoneOutlinedIcon
                    style={{ fontSize: "1.5vw" }}
                  />
                </Badge>
              </div>
              </Popover>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "10px",
                  padding: "10px",
                }}
              >
                <Avatar
                  sx={{
                    background: "orange",
                    width: "2.8vw",
                    height: "2.8vw",
                    fontSize: "1.4vw",
                  }}
                >
                  {firstName[0]?.toUpperCase()}
                </Avatar>
                <div className={mainContainerStyles.profile_Name}>
                  <div style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    font: "13px"
                  }}>
                    Welcome
                  </div>
                  <div className={mainContainerStyles.teacher_name}>
                    {firstName} {lastName} {"(" + getTextShortForm(localStorage.getItem("role")) + ")"}
                    {/* {"(" + localStorage.getItem("role")[0].toUpperCase() + ")"} */}
                  </div>
                  {/* <div
                    style={{
                      color: "white",
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                      font: "13px"
                    }}
                  >
                    {"(" + localStorage.getItem("role") + ")"}
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "end",
                    rowGap: "20px",
                  }}
                >
                  <div>
                    {
                      !open ? (
                        <div className="down_Arrow">
                          <div class="triangle-bottom"></div>
                        </div>
                      ) :

                        (
                          <div className="down_Arrow">
                            <div class="triangle-up"></div>
                          </div>
                        )
                    }
                    {/* {open ? (
                      <KeyboardArrowUpIcon
                        style={{ fontSize: "1.5vw", color: "black" }}
                      />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon
                        style={{ fontSize: "1.5vw", color: "black" }}
                      />
                    )} */}
                  </div>
                  <div />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => handleClose(e)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 12,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={(e) => handleClose(e)}>
                  <ListItemIcon onClick={(e) => handleClose(e, "Profile")}>
                    <AccountCircleOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#403b97" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "#403b97" }}>Profile</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e)}>
                  <ListItemIcon
                    onClick={(e) => handleClose(e, "Current Access")}
                  >
                    <ImportContactsOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#403b97" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "#403b97" }}>
                    Current Access
                  </ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </>
        ) : isPrincipal || localStorage.getItem("role") === "COORDINATOR" ? (
          <>
            <h3 className={mainContainerStyles.header_title}>
              {/* Welcome to {firstName} {lastName} */}
            </h3>
            <div className={mainContainerStyles.header_teacher}>
              <TextField
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    left: "-48px",
                    width: "342px",
                    height: "44px",
                    background: " #F3F2FF",
                    borderRadius: "10px",
                    opacity: 1,
                  },
                }}
                type={"text"}
                placeholder={"Search anything...."}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                          color: "#27334E",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
                size={"small"}
              />
                  <Popover
              content={<NotificationList/>}
              placement="bottom"
              title=""
              trigger="click"
              open={openNotification}
              onOpenChange={handleNotification}
            >

              <div className={mainContainerStyles.teacher_badge}>
                <Badge
                  variant="dot"
                  sx={{ color: "#403b97", fontSize: "1.5vw" }}
                  color={"secondary"}
                >
                  <NotificationsNoneOutlinedIcon
                    style={{ fontSize: "1.5vw" }}
                  />
                </Badge>
              </div>
            </Popover>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "10px",
                  padding: "10px",
                }}
              >
                <Avatar
                  sx={{
                    background: "orange",
                    width: "2.8vw",
                    height: "2.8vw",
                    fontSize: "1.4vw",
                  }}
                >
                  {firstName[0]?.toUpperCase()}
                </Avatar>
                <div className={mainContainerStyles.profile_Name}>
                  <div style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    font: "13px"
                  }}>
                    Welcome
                  </div>
                  <div className={mainContainerStyles.teacher_name}>
                    {firstName} {lastName} {"(" + getTextShortForm(localStorage.getItem("role")) + ")"}
                    {/* {firstName} {lastName} {"("+localStorage.getItem("role")[0].toUpperCase()+")"} */}
                  </div>
                  {/* <div
                    style={{
                      color: "white",
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                      font: "13px"
                    }}
                  >
                    {"("+localStorage.getItem("role")+")"}
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "end",
                    rowGap: "20px",
                  }}
                >
                  <div>
                    {
                      !open ? (
                        <div className="down_Arrow">
                          <div class="triangle-bottom"></div>
                        </div>
                      ) :

                        (
                          <div className="down_Arrow">
                            <div class="triangle-up"></div>
                          </div>
                        )
                    }
                    {/* {open ? (
                      <KeyboardArrowUpIcon
                        style={{ fontSize: "1.5vw", color: "black" }}
                      />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon
                        style={{ fontSize: "1.5vw", color: "black" }}
                      />
                    )} */}
                  </div>
                  <div />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => handleClose(e)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 12,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={(e) => handleClose(e)}>
                  <ListItemIcon onClick={(e) => handleClose(e, "Profile")}>
                    <AccountCircleOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#403b97" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "#403b97" }}>Profile</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e)}>
                  <ListItemIcon
                    onClick={(e) => handleClose(e, "Teacher Access")}
                  >
                    <AccountCircleOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#403b97" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "#403b97" }}>
                    Teacher Access
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e)}>
                  <ListItemIcon
                    onClick={(e) => handleClose(e, "Current Access")}
                  >
                    <ImportContactsOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#403b97" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "#403b97" }}>
                    Current Access
                  </ListItemText>
                </MenuItem>
                {localStorage.getItem("role") !== "COORDINATOR" && (
                  <MenuItem onClick={(e) => handleClose(e)}>
                    <ListItemIcon
                      onClick={(e) => handleClose(e, "School Strength")}
                    >
                      <ImportContactsOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#403b97" }}
                      />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "#403b97" }}>
                      School Strength
                    </ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </div>
          </>
        ) : isStudent ? (
          <>
            <Student_MC_TopBar
              anchorEl={anchorEl}
              firstName={firstName}
              lastName={lastName}
            />
          </>
        ) : (
          <>
            <h3 className={mainContainerStyles.header_title}>
              {/* Welcome to {firstName} {lastName} */}
            </h3>
            <div className={mainContainerStyles.header_teacher}>


              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "10px",
                  padding: "10px",
                }}
              >
                <Avatar
                  sx={{
                    background: "orange",
                    width: "2.8vw",
                    height: "2.8vw",
                    fontSize: "1.4vw",
                  }}
                >
                  {firstName[0]?.toUpperCase()}
                </Avatar>
                <div className={mainContainerStyles.profile_Name}>
                  <div style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    font: "13px"
                  }}>
                    Welcome
                  </div>
                  <div className={mainContainerStyles.teacher_name}>
                    {firstName} {lastName} {"(" + getTextShortForm(localStorage.getItem("role")) + ")"}
                    {/* {firstName} {lastName} {"("+localStorage.getItem("role")[0].toUpperCase()+")"} */}
                  </div>

                </div>

              </Button>

            </div>
          </>
        )}

        {/* <div
          className={mainContainerStyles.header_button_container}
          id="header-btn-container"
          onClick={handleToggle}
        >
          <button
            className={`${mainContainerStyles.header_button} ${mainContainerStyles.content_dev}`}
            id="content-dev"
          >
            Content Dev
          </button>
          <button className={mainContainerStyles.header_button}>Admin</button>
          <button
            className={
              contentDevOrAdmin
                ? mainContainerStyles.slider
                : `${mainContainerStyles.slider} ${mainContainerStyles.slider_right}`
            }
            id="header-btn-slider"
          >
            {contentDevOrAdmin ? "Content Dev" : "Admin"}
          </button>
        </div> */}
      </header>
      )}
      {/* dashboard content for various pages - start */}
      {props.children}
      {/* dashboard content for various pages - end */}
  

    </div>
  );
};

export default MainContainer;