import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, FormControl, Grid, Modal, Radio, RadioGroup, styled, TextField, Typography } from '@mui/material'
import SelectBoardAndGrade from './SelectBoardAndGrade'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import chapterStyles from "../../../css/createChapter.module.css";
import ChapterStyles from "../../../css/createChapter.module.css";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import action__Add from "../../../../../src/img/AddDefault.svg";
import action__Delete from "../../../../../src/img/Delete.svg";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import boards_controller_getall from '../../../API_CONTROLLER/boards-controller/boards_controller_getall';
import gradeListGet from '../../../API/grade-section-mapping-controller/grade_get';
import TextEditor from './TextEditor';
import { orange } from '@mui/material/colors';
import axios from 'axios';
import create_question_post from '../../../API_CONTROLLER/create-questions/question_post';
import DeleteConfirmationAlert from './DeleteConfirmation';
import map_content_get from '../../../API_CONTROLLER/map-controller/map_content_get';
import UploadMapModal from '../../UploadMapModal';
import ImageListModal from './ImageListModal';
import get_question from '../../../API_CONTROLLER/create-questions/get_questionbyid';
import update_question_post from '../../../API_CONTROLLER/create-questions/question_put';
import { useMemo } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router';
import DeleteBlanksModal from './DeleteBlanksModal';

const CustomHead = styled(Typography)(({ theme }) => ({
  '&': {
    color: "#FD8C00",
    fontFamily: "Poppins",
    fontSize: "1.3vw",
    fontWeight: 400,
    lineHeight: "27px",
  },
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  color: orange[600],
  boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto orange',
    color: orange[600],
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: orange[600],
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: orange[800],
  },
});

const CustomButton = styled(Button)(({ }) => ({
  color: "#FFFFFF",
  backgroundColor: '#FD8C00',
  fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
  fontSize: '10px',
  borderRadius: '30px',
  width: 'auto',
  padding: "0.6vw 1vw",
  '&:hover': {
    backgroundColor: '#FD8C00',
  },
}));

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '58%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "45vw",
  // overflow: "scroll",
  overflowX: "scroll",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
};

const CustomTextField = styled(TextField)(({ }) => ({
  width: "96%",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "2px !important",
  "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    opacity: "1 !important",
    background: "rgb(255 255 255 / 31%) !important",
    border: "1px solid #B5B6B6",
    borderRadius: "2px !important",
  }
}))

const SubjectiveText = (props) => {

  const [boardsList, setBoardsList] = useState();
  const [boardSelect, setBoardSelect] = useState('');

  const [gradeList, setGradeList] = useState();
  const [gradeSelect, setGradeSelect] = useState([]);
  const [clearContent, setClearContent] = useState(false);

  const [boardAndGrade, setBoardAndGrade] = useState([]);

  const [boardAndGradeCombo, setBoardAndGradeCombo] = useState([]);
  const [errorField, setErrorField] = useState({});
  const [errorFieldCombo, setErrorFieldCombo] = useState({});
  const [questionContent, setQuestionContent] = useState({ content: "" });
  const [questionList, setQuestionList] = useState([{ answerKey: '', mark: "", order: 1 }])
  const [answerKeyContent, setAnswerKeyContent] = useState({ content: "" });
  const [radioButtonValue, setRadioButtonValue] = useState("machine");

  const [uploadImage, setUploadImage] = useState();
  const [uploadImageMap, setUploadImageMap] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState();
  const [questionContentMap, setQuestionContentMap] = useState({ content: "" });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [openImageListModal, setOpenImageListModal] = React.useState(false);
  const handleImageListModalOpen = () => setOpenImageListModal(true);
  const handleImageListModalClose = () => setOpenImageListModal(false);

  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const handlePreviewModalOpen = () => setOpenPreviewModal(true);
  const handlePreviewModalClose = () => setOpenPreviewModal(false);
  const [createModal, setCreateModal] = useState(false);
  const [showAlertType, setShowAlertType] = useState("");
  const [mapcontent, setMapcontent] = useState([]);
  const [errorModalDetails, setErrorModalDetails] = useState({ open: false, message: '' })

  const [title, setTitle] = useState("");
  const [StepMarkDetails, setStepMarkDetails] = useState([])
  const navigate = useNavigate();

  // Repo Map
  const [openImageListModalImage, setOpenImageListModalImage] = React.useState(false);
  const handleImageListModalOpenImage = () => setOpenImageListModalImage(true);
  const handleImageListModalCloseImage = () => setOpenImageListModalImage(false);

  const subjectId = sessionStorage.getItem("subjectId");
  const conceptId = sessionStorage.getItem("conceptId");
  const subConceptId = sessionStorage.getItem("subConceptId");
  const subQuestionTypeId = sessionStorage.getItem("subQuestionType");
  const subQuestionConceptId = sessionStorage.getItem("subQuestionConceptId");
  const subQuestionSubConceptId = sessionStorage.getItem("subQuestionSubConceptId");
  const rootConcept = sessionStorage.getItem("rootConceptId");
  const questionTypeId = sessionStorage.getItem("questionType");
  const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
  const taxonomy = sessionStorage.getItem("taxonomy");
  const parentId = sessionStorage.getItem("parentId");
  const questionType = sessionStorage.getItem("questionType");
  const mapImage = sessionStorage.getItem("mapImage");
  const sessionQuestionType = sessionStorage.getItem('QuestionType');
  const subjectiveType = sessionStorage.getItem('subjectiveType');

  // Edit 
  const [responseValue, setResponseValue] = useState();
  const [edit, setEdit] = useState(false);
  const [questionValue, setQuestionValue] = useState();
  const [previewFromModal, setPreviewFromModal] = useState(false);
  const [questionEditContent, setEditQuestionContent] = useState({ content: "" });
  const [answerEditKeyContent, setEditAnswerKeyContent] = useState({ content: "" });
  const [answerContent, setAnswerContent] = useState([]);
  const [optionsEdit, setOptionsEdit] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const [radioButtonEnable, setRadioButtonEnable] = useState(false);
  const removeUploadImgMap = useRef(null);
  const [validationMessage,setValidationMessage] = useState(false);
  const [onTotalMarks,setOnTotalMarks] = useState('');


  const handleEditQuestion = () => {
    get_question(responseValue.id, setQuestionValue);
  }

  useEffect(() => {
    if (questionEditContent.content !== "") {
      setShowEditor(true);
    }
  }, [questionEditContent.content]);

  useEffect(() => {
    if (responseValue) {
      setQuestionContent({ content: responseValue.question });
      setAnswerKeyContent({ content: responseValue.explanation });
      // setImagePreview(responseValue.imageUrl);
      setSelectedImageUrl(responseValue.imageUrl);
      setTitle(responseValue.marks)
    }
  }, [responseValue]);

  useEffect(() => {
    if (props.questionData) {
      setQuestionValue(props.questionData);
      // setEdit(false);
    }
    if (questionValue) {
      let myObj = [];
      setEdit(true);
      if (props.setDisableTopFields) props.setDisableTopFields(true);
      setEditQuestionContent({ content: questionValue.question });
      setEditAnswerKeyContent({ content: questionValue.explanation });
      setSelectedImageUrl(questionValue.imageUrl);
      setSelectedImageName(questionValue.imageUrl);
      setOptionsEdit(questionValue.options[0]);
      setTitle(questionValue.marks)
      setQuestionContentMap({ content: questionValue.mapTitle })
      sessionStorage.setItem("mapImage", questionValue.mapUrl);
      setQuestionList(questionValue.options)
      if (!props.caseStudy) {
        questionValue.boardAndGrades.map((boardGrade, ind) => {
          let obj = {
            "boardId": boardGrade.boardId,
            "gradeId": boardGrade.gradeId
          };
          setBoardAndGrade(oldArray => [...oldArray, obj]);
          if (ind !== 0) {
            setErrorField(prevState => {
              delete prevState['combo'];
              return prevState;
            })
            const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
            if (foundKey) {
              myObj[boardGrade.boardId].push(boardGrade.gradeId);
              setBoardAndGradeCombo(myObj);
              setBoardSelect('');
              setGradeSelect([]);
            }
            else {
              myObj[boardGrade.boardId] = [boardGrade.gradeId];
              setBoardAndGradeCombo(myObj);
              setBoardSelect('');
              setGradeSelect([]);
            }
          }
          else {
            myObj = { [boardGrade.boardId]: [] };
            myObj[boardGrade.boardId].push(boardGrade.gradeId);
            setBoardAndGradeCombo(myObj);
            setBoardSelect('');
            setGradeSelect([]);
          }
        })
      }
    }
  }, [props.questionData, questionValue])

  useEffect(() => {
    boards_controller_getall(setBoardsList);
    gradeListGet(setGradeList);
    setClearContent(false);
  }, [])

  const handleBoardChange = (e) => {
    setBoardSelect(e.target.value);
    setErrorFieldCombo(prevState => {
      delete prevState['board'];
      return prevState;
    })
  }

  const handleGradeChange = (e) => {
    const value = e.target.value;
    setGradeSelect(typeof value === "string" ? value.split(",") : value);
    setErrorFieldCombo(prevState => {
      delete prevState['grade'];
      return prevState;
    })
  }

  const handleBoardAndGradeChange = () => {
    let errorObj = {};
    let error = false;
    if (boardSelect === "") {
      errorObj['board'] = "Please choose the Board";
      error = true;
    }
    if (gradeSelect.length === 0) {
      errorObj['grade'] = "Please choose atleast one Grade";
      error = true;
    }
    setErrorFieldCombo(errorObj);
    if (!error) {
      setErrorField(prevState => {
        delete prevState['combo'];
        return prevState;
      })
      if (Object.keys(boardAndGradeCombo).length > 0) {
        setErrorField({ message: "", id: "" });
        const foundKey = Object.hasOwn(boardAndGradeCombo, boardSelect);
        if (foundKey) {
          let found;
          found = gradeSelect.filter(item => !boardAndGradeCombo[boardSelect].includes(item))
          const concatArray = boardAndGradeCombo[boardSelect].concat(found);
          setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: concatArray } });
          setBoardSelect('');
          setGradeSelect([]);
        }
        else {
          setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
          setBoardSelect('');
          setGradeSelect([]);
        }
      }
      else {
        setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
        setBoardSelect('');
        setGradeSelect([]);
      }
      gradeSelect.map((grade, ind) => {
        let obj = {
          "boardId": boardSelect,
          "gradeId": grade
        };
        setBoardAndGrade(oldArray => [...oldArray, obj]);
      })
    }
  }

  const onRemoveCombo = (boardId, gradeId) => {
    boardAndGrade.map((key, index) => {
      if (key.boardId === boardId && key.gradeId === gradeId) {
        boardAndGrade.splice(index, 1);
      };
    })
    Object.keys(boardAndGradeCombo).map(key => {
      if (key === boardId) {
        boardAndGradeCombo[key].map((value, i) => {
          if (value === gradeId) {
            boardAndGradeCombo[key].splice(i, 1);
            if (boardAndGradeCombo[key].length === 0) {
              delete boardAndGradeCombo[key];
              setBoardSelect('');
              setGradeSelect([]);
            }
            else {
              setBoardSelect(key);
              setGradeSelect([...boardAndGradeCombo[key]]);
            }
          }
        })
      }
    })
  }
  const onQuestionHandler = (content) => {
    setQuestionContent({ content: content });
    setErrorField(prevState => {
      delete prevState['question'];
      return prevState;
    })
  }

  const onQuestionHandlerMap = (content) => {
    setQuestionContentMap({ content: content });
    setErrorField(prevState => {
      delete prevState['map'];
      return prevState;
    })
  }

  const addQuestion = () => {
    if (questionMarkTotal < title)
      setQuestionList([...questionList, { answerKey: '', mark: '', order: questionList.length + 1 }])
  }

  const deleteQuestion = (order) => {
    const newList = questionList.filter(ques => ques.order !== order).map((ques, i) => ({ ...ques, order: i + 1 }));
    console.log(newList)
    sessionStorage.setItem("markArray", JSON.stringify(newList))
    if (newList.length > 0) setQuestionList(newList)
  }

  const onQuestionChange = (order, key, value) => {
    console.log(order, key, value)
    const copyQuestions = JSON.parse(JSON.stringify(questionList))
    const questionIndex = copyQuestions.findIndex(ques => ques.order === order)
    let isValidValue = true
    if (key === 'mark') {
      if (!/^[0-9]*$/.test(value)) {
        isValidValue = false; return
      }

      let totalMark = +value;
      questionList.forEach((q => totalMark += +q.mark));
      setOnTotalMarks(totalMark)
      console.log(totalMark, title, totalMark > title);
      console.log(questionList)
      //if (Number(totalMark) > Number(title)) isValidValue = false // commented on 29/11 for removing input validation remove
    }
    console.log(isValidValue, key, value)
    if (isValidValue) {
      copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
      setQuestionList(copyQuestions)
      if (key === 'mark') {
        console.log(title >= value, title, value)
        if (Number(title) >= Number(value)) {
          copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
          // console.log("copyQuestions", copyQuestions)
          setQuestionList(copyQuestions);
          sessionStorage.setItem("markArray", JSON.stringify(copyQuestions))

        }
        else {
          copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
          // console.log("copyQuestions", copyQuestions)
          sessionStorage.setItem("markArray", JSON.stringify(copyQuestions))
          // console.log("======================")
          errorField["foundEmptyMark"] = "Step Marks Not Should Be Grater than Total marks."
        }
      }
      console.log("copyQuestions", copyQuestions)
      setStepMarkDetails(copyQuestions)
    }
  }
  const onRemoveImage = (type) => {
    if (type === "upload") {
      setUploadImage();
      setSelectedImageUrl()
      setUploadImage()
    }
    else {
      setImagePreview();
      setSelectedImageUrl();
      setSelectedImageName("");
    }
  }
  const handleCreateSubQuestion = (type) => {
    let isError = false;

    let errorObj = {};

    // let answerContentFoundEmpty = answerKeyContent.some(ansContent => ansContent.answerKey[0] === '');
    console.log(title, questionContent, JSON.parse(sessionStorage.getItem("markArray")))
    if (title === "" || questionContent.content === "" || props.caseStudy !== true && (boardAndGradeCombo.length === 0 || Object.keys(boardAndGradeCombo).length === 0)) {
      isError = true;
      if (boardAndGradeCombo.length === 0) {
        errorObj['combo'] = "Please provide the combination of board and grade";
      }
      if (Object.keys(boardAndGradeCombo).length === 0) {
        errorObj['combo'] = "Please provide the combination of board and grade";
      }
      if (title === "") {
        errorObj['numberOfMarks'] = "Please Enter Number Of Marks";
      }
      if (questionContent.content === "") {
        errorObj['question'] = "Please provide the question";
      }
      if (sessionQuestionType !== "TEXT" && questionContent.content === "") {
        errorObj['map'] = "Please provide the Map";
      }
    } else {

      var marksArray = JSON.parse(sessionStorage.getItem("markArray"))
      const totalMarks = marksArray.reduce((acc, item) => acc + parseInt(item.mark), 0);
      if (totalMarks > title) {
        isError = true;
        errorObj['numberOfMarks'] = "The entered mark exceeds the total marks";
      } else if (totalMarks < title) {
        console.log("Entered mark ", totalMarks, title);
        isError = true;
        errorObj['numberOfMarks'] = "The entered mark does not match the total marks."
      } else {
        sessionStorage.setItem("TotalMarks", totalMarks);
        console.log("SIMPLE TotalMarks >>>>", totalMarks)
      }
      console.log(totalMarks, marksArray, title);
      var emptyMark = marksArray.findIndex((val) => { return val.mark === "" });
      console.log(emptyMark);
      if (emptyMark !== -1) {
        isError = true;
        // errorObj['inputValidatio'] = "Please fill this";
        errorObj['inputIndex'] = emptyMark
        errorObj['foundEmptyMark'] = "Please fill this"
      }
    }
    console.log(errorObj)

    setErrorField(errorObj);

    if (!isError) {
      setClearContent(false);

      const formatedQueList = questionList.map(ques => ({ ...ques, mark: parseInt(ques.mark) }))
      if (type === "update") {

        setEdit(false);
        setShowEditor(false);
        const payload = {
          id: questionValue.id,
          subjectId: subjectId,
          conceptId: props.caseStudy ? subQuestionConceptId : conceptId,
          subConceptId: props.caseStudy ? subQuestionSubConceptId : subConceptId,
          rootConceptId: props.caseStudy ? null : rootConcept ? rootConcept : null,
          questionTypeId: props.caseStudy ? subQuestionTypeId : questionTypeId,
          taxonomyCategoryId: taxonomyCategory,
          taxonomyId: taxonomy,
          boardAndGrades: boardAndGrade,
          question: questionContent.content,
          explanation: answerKeyContent.content,
          marks: title,
          imageUrl: selectedImageUrl ? selectedImageUrl : questionValue?.imageUrl,
          // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
          hasSubQuestions: false,
          options: formatedQueList,
          questionPattern: "SUBJECTIVE",
          mapId: mapcontent.length ? mapcontent[0].id : questionValue?.mapUrl,
          subjectiveType: "SIMPLE",
          parentQuestionId: props.caseStudy && questionValue.parentQuestionId,
        }
        update_question_post(payload, (parentId ? setShowSuccessAlert : setShowAlertType), setResponseValue);
      }
      else {

        const totalmark = sessionStorage.getItem('TotalMarks');

        // Convert totalmark to a number if it's not null
        const totalmarkValue = totalmark ? parseInt(totalmark, 10) : 0;
        console.log("SUB QUES >>>>>>>>", title)
        if (title <= onTotalMarks && title > 0) {
          // Proceed with forming payload
          const payload = {
            subjectId: subjectId,
            conceptId: props.caseStudy ? subQuestionConceptId : conceptId,
            subConceptId: props.caseStudy ? subQuestionSubConceptId : subConceptId,
            rootConceptId: props.caseStudy ? null : rootConcept ? rootConcept : null,
            questionTypeId: props.caseStudy ? subQuestionTypeId : questionTypeId,
            taxonomyCategoryId: taxonomyCategory,
            taxonomyId: taxonomy,
            boardAndGrades: boardAndGrade,
            question: questionContent.content,
            explanation: answerKeyContent.content,
            marks: title,
            imageUrl: selectedImageUrl,
            // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
            hasSubQuestions: false,
            options: formatedQueList,
            questionPattern: "SUBJECTIVE",
            mapId: mapcontent.length ? mapcontent[0].id : null,
            subjectiveType: "SIMPLE",
            parentQuestionId: props.caseStudy && parentId,
          };

          // Call your function to create question post
          create_question_post(payload, setShowSuccessAlert, setResponseValue, showErrorMessage);

          // Calculate the difference between title and totalmark
          const difference = totalmarkValue - title;
          console.log(">>>>>>>>", difference)
          sessionStorage.setItem("TotalMarks", difference);
          setValidationMessage(false);
        } else {
          // Show an error message
          setValidationMessage(true);
          //alert('Sub Question Marks mismatching with the Total Marks');
        }

        // const payload = {
        //   subjectId: subjectId,
        //   conceptId: props.caseStudy ? subQuestionConceptId : conceptId,
        //   subConceptId: props.caseStudy ? subQuestionSubConceptId : subConceptId,
        //   rootConceptId: props.caseStudy ? null : rootConcept ? rootConcept : null,
        //   questionTypeId: props.caseStudy ? subQuestionTypeId : questionTypeId,
        //   taxonomyCategoryId: taxonomyCategory,
        //   taxonomyId: taxonomy,
        //   boardAndGrades: boardAndGrade,
        //   question: questionContent.content,
        //   explanation: answerKeyContent.content,
        //   marks: title,
        //   imageUrl: selectedImageUrl,
        //   // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
        //   hasSubQuestions: false,
        //   options: formatedQueList,
        //   questionPattern: "SUBJECTIVE",
        //   mapId: mapcontent.length ? mapcontent[0].id : null,
        //   subjectiveType: "SIMPLE",
        //   parentQuestionId: props.caseStudy && parentId,
        // }
        // create_question_post(payload, setShowSuccessAlert, setResponseValue, showErrorMessage);
      }



    } else {
      console.log("it has some error message")
    }
    sessionStorage.removeItem("mapImage");
  }

  const onAnswerKeyHandler = (content) => {
    setAnswerKeyContent({ content: content });
  };

  const fileInput = React.useRef();

  const imageUploadHandler = (e) => {
    setRadioButtonValue(e.target.value)
    if (e.target.value === "repository") {
      handleImageListModalOpen();
    }
  }
  useEffect(() => {
    if (uploadImage || selectedImageName) {
      setRadioButtonEnable(true);
    }
    else {
      setRadioButtonEnable(false);
    }
  }, [uploadImage, selectedImageName]);
  const onFileChange = (e) => {
    const file = e.target.files[0];
    setUploadImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    var fileMode = new File([file], file.name, { type: file.type });
    const formData = new FormData();
    formData.append("file", fileMode);
    const file_upload_post = async () => {
      let token = sessionStorage.getItem("token");
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            setSelectedImageUrl(response.data.data)
            sessionStorage.setItem("logoUrl", response.data.data)
          }
        )
        .catch(err => console.log(err))
    }
    file_upload_post();
  }

  const onTitleChange = (e) => {
    const { value } = e.target
    if (/^[0-9]*$/.test(value)) setTitle(value)
  }

  useEffect(() => {
    if (showSuccessAlert) {
      if (props.caseStudy) {
        props.setOpenQuestionModal(false);
        setTimeout(() => {
          props.setSuccessMessage("You have successfully Added subquestion Subjective Text for Case Study")
        }, 5000);
        props.setGetSubQuestion(true);
      }
      else {
        setSuccessMessage(`You have successfully created Subjective Text question`);
      }
      setBoardSelect('');
      setGradeSelect([]);
      setBoardAndGradeCombo([]);
      setClearContent(true);
      // setPreview(true);
      setUploadImage();
      setUploadImageMap();
      setImagePreview();
      setSelectedImageName("");
      setSelectedImageUrl();
      setQuestionList([{ answerKey: '', mark: "", order: 1 }])
      setTitle('')
      setShowEditor(false);
    }
  }, [showSuccessAlert]);

  const handleCancelUpdate = () => {
    if (props.fromViewAllQuestion !== undefined) {
      navigate("/dashboard/View All Questions");
    }
    setBoardSelect('');
    setGradeSelect([]);
    setBoardAndGradeCombo([]);
    setClearContent(true);
    // setPreview(true);
    setUploadImage();
    setUploadImageMap();
    setImagePreview();
    setSelectedImageName("");
    setSelectedImageUrl();
    setQuestionList([{ answerKey: '', mark: "", order: 1 }])
    setTitle('')
    setShowEditor(false);
    setBoardAndGrade([]);
    setErrorField({});
    setErrorFieldCombo({});
    if (!props.caseStudy) {
      props.setDisableTopFields(false);
    }
    setEdit(false);
  }


  const onHideSuccessModal = () => {
    setShowSuccessAlert(false);
  }

  const onHideModal = () => {
    setShowAlert(false);
  }

  const onHideModalType = () => {
    setShowAlert(false);
    navigate("/dashboard/View All Questions");
  }

  const showErrorMessage = (message) => {
    setErrorModalDetails({ open: true, message })
  }
  const closeErrorModal = () => { setErrorModalDetails({ open: false, message: '' }) }

  const closeCreateModal = () => {
    setCreateModal(false);
  };

  const closeModalAPI = () => {
    setCreateModal(false);
    map_content_get(setMapcontent);
  };

  const questionMarkTotal = useMemo(() => {
    return questionList.reduce((total, q) => {
      return total + (+q.mark);
    }, 0)
  }, [questionList])

  return (
    <>
      {props.caseStudy ?
        <>
        </>
        :
        <>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <SelectBoardAndGrade
              boardsList={boardsList}
              gradeList={gradeList}
              boardValue={boardSelect}
              handleBoardChange={handleBoardChange}
              gradeValue={gradeSelect}
              handleGradeChange={handleGradeChange}
              isError={errorFieldCombo}
            />
            <Grid item xs={3} md={3} lg={3}>
              <img
                className={
                  dashboardContentStyles.aRoleActionBtn
                }
                onClick={handleBoardAndGradeChange}
                src={action__Add}
                alt="Add-Btn"
                style={{ width: "18%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {!!errorField['combo'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['combo']}</Typography>}
              {Object.keys(boardAndGradeCombo).length > 0 &&
                <Grid container sx={{ border: "1px solid #B5B6B6", borderRadius: "4px", boxShadow: "0px 3px 6px #00000029" }}>
                  {Object.keys(boardAndGradeCombo).length > 0 ?
                    Object.keys(boardAndGradeCombo).map(key => {
                      return (
                        boardsList && boardsList.map(board => {
                          return (
                            board.id === key &&
                            gradeList && gradeList.map(grade => {
                              return (
                                boardAndGradeCombo[key].map((value, i) => {
                                  return (
                                    value === grade.id &&
                                    <Grid item xs={6} md={4} lg={4} key={key} sx={{ p: 1 }}>
                                      <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                        {board.board}/{grade.grade}
                                        <CloseOutlinedIcon onClick={() => onRemoveCombo(board.id, grade.id)} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                      </Typography>
                                    </Grid>

                                  )
                                })
                              )
                            })
                          )
                        })
                      )
                    })
                    :
                    null
                  }
                </Grid>
              }
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
            </Grid>
          </Grid>
        </>
      }
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={12} lg={12}>
          <CustomHead >{parentId ? "Subjective Text" : "Simple Questions"}</CustomHead>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>Question</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {!edit ? <TextEditor onEditorContentChange={onQuestionHandler} name={"question"} clearContent={clearContent && clearContent} /> :
              showEditor && <TextEditor onEditorContentChange={onQuestionHandler} name={"editQuestion"} initialValue={edit ? questionEditContent.content : ""} clearContent={clearContent && clearContent} />
            }
            {/* {errorField.id === "option" && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>} */}
            {!!errorField['question'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['question']}</Typography>}
          </Grid>
          {/* <TextEditor onEditorContentChange={onQuestionHandler} clearContent={clearContent && clearContent} />
            {!!errorField['question'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['question']}</Typography>}
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioButtonValue}
                  onChange={imageUploadHandler}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel disabled={radioButtonEnable} value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                  <FormControlLabel disabled={radioButtonEnable} value="repository" control={<CustomRadio />} label="Use from repository" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButtonValue === "machine" &&
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload Image</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography>
                    <input ref={fileInput} onChange={onFileChange} type="file" accept="image/*" style={{ display: 'none' }} /><CustomButton onClick={() => fileInput.current.click()}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
                  </Typography>
                  {uploadImage &&
                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                      {uploadImage && uploadImage.name}
                      <CloseOutlinedIcon onClick={() => onRemoveImage("upload")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                    </Typography>
                  }
                </Grid>
              </>
            }
            {radioButtonValue === "repository" &&
              <Grid item xs={12} md={12} lg={12}>
                {selectedImageName &&
                  <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                    {selectedImageName && selectedImageName.split('/').pop()}
                    <CloseOutlinedIcon onClick={() => onRemoveImage("repository")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                  </Typography>
                }
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography>Explanation/Answer Key</Typography>
          {showEditor && <TextEditor onEditorContentChange={onAnswerKeyHandler} name={"editExplanation"} initialValue={answerEditKeyContent.content} clearContent={clearContent && clearContent} />}
          {!edit && <TextEditor onEditorContentChange={onAnswerKeyHandler} name={"explanation"} clearContent={clearContent && clearContent} />}
          {/* <TextEditor onEditorContentChange={onAnswerKeyHandler} clearContent={clearContent && clearContent} /> */}
          <Grid item xs={12} md={6} lg={6} sx={{ marginLeft: 'auto' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={onTitleChange} placeholder="*Number of marks" value={title} />
            </Box>
            {!!errorField["numberOfMarks"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["numberOfMarks"]}</Typography>}
          </Grid>
        </Grid>
        {/* {!parentId && <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>Map Name</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextEditor onEditorContentChange={onQuestionHandlerMap} initialValue={edit ? questionContentMap.content : ""} clearContent={clearContent && clearContent} />
            {!!errorField['map'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['map']}</Typography>}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload MAP</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography>
                <CustomButton onClick={setCreateModal}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
              </Typography>
              <Typography sx={{ fontSize: '1vw' }}>
                {mapImage && mapImage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>} */}
      </Grid>
      <hr />
      {questionList.length ? questionList.map((ques, ind) => {
        return <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={6} lg={5}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={(e) => onQuestionChange(ques.order, "answerKey", e.target.value)} placeholder="Step Mark" value={ques.answerKey} />
            </Box>
            {
              console.log(errorField["foundEmpty"], "------------------")
            }
            {!!errorField["foundEmpty"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmpty"]}</Typography>}

          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={(e) => onQuestionChange(ques.order, "mark", e.target.value)} placeholder="Marks" value={ques.mark} />
              {!!errorField["inputValidatio"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["inputValidatio"]}</Typography>}
            </Box>
            {
              console.log(errorField["foundEmptyMark"], "-============", errorField["inputIndex"] === ind, ind)
              // errorObj['inputIndex'] = emptyMark
              // errorObj['foundEmptyMark']="Please fill this"
            }
            {(!!errorField["foundEmptyMark"] && errorField["inputIndex"] == ind) && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmptyMark"]}</Typography>}
          </Grid>
          <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", alignItems: "center" }} >
            <img
              className={
                dashboardContentStyles.aRoleActionBtn
              }
              onClick={addQuestion}
              src={action__Add}
              alt="Add-Btn"
              style={{ width: "70%", height: "70%", color: questionMarkTotal >= title ? 'red' : 'none' }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", alignItems: "center" }}>
            <img
              className={
                dashboardContentStyles.aRoleActionBtn
              }
              src={action__Delete}
              alt="Dlt-Btn"
              style={{ width: "70%", height: "70%" }}
              onClick={() => deleteQuestion(ques.order)}
            />
          </Grid>
        </Grid>
      }) : null}

      {
        validationMessage &&
        <h5 style={{ color: 'red', marginLeft: '20px' }}>Sub Question Marks mismatching with the Total Marks</h5>
      }

      <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl sx={{ ml: 1 }}>
          <button
            style={{ maxHeight: "50px" }}
            onClick={handlePreviewModalOpen}
            className={ChapterStyles.blueWhite_btn}
          >
            {"PREVIEWs"}
          </button>
        </FormControl>
        {edit ?
          <>
            <FormControl sx={{ ml: 1 }}>
              <button
                style={{ maxHeight: "50px" }}
                onClick={handleCancelUpdate}
                className={ChapterStyles.blue_btn}
              >
                CANCEL
              </button>
            </FormControl>
            <FormControl sx={{ ml: 1 }}>
              <button
                style={{ maxHeight: "50px" }}
                onClick={() => handleCreateSubQuestion("update")}
                className={ChapterStyles.blue_btn}
              >
                UPDATE
              </button>
            </FormControl>
          </>
          :
          <FormControl sx={{ ml: 1 }}>
            <button
              style={{ maxHeight: "50px" }}
              onClick={() => handleCreateSubQuestion("create")}
              className={chapterStyles.blue_btn}
            >
              CREATE
            </button>
            {errorField.id === "createButton" && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>}
          </FormControl>
        }


      </Grid>



      <UploadMapModal
        open={createModal}
        close={closeCreateModal}
        closeModalAPI={closeModalAPI}
        handleImageListModalOpen={handleImageListModalOpenImage}
        uploadImgMapData={() => { }}
        removeUploadImgMap={removeUploadImgMap}
        selectedImageName={mapImage}
      />
      {openImageListModal ? <ImageListModal
        openImageModal={openImageListModal}
        closeImageModal={handleImageListModalClose}
        selectedImage={setSelectedImageUrl}
        selectedImageName={setSelectedImageName}
      /> : null}
      {openImageListModalImage ? <ImageListModal
        openImageModal={openImageListModalImage}
        closeImageModal={handleImageListModalCloseImage}
        selectedImage={(imageUrl) => { sessionStorage.setItem("mapImage", imageUrl) }}
        selectedImageName={() => { }}
      /> : null}
      <Modal
        open={openPreviewModal}
        onClose={handlePreviewModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={8} className='UP_TaskSec' style={{ maxHeight: "600px", overflowY: "auto" }}>
                <Grid item xs={12} md={12} lg={12} style={{ position: "fixed", width: "50%", zIndex: "9999", backgroundColor: "white" }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    {subjectiveType === "Simple" ? "Simple Text Question" : "Compound Text Question"}
                  </Typography>
                </Grid>
                <Grid style={{ paddingTop: "50px !important" }} item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" style={{ paddingTop: "35px" }} variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Question
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                    dangerouslySetInnerHTML={{ __html: questionContent.content }}
                  >
                  </Typography>
                </Grid>
                {answerKeyContent.content ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Explanation
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                    dangerouslySetInnerHTML={{ __html: answerKeyContent.content }}
                  >
                  </Typography>
                </Grid> : null}
                {imagePreview || selectedImageUrl ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Uploaded Image 111
                  </Typography>
                  <Typography>
                    <img src={imagePreview ? imagePreview : selectedImageUrl} width="50%" height="200px" />
                  </Typography>
                </Grid> : null}
                {/* {imagePreview || selectedImageUrl ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Uploaded Image
                  </Typography>
                  <Typography>
                    <img src={imagePreview ? imagePreview : selectedImageUrl} width="100%" height="200px" />
                  </Typography>
                </Grid> : null} */}
                {/* {mapImage ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Map Image
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                    dangerouslySetInnerHTML={{ __html: questionContentMap.content }}
                  >
                  </Typography>
                  <Typography>
                    <img src={mapImage && mapImage} width="100%" height="200px" />
                  </Typography>
                </Grid> : null} */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {title ? <Grid container style={{ marginTop: "50px" }} spacing={4} sx={{ p: 2 }}>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                > */}
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  Number Of Marks : {title}
                </Typography>



                {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  </Typography> */}
                {/* <CustomTextField id="fullWidth" value={title} disabled /> */}
                {/* </Box> */}
              </Grid> : null}
              <Grid container>
                <Grid item xs={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  S.no
                </Grid>
                <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  Answerkey
                </Grid>
                <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  Mark
                </Grid>
              </Grid>
              {
                console.log("StepMarkDetails", StepMarkDetails)
              }
              {
                StepMarkDetails?.map((v, index) => {
                  console.log(v.answerKey, v.mark); // Add console log here
                  return (
                    <Grid container key={v.answerKey}> {/* Make sure to add a unique key */}
                      <Grid item xs={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {index + 1}.
                      </Grid>
                      <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {v.answerKey}
                      </Grid>
                      <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {v.mark}
                      </Grid>
                    </Grid>
                  );
                })
              }

              <Grid container style={{ marginTop: "50px" }} spacing={4} sx={{ p: 2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  Answer
                </Typography>
                {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}> */}
                <TextArea rows={5} id="fullWidth" value={""} disabled />
                {/* </Typography> */}
                {/* </Box> */}
              </Grid>

              {/* {imagePreview || selectedImageUrl ? <Grid container style={{ marginTop: "10px" }} spacing={4} sx={{ p: 2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  Uploaded Image
                </Typography>
                <Typography>
                  <img src={imagePreview ? imagePreview : selectedImageUrl} width="100%" height="200px" />
                </Typography>
              </Grid> : null} */}
            </Grid>
            {/* {questionList.answerKey ? <Grid item xs={12} md={6} lg={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                Step Mark
              </Typography>
              <Grid container spacing={4} sx={{ p: 2 }}>
                {questionList.map(value => {
                  return (
                    <Grid item xs={12} mg={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}
                          dangerouslySetInnerHTML={{ __html: questionList.answerKey }}
                        >
                        </Typography>
                        <CustomTextField id="fullWidth" value={value.answerKey} disabled />
                      </Box>
                    </Grid>
                  )
                })
                }
              </Grid>
            </Grid> : null}
            {questionList.mark ? <Grid item xs={12} md={6} lg={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                Marks
              </Typography>
              <Grid container spacing={4} sx={{ p: 2 }}>
                {questionList.map(value => {
                  return (
                    <Grid item xs={12} mg={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}
                          dangerouslySetInnerHTML={{ __html: questionList.mark }}
                        >
                        </Typography>
                        <CustomTextField id="fullWidth" value={value.mark} disabled />
                      </Box>
                    </Grid>
                  )
                })
                }
              </Grid>
            </Grid> : null} */}

            <Grid container>
              <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton onClick={handlePreviewModalClose}>Exit</CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal >
      <DeleteConfirmationAlert
        open={showAlert}
        close={onHideModal}
        alert={alertMessage}
        onBackdropClickable={false}
      />
      <DeleteBlanksModal
        open={showAlertType}
        close={onHideModalType}
        alert={"You have succefully Updated Subjective Text Question."}
        caseStudy={true}
      />
      <DeleteConfirmationAlert
        open={showSuccessAlert}
        close={onHideSuccessModal}
        alert={successMessage}
        caseStudy={true}
        openPreview={handlePreviewModalOpen}
        // closePreview={handlePreviewModalClose}
        isEditQuestion={handleEditQuestion}
        setNewConcept={props.setNewConcept}
        setGoToQuestionBank={props.setGoToQuestionBank}
        setPreviewFromModal={setOpenPreviewModal}
        setDisableTopFields={props.setDisableTopFields}
        onBackdropClickable={true}
      />
      {errorModalDetails.open ? <DeleteConfirmationAlert
        open={errorModalDetails.open}
        close={closeErrorModal}
        alert={errorModalDetails.message}
      /> : null}
    </>
  )
}

export default SubjectiveText;
