import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumbs from "../General/BreadCrumbs";
import FilterBar from "./FilterBar";
import TabMenu from "./TabMenu";
import TableComponent from "./TableComponent";
import axios from "axios";
import { isNOTNullOrUndefined } from "../../TeachValidate";
import RejectCommentModal from "../General/Modal/RejectCommentModal";
import RejectConfirmModal from "../General/Modal/RejectConfirmModal";
import SuccessModal from "../General/Modal/SuccessModal";
import SuccessModalPublish from "../General/Modal/SuccessModalPublish"
import QuestionModal from "../../QuestionModal";
import { useLocation } from "react-router";
import RejectedModal from "../General/Modal/RejectedModal";
import commonsort from "../../../js/CommonSort"

function ReviewerDashboard() {
	const location = useLocation();
	const { state } = location;
	console.log(state, "state hello");
	const [selectedMenu, setSelectedMenu] = useState(
		state?.quizStatus ? state?.quizStatus : "PENDING"
	);
	const [selectedChapter, setSelectedChapter] = useState(
		state?.chapter ? state?.chapter : null
	);
	const [selectedQuizType, setSelectedQuizType] = useState(
		state?.quizTypeId ? state?.quizTypeId : null
	);

	useEffect(() => {
		if (state?.quizStatus) {
			setSelectedMenu(state.quizStatus);
		} else {
			setSelectedMenu("PENDING");
			setSelectedChapter("");
			setSelectedQuizType("");
		}
	}, [state]);

	const [selectedQuiz, setSelectedQuiz] = useState({});
	const [isCommentModal, setCommentModal] = useState(false);
	const [isRejectModal, setRejectModal] = useState(false);
	const [comment, setComment] = useState("");
	const [isSuccessModal, setSuccessModal] = useState(false);
	const [isSuccessModalPublish, setSuccessModalPublish] = useState(false);
	const [isRejectedModal, setRejectedModal] = useState(false);
	const [isPreview, setPreview] = useState(false);
	// <-----------state for Tabmenu componenet-------------->
	const [quizes, setQuizes] = useState([]);
	const [pageSize, setPageSize] = useState(20);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const [sortOrder, setSortOrder] = useState(true);
	const [sortBy, setSortBy] = useState("id");
	// <-----------states for filter componenet-------------->
	const [grades, setGrades] = useState([]);
	const [subjects, setSubject] = useState([]);
	const [boards, setBoard] = useState([]);
	const [isCommentError, setCommentError] = useState(false);

	const [filterState, setFilterState] = useState({
		grade: {},
		board: {},
		subject: {},
		searchParams: "",
	});

	const [changeCount, setChangeCount] = useState(1);

	// <-----------state for Tabmenu componenet-------------->

	const handleChange = (e) => {
		setCurrentPage(0);
		setChangeCount((prev) => prev + 1);
		let currentDate = filterState;
		let value = e.target.value;
		let name = e.target.name;
		if (name === "grade") {
			currentDate[name] = grades.find((item) => item.id === value);
		} else if (name === "board") {
			currentDate[name] = boards.find((item) => item.id === value);
		} else if (name === "subject") {
			currentDate[name] = subjects.find((item) => item.id === value);
		} else {
			currentDate[name] = value;
		}
	};

	//<--------------Api to fetch quiz data -------------------->

	const getQuizData = () => {
		const token = sessionStorage.getItem("token");
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz/page?pageNumber=${currentPage}&pageSize=${pageSize}
				&sortOrder=${sortOrder}&sortBy=${sortBy}&active=true
				${isNOTNullOrUndefined(filterState.searchParams)
					? "&search=" + filterState.searchParams
					: ""
				}${isNOTNullOrUndefined(filterState.board?.id)
					? "&boardId=" + filterState.board?.id
					: ""
				}${isNOTNullOrUndefined(filterState.grade?.id)
					? "&gradeId=" + filterState.grade?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subject?.id)
					? "&subjectId=" + filterState.subject?.id
					: ""
				}${isNOTNullOrUndefined(selectedChapter)
					? "&chapterId=" + selectedChapter
					: ""
				}${isNOTNullOrUndefined(selectedQuizType)
					? "&quizTypeId=" + selectedQuizType
					: ""
				}
				&reviewStatus=${selectedMenu}
				`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					console.log(res.data.data.data, sortBy, sortOrder)
					var sortedData = await commonsort(res.data.data.data || [], sortBy, sortOrder);
					res.data.data.data = sortedData
					setQuizes(res.data.data.data);
					setTotalPages(res.data.data.totalPages);
				} else {
					alert("Something went wrong");
				}
			})
			.catch((err) => {
				console.log(err);
				alert(err);
			});
	};
	useEffect(() => {
		getQuizData();
	}, [
		changeCount,
		pageSize,
		currentPage,
		selectedMenu,
		isSuccessModal,
		isCommentModal,
		sortBy,
		sortOrder,
		state,
		selectedChapter,
		selectedQuizType,
	]);

	const rejectQuiz = () => {
		const token = sessionStorage.getItem("token");
		if (comment) {
			axios
				.put(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/review`,
					{
						id: selectedQuiz.id,
						status: "REJECTED",
						comment: comment,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					if (res.data.success) {
						setCommentModal(false);
						setRejectedModal(true);
						setComment("");
					} else {
						alert("Something went wrong");
					}
				})
				.catch((err) => {
					console.log(err);
					alert(err);
				});
		} else {
			setCommentError(true);
		}
	};

	const publishCDN = (id) => {
		const token = sessionStorage.getItem("token");
		console.log("publishCDN ", token, id)
		axios.post(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/entity/upload-quizById-json-s3?quizId=${id}`,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		).then((response) => {
			console.log(response, "published successfully");
			// alert("published successfully")
		}).catch((err) => { console.log(err); });

		setTimeout(() => {
			setSuccessModalPublish(true);
		}, 500);
	}


	const approveQuiz = (id) => {
		const token = sessionStorage.getItem("token");
		console.log("ididid", id, boards)

		axios
			.put(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/review`,
				{
					id: id,
					status: "APPROVED",
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.success) {
					// ${process.env.REACT_APP_SWAGGER_URL}
					axios.post(
						`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/entity/upload-quizById-json-s3?quizId=${id}`,
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
						}
					).then((response) => {
						console.log(response, "published successfully");
						// alert("published successfully")
					}).catch((err) => { console.log(err); });

					setTimeout(() => {
						setSuccessModal(true);
					}, 500);
				} else {
					// alert("Something went wrong");
				}
			})
			.catch((err) => {
				console.log(err);
				alert(err);
			});


		// axios
		// 	.put(
		// 		`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/review`,
		// 		{
		// 			id: id,
		// 			status: "APPROVED",
		// 		},
		// 		{
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `Bearer ${token}`,
		// 			},
		// 		}
		// 	)
		// 	.then((res) => {
		// 		if (res.data.success) {
		// 			setSuccessModal(true);
		// 		} else {
		// 			alert("Something went wrong");
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 		alert(err);
		// 	});
	};

	const addComment = () => {
		const token = sessionStorage.getItem("token");
		if (comment) {
			axios
				.post(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/reject-comment`,
					{
						id: selectedQuiz.id,
						comment: comment,
						status: selectedQuiz.status,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					if (res.data.success) {
						alert("Comment added successfully");
						setCommentModal(false);
						setComment("");
					} else {
						alert("Something went wrong");
					}
				})
				.catch((err) => {
					console.log(err);
					alert(err);
				});
		} else {
			setCommentError(true);
		}
	};

	return (
		<Container>
			<RejectCommentModal
				isOpen={isCommentModal}
				setCommentModal={setCommentModal}
				selectedQuiz={selectedQuiz}
				setComment={setComment}
				comment={comment}
				rejectQuiz={rejectQuiz}
				addComment={addComment}
				isCommentError={isCommentError}
				setCommentError={setCommentError}
			/>
			<SuccessModal
				isOpen={isSuccessModal}
				setSuccessModal={setSuccessModal}
			/>
			<SuccessModalPublish
				isOpen={isSuccessModalPublish}
				setSuccessModal={setSuccessModalPublish}
			/>
			<RejectedModal
				isOpen={isRejectedModal}
				setSuccessModal={setRejectedModal}
			/>

			<RejectConfirmModal
				isOpen={isRejectModal}
				setRejectModal={setRejectModal}
				selectedQuiz={selectedQuiz}
				setSelectedQuiz={setSelectedQuiz}
				setCommentModal={setCommentModal}
			/>

			<MainContainer>
				<BreadCrumbs currentPage={"Quiz Approval"} />
				<FilterBar
					selectedMenu={selectedMenu}
					filterState={filterState}
					setFilterState={setFilterState}
					setGrades={setGrades}
					grades={grades}
					setSubject={setSubject}
					subjects={subjects}
					boards={boards}
					setBoard={setBoard}
					handleChange={handleChange}
				/>
				<TabMenu
					selectedMenu={selectedMenu}
					setSelectedMenu={setSelectedMenu}
					setCurrentPage={setCurrentPage}
				/>
				<TableComponent
					quizes={quizes}
					setQuizes={setQuizes}
					pageSize={pageSize}
					setPageSize={setPageSize}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					totalPages={totalPages}
					setSelectedQuiz={setSelectedQuiz}
					setCommentModal={setCommentModal}
					setRejectModal={setRejectModal}
					approveQuiz={approveQuiz}
					setPreview={setPreview}
					setSortOrder={setSortOrder}
					setSortBy={setSortBy}
					publishCDN={publishCDN}
					selectedMenu={selectedMenu}
				/>
			</MainContainer>

			{isPreview && (
				<QuestionModal
					open={isPreview}
					data={selectedQuiz}
					close={() => {
						setPreview(false);
						setSelectedQuiz({});
					}}
				/>
			)}
		</Container>
	);
}

export default ReviewerDashboard;
const Container = styled.div`
	width: 100% !important;
	display: flex;
`;

const MainContainer = styled.div`
	padding: 10px 20px;
	width: 100% !important;
	background-color: #ffffff;
`;
