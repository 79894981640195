import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import sharestyle from "../css/shareModal.module.css"; //share model css file
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Addbranchtcss from "./../css/addbranch.module.css";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteModal from "./DeleteModal";
import Switch from "../../components/common/Switch";
import teacheridstyles from "../css/TeacherId.module.css";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import axios from "axios";
import GreenAddButton from "../../components/common/GreenAddButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import ConfirmationModal from "./ConfirmationModal";
import sortIcon from "../../../src/pages/img/sort_1.png";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import commonsort from "../js/CommonSort"
// Mui Dropdown Down Arrow Icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Last update details
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

//Action Icon Images
import actionLock from "../../../src/img/password.svg";
import actionEdit from "../../../src/img/Edit.svg";
import actionShare from "../../../src/img/Share.svg";
import actionDelete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import changePasswordPut from "../ChangePassword";
import changepasswordstyle from "../css/changePassword.module.css";
import share from "../Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { MenuProps } from "./Data";
import SuccesModalGeneral from "../../components/common/SuccessModelGeneral";

const AdminUsers = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const token = sessionStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [userlist, setUserlist] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addAdminVisible, setAddAdminVisible] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mail, setMail] = useState("");
  const [num, setNum] = useState("");
  const [message, setMessage] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("userName");
  const [search, setSearch] = useState({
    search: "",
  });
  const [validation, setValidation] = useState(false);


  // Last update details
  const [modifiedDate, setModifiedDate] = useState({});
  const [loginPassword, setLoginPassword] = useState("");

  // Mui TextField Hover Style
  const [adminRoleLabelHovered, setAdminRoleLabelHovered] = useState(false);
  const [fNameLabelHovered, setFnameLabelHovered] = useState(false);
  const [lNamelabelHovered, setlNameLabelHovered] = useState(false);
  const [emailLabelHovered, setemailLabelHovered] = useState(false);
  const [phoneNolabelHovered, setphoneNoLabelHovered] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [showError, setShowError] = useState("");
  const [isError, setIsError] = useState(false);

  const handleLabelMouseEnter1 = () => {
    setAdminRoleLabelHovered(true);
  };
  const handleLabelMouseLeave1 = () => {
    setFnameLabelHovered(false);
  };

  const handleLabelMouseEnter2 = () => {
    setFnameLabelHovered(true);
  };
  const handleLabelMouseLeave2 = () => {
    setFnameLabelHovered(false);
  };

  const handleLabelMouseEnter3 = () => {
    setlNameLabelHovered(true);
  };
  const handleLabelMouseLeave3 = () => {
    setlNameLabelHovered(false);
  };

  const handleLabelMouseEnter4 = () => {
    setemailLabelHovered(true);
  };
  const handleLabelMouseLeave4 = () => {
    setemailLabelHovered(false);
  };

  const handleLabelMouseEnter5 = () => {
    setphoneNoLabelHovered(true);
  };
  const handleLabelMouseLeave5 = () => {
    setphoneNoLabelHovered(false);
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  useEffect(() => {
    initData(sortOrder, sortBy);
  }, []);

  const navigate = useNavigate();

  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.userName?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
  };
  const updateToggle = () => {
    console.log("ckickckcckck");
    let useritem = {
      id: toggleDetails?.id,
      active: toggleDetails?.value,
    };
    console.log(toggleDetails.id, "idd");
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/toggle-active`,
        useritem,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
        }
      })
      .catch((err) => console.log(err));
  };
  const [toggleDetails, setToggleDetails] = useState({});

  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this AdminUser");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this AdminUser");
    }
  };

  // const switchHandler = async (id, e) => {
  //   setToggleDetails({});
  //   const value = e.target.checked;
  //   if (!value) {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/confirmation-api?id=${id}&operationType=TOGGLE`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setToggleConfirmation(true);
  //     settoggleMessage(response?.data?.data?.message);
  //     setToggleDetails({ id, value });
  //   } else {
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/toggle-active`,

  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           data: value,
  //         }
  //       )
  //       .then(function (response) {
  //         if (response?.data) {
  //           const find = updatedData?.find((item) => item.id === id);
  //           find.active = value;
  //           const clone = [...updatedData];
  //           clone.forEach((data) => {
  //             data = find;
  //           });
  //           setFilterValue(clone);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showDeleteModal = async (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
    // const response = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/confirmation-api?id=${row.id}&operationType=DELETE`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    setMessage(row.firstName + " " + row.lastName);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  useEffect(() => {
    setFilterValue(adminUsers);
  }, [adminUsers]);

  const getAllUser = (setUserlist) => {
    let token = sessionStorage.getItem("token");

    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/admin-roles`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserlist(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }

  const addAdmin = () => {
    let token = sessionStorage.getItem("token");
    if (!validateEmail(mail)) {
      setIsError(true);
      setShowError("Please enter a valid email address");
      setValidation(true);
      return;
    }
    if (
      !userRole ||
      fname.length == 0 ||
      lname.length == 0 ||
      mail.length == 0 ||
      num.length === 0
    ) {
      setValidation(true);
      return;
    }
    var item = {
      // "id": "ff80818180f65df90180f67e409a0172",
      firstName: fname,
      lastName: lname,
      email: mail.toLowerCase(),
      phoneNumber: num,
      userRoles: [
        {
          roleId: userRole,
          active: true,
        },
      ],
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };
    setSuccessDisplayName(item.firstName + " " + item.lastName);
    let result = axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("success",res)

        setShowError(false);
        setAddAdminVisible(false);
        setValidation(false);
        initData(sortOrder, sortBy, search, true);
        setUserRole(null);
        setFname("");
        setLname("");
        setMail("");
        setNum("");
        // setTimeout(() => {
        //   setSuccessCreateModal(true);
        // }, 1500);
      })
      .catch((err) => {
        console.log(err);
        if (err.message.includes("400")) {
          setIsError(true);
          setShowError("Please enter a valid data., mobile must be 10 Digits");
        }
      });
  };

  const deleteHandler = () => {
    let id = selectedItem.id;
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== id);
          setFilterValue(find);
        }
      })
      .catch((err) => console.log(err));
    hideDeleteModal();
    navigate("/dashboard/CreateAdminUser");
  };

  const handleSearch = (e) => {
    const search = {
      search: e.target.value,
    };
    initData(sortOrder, sortBy, search);
  };
  // const initData1 = async (array, field, order) => {
  //   console.log(array, field, order);
  //   order = order === true ? "desc" : "asc";

  //   if (array?.length > 0) {
  //     const nullsFirst = order === "asc";

  //     var x = array.sort((a, b) => {
  //       const aRoles = a?.roles;
  //       const bRoles = b?.roles;

  //       // Check if roles are null
  //       if (aRoles === null && bRoles === null) return 0;
  //       if (aRoles === null) return nullsFirst ? -1 : 1;
  //       if (bRoles === null) return nullsFirst ? 1 : -1;

  //       const aValue = aRoles[0]?.[field];
  //       const bValue = bRoles[0]?.[field];

  //       // Check if field values are null
  //       if (aValue === null && bValue === null) return 0;
  //       if (aValue === null) return nullsFirst ? -1 : 1;
  //       if (bValue === null) return nullsFirst ? 1 : -1;

  //       // Sort based on the type of the field value
  //       if (typeof aValue === 'boolean') {
  //         if (order === 'asc') {
  //           return aValue === bValue ? 0 : aValue ? 1 : -1;
  //         } else {
  //           return aValue === bValue ? 0 : aValue ? -1 : 1;
  //         }
  //       } else if (typeof aValue === 'string') {
  //         if (order === 'asc') {
  //           return aValue.localeCompare(bValue);
  //         } else {
  //           return bValue.localeCompare(aValue);
  //         }
  //       } else if (typeof aValue === 'number' || aValue instanceof Date) {
  //         if (order === 'asc') {
  //           return aValue - bValue;
  //         } else {
  //           return bValue - aValue;
  //         }
  //       }
  //     });

  //     console.log(x, array);
  //   }
  // };

  const initData = async (sortOrder, sortBy, search, exe) => {
    setUpdatedData([]);
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/user/admin-users/all?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search || ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );



    if (data.data.data.length > 0) {
      // console.log(data.data.data);
      // setPage(0);
      var sorted = await commonsort(data.data.data || [], sortBy, sortOrder);
      setAdminUsers(sorted);
      setUpdatedData(sorted);
      if (exe) {
        setTimeout(() => {
          setSuccessCreateModal(true);
        }, 500);
      }
    } else {
      setAdminUsers([]);
    } //
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  useEffect(() => {
    initData(sortOrder, sortBy, search);
  }, [search]);
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);

  useEffect(() => {
    getAllUser(setUserlist);
    // setFilterValue(adminUsers)
  }, []);

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };

  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "ADMIN_USERS";
  const [regexPass, setRegexPass] = useState(true);
  const [successP, setPSuccess] = useState(false);
  const [shareClick, setShareClick] = useState(false)
  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }

  const handleSubmit = (e) => {
    changePasswordPut(userName, userId, password, persona, setPSuccess);

    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);
      return;
    }
    if (successP === true) {
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
    setLoginPassword("");
    setConfirmLoginPassword("");
    setSuccessModalGeneral(true);
    setSuccessDisplayName(
      "Password updated successfully"
    );
  };
  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };
  // const handleShareModalOk = (e) => {
  //   setShowModalShare(false);
  // };
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };

  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };

  const shareHandler = (row) => {
    setUserId(row.id);
    setUserName(row.userName);
    userId && share(userName, userId, persona);
    setShowModalShare(true);
    setShareClick(true)
  };
  const [showModalShare, setShowModalShare] = useState(false);
  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  useEffect(() => {
    if (shareClick) { share(userName, userId, persona) };
  }, [userName, userId]);


  const [selectedCode, setSelectedCode] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const textfieldStyle = {
    fontSize: "14px !important",
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",
        fontSize: "13px !important",
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "orange !important",
      fontSize: "13px !important",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "& .MuiInputLabel-root": {
      color: "#B5B6B6 !important",
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      height: "48px !important",
      // width: "270px !important",
      borderRadius: "5px !important",
    },
  };
  const [SuccessDisplayName, setSuccessDisplayName] = useState("");

  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [successModalGeneral, setSuccessModalGeneral] = useState(false);

  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
    setSuccessModalGeneral(false);
  };
  const lastNameHandler = (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");
    setLname(result);
  };
  const firstNameHandler = (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");
    setFname(result);
  };

  const handleKeyPress = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setNum(result);
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };


  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );



  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}

            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            id={`az-breadscrum-home`}
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          {/* {"  "}
          <a>Admin User</a> */}
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Admin Users
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addAdminVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Admin User" : "Add Admin User"}
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    padding: "20px 20px",
                  }}
                >
                  <div>
                    {/*  <InputLabel  id="demo-multiple-checkbox-label">
                   

                      *Grade(s)
                    </InputLabel>
                    <Select
                      name="gradeId"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      input={<OutlinedInput label="Tag" />}
                    //   value={chapterInputs.gradeId}
                    //   onChange={handlerChapterInputs}
                    >

                    {gradesName.length > 0 ? gradesName.map((elem,i)=>{

                     

                      return(
                          <MenuItem key={i} value={elem.id}>
                          <ListItemText primary={elem.grade} />
                          </MenuItem>
                        )

                    }) : <MenuItem>Select</MenuItem>}

                    {optionsRender(gradesName)}
                     
                    </Select>*/}

                    {/* <Box style={{ margin: "auto 1px", width: "247px" }}> */}
                    <FormControl
                      sx={{
                        marginBottom: "10px",
                        width: "225px",
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            border: "1px solid #272727 !important",
                            fontSize: "13px !important",
                          },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "orange !important",
                          fontSize: "13px !important",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#B5B6B6 !important",
                          fontSize: "14px !important",
                        },
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          // width: "270px !important",
                          width: "100% !important",
                          borderRadius: "150px !important",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                      // shrink
                      >
                        *Select Admin Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Admin role sel"
                        value={userRole}
                        onChange={(e) => setUserRole(e.target.value)}
                        style={{ background: "#fff", borderRadius: 150, color: "#ffa500" }}
                        sx={{
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffa500',
                          },
                        }}
                        //   MenuProps={MenuProps}
                        // MenuProps={{
                        //   anchorOrigin: {
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        //   },
                        //   transformOrigin: {
                        //     vertical: "top",
                        //     horizontal: "left",
                        //   },
                        //   getContentAnchorEl: null,
                        //   style: {
                        //     maxHeight: "300px",
                        //   },
                        // }}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem
                            value=""
                            className={dashboardContentStyles.menuitem}
                            style={{ fontSize: "13px", paddingLeft: "20px" }}
                          >
                            All
                          </MenuItem> */}
                        {userlist.length > 0 ? (
                          userlist?.map((item) => (
                            <MenuItem
                              className={dashboardContentStyles.dropdowns}
                              key={item.id}
                              value={item.id}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: '#FFA500',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#FFA500',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <ListItemText
                                primaryTypographyProps={{ fontSize: "13px" }}
                                className={dashboardContentStyles.listitemtext}
                                primary={item.role}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>Select</MenuItem>
                        )}
                      </Select>
                      {validation && !userRole && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Admin Role
                        </span>
                      )}
                    </FormControl>
                    {/* </Box> */}
                  </div>
                  <Box
                    style={{
                      marginTop: "10px",
                      // width: "75%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",

                    }}
                  >
                    <FormControl >
                      {/* <TextField
                        sx={textfieldStyle}
                        id="demo-helper-text-aligned-no-helper"
                        label="*First-Name"
                        autoComplete="off"
                        onChange={(e) => firstNameHandler(e)}
                        variant="outlined"
                        value={fname}
                      /> */}
                      <FormControl>
                        <div
                          className={
                            Addbranchtcss.input_element
                          }
                        >
                          <input
                            style={{ width: "225px", borderRadius: "150px", color: "#ffa500" }}


                            className={
                              Addbranchtcss.form_input_element
                            }
                            type="text"
                            name="name"
                            id="name"
                            value={fname}
                            placeholder=" "
                            onChange={(e) =>
                              firstNameHandler(e)
                            }
                          />

                          <label htmlFor="name">
                            *First-Name
                          </label>
                        </div>
                      </FormControl>

                      {/* <TextField
                      // Hover effect
                      onMouseEnter={handleLabelMouseEnter2}
                      onMouseLeave={handleLabelMouseLeave2}
                      // InputLabelProps={{ shrink: false }}
                      InputLabelProps={{
                        shrink: fNameLabelHovered,
                        style: { color: "#FD8C00" } || Boolean.value,
                       
                      }}
                      //-----------------------------------------------------------
                      id="demo-helper-text-aligned-no-helper"
                      label="*First-Name"
                      autoComplete="none"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      sx={
                        fNameLabelHovered === false
                          ? {
                              "& .MuiInputLabel-root": {
                                color: "#B5B6B6 !important",
                                fontSize: "14px !important",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                          : {
                              "& .MuiInputLabel-root": {
                                color: "#FD8C00 !important",
                                fontSize: "19px !important",
                                fontWeight: "bolder",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                      }
                    /> */}
                      {validation && !fname && (
                        <span style={{ color: "red", padding: "5px" }}>
                          FirstName is required
                        </span>
                      )}
                    </FormControl>
                    {/* <TextField
                      // Hover effect
                      onMouseEnter={handleLabelMouseEnter3}
                      onMouseLeave={handleLabelMouseLeave3}
                      InputLabelProps={{
                        shrink: lNamelabelHovered,
                        style: { color: "#FD8C00 " } || Boolean.value,
                      }}
                      //-----------------------------------------------------------
                      id="demo-helper-text-aligned-no-helper"
                      label="*Last Name"
                      autoComplete="none"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                      sx={
                        lNamelabelHovered === false
                          ? {
                              "& .MuiInputLabel-root": {
                                color: "#B5B6B6 !important",
                                fontSize: "14px !important",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                          : {
                              "& .MuiInputLabel-root": {
                                color: "#FD8C00 !important",
                                fontSize: "19px !important",
                                fontWeight: "bolder",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                      }
                    /> */}
                    <FormControl >
                      {/* <TextField
                        sx={textfieldStyle}
                        id="demo-helper-text-aligned-no-helper"
                        label="*Last Name"
                        autoComplete="off"
                        onChange={(e) => lastNameHandler(e)}
                        variant="outlined"
                        value={lname}
                      /> */}
                      <FormControl>
                        <div
                          className={
                            Addbranchtcss.input_element
                          }
                        >
                          <input
                            style={{ width: "225px", borderRadius: "150px", color: "#ffa500" }}
                            className={
                              Addbranchtcss.form_input_element
                            }
                            type="text"
                            name="name"
                            id="name"
                            value={lname}
                            placeholder=" "
                            onChange={(e) =>
                              lastNameHandler(e)
                            }
                          />

                          <label htmlFor="name">
                            *Last Name
                          </label>
                        </div>
                      </FormControl>
                      {validation && !lname && (
                        <span style={{ color: "red", padding: "5px" }}>
                          LastName is required
                        </span>
                      )}
                    </FormControl>
                    {/* <TextField
                      // Hover effect
                      onMouseEnter={handleLabelMouseEnter4}
                      onMouseLeave={handleLabelMouseLeave4}
                      InputLabelProps={{
                        shrink: emailLabelHovered,
                        style: { color: "#FD8C00 " } || Boolean.value,
                      }}
                      //-----------------------------------------------------------
                      id="demo-helper-text-aligned-no-helper"
                      label="*Primary Email"
                      autoComplete="none"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      sx={
                        emailLabelHovered === false
                          ? {
                              "& .MuiInputLabel-root": {
                                color: "#B5B6B6 !important",
                                fontSize: "14px !important",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                          : {
                              "& .MuiInputLabel-root": {
                                color: "#FD8C00 !important",
                                fontSize: "19px !important",
                                fontWeight: "bolder",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                      }
                    /> */}
                    <FormControl >
                      {/* <TextField
                        sx={textfieldStyle}
                        id="demo-helper-text-aligned-no-helper"
                        label="*E-mail"
                        autoComplete="off"
                        onChange={(e) => setMail(e.target.value)}
                        variant="outlined"
                        value={mail}
                      /> */}
                      <FormControl>
                        <div
                          className={
                            Addbranchtcss.input_element
                          }
                        >
                          <input
                            style={{ width: "225px", borderRadius: "150px", color: "#ffa500" }}
                            className={
                              Addbranchtcss.form_input_element
                            }
                            type="text"
                            name="name"
                            id="name"
                            value={mail}
                            placeholder=" "
                            onChange={(e) =>
                              setMail(e.target.value)
                            }
                          />

                          <label htmlFor="name">
                            *E-mail
                          </label>
                        </div>
                      </FormControl>
                      {validation && !mail && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Email is required
                        </span>
                      )}
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <select
                        style={{
                          width: "60px",
                          height: "48px",
                          borderRadius: "8px",
                        }}
                        value={selectedCode}
                        onChange={handleCodeChange}
                      >
                        <option value="+1">+91</option>
                        <option value="+44">+44</option>
                        <option value="+91">+1</option>
                      </select>
                      {/* <TextField
                      // Hover effect
                      onMouseEnter={handleLabelMouseEnter5}
                      onMouseLeave={handleLabelMouseLeave5}
                      InputLabelProps={{
                        shrink: phoneNolabelHovered,
                        style: { color: "#FD8C00" } || Boolean.value,
                      }}
                      //-----------------------------------------------------------
                      id="demo-helper-text-aligned-no-helper"
                      label="*Primary Phone no."
                      autoComplete="none"
                      value={num}
                      onChange={(e) => setNum(e.target.value)}
                      sx={
                        phoneNolabelHovered === false
                          ? {
                              "& .MuiInputLabel-root": {
                                color: "#B5B6B6 !important",
                                fontSize: "14px !important",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                          : {
                              "& .MuiInputLabel-root": {
                                color: "#FD8C00 !important",
                                fontSize: "19px !important",
                                fontWeight: "bolder",
                              },
                              "& .MuiInputBase-root": {
                                height: "48px !important",
                                // width: "270px !important",
                                width: "100% !important",
                                borderRadius: "5px !important",
                              },
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }
                      }
                    /> */}
                      <FormControl >
                        {/* <TextField
                          sx={textfieldStyle}
                          id="demo-helper-text-aligned-no-helper"
                          label="*Primary-Number"
                          autoComplete="off"
                          onChange={(e) => handleKeyPress(e)}
                          variant="outlined"
                          value={num}
                          maxLength="10"
                        /> */}
                        <FormControl>
                          <div
                            className={
                              Addbranchtcss.input_element
                            }
                          >
                            <input
                              style={{ width: "210px", borderRadius: "150px", color: "#ffa500" }}
                              className={
                                Addbranchtcss.form_input_element
                              }
                              type="text"
                              name="name"
                              id="name"
                              value={num}
                              maxLength={10}
                              placeholder=" "
                              onChange={(e) =>
                                handleKeyPress(e)
                              }
                            />

                            <label htmlFor="name">
                              *Primary-Number
                            </label>
                          </div>
                        </FormControl>
                        {validation && !num && (
                          <span style={{ color: "red", padding: "5px" }}>
                            phone Number is required
                          </span>
                        )}
                      </FormControl>
                    </div>
                  </Box>
                  <div
                    style={{
                      // marginTop: "10px",
                      marginTop: "50px",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      columnGap: "1rem",
                    }}
                  >
                    <div
                      className={teacheridstyles.error_box}
                      style={
                        isError ? { display: "block" } : { display: "none" }
                      }
                    >
                      {showError}
                    </div>
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        setIsError(false)
                        setShowError("")
                        setAddAdminVisible(false);
                        setValidation(false);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton>Update</BlueButton>
                    ) : (
                      <BlueButton
                        onClick={(e) => {
                          // navigate('/dashboard/CreateAdminUser')
                          addAdmin();
                        }}
                      // disabled={roleDetail.hasRoleError}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ManageAccountsOutlinedIcon style={{ fontSize: "25px" }} />
              <span
                style={{
                  fontSize: "18px",
                  color: "#354052",
                  fontWeight: "500",
                }}
              >
                Admin User List
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Name/Email/Mobile..."}
                name="search"
                Value={search.search}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    marginLeft: "50px !important",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px #FFFFFF",
                    opacity: 1,
                  },
                }}
                // onChange={onSearch}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />

              <GreenAddButton
                onClick={() => {
                  setAddAdminVisible(true);
                  //navigate('/dashboard/CreateAdminUser')
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  style={{
                    marginRight: "10px",
                    marginBottom: "2px",
                    fontSize: "medium",
                  }}
                />
                Add Admin User
              </GreenAddButton>
            </div>
          </div>
          <Paper sx={{ width: "81vw", overflow: "hidden" }}>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ zIndex: "0" }}
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        #
                        {/* <img
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        style={{paddingLeft: "5px"}}
                       
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("id");
                          await initData(!sortOrder, "id");
                        }}
                      /> */}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ zIndex: "0" }}
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Name{" "}
                        <img
                          style={{ marginLeft: "5px" }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("userName");
                            await initData(!sortOrder, "userName");
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ zIndex: "0" }}
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Role{" "}
                        <img
                          style={{ marginLeft: "5px" }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("role");
                           // console.log("adminUsers", adminUsers, "field", "role")
                            // await initData1(adminUsers, "role", !sortOrder);
                            // await initData1(updatedData, "role", !sortOrder);
                            await initData(!sortOrder, "role");
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ zIndex: "0" }}
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Email{" "}
                        <img
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          style={{ margin: "0 0 3px 5px" }}
                          src={sortIcon}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("email");
                            await initData(!sortOrder, "email");
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ zIndex: "0" }}
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Mobile{" "}
                        <img
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          style={{ margin: "0 0 2px 5px" }}
                          src={sortIcon}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("phoneNumber");
                            await initData(!sortOrder, "phoneNumber");
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell
                      style={{ zIndex: "1" }}
                      align="right"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{
                        textAlign: "center",
                        paddingRight: "36px",
                        zIndex: "1",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}

                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={dashboardContentStyles.table_cell}

                        >
                          {page * rowsPerPage + (index + 1)}

                        </TableCell>
                        <TableCell

                          align="left"
                          className={dashboardContentStyles.table_cell_name}
                        >
                          {row?.firstName + " " + row?.lastName}
                        </TableCell>

                        <TableCell

                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {Array.isArray(row.roles) && row.roles.map((v, i) => {
                            return <span key={i}>{v.role}</span>;
                          })}

                          {/* {row.roles.length &&
                            row.roles.map((v, i) => {
                              return <span key={i}>{v.role}</span>;
                            })} */}
                        </TableCell>
                        <TableCell

                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell

                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.phoneNumber}
                        </TableCell>

                        <TableCell align="right" >
                          <div>
                            <Switch
                              checked={row?.active}
                              onChange={(e) => switchHandler(row?.id, e)}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right" >
                          <LockIconButton>
                            <CustomTooltip title="Change Password" placement={"top"}>


                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={actionLock}
                                alt="Lock-btn"
                                onClick={() => {
                                  setShowModalChangePassword(true);
                                  setUserId(row.id);
                                  setUserName(row.userName);
                                  setRegexPass(true);
                                }}
                              />
                            </CustomTooltip>
                          </LockIconButton>
                          <EditIconButton
                            onClick={(e) => {
                              e.preventDefault();
                              var userId = row.id;
                              navigate("/dashboard/EditAdminUser");
                              sessionStorage.setItem("userId", userId);
                            }}
                          >
                            <CustomTooltip title="Edit" placement={"top"}>
                              {/* <EditOutlinedIcon style={{ fontSize: "1.5vw" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={actionEdit}
                                alt="Edit-btn"
                              />
                            </CustomTooltip>
                          </EditIconButton>

                          <ShareIconButton>
                            <CustomTooltip title="Share " placement={"top"}>
                              {/* <ShareOutlinedIconstyle={{ fontSize: "1.5vw" }}/> */}
                              <img
                                // onClick={shareHandler}
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={actionShare}
                                alt="Share-btn"
                                onClick={() => shareHandler(row)}
                              />
                            </CustomTooltip>
                          </ShareIconButton>

                          {/* <DeleteIconButton
                            onClick={() => {
                              showDeleteModal(row);
                            }}
                          > */}
                          {/* <Tooltip title="Remove" placement={"top"}> */}
                          {/* <DeleteOutlinedIcon style={{ fontSize: "1.5vw" }}/> */}
                          {/* <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={actionDelete}
                                alt="Dlt-btn"
                              />
                            </Tooltip>
                          </DeleteIconButton> */}
                        </TableCell>
                      </TableRow>
                    ))

                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div
              className={changepasswordstyle.flex_container_teacherid}
              id={changepasswordstyle.modal_teacherid}
              style={
                showModalChangePassword
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <form class={changepasswordstyle.setPassword_login_form}>
                <div class={changepasswordstyle.login_form_title}>
                  <h5>Change Password</h5>
                  <span>
                    <i
                      class="fa-solid fa-xmark"
                      onClick={handleUpdatedPassword}
                    ></i>
                  </span>
                </div>
                <div class={changepasswordstyle.password_container}>
                  <fieldset className={changepasswordstyle.password_fieldset}>
                    <legend>New Password</legend>
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="userPassword"
                      id={changepasswordstyle.password}
                      value={loginPassword}
                      onChange={PassOnChange}
                    />
                    <i
                      className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"
                        } ${changepasswordstyle.bi_eye_slash}`}
                      id={changepasswordstyle.togglePassword}
                      onMouseEnter={() => {
                        toggleLoginPasswordVisibility("show");
                      }}
                      onMouseLeave={() => {
                        toggleLoginPasswordVisibility("dontShow");
                      }}
                    ></i>
                  </fieldset>
                </div>
                <div class={changepasswordstyle.password_container}>
                  <fieldset className={changepasswordstyle.password_fieldset}>
                    <legend>Confirm Password</legend>
                    <Tooltip
                      title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                      open={isTooltipOpen}
                      onOpen={handleTooltipOpen}
                      onClose={handleTooltipClose}
                    >
                      <input
                        type={confirmPasswordShown ? "text" : "password"}
                        name="confirmPassword"
                        id={changepasswordstyle.password}
                        value={confirmLoginPassword}
                        onChange={(e) =>
                          setConfirmLoginPassword(e.target.value)
                        }
                      />
                    </Tooltip>
                    <i
                      className={`bi ${confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                        } ${changepasswordstyle.bi_eye_slash}`}
                      id={changepasswordstyle.togglePassword}
                      onMouseEnter={() => {
                        toggleconfirmLoginPasswordVisibility("Confirmshow");
                      }}
                      onMouseLeave={() => {
                        toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                      }}
                    ></i>
                  </fieldset>
                  <span
                    class={changepasswordstyle.error_span}
                    style={
                      passwordMatch
                        ? { display: "none" }
                        : { display: "inline" }
                    }
                  >
                    Passwords do not match
                  </span>
                  <span
                    className={changepasswordstyle.error_span}
                    style={
                      isPassEmpty ? { display: "inline" } : { display: "none" }
                    }
                  >
                    Password cannot be empty
                  </span>
                </div>
                <div class={changepasswordstyle.login_container}>
                  <button
                    class={changepasswordstyle.sp_login_btn}
                    onClick={handleSubmit}
                  >
                    UPDATE
                  </button>
                </div>
              </form>
            </div>

            {/* change password modal ends  */}

            {/* share modal starts  */}
            <div
              className={sharestyle.flex_container_teacherid}
              id={sharestyle.modal_teacherid}
              style={showModalShare ? { display: "flex" } : { display: "none" }}
            >
              <div
                className={sharestyle.main_content_teacherid}
                id={sharestyle.modal_content_teacherid}
              >
                <span className={sharestyle.img}>
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: "70px",
                      marginLeft: "10px",
                      color: "#ffa500",
                    }}
                  />{" "}
                </span>

                <h5 className={sharestyle.head}>Successfully shared User Id</h5>
                <p
                  className={sharestyle.para}
                  style={{ marginTop: "8px", fontWeight: "bold" }}
                >
                  " {userName}"
                </p>
                <div className={sharestyle.modal_buttons_teacherid}>
                  <button
                    className={sharestyle.ok_btn}
                    id={sharestyle.ok_btn_1_teacherid}
                    onClick={handleShareModalOk}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
            {/* share modal ends */}
            <TablePagination
              style={{
                bottom: "0",
                width: '81vw',
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Admin User"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Admin User"}
          userName_Modal={SuccessDisplayName}
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <SuccesModalGeneral
          userType={"Admin User"}
          userName_Modal={SuccessDisplayName}
          open={successModalGeneral}
          handleOk={closeSuccessModal}
        />
      </div>
    </>
  );
};

export default AdminUsers;