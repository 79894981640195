import axios from 'axios'
import commonsort from "../../js/CommonSort";

async function fileStatus_approved_get(setChapters, rowsInput, page, setPageDetail, search, gradeInput, subjectInput, subTopicInput, boardInput, sortBy, sortOrder, chapterId
) {
  let token = sessionStorage.getItem("token");
  let URL = ''

  if (gradeInput !== "" || subjectInput !== "" || subTopicInput !== "" || boardInput !== "") {
    if (gradeInput !== "" && subjectInput === "" && subTopicInput === "" && boardInput === "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}`;
    }
    if (gradeInput === "" && subjectInput !== "" && subTopicInput === "" && boardInput === "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subjectId=${subjectInput}`;
    }
    if (gradeInput === "" && subjectInput === "" && subTopicInput !== "" && boardInput === "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subTopicId=${subTopicInput}`;
    }
    if (gradeInput === "" && subjectInput === "" && subTopicInput === "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput !== "" && subTopicInput === "" && boardInput === "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}`;
    }
    if (gradeInput === "" && subjectInput !== "" && subTopicInput !== "" && boardInput === "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subjectId=${subjectInput}&subTopicId=${subTopicInput}`;
    }
    if (gradeInput === "" && subjectInput === "" && subTopicInput !== "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&boardId=${boardInput}&subTopicId=${subTopicInput}`;
    }
    if (gradeInput === "" && subjectInput !== "" && subTopicInput === "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subjectId=${subjectInput}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput === "" && subTopicInput === "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput !== "" && subTopicInput === "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput !== "" && subTopicInput !== "" && boardInput !== "") {
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?chapterStatus=APPROVED&pageNumber=${page - 1 == -1 ? 0 : page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}&boardId=${boardInput}&subTopicId=${subTopicInput}`;
    }
    if (chapterId && URL) URL += `&chapterId=${chapterId}`
  } else {
    URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}&chapterStatus=APPROVED&adminType=REVIEWER&search=${search?.search}`
    if (chapterId) URL += `&chapterId=${chapterId}`
  }
  let result = await axios.get(URL,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (json) => {
      if (json.data.data) {
        var sortedData = await commonsort(json.data.data.data || [], sortBy, sortOrder);
        json.data.data.data = sortedData;
        setChapters(json.data.data.data)
        setPageDetail(json.data.data)
      }
    })
    .catch(err => console.log(err))
}

export default fileStatus_approved_get;
