import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import Pagination1 from "./pagination1";
import quizStyles from "./../css/quiz.module.css";
// get create school css in this css file
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Table,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Switch,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import quiz_paginated_get from "../API_CONTROLLER/quiz-controller/quiz_paginated_get";
// import documentType_controller_getall from '../API_CONTROLLER/document-category-controller/documentType_controller_getall';
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import sortIcon from "../../../src/pages/img/sort_1.png";
import quiz_last_modified from "../API_CONTROLLER/quiz-controller/quiz_last_modified";
import CommonDeleteModal from "./CommonDeleteModal";
import ConfirmationModal from "./ConfirmationModal";
import EditableQuizModal from "./EditableQuizModal";
import BreadCrumbs from "../js/RevisionAdmin/General/BreadCrumbs";
import { makeStyles } from '@material-ui/core/styles';
import commonSort from "../js/CommonSort";

const useStyles = makeStyles((theme) => ({
	tableRow: {
		display: 'flex',
		gap: '100%',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: '10px 20px',
		position: 'relative',
		'&:hover': {
			borderColor: '#fd8c00', // Change border color on hover
		},
	},
}));

const ViewQuizzes = () => {
	//sort
	const [sortOrder, setSortOrder] = useState(true);
	const [sortBy, setSortBy] = useState("id");

	const [gradesName, setGradesName] = useState({});
	const [board, setBoard] = useState({});
	const [subject, setSubject] = useState({});
	const [subtopic, setSubtopic] = useState({});
	const [chapters, setChapters] = useState({});
	const [chapter, setChapter] = useState("");
	const [lastModifiedDate, setLastModifiedDate] = useState("");
	const [documenttype, setDocumenttype] = useState({});
	const [selectedImage, setSelectedImage] = useState("");
	const [selectedSubtopic, setSelectedSubtopic] = useState("");
	const [selectedChapter, setSelectedChapter] = useState("");
	const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
	const [docType, setDoctype] = useState({});
	const [gradeInput, setGradeInput] = useState("");
	const [subjectInput, setSubjectInput] = useState("");
	const [subTopicInput, setSubTopicInput] = useState("");

	const [boardsList, setBoardsList] = useState([]);
	const [selectedBoard, setSelectedBoard] = useState("");

	const [gradesList, setGradesList] = useState([]);
	const [selectedGrade, setSelectedGrade] = useState("");

	const [subjectsList, setSubjectsList] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(true);
	const [selectedSubject, setSelectedSubject] = useState("");

	const [subTopicsList, setSubTopicsList] = useState([]);
	const [selectedSubTopic, setSelectedSubTopic] = useState("");

	const [quizTypeList, setQuizTypeList] = useState([]);
	const [selectedQuizType, setSelectedQuizType] = useState("");

	const [academicYearsList, setAcademicYearsList] = useState([]);
	const [academicYears, setAcademicYears] = useState("");

	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState("");

	const [toggleConfirmation, setToggleConfirmation] = useState(false);
	const [toggleMessage, settoggleMessage] = useState();
	const [toggleDetails, setToggleDetails] = useState({});
	const navigate = useNavigate();

	const [chapterInputs, setChapterInputs] = useState({
		// selectedBoard : "",
		// selectedGrade : "",
		// selectedSubject: "",
		// selectedSubtopic:"",
		// selectedChapter:"",
		boardId: "",
		gradeId: "",
		subjectId: "",
		chapter: "",
		docType: "",
		logoUrl: "",
		chapterStatus: "PENDING",
	});

	const [theQuizData, setTheQuizData] = useState([]);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [quiztable, setQuiztable] = useState([]);
	let token = sessionStorage.getItem("token");
	const classes = useStyles();


	const handleCreateChapter = (e) => {
		e.preventDefault();
		chapters_controller_post(chapterInputs);
		// setChapterInputs("")
		navigate("/dashboard/coadminDashboard");
	};

	const handlerChapterInputs = (e) => {
		e.preventDefault();
		setChapterInputs({
			...chapterInputs,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		quiz_last_modified(setLastModifiedDate);
		return () => { };
	}, []);

	// pagination starts
	const [rowsInput, setRowsInputs] = useState({ rows: 10 });
	const [page, setPage] = useState(0);
	const [pageDetail, setPageDetail] = useState({});

	const handleChangePage = (event, newPage) => {
		console.log("handleChangePage", newPage)
		paginationAPI(newPage, rowsPerPage);
		setPage(newPage);
	};
	const handleRowsPerPage = (event) => {
		console.log(event.target.value, parseInt(event.target.value, 10))
		paginationAPI(0, event.target.value);
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const handlerRowsInputs = (e) => {
		e.preventDefault();
		setRowsInputs({
			...rowsInput,
			[e.target.name]: e.target.value,
		});
	};

	const showDeleteModal = (row) => {
		setOpenDeleteModal(true);
	};
	const hideDeleteModal = () => {
		setOpenDeleteModal(false);
		setSelectedItem("");
	};

	// pagination ends

	const [search, setSearch] = useState({
		search: "",
	});

	const handleSearch = async (e) => {
		e.preventDefault();
		const search = {
			search: e.target.value,
		};
		if (e.target.value.length > 3) {
			axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?search=${e.target.value}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					// setBoardsList(res.data.data);
					setQuiztable(res.data.data);
				})
				.catch((err) => {
					console.log(err, "list err");
				});
		} else {
			if (e.target.value.length < 1) {
				axios
					.get(
						`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?pageNumber=0&pageSize=10&sortOrder=${sortOrder}&sortBy=${sortBy}`,
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setQuiztable(res.data.data);
					})
					.catch((err) => {
						console.log(err, "list err");
					});
			}
		}

		// quiz_paginated_get(
		//   setChapters,
		//   rowsInput,
		//   page,
		//   setPageDetail,
		//   search,
		//   gradeInput,
		//   subjectInput,
		//   subTopicInput
		// );
	};

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setBoardsList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});

		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setGradesList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});

		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setSubjectsList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});

		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-types`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setQuizTypeList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});

		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setAcademicYearsList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	}, []);

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${selectedSubject}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setSubTopicsList(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	}, [selectedSubject]);

	useEffect(() => {
		if (
			!selectedBoard &&
			!selectedGrade &&
			!selectedSubject &&
			!selectedSubTopic &&
			!selectedQuizType &&
			!academicYears
		) {
			axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?pageNumber=0&pageSize=10&sortOrder=${sortOrder}&sortBy=${sortBy}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					setQuiztable(res.data.data);
				})
				.catch((err) => {
					console.log(err, "list err");
				});
		} else {
			axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL
					}v1/api/content/quiz/page?pageNumber=1&pageSize=10${sortOrder ? `&sortOrder=${sortOrder}` : ""
					}${sortBy ? `&sortBy=${sortBy}` : ""}${selectedBoard ? `&boardId=${selectedBoard}` : ""
					}${selectedGrade ? `&gradeId=${selectedGrade}` : ""}${selectedSubject ? `&subjectId=${selectedSubject}` : ""
					}${selectedSubTopic
						? `&subTopicId=${selectedSubTopic}`
						: ""
					}${selectedQuizType
						? `&quizTypeId=${selectedQuizType}`
						: ""
					}${academicYears ? `&academicYearId=${academicYears}` : ""
					}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					setQuiztable(res.data.data);
				})
				.catch((err) => {
					console.log(err, "list err");
				});
		}
	}, [
		selectedBoard,
		selectedGrade,
		selectedSubject,
		selectedSubTopic,
		academicYears,
		selectedQuizType,
	]);

	useEffect(() => {
		getAllQuizAPI();
	}, [sortOrder, sortBy]);

	const getAllQuizAPI = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?pageNumber=${page}&pageSize=${rowsPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async (res) => {
				console.log(res.data.data, sortOrder, sortBy);
				var Sdata = res.data.data.data
				Sdata.sort(compareValues(sortBy, sortOrder ? 'asc' : 'desc'));
				// var Sdata = await commonSort(res.data.data.data, sortBy, sortOrder);
				res.data.data.data = Sdata;
				setQuiztable(res.data.data);
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	};

	function compareValues(key, order = 'asc') {
		return function (a, b) {
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				// If either value does not have the property, consider it as null
				return 0;
			}

			const varA = (a[key] === null || a[key] === '') ? '' : a[key].toString().toUpperCase();
			const varB = (b[key] === null || b[key] === '') ? '' : b[key].toString().toUpperCase();

			let comparison = 0;
			if (varA === '' && varB === '') {
				comparison = 0;
			} else if (varA === '') {
				comparison = -1;
			} else if (varB === '') {
				comparison = 1;
			} else if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}

			return (order === 'desc') ? (comparison * -1) : comparison;
		};
	}

	const handletoggleChange = () => {
		axios
			.put(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/toggle-active-status`,
				{
					id: selectedItem.id,
					active: !selectedItem?.active,
					mappingData: [],
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				// setQuiztable(res.data.data.data);
				getAllQuizAPI();
				setToggleConfirmation(false);
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	};

	const handleConfirmationClose = () => {
		setToggleConfirmation(false);
		setToggleDetails({});
	};

	const paginationAPI = (pageNumber, pageSize) => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}
				${selectedBoard ? `&boardId=${selectedBoard}` : ""}
				${selectedGrade ? `&gradeId=${selectedGrade}` : ""}
				${selectedSubject ? `&subjectId=${selectedSubject}` : ""}
				${selectedSubTopic ? `&subTopicId=${selectedSubTopic}` : ""}
				${selectedQuizType ? `&quizTypeId=${selectedQuizType}` : ""}
				${academicYears ? `&academicYearId=${academicYears}` : ""}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setQuiztable(res.data.data);
				console.log(res.data.data, "res.data.data");
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	};

	const openDeleteModalAPI = () => {
		axios
			.delete(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${selectedItem.id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				hideDeleteModal();
				getAllQuizAPI();
			})
			.catch((err) => {
				console.log(err, "list err");
			});
	};

	const fetchDataUsingQuizId = async (quizId) => {
		const randomizeQuestions = false;
		if (localStorage.getItem("role") == "STUDENT") {
			randomizeQuestions = true;
		}
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${quizId}?showCorrectAnswers=true&randomizeQuestions=${randomizeQuestions}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		// sessionStorage.setItem("quistionId", response.data.data.questionIdList);
		// return response?.data.data || [];
		sessionStorage.setItem(
			"questionIdView",
			JSON.stringify(response?.data.data)
		);
		setTheQuizData(response?.data.data)
		// navigate("/dashboard/create quiz?isEdit=true");
		handleOpenEditableQuizModal(quizId)
	};

	// const fetchDataUsingQuizId = async (id) => {
	//   const QuizData = quiztable.find(quiz => quiz.id === id)
	//   sessionStorage.setItem("questionIdView", JSON.stringify(QuizData));
	//   navigate("/dashboard/create quiz");
	// };

	const [openEditableQuizModal, setOpenEditableQuizModal] = useState(false);

	const handleOpenEditableQuizModal = () => setOpenEditableQuizModal(true);
	const handleCloseEditableQuizModal = () => setOpenEditableQuizModal(false);

	return (
		<>
			<div style={{ width: "100%" }}>
				<article>
					<BreadCrumbs currentPage={"View Quizzes"} />
					{/* <div className={dashboardContentStyles.dashboard_link}>
						<span className={dashboardContentStyles.link_icon}>
							<img
								src={dashHome}
								alt="no img"
								width="15px"
								height="20px"
								style={{ verticalAlign: "middle" }}
							/>
						</span>
						{"  "}
						<span className={dashboardContentStyles.link_text}>
							Home
						</span>
						{"  "}
						<span>
							<i className="fa-solid fa-angle-right"></i>
						</span>
						<span>
							<i className="fa-solid fa-angle-right"></i>
						</span>
						{"  "}

						<a>View Quizzes </a>
					</div>
					<div
						className={
							dashboardContentStyles.dashboard_last_updated
						}
					>
						<p>
							Last Update: {lastModifiedDate.data}
						</p>
					</div> */}
				</article>
				{/* create chapter */}

				<div
					style={{ marginTop: "15px" }}
					className={chapterStyles.flex}
				></div>
				<div
					style={{
						display: "block",
						width: "100%",
						textAlign: "right",
						padding: "0 20px",
					}}
				>
					<TextField
						type={"search"}
						name="search"
						sx={{
							"& .MuiInputBase-root": {
								width: "247px !important",
								height: "32px !important",
								borderRadius: "50px !important",
								background: "#fff !important",
								lineHeight: "21px !important",
								opacity: 1,
								font: "normal normal normal 13px/20px Poppins !important",
								marginLeft: "auto",
							},
						}}
						placeholder={"Search...."}
						onChange={handleSearch}
						Value={search.search}
						InputProps={{
							endAdornment: (
								<IconButton>
									<img
										src={searchIcn}
										style={{
											verticalAlign: "top",
											fontSize: "large",
										}}
										aria-hidden="true"
									/>
								</IconButton>
							),
						}}
					/>
				</div>
				<div
					className={chapterStyles.flex}
					style={{
						marginTop: "15px",
						position: "relative",
						padding: "0 20px",
						marginBottom: "30px",
					}}
				>
					<div style={{ display: "flex", alignItems: "center" }}>
						<i class="bi bi-lightbulb"></i>
						<p
							style={{
								margin: "5px 0px 0px 5px",
								fontSize: "1.4vw",
								color: "#354052",
							}}
						>
							Quizzes
						</p>
					</div>

					<div
						style={{
							display: "flex",
							marginLeft: "15px",
							marginLeft: "auto",
						}}
					>
						<Box
							sx={{
								minWidth: 150,
								maxWidth: 150,
							}}
						>
							<FormControl fullWidth size="small">
								<InputLabel
									className={chapterStyles.select_input}
									id="demo-select-small"
									style={{ top: "3px" }}
								>
									Board
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									label="Board"
									value={selectedBoard}
									onChange={(e) =>
										setSelectedBoard(e.target.value)
									}
									style={{
										background: "#fff",
										borderRadius: 150,
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
									sx={{
										"& .css-10hburv-MuiTypography-root": {
											font: "normal normal normal 15px/15px Poppins !important",
										},
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000",
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									{/* <MenuItem value={"all"}>All</MenuItem> */}
									{boardsList?.length > 0 ? (
										boardsList.map((board, i) => {
											return (
												<MenuItem
													key={i}
													value={board.id}
													id={`az-revisionadmin-boarddropdown-${board.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}
												>
													<ListItemText
														primary={board.board}
													/>
												</MenuItem>
											);
										})
									) : (
										<MenuItem>Select</MenuItem>
									)}
								</Select>
							</FormControl>
						</Box>

						<Box
							style={{ margin: "auto 8px" }}
							sx={{ minWidth: 150 }}
						>
							<FormControl fullWidth size="small">
								<InputLabel
									className={chapterStyles.select_input}
									id="demo-select-small"
									style={{ top: "3px" }}
								>
									Grade
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									label="Grade"
									value={selectedGrade}
									onChange={(e) =>
										setSelectedGrade(e.target.value)
									}
									style={{
										background: "#fff",
										borderRadius: 150,
									}}
								>
									{gradesList?.length > 0 ? (
										gradesList?.map((item) => (
											<MenuItem
												key={item.id}
												value={item.id}
												id={`az-revisionadmin-gradedropdown-${item.id}`}
												sx={{
													"&.Mui-selected": {
														backgroundColor: 'orange',
														color: "#FFFFFF"
													},
													"&.Mui-selected:hover": {
														backgroundColor: '#fcedc2',
														color: "#000000"
													},
													"&:not(.Mui-selected):hover": {
														backgroundColor: '#fcedc2',
														color: '#000000',
													},
												}}
											>
												<ListItemText
													primary={item.grade}
												/>
											</MenuItem>
										))
									) : (
										<MenuItem>Select</MenuItem>
									)}
								</Select>
							</FormControl>
						</Box>
						<Box
							style={{ margin: "auto 8px" }}
							sx={{ minWidth: 150 }}
						>
							<FormControl
								fullWidth
								size="small"
								style={{ width: "12vw" }}
							>
								<InputLabel
									className={chapterStyles.select_input}
									id="demo-select-small"
									style={{ top: "3px" }}
								>
									Select subject
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									label="subject"
									value={selectedSubject}
									onChange={(e) => {

										setSelectedSubject(e.target.value)

										const selectedSubject = subjectsList.find(val => val.id === String(e.target.value));
										console.log(selectedSubject)
										console.log(selectedSubject?.hideSubtopics, selectedSubject.id)
										if (selectedSubject && selectedSubject.hideSubtopics) {
											setShowSubTopic(false);
											// console.log("false")
											// alert("pac false");
										} else {
											// console.log("true")
											setShowSubTopic(true);
											// alert("pac true");
										}
									}

									}
									style={{
										background: "#fff",
										borderRadius: 150,
									}}
								//   MenuProps={MenuProps}
								>
									<MenuItem value="">All</MenuItem>
									{subjectsList?.length > 0 ? (
										subjectsList.map((subject, i) => {
											return (
												<MenuItem
													key={i}
													value={subject.id}
													id={`az-revisionadmin-subjectdropdown-${subject.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}
												>
													<ListItemText
														primary={
															subject.subject
														}
													/>
												</MenuItem>
											);
										})
									) : (
										<MenuItem>Select</MenuItem>
									)}
								</Select>
							</FormControl>
						</Box>
						{
							showSubTopic &&
							<Box
								style={{ margin: "auto 8px" }}
								sx={{ minWidth: 150 }}
							>
								<FormControl
									fullWidth
									size="small"
									style={{ width: "12vw" }}
								>
									<InputLabel
										className={chapterStyles.select_input}
										id="demo-select-small"
										style={{ top: "3px" }}
									>
										Sub Topic
									</InputLabel>
									<Select
										labelId="demo-select-small"
										id="demo-select-small"
										label="subject"
										value={selectedSubTopic}
										onChange={(e) =>
											setSelectedSubTopic(e.target.value)
										}
										style={{
											background: "#fff",
											borderRadius: 150,
										}}
									//   MenuProps={MenuProps}
									>
										<MenuItem value="">All</MenuItem>

										{subTopicsList?.length > 0 ? (
											subTopicsList.map((subject, i) => {
												return (
													<MenuItem
														key={i}
														value={subject.id}
														id={`az-revisionadmin-subtopicdropdown-${subject.id}`}
														sx={{
															"&.Mui-selected": {
																backgroundColor: 'orange',
																color: "#FFFFFF"
															},
															"&.Mui-selected:hover": {
																backgroundColor: '#fcedc2',
																color: "#000000"
															},
															"&:not(.Mui-selected):hover": {
																backgroundColor: '#fcedc2',
																color: '#000000',
															},
														}}
													>
														<ListItemText
															primary={
																subject.subTopic
															}
														/>
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Select</MenuItem>
										)}
									</Select>
								</FormControl>
							</Box>
						}

						<Box
							style={{ margin: "auto 8px" }}
							sx={{ minWidth: 180 }}
						>
							<FormControl
								fullWidth
								size="small"
								style={{ width: "12vw" }}
							>
								<InputLabel
									className={chapterStyles.select_input}
									id="demo-select-small"
									style={{ top: "3px" }}
								>
									Quiz Type
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									label="subject"
									value={selectedQuizType}
									onChange={(e) =>
										setSelectedQuizType(e.target.value)
									}
									style={{
										background: "#fff",
										borderRadius: 150,
									}}
								//   MenuProps={MenuProps}
								>
									<MenuItem value="">All</MenuItem>

									{quizTypeList?.length > 0 ? (
										quizTypeList?.map((subject, i) => {
											return (
												<MenuItem
													key={i}
													value={subject.id}
													id={`az-revisionadmin-quiztypedropdown-${subject.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}
												>
													<ListItemText
														primary={
															subject.quizType
														}
													/>
												</MenuItem>
											);
										})
									) : (
										<MenuItem>Select</MenuItem>
									)}
								</Select>
							</FormControl>
						</Box>
						<Box sx={{ minWidth: 180 }}>
							<FormControl fullWidth size="small">
								<InputLabel
									className={chapterStyles.select_input}
									id="demo-select-small"
									style={{ top: "3px" }}
								>
									Acedemic Year
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									label="Acedemic Year"
									value={academicYears}
									onChange={(e) =>
										setAcademicYears(e.target.value)
									}
									style={{
										background: "#fff",
										borderRadius: 150,
									}}
								>
									<MenuItem value={""}>
										<ListItemText primary={"All"} />
									</MenuItem>
									{academicYearsList?.length > 0 ? (
										academicYearsList?.map((data) => {
											return (
												<MenuItem
													value={data.academicYear}
													id={`az-revisionadmin-academicyeardropdown-${data.academicYear.toLowerCase().replace(/ /g, "_")}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}
												>
													<ListItemText
														primary={
															data.academicYear
														}
													/>
												</MenuItem>
											);
										})
									) : (
										<MenuItem>Select</MenuItem>
									)}
								</Select>
							</FormControl>
						</Box>
					</div>
				</div>

				{/* Table */}

				<Paper>
					<TableContainer
						sx={{
							maxHeight: "440px",
							overflow: "auto",
						}}
					>
						<Table
							sx={{ minWidth: 650 }}
							stickyHeader={true}
							aria-label="customized table"
						>
							<TableHead>
								<TableRow>
									<TableCell
										className={
											dashboardContentStyles.table_head
										}
									>
										#
									</TableCell>
									<TableCell
										align="left"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Quiz Name
										<img
											style={{
												verticalAlign: "middle",
												margin: "0 0 1px 3px",
											}}
											className="cursor-pointer"
											width="10"
											height="15"
											src={sortIcon}
											onClick={async () => {
												await setSortOrder(!sortOrder);
												await setSortBy("name");

											}}
										/>
									</TableCell>
									<TableCell
										align="left"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Board
										<img
											style={{
												verticalAlign: "middle",
												margin: "0 0 2px 6px",
											}}
											className="cursor-pointer"
											width="10"
											height="15"
											src={sortIcon}
											onClick={async () => {
												await setSortOrder(!sortOrder);
												await setSortBy("board");
												// await initData(!sortOrder, "id");

											}}
										/>
									</TableCell>
									<TableCell
										align="left"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Grade
										<img
											style={{
												verticalAlign: "middle",
												margin: "1px 0 2px 6px",
											}}
											className="cursor-pointer"
											width="10"
											height="15"
											src={sortIcon}
											onClick={async () => {
												await setSortOrder(!sortOrder);
												await setSortBy("grade");
												// await initData(!sortOrder, "id");

											}}
										/>
									</TableCell>
									<TableCell
										align="left"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Subject
										<img
											style={{
												verticalAlign: "middle",
												margin: "1px 0 3px 2px",
											}}
											className="cursor-pointer"
											width="10"
											height="15"
											src={sortIcon}
											onClick={async () => {
												await setSortOrder(!sortOrder);
												await setSortBy("subject");
												// await initData(!sortOrder, "id");

											}}
										/>
									</TableCell>
									<TableCell
										align="left"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Subtopic
										<img
											style={{
												verticalAlign: "middle",
												margin: "1px 0 2px 2px",
											}}
											className="cursor-pointer"
											width="10"
											height="15"
											src={sortIcon}
											onClick={async () => {
												await setSortOrder(!sortOrder);
												await setSortBy("subTopic");

											}}
										// await initData(!sortOrder, "id");
										/>
									</TableCell>
									<TableCell
										align="center"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Questions
									</TableCell>
									<TableCell
										align="center"
										className={
											dashboardContentStyles.table_head
										}
										style={{ whiteSpace: "nowrap" }}
									>
										Quiz Type
									</TableCell>
									<TableCell
										align="center"
										className={
											dashboardContentStyles.table_head
										}
										style={{
											paddingRight: "50px",
											whiteSpace: "nowrap",
										}}
									>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{/* {console.log("5555", quiztable?.data)} */}
								{quiztable?.data?.length > 0 ? (
									quiztable?.data
										// ?.slice(
										//   page * rowsPerPage,
										//   page * rowsPerPage + rowsPerPage
										// )
										?.map((row, index) => {
											return (
												<TableRow
													style={{
														backgroundColor: index % 2 === 0 ? '#F5F5F8' : 'white',

													}}
													key={row?.id}
													//hover={true}
													hover
													sx={{
														'&.MuiTableRow-root:hover': {
															//a  backgroundColor: 'grey !important' ,
															//  border: '5px solid #fd8c00 !important'
															borderColor: "#564345"

														},
													}}

												//className={`${dashboardContentStyles.table_rw} ${dashboardContentStyles.hovered}`}

												>
													<TableCell
														component="th"
														scope="row"
														className={
															dashboardContentStyles.table_cell
														}
													>
														{page * rowsPerPage +
															(index + 1)}
													</TableCell>
													<TableCell
														align="left"
														className={
															dashboardContentStyles.table_cell_name
														}
													>
														{row.name}
													</TableCell>
													<TableCell
														align="left"
														className={
															dashboardContentStyles.table_cell
														}
													>
														{row.board}
													</TableCell>
													<TableCell
														align="left"
														className={
															dashboardContentStyles.table_cell
														}
													>
														{row.grade}
													</TableCell>
													<TableCell
														align="left"
														className={
															dashboardContentStyles.table_cell
														}
													>
														{row.subject}
													</TableCell>
													<TableCell
														align="left"
														style={{
															fontSize: "1vw",
															display:
																"tableCell",
															flexDirection:
																"row",
															height: "100%",
															cursor: "pointer",
														}}
														// onClick={() => showSubjectAndSubtopic(row.id)}
														className={
															dashboardContentStyles.table_cell_name
														}
													>
														{row?.subTopics
															?.length > 0
															? row?.subTopics?.map(
																(
																	item,
																	i
																) => {
																	return (
																		<div
																			key={
																				item?.subTopicId
																			}
																		>
																			{(i
																				? ", "
																				: "") +
																				item?.subTopic}
																		</div>
																	);
																}
															)
															: "-"}
													</TableCell>
													<TableCell align="center">
														<div>
															{row.totalQuestions}
														</div>
													</TableCell>
													<TableCell align="center">
														<div>
															{row.quizType}
														</div>
													</TableCell>
													<TableCell align="center">
														<div
															style={{
																display: "flex",
																justifyContent:
																	"space-evenly",
																minWidth:
																	"290px",
															}}
														>
															{row.active ? (
																<button
																	className={
																		chapterStyles.green_btn
																	}
																>
																	ACTIVE
																</button>
															) : (
																<button
																	className={
																		chapterStyles.red_btn
																	}
																>
																	in-Active
																</button>
															)}
															{/* <label className={quizStyles.active_switch}> */}
															{/* <input
                                    type="checkbox"
                                    disabled={row.reviewStatus === "APPROVED"}
                                    defaultChecked={row.active}
                                    value={row.active}
                                    // onChange={() => handletoggleChange(!row.active, row.id)}
                                    onChange={() => {
                                      setToggleConfirmation(true);
                                      setSelectedItem(row);
                                    }}
                                  /> */}
															<Switch
																checked={
																	row.active
																}
																defaultChecked={
																	row.active
																}
																onClick={() => {
																	setToggleConfirmation(
																		true
																	);
																	setSelectedItem(
																		row
																	);
																	settoggleMessage(
																		row.active
																			? "Do you want to deactivate this quiz?"
																			: "Do you want to activate this quiz?"
																	);
																}}
																disabled={
																	row.reviewStatus ===
																	"APPROVED"
																}
																name="checkedB"
																color="primary"
															/>
															{/* <span className={quizStyles.active_slider}></span> */}
															{/* </label> */}
															<EditIconButton
																onClick={() => {
																	fetchDataUsingQuizId(
																		row.id
																	)
																	console.log("check")
																}
																}
															>
																<Tooltip
																	title="Edit"
																	placement={
																		"top"
																	}
																>
																	<EditOutlinedIcon
																		style={{
																			fontSize:
																				"25px",
																		}}
																	/>
																</Tooltip>
															</EditIconButton>
															{row.reviewStatus ===
																"APPROVED" ? (
																<DeleteIconButton
																	style={{
																		marginRight:
																			"0px",
																	}}
																	disabled={
																		row.reviewStatus ===
																		"APPROVED"
																	}
																>
																	{/* <Tooltip title="Remove" placement={"top"}> */}
																	<DeleteOutlinedIcon
																		style={{
																			fontSize:
																				"25px",
																		}}
																	/>
																	{/* </Tooltip> */}
																</DeleteIconButton>
															) : (
																<DeleteIconButton
																	disabled={
																		row.reviewStatus ===
																		"APPROVED"
																	}
																	onClick={() => {
																		showDeleteModal(
																			true
																		);
																		setSelectedItem(
																			row
																		);
																	}}
																>
																	<Tooltip
																		title="Remove"
																		placement={
																			"top"
																		}
																	>
																		<DeleteOutlinedIcon
																			style={{
																				fontSize:
																					"25px",
																			}}
																		/>
																	</Tooltip>
																</DeleteIconButton>
															)}
														</div>
													</TableCell>
												</TableRow>
											);
										})
								) : (
									<h1
										style={{
											textAlign: "center",
											width: "100%",
											position: "absolute",
											marginTop: "10%",
										}}
									>
										NO DATA FOUND!
									</h1>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{/* {console.log(quiztable, "quiztable")} */}
					<TablePagination
						style={{
							bottom: "0",
							width: "83%",
							position: "fixed",
							backgroundColor: "white",
						}}
						rowsPerPageOptions={[5, 10, 20, 50]}
						component="div"
						count={quiztable?.totalElements || 0}
						page={quiztable?.pageNumber - 1}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPage}
						showFirstButton={true}
						showLastButton={true}
					/>
					<CommonDeleteModal
						open={openDeleteModal}
						close={hideDeleteModal}
						// deleteTitle={"this"}
						deleteHandler={openDeleteModalAPI}
						deleteId={selectedItem?.id}
						deleteName={selectedItem?.name}
					/>
					<ConfirmationModal
						open={toggleConfirmation}
						confirmationmessage={toggleMessage}
						close={handleConfirmationClose}
						updateToggle={handletoggleChange}
					/>
				</Paper>
			</div>
			<EditableQuizModal
				openEditableQuizModal={openEditableQuizModal}
				handleCloseEditableQuizModal={handleCloseEditableQuizModal}
				fetchDataUsingQuizId={fetchDataUsingQuizId}
				theQuizData={theQuizData}
			/>
		</>
	);
};

export default ViewQuizzes;
