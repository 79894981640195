import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logostyles from "./../css/Logo.module.css";
import ImageUploading from "./uploadImage";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id"; //branch list changer
import file_upload_controller_post from "../API_CONTROLLER_3/file_upload_controller_post";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import BranchControllerIdGet from "../API/branch-controller/User_Branches_id";
import Branch_update_Id_put from "../API/branch-controller/Branch_update_Id_put";
import studentmodal from "./../css/StudentModal.module.css";
// import Branch_update_Id_put from "../API/branch-controller/Branch_update_Id_put";
// input choose file label image
import Cf_img from "../../../src/img/csv.svg";
import C_img from "../../../src/img/image_icon.png";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";

const Logo = (props) => {
  const [branchInputs, setbranchInputs] = useState({
    logoUrl: null
  })
  const navigate = useNavigate();
  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
  };


  const callback = (response) => {
    console.log(response,"res")
    setbranchInputs({
      ...response,
    })
  }

  const getBranchListApiCall = () => {
    let token = sessionStorage.getItem("token");

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${schoolDetails.branch}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(response => {
        const { data } = response.data || {}
        console.log('Response:', response);
        // setSchoolIdData(response?.data?.data)
        setbranchInputs({...data })
        setSelectedImage(data.logoUrl);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(()=> {
    getBranchListApiCall()
  },[])

  async function file_upload_controller_post(
    setSelectedImage,
    formData,
    setSchoolInputs,
    schoolInputs,
  ) {
    let token = sessionStorage.getItem("token");
  
    
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=USER_SERVICE&subFolders=BRANCH`,formData,
      {
       headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (response)=>{
        setSelectedImage(response.data.data)
        setbranchInputs({...branchInputs, logoUrl: response.data.data})
        sessionStorage.setItem("logoUrl", response.data.data)
        Branch_update_Id_put(branchInputs,branchInputs?.phoneNumber, callback)
      })
      .catch(err=>console.log(err))
  }

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => {};
  }, [schoolDetails]);


  const [selectedImage, setSelectedImage] = useState();
  const subFolders = "BRANCH"

  const imageChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    file_upload_controller_post(setSelectedImage, formData,subFolders)
    setTimeout(() => {
      setShowModalUpdated(true)
    }, 500);
      // .then((res) => {
      //   navigate("/dashboard/createschool", {
      //     state: "logo",
      //   });
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  };

  const removeSelectedImage = () => {
    setSelectedImage('');
    sessionStorage.removeItem("logoUrl")
    setbranchInputs({
      ...branchInputs,
      logoUrl: null,
    })
    let data = {
      boardId: branchInputs?.boardId,
      cityId: branchInputs?.cityId,
      locality: branchInputs?.locality,
      logoUrl: null,
      name: branchInputs?.name,
      phoneNumber: branchInputs?.phoneNumber,
      plans: branchInputs?.plans,
      pocEmail: branchInputs?.pocEmail,
      schoolId: branchInputs?.schoolId
    }
    Branch_update_Id_put(data, data?.phoneNumber, callback)
    // window.location.reload(false);
  };

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends
 
  const [showModalUpdated, setShowModalUpdated] = useState(false);

  const handleOkEdit = () => {
    setShowModalUpdated(false)
  }

  return (
    <> 
    <SchoolAdminBreadcrumb 
      dashHome = {dashHome}
      sectionName = {'Registered Schools'}
      date = {modifiedDate.data}
      isSecondOne={true}
      sectionLink={'dashboard/Registered'}
      secondSectionName={'Add New School'}
    /> 
      {/* <div className="az_home_navigation_section">
          <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}>
                <div style={{ display: "flex", alignItems: "flex-start"}}>
                    <p className="az_navigation_text"><span style={{position:"relative"}}>
                        <img style={{position: "absolute", top:"1px"}}
                          src={dashHome}
                          alt="no img"
                          width="18px"
                          height="18px"
                          
                        /> </span>
                        <span className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                    </p>
                </div>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span> Resigtered Schools</span></p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p><span style={{color: "#ffa500"}}> Add New School</span></p>
          </div>
          <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
      </div> */}
      <article className={logostyles.article_add_school_plan}>
        <div style={{marginTop:"20px"}} className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={logostyles.grid_container_header_plan}>
          <div
            className={logostyles.item_plan1 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={logostyles.item_plan2 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={logostyles.item_plan3 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={logostyles.item_plan4 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={logostyles.item_plan5 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={logostyles.item_plan6 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={logostyles.item_plan7 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>
        <div className={logostyles.logo_part}>
          <div className={logostyles.upload_logo_container}>
            <span>Upload Logo</span>
            <span className={logostyles.custom_file_container}>
              <input
                type="file"
                style={{ display: "none" }}
                id="testId1"
                onChange={imageChange}
                disabled={branchInputs.logoUrl !== null && "disabled"}
                className={logostyles.custom_file_input}
              />

              <div className={logostyles.chooseFile_btn_wrapper}>
                <img src={C_img} alt="img" />
                <label htmlFor="testId1" className={logostyles.chooseFile_btn}>
                  Choose File
                </label>
              </div>

              {selectedImage && (
                <span>
                  <img
                    src={branchInputs.logoUrl}
                    alt="Thumb"
                    width="75"
                    height="75"
                  />
                  <span>
                    <a>
                      {branchInputs.logoUrl !== null &&
                        branchInputs.logoUrl.substring(
                          branchInputs.logoUrl.lastIndexOf("_") + 1,
                          branchInputs.logoUrl.length
                        )}
                    </a>
                    <br />

                    <p className="az_cursor_pointer"
                      onClick={removeSelectedImage}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        paddingTop: "10px",
                      }}
                    >
                      Remove{" "}
                    </p>
                  </span>
                </span>
              )}
            </span>
          </div>
          <div
        className={studentmodal.flex_container}
        id={studentmodal.modal}
        style={showModalUpdated ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={studentmodal.main_content}
          id={studentmodal.modal_content}
        >
          <span style={{ textAlign: "center" }}>
            <i
              style={{ color: "orange", fontSize: "50px" }}
              class="fa-regular fa-circle-check"
            ></i>
            {/* <img src={tickImage} alt="red checkmark" /> */}
          </span>

          <h5>You have successfully Uploaded Branch Logo</h5>
          {/* <h6>
            "{shareFirstName} {shareLastName} "
          </h6> */}
          <div className={studentmodal.modal_buttons}>
            <button
              className={studentmodal.choosefile_button_1}
              id={studentmodal.choose_button_id_1}
              onClick={handleOkEdit}
            >
              ok
            </button>
          </div>
        </div>
      </div>
        </div>
      </article>
    </>
  );
};
export default Logo;
