import React, { useEffect, useState } from "react";
import FactCheckRoundedIcon from "@mui/icons-material/FactCheckRounded";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { MenuProps, startingYearData,endingYearData } from "./Data";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ClassRoundedIcon from "@mui/icons-material/ClassRounded";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import axios from "axios";
import CommonDeleteModal from "./CommonDeleteModal";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import searchIcn from "../../../src/img/search.svg";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort.png";

const AcademicYear = () => {
  const token = sessionStorage.getItem("token");
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [visibleAddAcademic, setVisibleAddAcademic] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [startingYearList, setStartingYearList] = useState(startingYearData);
  const [endingYearList, setEndingYearList] = useState(endingYearData);
  const [startingYear, setStartingYear] = useState("");
  const [endingYear, setEndingYear] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const [validation, setValidation] = useState(false);
  const [message, setMessage] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
      let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/last-modified-at`,
      {
       headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json)=>{
        ModifiedDate(json.data)
      })
      .catch(err=>console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  const openAddAcademic = () => {
    setVisibleAddAcademic(true);
  };
  const fetchData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/page?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUpdatedData(response.data.data.data);
  };
  useEffect(() => {
    fetchData();
  }, [sortOrder]);
  const closeAddAcademic = () => {
    setVisibleAddAcademic(false);
    setIsEdit(false);
    setStartingYear("");
    setEndingYear("");
    setStartingYearList(startingYearData);
    setEndingYearList(endingYearData);
  };
  const showDeleteModal = async (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/confirmation-api?id=${item.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.academicYear?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
    setPage(0);
  };
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {});
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    const value = e.target.checked;
    setToggleDetails({});
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setUpdatedData(clone);
          }
        })
        .catch(function (response) {});
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/${selectedItem?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter(
            (data) => data.id !== selectedItem?.id
          );
          setFilterValue(find);
        }
      })
      .catch(function (response) {});
    hideDeleteModal();
  };
  useEffect(() => {
    if (startingYear) {
      const filterValue = endingYearData.filter(
        (item) => item.year > startingYear
      );
      setEndingYearList(filterValue);
    }
  }, [startingYear]);
  const startingYearChangeHandler = (e) => {
    const year = e.target.value;
    setStartingYear(year);
  };
  const setEndingYearChangeHandler = (e) => {
    const year = e.target.value;
    setEndingYear(year);
    // const filterValue = startingYearList.filter((item) => item.year < year);
    // setStartingYearList(filterValue);
  };
  const addAcademicYearHandler = (startingYear, endingYear) => {
    if (!AcademicYear || startingYear.length == 0 || endingYear.length == 0) {
      setValidation(true);
      return;
    }
    const data = {
      startingYear: startingYear,
      endingYear: endingYear,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
        }
      })
      .catch(function (response) {
        // console.log("error ==> ", response);
      });
    closeAddAcademic();
  };
  const editHandler = (startingYear, endingYear, id) => {
    if (!AcademicYear || startingYear.length == 0 || endingYear.length == 0) {
      setValidation(true);
      return;
    }
    const updatedValue = {
      startingYear: startingYear,
      endingYear: endingYear,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          const newData = response.data.data;
          find.startingYear = newData.startingYear;
          find.endingYear = newData.endingYear;
          find.academicYear = newData.academicYear;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
        }
      })
      .catch(function (response) {});
    closeAddAcademic();
  };
  // console.log(rowsPerPage,"rows")
  // console.log(page,"page")
  console.log(endingYearList,"endingYearList")
  console.log(startingYearData,"startingYearData")
  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>Master</span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Academic Year</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          {visibleAddAcademic && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ClassRoundedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>Add Academic Year</div>
              </div>
              <Paper>
                <div
                  style={{
                    display: "flex",
                    padding: "20px 20px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="demo-simple-select-label">
                        *Academic Year From
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={startingYear}
                        onChange={(e) => startingYearChangeHandler(e)}
                      >
                        {startingYearList?.map((item) => (
                          <MenuItem key={item.id} value={item.year}>
                            <ListItemText primary={item?.year} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && !startingYear && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Academic Year From is Required
                        </span>
                      )}
                    </FormControl>
                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="demo-simple-select-label">
                        *Academic Year To
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={endingYear}
                        onChange={(e) => setEndingYearChangeHandler(e)}
                      >
                        {endingYearList?.map((item) => (
                          <MenuItem key={item.id} value={item.year}>
                            <ListItemText primary={item?.year} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && !endingYear && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Academic Year To is Required
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => closeAddAcademic()}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton
                        onClick={() =>
                          editHandler(startingYear, endingYear, selectedItem.id)
                        }
                      >
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton
                        onClick={() =>
                          addAcademicYearHandler(startingYear, endingYear)
                        }
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <FactCheckRoundedIcon style={{ fontSize: "30px" }} />
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                Academic Year List
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                type={"text"}
                placeholder={"Search...."}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!visibleAddAcademic && (
                <GreenAddButton onClick={() => openAddAcademic()}>
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add Academic Year
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{
                maxHeight: 640,
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Academic Year
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("academicYear");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                        paddingRight: 60,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => {
                      return (
                        <TableRow
                          key={item?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          // hover={true}
                          // style={{
                          //   background: "#f9f8f8",
                          // }}
                          className={dashboardContentStyles.table_rw}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={dashboardContentStyles.table_cell}
                          >
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cellll}
                          >
                            {item?.academicYear}
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Switch
                                checked={item?.active}
                                onChange={(e) => switchHandler(item?.id, e)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <EditIconButton
                              onClick={() => {
                                openAddAcademic();
                                setIsEdit(true);
                                setStartingYear(item?.startingYear);
                                setEndingYear(item?.endingYear);
                                setSelectedItem(item);
                              }}
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                {/* <EditOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </Tooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => {
                                showDeleteModal(item);
                              }}
                            >
                              <Tooltip title="Remove" placement={"top"}>
                                {/* <DeleteOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </Tooltip>
                            </DeleteIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Academic Year"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={"Do you want to deactivate this year?"}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
      </div>
    </>
  );
};
export default AcademicYear;
