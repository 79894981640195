import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { MenuProps } from "./Data";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import makeStyles from '@mui/styles/makeStyles';
import GreenAddButton from "../../components/common/GreenAddButton";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TableContainer,
  ListItemText,
  Tooltip
} from "@mui/material";
import Studentprofile from "./../css/Studentpg311.module.css";
import studentedit from "./../css/EditModelAdmin.module.css";
import tickImage from "./../img/tick-inside-a-circle.svg";
import { blue } from "@mui/material/colors";
import createschool from "./../css/CreateSchool.module.css";
import Switch from "../../components/common/Switch";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import axios from "axios";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import dashHome from "../../../src/img/dashHome.svg";
import Gender_Controller_Get from "../API/misc-controller/gender_controller_get";
import changepasswordstyle from "../css/changePassword.module.css";
import sharestyle from "../css/shareModal.module.css"; //share modal css
import SchoolControllerAll from "./../API/school-controller/user_schools_all";
import Studentdynamic from "./../css/student32.module.css";
import MappingAlert from "../../components/common/MappingAlert";
import teacheridstyles from "../css/TeacherId.module.css";
import regschool from "./../css/RegisteredSchool.module.css";
import Pagination1 from "./pagination1";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GetLastModifiedAt from "../API/administration-controller/GetLastModifiedAt";
import searchIcn from "../../../src/img/search.svg";
import SchoolControllerWithBranchesGet from "../API/school-controller/user_schools_all_withBranches";
import share from "../Share";
import AdmininstrationPopup2 from "./AdmininstrationPopup2";
// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import NestedSelect from "./NestedSelect";
import changePasswordPut from "../ChangePassword";
import NestDropdown from "../NestDropdown";
import ConfirmationModal from "./ConfirmationModal";
import Administration_Id_get from "./Administration_Id_get";
//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import TreeSelectComponent from "../../components/common/TreeSelect";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles({
  root: {
    borderColor:" #BDBDBD !important",
      color:"#FFA500 !important",
      // "&:hover":{
    //   border: "1.5px solid #FFA500 !important"
    // }
    "&.MuiInputBase-root":{
      "&:hover":{
          border: "1.5px solid #FFA500 !important"
        }
    },
    "&&.MuiOutlinedInput-input":{
      color:"#FFA500 !important",
    }
  },
  "&.MuiInputBase-formControl" :{
    input:{
      "&:hover":{
          border: "1.5px solid #FFA500 !important"
        }
    },
  },
  Button:{
    border: "2px solid red !important"
  }
});

const SchoolAdmin = () => {
  const [gradeData, setGradeData] = useState([]);
  const token = sessionStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addAdminVisible, setAddAdminVisible] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [isAddUser, setIsAddUser] = useState(true);
  const [genderType, setGenderType] = useState({});
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [Expanded, setExpanded] = useState(false);
  const [pageDetail, setPageDetail] = useState({});
  const [SelectedBranch, setSelectedBranch] = useState([])
  const [SelectedSchool, setSelectedSchool] = useState([])
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });

  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [mminputs, setMmInputs] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    gender: "",
    lmsEnv: process.env.REACT_APP_LMS_ENV,
  });
  const [branchSelect, setBranchSelect] = useState([]);
  const [schoolAdminId, setSchoolAdminId] = useState({});
  const [schoolAdminIdSchoolName, setSchoolAdminIdSchoolName] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [shareName, setShareName] = useState("");
  const [shareNo, setShareNo] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [shareFirstName, setShareFirstName] = useState("");
  const [shareLastName, setShareLastName] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [showUpdateModalCreate, setShowUpdateModalCreate] = useState(false);
  const [ExpandedId, setExpandedId] = useState(null);
  const [regexPass, setRegexPass] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const [shareClick, setShareClick] = useState(false)
  const classes = useStyles();

  const [selectAll, setSelectAll] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState([]);

  useEffect(() => {
    setIndividualCheckboxes(Array(schoolAdminId.length).fill(false));
    console.log(">>> ",individualCheckboxes.length)
  }, [schoolAdminId]);
  
  // Function to handle the change in state of the table header checkbox
  const handleSelectAll = (event) => {
    // Update the state to the new checked status of the table header checkbox
    const newState = event.target.checked;
    setSelectAll(newState);
    setIndividualCheckboxes(individualCheckboxes.map(() => newState));
  };

  // Function to handle the change in state of individual table body checkboxes
  const handleIndividualCheckboxChange = (index) => {
    // Update the state of individual checkbox at the given index
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);

  
  };


  const showAlertHandler = () => {
    // setOpenDeleteModal(true);
    setShowAlert(true);
  };

  const hideAlertHandler = () => {
    setShowAlert(false);
  };

  const handleChangePassword = (e) => {
    setShowModalChangePassword(true);
  };
  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };

  const handleSubmit = (e) => {
    changePasswordPut(userName, userId, password, persona);

    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };


  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [search, setSearch] = useState("");

  const [isSearch, setisSearch] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
   setQuery("");
   setShowClear(false);
   user_SchoolAdmin_Id_get(setSchoolAdminId);
 };

 const handleInputChange = (e) => {
   const inputValue = e.target.value.toLowerCase();
   if (inputValue) {
    let result = [...totalele];
   result = [...totalele]?.filter((data) => {
      return data?.firstName?.toLowerCase().includes(inputValue);
    });
    setSchoolAdminId(result)
  }else {
    user_SchoolAdmin_Id_get(setSchoolAdminId);
  }
   setQuery(inputValue);
   setShowClear(inputValue !== "");
 };
 useEffect(()=>{
  if(isSearch){
    searchingData()
  }
},[])

const searchingData =async ()=>{
  setisSearch(false);
  let token = sessionStorage.getItem("token");
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations?pageNumber=0&pageSize=10&sortOrder=${sortOrder || false}&sortBy=${sortBy || "id"}&search=${search}&adminType=MANAGEMENT`,
  {
    headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    }
},)
const totlaele = result?.data?.data?.totalElements
let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations?pageNumber=${page - 1}&pageSize=${totlaele}&sortOrder=${sortOrder || false}&sortBy=${sortBy || "id"}&search=${search}&adminType=MANAGEMENT`,
{
  headers: {
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
  }
},)   

const totalData = response?.data?.data?.data    
setTotalelements(totalData);
}

  
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleAddSchooAdmin = () => {
    setExpanded(true);
  };

  useEffect(() => {
    setFilterValue(schoolAdminId);
  }, [schoolAdminId]);
  let role = localStorage.getItem("role");
  async function BranchesGet(value, setBranchIdGet) {
    let token = sessionStorage.getItem("token");
    // management role
    // let roleId = "2c9180858104272401812308c507000d";
    const schoolid = value;
    let role = localStorage.getItem("role");
    let userId = sessionStorage.getItem("userId");
    if (role === "SCHOOL_ADMIN" && "MANAGEMENT") {
      await axios
        .get(
          `${
            process.env.REACT_APP_SWAGGER_URL
          }v1/api/user/branches/all?schoolId=${sessionStorage.getItem(
            "schoolid"
          )}&userId=${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBranchList(response.data.data);
          // setBranchIdGet(response.data.data.branchId);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .get(
          `${
            process.env.REACT_APP_SWAGGER_URL
          }v1/api/user/branches/all?schoolId=${sessionStorage.getItem(
            "schoolid"
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBranchList(response.data.data);
          // setBranchIdGet(response.data.data.branchId);
        })
        .catch((err) => console.log(err));
    }
  }
const [schoolUserData, setschoolUserData] = useState([])
  useEffect(() => {
    user_SchoolAdmin_Id_get(setSchoolAdminId);
    // user_SchoolAdmin_Id_get(setUpdatedData)
    // management_table_get(setUpdatedData)
    Gender_Controller_Get(setGenderType);
    SchoolControllerAll(setschoolUserData);
    SchoolControllerWithBranchesGet(setSchoolList);
    // BranchesGet(setBranchList,setAdministrationId)
  }, []);

  const handleMmInputs = (e) => {
    // if (e.target.name === "planId") {
    //   const currentPlan = e.target.value.split(",");
    //   setMmInputs({
    //     ...mminputs,
    //     institutions: [
    //       {
    //         branchId: branchList.id[0],
    //         schoolId:  schoolList.id[0],
    //       },
    //     ],
    //   });
    // } else {
    //   setMmInputs({
    //     ...mminputs,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    e.preventDefault();
    setMmInputs({
      ...mminputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleNameInputs = (e) => {
   
    e.preventDefault();
      const result = e.target.value.replace(/[^a-z]/gi, "");
    setMmInputs({
      ...mminputs,
      [e.target.name]: result,
    });
  };

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }

  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }
  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }
  const [SuccessDisplayName, setSuccessDisplayName] = useState("")
  console.log(gradeData,"gradeData")
  const branches = schoolList.map((branch)=> branch.children.map((branchId)=>branchId.value))
  console.log(branches,"branches")
  var filteredBranches = branches.filter((branch)=>branch.value === gradeData)
  console.log(filteredBranches,"filterBranch")
  const addAdmin = (event) => {
    for (const input in mminputs) {
      if (mminputs[input] === "") {
        setIsError(true);
        setShowError("Please Enter All Required Values");
        return;
      }
    }
    if (!validateMobile(mminputs.mobile)) {
      setIsError(true);
      setShowError("Phone number invalid");
      return;
    }

    if (!validateEmail(mminputs.email)) {
      setIsError("true");
      setShowError("Please enter a valid email address");
      return;
    }

    

    if (gradeData.length === 0) {
      setIsError(true);
      setShowError("Please Enter All Required Values");
      return;
    }
    if (role === "SCHOOL_ADMIN" && branchSelect.length === 0) {
      setIsError(true);
      setShowError("Please Enter All Required Values");
      return;
    }else if(role === "SUPER_ADMIN"){
      setIsError(false)
      setShowError("");
    }

    if(role==="SUPER_ADMIN"){   const institutions = [];
    console.log(gradeData,"gradeData")
    gradeData.forEach((gd) => {
      const sch = schoolList.find((sl) =>
        sl.children.find((ch) => ch.key === gd)
      );
      console.log(sch,"sch")
      if (sch) {
        const isSchoolexist = institutions.findIndex(
          (s) => s.schoolId === sch.value
        );
        if (isSchoolexist >= 0) {
          institutions[isSchoolexist] = {
            ...institutions[isSchoolexist],
            branchesId: [...institutions[isSchoolexist].branchesId, gd],
          };
        } else
          institutions.push({
            branchesId: [gd],
            schoolId: sch.value,
          }) ;
          
          // setSelectedSchool(sch.title)
          console.log(gd,"gd")
          let branchNames = institutions.map((item) => {
            console.log(item,"item")
            let branch = branchList.find((branch) => branch.branchId === item.branchesId);
            return branch ? branch.branch : null;
          });
          console.log(branchNames,"brNme")
          console.log(institutions,"institution")
        
          ;
      }
    });
    console.log(branchList,"branchlist")
    let token = sessionStorage.getItem("token");
   var item = {
      firstName: mminputs.firstName,
      lastName: mminputs.lastName,
      mobile: mminputs.mobile,
      email: mminputs.email.toLowerCase(),
      gender: mminputs.gender,
      institutions,
      role: "MANAGEMENT",
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    }}else{
      var item = {
        firstName: mminputs.firstName,
        lastName: mminputs.lastName,
        mobile: mminputs.mobile,
        email: mminputs.email.toLowerCase(),
        gender: mminputs.gender,
        institutions: [
          {
            schoolId: gradeData,
            branchesId: 
             branchSelect
            
          }
        ],
        role: "MANAGEMENT",
        lmsEnv: process.env.REACT_APP_LMS_ENV,
      }
    };
    // let schoolId = institutions.map((school)=>school.schoolId)
    // console.log(schoolId,"schId")
    // let branches = institutions[0].branchesId
    // let selSchool = schoolList.filter((schl)=> schl.value === schoolId)
    // console.log(selSchool,"selschool")
    setSuccessDisplayName(item.firstName)
    let result = axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response,"respPost")
        setSelectedBranch(response.data.data.institutions.map((branch)=>branch.branches))
        setSelectedSchool(response.data.data.institutions)
        setExpanded(false);
        
        setShowError(response.message);
        setMmInputs({
          firstName: "",
          lastName: "",
          mobile: "",
          email: "",
          gender: "",
        });
        setGradeData([]);
        setBranchSelect([]);
        user_SchoolAdmin_Id_get().then(()=> {
          console.log("gett")
          
          
        }) ;
        setTimeout(() => {
          setSuccessCreateModal(true)
        }, 500);
        // setSuccess(response)
      })
      //  console.log(response,"admin")
      .catch((err) => console.log(err));
      
  };
  
  console.log(SelectedBranch,"selectedBranch")
  console.log(schoolList,"listsc") 
  const gradeHandleChange = (event) => {
  
   let schlName = schoolList.filter((school) => school.schoolId == event.target.value ) 
   setSelectedSchool(schlName.schoolName)
   console.log(schlName,"schlName")
    const {
      target: { value },
    } = event;

    setGradeData(value);
    BranchesGet(value);
    setBranchSelect([]);
  };

  // function get_userBy_id() {}
  async function user_SchoolAdmin_Id_get() {
    let token = sessionStorage.getItem("token");
    let role = "MANAGEMENT";
    let result = await axios
      .get(
        `${
          process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/administrations?pageNumber=${page - 1}&pageSize=${
          rowsInput.rows
        }&sortOrder=${sortOrder || false}&sortBy=${
          sortBy || "id"
        }&search=${search}&adminType=MANAGEMENT`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        setSchoolAdminId(response.data.data.data);
        //setUpdatedData(response.data.data.data);
        setFilterValue(response.data.data.data);
        setSchoolAdminIdSchoolName(response.data.institutions);
        setPageDetail(response.data.data);
      })
      .catch((err) => console.log(err));
    let res = await axios
      .get(
        `${
          process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/administrations?pageNumber=${
          page - 1
        }&pageSize=100&sortOrder=${sortOrder || false}&sortBy=${
          sortBy || "id"
        }&search=${search}&adminType=MANAGEMENT`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        setUpdatedData(response.data.data.data);
      });
  }
console.log(schoolAdminId,"data")
  const [adminIdData, setAdminIdData] = useState([]);
  const showDeleteModal = (id) => {
    setSelectedItem(id);
    setOpenDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter(
            (data) => data.id !== selectedItem.id
          );
          setFilterValue(find);
          user_SchoolAdmin_Id_get();
        }
      })
      .catch(function (response) {});
    hideDeleteModal();
  };

  // const editClickHandler = (id) => {
  //   setExpanded(true);
  //   const updatedValue = {};
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${id}`,
  //       updatedValue,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )

  //     .catch(function (response) {

  //     });
  // };

  const [toggleDetails, setToggleDetails] = useState({});
  const [message, setMessage] = useState("");
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = schoolAdminId?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...schoolAdminId];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
          user_SchoolAdmin_Id_get();
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id, userId) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      setMessage("Are you sure you want to Deactivate this Management");
    } else {
      setToggleConfirmation(true);
      setMessage("Are you sure you want to Activate this Management");
    }

    const handleConfirmationClose = () => {
      setToggleConfirmation(false);
      setToggleDetails({});
    };
  };

  const handleBChange = (event) => {
  //   let brnchName = branchList.filter((branch) => branch.branchId == event.target.value ) 
  // console.log(branchList,"bb")
  // console.log(brnchName[0].branch,"brnchname")
  // setSelectedBranch(brnchName[0].branch)
  // console.log(SelectedBranch,"selBranch")
    const {
      target: { value },
    } = event;
    setBranchSelect(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function user_SchoolAdmin_Id_put(mminputs, setSuccess, id) {
    let token = sessionStorage.getItem("token");
    let item = {
      firstName: mminputs.firstName,
      lastName: mminputs.lastName,
      mobile: mminputs.mobile,
      email: mminputs.email.toLowerCase(),
      gender: mminputs.gender,
      institutions: [
        {
          schoolId: gradeData,
          branchesId: branchSelect,
        },
      ],
      role: "MANAGEMENT",
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };
    let result = await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${adminId}`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSuccess(response);
      })
      .catch((err) => console.log(err));
    user_SchoolAdmin_Id_get();
  }

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  useEffect(() => {
    user_SchoolAdmin_Id_get();

    return () => {};
  }, [rowsInput, page, sortBy,sortOrder]);

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };

  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "ADMINISTRATION";
  useEffect(() => {
    if(shareClick) { share(userName, userId, persona)};
   }, [userName, userId]);

  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
  };
  const firstNameHandler = (e, id) => {
    {
      var adminnId = schoolAdminId.id;
      sessionStorage.setItem("adminId", adminnId);
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => setAdminIdData(res.data.data));
      var id = schoolAdminId.id;
      sessionStorage.setItem("id", id);
      setShowModalEdit(true);
    }
  };

  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    user_SchoolAdmin_Id_get();
  };


  const [successCreateModal, setSuccessCreateModal] = useState(false)
const closeSuccessModal = () => {
  setSuccessCreateModal(false)
}

const [selectedCode, setSelectedCode] = useState("");

const handleCodeChange = (event) => {
  setSelectedCode(event.target.value);
};

const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  
const handleTooltipOpen = () => {
  setIsTooltipOpen(false);
 
};


const handleTooltipClose = () => {
  setIsTooltipOpen(false);
 
};

 const shareHandler = (schoolAdminId) => {   
  setUserId(schoolAdminId.userId);
  setUserName(schoolAdminId.userName);
userId &&  share(userName, userId, persona);
  setShowModalShare(true);
  setShareClick(true)
};
  return (
    <>
      <SchoolAdminBreadcrumb 
        dashHome = {dashHome}
        sectionName = {'Management Id'}
        date = {modifiedDate.data}
      />
      <div style={{ width: "84vw" , marginTop:"40px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            gap: "16px",
          }}
        >
          {addAdminVisible && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {!Expanded ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "80vw",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#606060",
                        columnGap: "10px",
                      }}
                    >
                      <ManageAccountsOutlinedIcon
                        style={{ fontSize: "30px" }}
                      />
                      <span>Management Id </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: "2rem",
                      }}
                    >
                      <div
                        className={
                          Studentdynamic.school_list_search_container_32
                        }
                      >
                        <div>
                      <TextField
                      className={classes.root}
                      onChange={handleInputChange}
                      type={"text"}
                      value={query}
                      placeholder={"Search anything..."}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "247px !important",
                          height: "32px !important",
                          borderRadius: "50px !important",
                          fontFamily: "Poppins !important",
                          fontSize: "13px",
                          marginLeft: "20px !important",
                          background: "#FFFFFF",
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showClear && (
                              <IconButton onClick={handleClear}>
                                <ClearIcon
                                  style={{ color: "#F05262", fontSize: "1vw" }}
                                />
                              </IconButton>
                            )}
                            {!showClear && (
                              <IconButton>
                                <img
                                  src={searchIcn}
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: "large",
                                  }}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                        </div>
                      </div>
                      <GreenAddButton
                      id="az-school-admin-add-management-btn"
                      className="az_btn"
                        onClick={handleAddSchooAdmin}
                        variant="contained"
                        size="small"
                        sx={{
                          borderRadius: "16px",
                          backgroundColor: "#4caf50",
                        }}
                      >
                        <i className="fa fa-plus-circle"></i>
                <span className={regschool.Add_school}> Add Management Id</span>
                        {/* <AddCircleOutlineOutlinedIcon
                          style={{
                            marginRight: "6px",
                            marginBottom: "2px",
                            fontSize: "18px",
                          }}
                        />
                        Add Management Id */}
                      </GreenAddButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#606060",
                        columnGap: "10px",
                      }}
                    >
                      <ManageAccountsOutlinedIcon
                        style={{ fontSize: "30px" }}
                      />
                      <div style={{ fontWeight: 600 }}>
                        {!isEdit ? "Add Management Id" : "Edit Management Id"}
                      </div>
                    </div>
                    <TableContainer component={Paper}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "20px 20px",
                        }}
                      >
                        <div></div>
                        <div
                          style={{
                            marginTop: "10px",
                            // width: "90%",
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "1rem",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={createschool.input_grid_rc}>
                            <div className={createschool.input_element}>
                              <input
                                className={createschool.form_input_element + ' ' + 'az_text_input'}
                                type="text"
                                name="firstName"
                                id="az-super-admin-add-management-text-input-first-Name"
                                value={mminputs.firstName}
                                onChange={handleNameInputs}
                                placeholder=" "
                                autocomplete="none"
                              />
                              {/* <label style={{ fontWeight: "300" }} for="name">
                                *First Name
                              </label> */}
                              <label htmlFor="az-super-admin-add-management-text-input-first-Name">*First Name</label>
                            </div>

                            <div className={createschool.input_element}>
                              <input
                                className={createschool.form_input_element + ' ' + 'az_text_input'}
                                type="text"
                                name="lastName"
                                id="az-super-admin-add-management-text-input-last-Name"
                                value={mminputs.lastName}
                                onChange={handleNameInputs}
                                placeholder=" "
                                autocomplete="none"
                              />
                              {/* <label style={{ fontWeight: "300" }} for="name">
                                *Last Name
                              </label> */}
                              <label htmlFor="az-super-admin-add-management-text-input-last-Name">*Last Name</label>
                            </div>

                            <div style={{display:"flex",justifyContent:"space-around",width:"95%"}}>
                            <select
                              style={{width:"60px",height:"48px",borderRadius:"8px"}}
                                value={selectedCode}
                                onChange={handleCodeChange}
                                className="az_phone_code"
                              >
                                <option value="+1">+91</option>
                                <option value="+44">
                                  +44
                                </option>
                                <option value="+91">+1</option>
                              </select>

                            <div className={createschool.input_element}>
                              {/* <span
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "-32px",
                                }}
                              >
                                +91
                              </span> */}
                              <input
                                className={createschool.form_input_element + ' ' + 'az_text_input'}
                                type="text"
                                name="mobile"
                                maxlength="10"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                id="az-super-admin-add-management-text-input-mobile"
                                value={mminputs.mobile}
                                onChange={handleMmInputs}
                                placeholder=""
                                autocomplete="none"
                              />
                              {/* <label style={{ fontWeight: "300" }} for="name">
                                *Primary Phone Number
                              </label> */}
                              <label htmFor="az-super-admin-add-management-text-input-mobile">*Phone Number</label>
                            </div>
                              </div>

                            <div className={createschool.input_element}>
                              <input
                                className={createschool.form_input_element + ' ' + 'az_text_input'}
                                type="text"
                                name="email"
                                id="az-super-admin-add-management-text-input-email"
                                value={mminputs.email}
                                onChange={handleMmInputs}
                                placeholder=" "
                                autocomplete="none"
                              />
                              {/* <label style={{ fontWeight: "300" }} for="name">
                                *Primary Email
                              </label> */}
                              <label htmlFor="az-super-admin-add-management-text-input-email">*Primary Email</label>
                            </div>

                            <div className={createschool.input_element}>
                              <select
                                className={createschool.form_select_element + ' ' + 'az_select'}
                                name="gender"
                                value={mminputs.gender}
                                id={genderType.code}
                                onChange={handleMmInputs}
                                placeholder=" "
                              >
                                <option
                                  className={createschool.option}
                                  value=""
                                >
                                  Select
                                </option>
                                {/* <option className={createschool.option}>
                                  select
                                </option> */}
                                {genderType.length > 0 ? (
                                  genderType?.map((genderType, index) => {
                                    return (
                                      <option
                                        className={createschool.option}
                                        value={genderType.code}
                                        placeholder=" "
                                      >
                                        {genderType.name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option
                                    className={createschool.option}
                                    value=""
                                  >
                                    Select
                                  </option>
                                )}
                              </select>

                              {/* <label
                                style={{ fontWeight: "300" }}
                                for="cityName"
                              >
                                *Gender
                              </label> */}
                              <label
                                htmlFor={genderType.code}
                                className={
                                  mminputs.gender === ""
                                    ? createschool.std_cat
                                    : "NONE"
                                }
                              >
                                *Gender
                              </label>
                              <label
                                htmlFor={genderType.code}
                                className={
                                  mminputs.gender !== ""
                                    ? createschool.std_cat
                                    : "NONE"
                                }
                              >
                                *Gender
                              </label>
                            </div>
                                {role==="SCHOOL_ADMIN" ? <><div className={createschool.input_element}>
                              <select
                                className={createschool.form_select_element}
                                name="school name"
                                value={gradeData}
                                id={gradeData}
                                onChange={gradeHandleChange}
                                placeholder=" "
                              >
                                <option
                                  className={createschool.option}
                                  value=""
                                >
                                  Select
                                </option>

                                {schoolUserData?.length > 0 ? (
                                  schoolUserData?.map((name) => {
                                    return (
                                      <option
                                        className={name.schoolId}
                                        value={name?.schoolId}
                                        placeholder=" "
                                      >
                                        {name?.schoolName}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option
                                    className={createschool.option}
                                    value=""
                                  >
                                    Select
                                  </option>
                                )}
                              </select>

                              <label
                                htmlFor={gradeData}
                                className={
                                  gradeData === ""
                                    ? createschool.std_cat
                                    : "NONE"
                                }
                              >
                                *School Name
                              </label>
                              <label
                                htmlFor={gradeData}
                                className={
                                  gradeData !== ""
                                    ? createschool.std_cat
                                    : "NONE"
                                }
                              >
                                *School Name
                              </label>
                            </div> 
                            
                             <FormControl sx={{ width: "270px",height:"48px" }}>
                              <InputLabel
                                sx={{
                                  fontSize:"14px",
                                  marginBottom:6,
                                  "&.Mui-focused": {
                                    color: "orange"
                                  }
                                }} id="demo-multiple-checkbox-label"
                              >
                                *Branch Name
                              </InputLabel>

                              <Select
                                 sx={{
                                  borderRadius: "10px",
                                  width: "250px",
                                  height: "50px",
                                  "& .css-10hburv-MuiTypography-root":{
                                    fontSize:"14px !important",
                                  },
                                  "&.MuiOutlinedInput-root": {
                                    "& > fieldset": {
                              border: "1px solid #D6D5DF !important"
                                    }
                                  },
                                  "&.MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                              border: "1px solid #272727 !important"
                                    }
                                  },
                                  fontSize: "14px !important",
                                }}
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={branchSelect}
                                name="first"
                                onChange={handleBChange}
                                input={<OutlinedInput label="*Branch Name" />}
                                renderValue={(selected) =>
                                  selected
                                    .map(
                                      (obj) =>
                                        branchList.find(
                                          (item) => item.branchId === obj
                                        ).branch
                                    )
                                    .join(", ")
                                }
                              >
                                {branchList?.map((branch) => (
                                  <MenuItem
                                    key={branch.branchId}
                                    value={branch.branchId}
                                  >
                                    <Checkbox
                                      checked={
                                        branchSelect.indexOf(branch.branchId) >
                                        -1
                                      }
                                    />
                                    <ListItemText primary={branch.branch} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> 
                         </>:  <div className={createschool.input_element}>
                              <TreeSelectComponent
                                treeData={schoolList}
                                onChange={(data) => setGradeData(data)}
                                value={gradeData}
                              />
                            </div>}
                            </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "auto",
                            columnGap: "1rem",
                          }}
                        >
                          <p
                            style={
                              isError
                                ? { display: "block", color: "red" }
                                : { display: "none" }
                            }
                          >
                            {showError}
                          </p>
                          <HoverGreyCancelButton
                            variant="outlined"
                            onClick={() => {
                              setExpanded(false);
                              // setAddAdminVisible(false);
                              setIsEdit(false);
                              setMmInputs({
                                firstName: "",
                                lastName: "",
                                mobile: "",
                                email: "",
                                gender: "",
                              });
                              setBranchSelect([]);
                              setGradeData([]);
                            }}
                          >
                            Cancel
                          </HoverGreyCancelButton>
                          {isEdit ? (
                            <Button
                            id="az-super-admin-update-management-update-btn"
                            sx={{
                              backgroundColor:"#403E75 !important",
                              color: "#FFFFFF",
                              width: "150px",
                              fontSize: "14px",
                              borderRadius: "150px",
                              "&:hover":{
                                backgroundColor:"#2A2859 !important",
                              }
                            }}
                              onClick={async (e) => {
                                e.preventDefault();
                                setExpanded(false);
                                setMmInputs("");
                                setGradeData([]);
                                setBranchSelect([]);
                                setShowUpdateModalCreate(true);
                                for (const input in mminputs) {
                                  if (mminputs[input] === "") {
                                    setIsError(true);
                                    setShowError(
                                      "Please enter all the required values"
                                    );
                                    return;
                                  }
                                }
                                await user_SchoolAdmin_Id_put(mminputs);
                              }}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                            id="az-super-admin-create-management-create-btn"
                            sx={{
                              backgroundColor:"#403E75 !important",
                              color: "#FFFFFF",
                              width: "150px",
                              fontSize: "14px",
                              borderRadius: "150px",
                              "&:hover":{
                                backgroundColor:"#2A2859 !important",
                              }
                            }}
                              onClick={(e) => {
                                addAdmin();

                                // setAddAdminVisible(false);
                              }}
                              // disabled={roleDetail.hasRoleError}
                            >
                              Create
                            </Button>
                          )}
                        </div>
                      </div>
                    </TableContainer>
                    {/* </>
             )} */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "80vw",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color: "#606060",
                          columnGap: "10px",
                        }}
                      >
                        <ManageAccountsOutlinedIcon
                          style={{ fontSize: "30px" }}
                        />
                        <span>Management Id List</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          className={
                            Studentdynamic.school_list_search_container_32
                          }
                        >
                          <div>
                  <TextField
                  id="az-school-admin-input-search-field"
                      onChange={handleInputChange}
                      type={"text"}
                      value={query}
                      placeholder={"Search anything..."}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "247px !important",
                          height: "32px !important",
                          borderRadius: "50px !important",
                          fontFamily: "Poppins !important",
                          fontSize: "13px",
                          marginLeft: "20px !important",
                          background: "#FFFFFF",
                          
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showClear && (
                              <IconButton onClick={handleClear}>
                                <ClearIcon
                                  style={{ color: "#F05262", fontSize: "1vw" }}
                                />
                              </IconButton>
                            )}
                            {!showClear && (
                              <IconButton>
                                <img
                                  src={searchIcn}
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: "large",
                                  }}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <div className={Studentdynamic.section_list_table}>
                    <table className={Studentdynamic.table_section + ' ' + 'az_table'}>
                      <thead className={Studentdynamic.thead_32  + ' ' + 'az_table_head'}>
                        <tr className={Studentdynamic.table_head_section}>
                          <td className={Studentdynamic.cbse_checkbox}>
                            <input className="az_checkbox" type="checkbox" id="test2" 
                            onChange={handleSelectAll} 
                            checked={selectAll}/>
                            <label for="test2"></label>{" "}
                          </td>
                          
                          <td className={Studentdynamic.cbse_table} style={{paddingLeft:"15px"}}>{" "} # {" "}</td>
                          
                          <td>
                            {" "}
                            Name
                            {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                            <img
                              style={{
                                verticalAlign: "middle",
                                paddingLeft: "3px",
                                marginBottom: "2px",
                              }}
                              className="cursor-pointer"
                              width="14"
                              height="15"
                              src={sortIcon}
                              onClick={async () => {
                                await setSortOrder(!sortOrder);
                                await setSortBy("firstName");
                                // await initData(!sortOrder, "id");
                              }}
                            />
                          </td>
                          <td>
                            No of Schools{" "}
                            {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                            <img
                              style={{
                                verticalAlign: "middle",
                                paddingLeft: "3px",
                                marginBottom: "2px",
                              }}
                              className="cursor-pointer"
                              width="14"
                              height="15"
                              src={sortIcon}
                              onClick={async () => {
                                await setSortOrder(!sortOrder);
                                await setSortBy("numberOfSchools");
                                // await initData(!sortOrder, "id");
                              }}
                            />
                          </td>
                          <td className={Studentprofile.cbse_table}>
                            No of Branches{" "}
                            {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                            <img
                              style={{
                                verticalAlign: "middle",
                                paddingLeft: "3px",
                                marginBottom: "2px",
                              }}
                              className="cursor-pointer"
                              width="14"
                              height="15"
                              src={sortIcon}
                              onClick={async () => {
                                await setSortOrder(!sortOrder);
                                await setSortBy("numberOfBranches");
                                // await initData(!sortOrder, "id");
                              }}
                            />
                          </td>
                          <td>
                            Active{" "}
                            <img
                              style={{
                                verticalAlign: "middle",
                                paddingLeft: "3px",
                                marginBottom: "2px",
                              }}
                              className="cursor-pointer"
                              width="14"
                              height="15"
                              src={sortIcon}
                              onClick={async () => {
                                await setSortOrder(!sortOrder);
                                await setSortBy("active");
                                // await initData(!sortOrder, "id");
                              }}/>
                            {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                          </td>
                          <td>
                            Action{" "}
                            {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                          </td>
                        </tr>
                      </thead>
                      <tbody className="az_table_body">
                        {filterValue.length > 0 ? (
                          filterValue?.map((schoolAdminId, index) => {
                            return (
                              <tr
                                className={Studentprofile.data_row}
                                id={schoolAdminId.id}
                                key={index}
                              >
                                <td className={Studentdynamic.cbse_checkbox}>
                                  <input className="az_checkbox" type="checkbox" id="test2" 
                                  checked={individualCheckboxes[index]} 
                                  onChange={() => handleIndividualCheckboxChange(index)} />
                                  <label for="test2"></label>{" "}
                                </td>
                                {/* <td><Checkbox {...label} /></td> */}
                                
                                <td style={{paddingLeft:"15px"}}>
                                  {(page - 1) * rowsInput.rows + (index + 1)}
                                </td>
                                <td className={Studentprofile.wrapdata}>
                                  <div
                                    className={schoolAdminId.firstName}
                                    onClick={(e) =>
                                      firstNameHandler(e, schoolAdminId.id)
                                    }
                                  >
                                    {schoolAdminId.firstName +
                                      " " +
                                      schoolAdminId.lastName}
                                  </div>
                                </td>
                                <td className={Studentprofile.grade}>
                                  {schoolAdminId.numberOfSchools}
                                </td>
                                {/* <td>{adminListData.section}</td> */}
                                <td className={Studentprofile.wrapdata}>
                                  {schoolAdminId.numberOfBranches}
                                </td>
                                {/* <td>
                        <div className={Studentprofile.wrapdata}>
                          {schoolAdminId.email}
                        </div>
                      </td> */}
                                {/* <td>{adminListData.numberOfQuiz}</td> */}
                                {/* <td>
                        <div className={Studentprofile.wrapdata}>
                          <div className={Studentprofile.name}>
                            {adminListData.userId}
                          </div>
                        </div>
                      </td> */}
                                {/* <td>{adminListData.lastLoginTime}</td> */}
                                <td>
                                  {" "}
                                  <Switch
                                    checked={schoolAdminId.active}
                                    onChange={(e) => {
                                      switchHandler(
                                        e,
                                        schoolAdminId.id,
                                        schoolAdminId.userId
                                      );
                                    }}
                                  ></Switch>
                                </td>
                                <td   style={{alignItems:"center"}}>
                                  <div
                                    className={Studentprofile.wrapdata}
                                  >
                                    {schoolAdminId.Action}
                                  </div>
                                  <div
                                    className={Studentprofile.wrapper_teacherid}
                                    
                                  >
                                    <div
                                      className={Studentprofile.icon}
                                      onClick={() => {
                                        setShowModalChangePassword(true);
                                        setUserId(schoolAdminId.userId);
                                        setUserName(schoolAdminId.userName);
                                        setLoginPassword("");
                                        setConfirmLoginPassword("");
                                        setRegexPass(true);
                                      }}
                                    >
                                      <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>
                                        Change Password
                                      </div>
                                      <span
                                        id={
                                          Studentprofile.change_password_teacherid
                                        }
                                      >
                                        <img
                                          className={
                                            dashboardContentStyles.btnHoverEffect
                                          }
                                          src={action_sa_Lock}
                                          alt="Lock-btn"
                                        />
                                      </span>
                                    </div>
                                    <div className={Studentprofile.icon}>
                                      <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>
                                        Edit
                                      </div>
                                      <span
                                        onClick={async (e) => {
                                          e.preventDefault();
                                          setExpanded(true);
                                          setIsEdit(true);
                                          var adminId = schoolAdminId.id;
                                          await BranchesGet(
                                            schoolAdminId.institutions[0]
                                              .schoolId
                                          );
                                          const {
                                            firstName,
                                            lastName,
                                            mobile,
                                            email,
                                            gender,
                                            id,
                                          } = schoolAdminId;
                                          setGradeData(
                                            schoolAdminId.institutions[0]
                                              .schoolId
                                          );
                                          setMmInputs({
                                            firstName,
                                            lastName,
                                            mobile,
                                            email,
                                            gender,
                                          });
                                          // var branchesId = branchList.map((branch)=> {branch.branch})
                                          // console.log(
                                          //   schoolAdminId.institutions[0].branches.map(
                                          //     (branch) => {
                                          //       return branch.branchId;
                                          //     }
                                          //   ),
                                          //   "eff"
                                          // );
                                          // setBranchSelect(schoolAdminId.institutions[0].branches.map(
                                          //   (branch) => {
                                          //     return branch.branchId;
                                          //   }
                                          // ))
                                          setBranchSelect(branchList.map((branch)=>branch.branchId)
                                            )
                                          setAdminId(id);
                                        }}
                                      >
                                        <img
                                          className={
                                            dashboardContentStyles.btnHoverEffect
                                          }
                                          src={action_sa_Edit}
                                          alt="Lock-btn"
                                        />
                                      </span>
                                    </div>
                                    <div
                                      className={Studentprofile.icon}
                                      // onClick={handleShareModal}
                                      onClick={(e) => shareHandler(schoolAdminId)}
                                    >
                                      <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>
                                        Share
                                      </div>
                                      <span>
                                        <img
                                          className={
                                            dashboardContentStyles.btnHoverEffect
                                          }
                                          src={action_sa_Share}
                                          alt="Lock-btn"
                                        />
                                      </span>
                                    </div>
                                    <div
                                      className={Studentprofile.icon}
                                      onClick={() => {
                                        showDeleteModal(schoolAdminId);
                                        // deleteHandler(schoolAdminId.id)
                                        // hideDeleteModal()
                                      }}
                                    >
                                      <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>
                                        Remove
                                      </div>
                                      <span>
                                        <img
                                          className={
                                            dashboardContentStyles.btnHoverEffect
                                          }
                                          src={action_sa_Delete}
                                          alt="Lock-btn"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <td
                            colSpan="10"
                            style={{
                              // "white-space": "nowrap",/
                              height: "23rem",
                              // overflow: "scroll",
                              // "text-overflow": "clip",
                              // margin: "0px 10px",
                            }}
                          >
                            NO DATA AVAILABLE
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className={changepasswordstyle.flex_container_teacherid}
                    id={changepasswordstyle.modal_teacherid}
                    style={
                      showModalChangePassword
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <form class={changepasswordstyle.setPassword_login_form}>
                      <div class={changepasswordstyle.login_form_title}>
                        <h5>Change Password</h5>
                        <span>
                          <i
                            class="fa-solid fa-xmark"
                            onClick={handleUpdatedPassword}
                          ></i>
                        </span>
                      </div>
                      <div class={changepasswordstyle.password_container}>
                        <fieldset
                          className={changepasswordstyle.password_fieldset}
                        >
                          <legend>New Password</legend>
                          <input
                            type={passwordShown ? "text" : "password"}
                            name="userPassword"
                            id={changepasswordstyle.password}
                            value={loginPassword}
                            onChange={PassOnChange}
                          />
                          <i
                            className={`bi ${
                              passwordShown ? "bi-eye" : "bi-eye-slash"
                            } ${changepasswordstyle.bi_eye_slash}`}
                            id={changepasswordstyle.togglePassword}
                            onMouseEnter={() => {
                              toggleLoginPasswordVisibility("show");
                            }}
                            onMouseLeave={() => {
                              toggleLoginPasswordVisibility("dontShow");
                            }}
                          ></i>
                        </fieldset>
                        
                      </div>
                      <div class={changepasswordstyle.password_container}>
                        <fieldset
                          className={changepasswordstyle.password_fieldset}
                        >
                          <legend>Confirm Password</legend>
                          <Tooltip title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                          enterTouchDelay={1000000}
                          open={isTooltipOpen}
                          onOpen={handleTooltipOpen}
                          onClose={handleTooltipClose}
                         
                          >
                          <input
                            type={confirmPasswordShown ? "text" : "password"}
                            name="confirmPassword"
                            id={changepasswordstyle.password}
                            value={confirmLoginPassword}
                            onChange={(e) =>
                              setConfirmLoginPassword(e.target.value)
                            }
                          /></Tooltip>
                          <i
                            className={`bi ${
                              confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                            } ${changepasswordstyle.bi_eye_slash}`}
                            id={changepasswordstyle.togglePassword}
                            onMouseEnter={() => {
                              toggleconfirmLoginPasswordVisibility(
                                "Confirmshow"
                              );
                            }}
                            onMouseLeave={() => {
                              toggleconfirmLoginPasswordVisibility(
                                "ConfirmdontShow"
                              );
                            }}
                          ></i>
                        </fieldset>
                        <span
                          className={changepasswordstyle.error_span}
                          style={
                            isPassEmpty
                              ? { display: "inline" }
                              : { display: "none" }
                          }
                        >
                          Password cannot be empty
                        </span>
                        <span
                          class={changepasswordstyle.error_span}
                          style={
                            passwordMatch
                              ? { display: "none" }
                              : { display: "inline" }
                          }
                        >
                          Passwords do not match
                        </span>

                        <span
                          class={changepasswordstyle.error_span}
                          style={
                            regexPass
                              ? { display: "none" }
                              : { display: "inline" }
                          }
                        >
                     
                        </span>
                      </div>
                      <div class={changepasswordstyle.login_container}>
                        <button
                          class={changepasswordstyle.sp_login_btn}
                          onClick={handleSubmit}
                        >
                          UPDATE
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* change password modal ends  */}

                  {/* share modal starts  */}

                  <div
                    className={sharestyle.flex_container_teacherid}
                    id={sharestyle.modal_teacherid}
                    style={
                      showModalShare ? { display: "flex" } : { display: "none" }
                    }
                  >
                    <div
                      className={sharestyle.main_content_teacherid}
                      id={sharestyle.modal_content_teacherid}
                    >
                      <span
                        style={{ textAlign: "center" }}
                        className={sharestyle.img}
                      >
                        {/* <img src={tickImage} alt="red checkmark" /> */}
                        <i
                          style={{ color: "orange", fontSize: "50px" }}
                          class="fa-regular fa-circle-check"
                        ></i>
                      </span>

                      <h5 className={sharestyle.head}>
                        Successfully shared User Id
                      </h5>

                      {/* <p className={sharestyle.para}>" {userId}"</p> */}
                      <p
                        className={sharestyle.para}
                        style={{ marginTop: "8px", fontWeight: "bold" }}
                      >
                        " {userName}"
                      </p>
                      <div className={sharestyle.modal_buttons_teacherid}>
                        <button
                          className={sharestyle.ok_btn}
                          id={sharestyle.ok_btn_1_teacherid}
                          onClick={handleShareModalOk}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={teacheridstyles.flex_container_teacherid}
                    id={teacheridstyles.modal_teacherid}
                    style={
                      showModalCreate
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <div
                      className={teacheridstyles.main_content_teacherid}
                      id={teacheridstyles.modal_content_teacherid}
                    >
                      <span className={teacheridstyles.img}>
                        {/* <img src={tickImage} alt="red checkmark" /> */}
                        <CheckCircleOutlineIcon
                          sx={{
                            marginLeft: "20px",
                            fontSize: "70px",
                            color: "orange",
                          }}
                        ></CheckCircleOutlineIcon>
                      </span>

                      <h5 className={teacheridstyles.head}>
                        You have successfully created Management Id
                      </h5>

                      <p className={teacheridstyles.para}>
                        {shareFirstName} {shareLastName}
                      </p>
                      <div className={teacheridstyles.modal_buttons_teacherid}>
                        <button
                          className={teacheridstyles.ok_btn}
                          id={teacheridstyles.ok_btn_1_teacherid}
                          onClick={(e) => {
                            setShowModalCreate(false);
                            e.preventDefault();
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={teacheridstyles.flex_container_teacherid}
                    id={teacheridstyles.modal_teacherid}
                    style={
                      showUpdateModalCreate
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <div
                      className={teacheridstyles.main_content_teacherid}
                      id={teacheridstyles.modal_content_teacherid}
                    >
                      <span className={teacheridstyles.img}>
                        {/* <img src={tickImage} alt="red checkmark" /> */}
                        <CheckCircleOutlineIcon
                          sx={{
                            marginLeft: "20px",
                            fontSize: "70px",
                            color: "orange",
                          }}
                        ></CheckCircleOutlineIcon>
                      </span>

                      <h5 className={teacheridstyles.head}>
                        You have successfully updated Management Id
                      </h5>

                      <p className={teacheridstyles.para}>
                        {shareFirstName} {shareLastName}
                      </p>
                      <div className={teacheridstyles.modal_buttons_teacherid}>
                        <button
                          className={teacheridstyles.ok_btn}
                          id={teacheridstyles.ok_btn_1_teacherid}
                          onClick={(e) => {
                            setShowUpdateModalCreate(false);
                            e.preventDefault();
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* edit modal page starts */}
                  <div
                    className={studentedit.flex_container_teacherid}
                    id={studentedit.modal_teacherid}
                    style={
                      showModalEdit ? { display: "flex",fontSize:"14px" } : { display: "none" }
                    }
                  >
                    <div
                      className={studentedit.main_content_teacherid}
                      id={studentedit.modal_content_teacherid}
                    >
                      <div className={studentedit.EditHeadContainer}>
                        <h5 className={studentedit.head}>Management Details</h5>
                        <i
                          onClick={handleEditModalcross}
                          class="fa-solid fa-xmark"
                        ></i>
                      </div>
                      <div className={studentedit.EditBox}>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            First Name
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData.firstName}{" "}
                          </label>
                        </div>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            Last Name
                          </label>
                          <label className={studentedit.Input}>
                            {adminIdData.lastName}
                          </label>
                        </div>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            Primary Phone no.{"+91 "}
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData.mobile}
                          </label>
                        </div>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            Primary Email
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData.email}
                          </label>
                        </div>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            Gender{" "}
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData.gender}
                          </label>
                        </div>

                      </div>
                      <div style={{borderBottom:"1px solid #e8e8e8"}}></div>

                        <div style={{display:"flex",marginTop:"10px"}}>
                        <div className={studentedit.Edithead} style={{marginRight:"30px"}}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            School Name{" "}
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData?.institutions?.map((school)=><div>{school.school}</div>)}
                          </label>
                        </div>
                        <div className={studentedit.Edithead}>
                          <label className={studentedit.Input_head}>
                            {" "}
                            Branch Name{" "}
                          </label>
                          <label className={studentedit.Input}>
                            {" "}
                            {adminIdData?.institutions &&
                              adminIdData?.institutions?.map((br)=>br.branches.map((branch)=><div>{branch.branch}</div>))}
                          </label>
                        </div>
                        </div>
                      <div className={studentedit.modal_buttons_teacherid}>
                        <button
                          className={studentedit.Edit_btn}
                          id={studentedit.Edit_btn_1_teacherid}
                          onClick={handleEditModalEdit}
                        >
                          <i class="fa-solid fa-pen"></i> Edit
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* pagination container - Start */}
                  <div className={regschool.pagination}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <span className={regschool.rows}>
                        <label htmlFor="rows">Rows per page</label>
                        <select
                          name="rows"
                          id="rows"
                          value={rowsInput.rows}
                          onChange={handlerRowsInputs}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </span>
                    </form>
                    <span>
                      <Pagination1
                        pageDetail={pageDetail}
                        handleChange={handleChange}
                      />
                    </span>
                  </div>
                  {/* pagination container - End */}
                </Paper>
              </div>
              <CommonDeleteModal
                open={openDeleteModal}
                close={hideDeleteModal}
                deleteTitle={"Management Id"}
                deleteHandler={deleteHandler}
                deleteId={selectedItem?.id}
                deleteName={
                  selectedItem?.firstName + " " + selectedItem?.lastName
                }
              />
              <MappingAlert
                open={showAlert}
                onClose={hideAlertHandler}
                close={hideAlertHandler}
                alert="This Field has other child links"
              />
              <ConfirmationModal
                open={toggleConfirmation}
                confirmationmessage={message}
                close={handleConfirmationClose}
                updateToggle={updateToggle}
              />
              <AdmininstrationPopup2
              userType={"Management"}
              userName_Modal={
                SuccessDisplayName
              }
              open={successCreateModal}
              handleOk={closeSuccessModal}
              SelectedSchool={SelectedSchool}
              SelectedBranch={SelectedBranch}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SchoolAdmin;
