import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import { SearchOutlined } from "@mui/icons-material";
import associateStyle from "./../css/associateConcept.module.css";
import { blue } from "@mui/material/colors";
import BlueButton from "../../components/common/BlueButton";
import SuccessModal from "./data/SuccessModal";
import MappingAlert from "../../components/common/MappingAlert";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import styled from "styled-components";

// sort
import sortIcon from "../../../src/pages/img/sort.png";

import {
  Checkbox,
  Box,
  Modal,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import association_last_update_get from "../API_CONTROLLER/concept-association-controller/association_last_update_get";
import ConfirmationModal from "./ConfirmationModal";

const AssociateCptDetails = () => {
  const assoIniVal = {
    conceptId: "",
    subConcept: [
      {
        subConceptId: "",
        rootConceptIds: [],
      },
    ],
  };
  const token = sessionStorage.getItem("token");
  const subjectId = sessionStorage.getItem("subjectId");
  const boardId = sessionStorage.getItem("boardId");
  const gradeId = sessionStorage.getItem("gradeId");
  const chapterId = sessionStorage.getItem("chapterId");
  const chapterName = sessionStorage.getItem("chapter");
  const [previewData, setPreviewData] = useState([]);
  const [AlertModal, setAlertModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [conceptAll, setConceptAll] = useState([]);
  const [totalConcept, setTotalConcept] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [subconcept, setSubconcept] = useState([]);
  const [rootconcept, setRootconcept] = useState([]);
  const [search, setSearch] = useState({
    search: "",
  });
  const [association, setAssociation] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [toggleConfirmationSub, setToggleConfirmationSub] = useState(false);
  const [toggleConfirmationRoot, setToggleConfirmationRoot] = useState(false);
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleDetails, setToggleDetails] = useState({});

  // sort
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(false);

  const [selectedCpt, setSelectedCpt] = useState([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  const closeSuccessModal = () => {
    setSuccessModal(true);
  };
  const close = () => {
    setSuccessModal(false);
  };
  const closeAlertModal = () => {
    setAlertModal(false);
  };

  const getAssociateion = (cb) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association?boardId=${boardId}&subjectId=${subjectId}&gradeId=${gradeId}&chapterId=${chapterId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data) {
          setPreviewData(res.data.data);
          const tmcon = res?.data?.data?.map((data) => ({
            ...data,
            id: data.conceptId.toString(),
            concept: data.concept,
            active: data.active,
            isSelectedSearch: true,
            isSelectedConcept: true,
          }));
          setConceptAll(tmcon);
          const tempSub = [];
          const tempRoot = [];
          res.data.data.forEach((data) => {
            data?.subConcept?.forEach((subData) => {
              tempSub.push({
                id: subData.id,
                subConcept: subData.subConcept,
                concept_id: data.conceptId,
                isSelectedSubconcept: true,
                active: subData.active,
              });
              subData?.rootConceptResponses?.forEach((rootData) =>
                tempRoot.push({
                  id: rootData.id,
                  rootConcept: rootData.rootConcept,
                  active: rootData.active,
                  concept_id: data.conceptId,
                  sub_concept_id: subData.id,
                  isSelectRootConcept: true,
                })
              );
            });
          });
          setSubconcept(tempSub);
          setRootconcept(tempRoot);
          cb(tmcon);
        }

        // setCptmapping(res.data.data)
      })
      .catch((err) => console.log(err));
  };

  const createAssociation = () => {
    const associationData = handlePostData();
    setPreviewData(associationData);
    if (!associationData.length > 0) {
      setAlertModal(true);
      return;
    }
    let data = {
      boardId: boardId,
      gradeId: gradeId,
      subjectId: subjectId,
      // "subTopicId": "402880e88092a7ad018092f47ef13000",
      chapterId: chapterId,
      association: associationData,
    };

    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAlertModal(true);
        APIcall();
      })
      .catch((err) => console.log(err));
  };

  const checboxOnchange = (e, id) => {
    // var tempCpt = [] ;

    if (e.target.checked) {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedSearch: true, isSelectedConcept: true };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
    } else {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === id.toString()) {
          return { ...d, isSelectedSearch: false, isSelectedConcept: false };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      subconcept?.length > 0 && handleRemoveSubcomceptState(id);
    }
  };

  const handlePostData = () => {
    var temp = [];
    var tempsub = [];
    var rtempoot = [];
    conceptAll.forEach((con) => {
      if (con.isSelectedConcept) {
        subconcept.forEach((sub) => {
          if (
            sub.isSelectedSubconcept &&
            sub.concept_id?.toString() === con.id?.toString()
          ) {
            rootconcept?.forEach((root) => {
              if (
                root.isSelectRootConcept &&
                root.concept_id?.toString() === con.id?.toString() &&
                root.sub_concept_id?.toString() === sub.id?.toString()
              ) {
                rtempoot.push(root.id);
              }
            });
            tempsub.push({ subConceptId: sub.id, rootConceptIds: rtempoot });
            rtempoot = [];
          }
        });
        temp.push({
          conceptId: con.id,
          subConcept: tempsub,
        });
        tempsub = [];
      }
    });
    return temp;
  };

  // Confirmation-API
  const ConfirmationAPI = async (conceptItem, subItem, rootItem) => {
    // setRow(item);
    // const payload = {
    //   boardId: boardId,
    //   gradeId: gradeId,
    //   subjectId: subjectId,
    //   chapterId: chapterId,
    //   operationType: "DELETE",
    //   concepts: [
    //     {
    //       ...(conceptItem ? { conceptId: conceptItem.id } : {}),
    //       ...(subItem ? { conceptId: subItem.concept_id, subConcepts: [{ subConceptId: subItem.id }] } : {}),
    //       ...(rootItem ? { conceptId: rootItem.concept_id, subConcepts: [{ subConceptId: rootItem.sub_concept_id, rootConceptIds: [rootItem.id] }] } : {}),
    //     }
    //   ]
    // }
    // const response = await axios.put(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association/confirmation-api`,
    //   payload,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //     },
    //   }
    // );
    // settoggleMessage("response?.data?.data?.message");
    if (conceptItem) {
      setToggleConfirmation(true);
      settoggleMessage("Do you want to unlink this concept?");
    } else if (subItem) {
      setToggleConfirmationSub(true);
      settoggleMessage("Do you want to unlink this SubConcept?");
    } else if (rootItem) {
      setToggleConfirmationRoot(true);
      settoggleMessage("Do you want to unlink this RootConcept?");
    }
    // setMessage(response?.data?.data?.message);
  };

  const handleSearch = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const hanleSelctConcepts = (event, item) => {
    const conceptItem = item ? item : selectedItem;
    if (!conceptItem.isSelectedConcept) {
      const temp = conceptAll.map((d) => {
        if (d.id?.toString() === conceptItem.id?.toString()) {
          return { ...d, isSelectedConcept: true };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      // getSubConcept(id);
      const tempAssociat = association;
      tempAssociat.push({
        conceptId: conceptItem.id,
        subConcept: [],
      });
      setAssociation(tempAssociat);
    } else {
      const temp = conceptAll.map((d) => {
        if (d.id.toString() === conceptItem.id.toString()) {
          return { ...d, isSelectedConcept: false };
        } else {
          return d;
        }
      });
      setConceptAll(temp);
      handleRemoveSubcomceptState(conceptItem.id);
      const tempAssociat = association.filter(
        (data) => data.conceptId.toString() !== conceptItem.id.toString()
      );
      setAssociation(tempAssociat);
    }
    setToggleConfirmation(false);
    setSelectedItem({});
  };
  const handleRemoveSubcomceptState = (id) => {
    if (subconcept && subconcept.length > 0) {
      const tempSubConcept = subconcept.filter(
        (data) => data.concept_id?.toString() !== id?.toString()
      );
      setSubconcept(tempSubConcept);
      const tempAssociat = association.map((data) => {
        if (data.subConcept && data.conceptId === id.toString()) {
          return {
            ...data,
            subConcept: data.subConcept.filter(
              (sub_data) =>
                !tempSubConcept
                  .map((d) => d.id?.toString())
                  .includes(sub_data.subConceptId?.toString())
            ),
          };
        } else return data;
      });
      setAssociation(tempAssociat);
      if (rootconcept && rootconcept.length > 0) {
        const te = subconcept.map(
          (data) =>
            data.concept_id.toString() === id.toString() && data.id.toString()
        );
        const tempSubConcept = [];
        rootconcept.forEach(
          (data) =>
            !te.includes(data.sub_concept_id.toString()) &&
            tempSubConcept.push(data)
        );
        setRootconcept(tempSubConcept);
      }
    }
  };
  const haldeRemoveRoootConcepts = async (id) => {
    if (rootconcept && rootconcept.length > 0) {
      const tempSubConcept = await rootconcept.filter(
        (data) => data.sub_concept_id.toString() !== id.toString()
      );
      await setRootconcept(tempSubConcept);
    }
  };

  const handleSelectSubconcept = (event, item, list) => {
    const conceptItem = item ? item : selectedItem;
    const subConceptList = list ? list : subconcept;
    const tempSubId = [];
    if (!conceptItem.isSelectedSubconcept || (list && conceptItem.isSelectedSubconcept)) {
      if (!list || !conceptItem.isSelectedSubconcept) {
        const subTemp = subConceptList.map((d) => {
          if (d.id?.toString() === conceptItem.id?.toString()) {
            tempSubId.push(d.concept_id?.toString());
            return { ...d, isSelectedSubconcept: true };
          } else {
            return d;
          }
        });

        setSubconcept(subTemp);
      }
      getRootConcept(conceptItem.id, tempSubId[0] || item.concept_id?.toString());
      const tempAssociat = association.map((data) => {
        if (tempSubId.includes(data.conceptId?.toString())) {
          return {
            ...data,
            subConcept: [
              ...data.subConcept,
              { subConceptId: conceptItem.id, rootConceptIds: [] },
            ],
          };
        } else return data;
      });
      setAssociation(tempAssociat);
    } else {
      const subTemp = subconcept.map((d) => {
        if (d.id.toString() === conceptItem?.id?.toString()) {
          tempSubId.push(d.concept_id?.toString());
          return { ...d, isSelectedSubconcept: false };
        } else {
          return d;
        }
      });
      setSubconcept(subTemp);
      haldeRemoveRoootConcepts(conceptItem.id);
      const tempAssociat = association.map((data) => {
        if (tempSubId.includes(data.conceptId.toString())) {
          return {
            ...data,
            subConcept: data.subConcept.filter(
              (d) => d.subConceptId?.toString() !== conceptItem.id?.toString()
            ),
          };
        } else return data;
      });
      setAssociation(tempAssociat);
      // getRootConcept(id)
    }
    setToggleConfirmationSub(false);
    setSelectedItem({});
  };
  const handleSelectRootconcept = (event, item) => {
    const tempSubId = [];
    const tempConId = [];
    const conceptItem = item ? item : selectedItem;

    if (!conceptItem.isSelectRootConcept) {
      const subTemp = rootconcept.map((d) => {
        if (d.id.toString() === conceptItem.id.toString()) {
          tempConId.push(d.concept_id?.toString());
          tempSubId.push(d.sub_concept_id?.toString());

          return { ...d, isSelectRootConcept: true };
        } else {
          return d;
        }
      });
      setRootconcept(subTemp);
      const tempAssociat = association.map((data) => {
        if (tempConId.includes(data.conceptId.toString()) && data.subConcept) {
          const tempSub = data.subConcept?.map((sub) => {
            if (tempSubId.includes(sub.subConceptId?.toString() || sub.id?.toString())) {
              return { ...sub, rootConceptIds: sub.rootConceptIds ? [...sub.rootConceptIds, conceptItem.id] : [conceptItem.id] };
            } else return sub;
          });
          return {
            ...data,
            subConcept: tempSub,
          };
        } else return data;
      });
      setAssociation(tempAssociat);
    } else {
      const subTemp = rootconcept.map((d) => {
        if (d.id.toString() === conceptItem.id.toString()) {
          tempConId.push(d.concept_id.toString());
          tempSubId.push(d.sub_concept_id.toString());
          return { ...d, isSelectRootConcept: false };
        } else {
          return d;
        }
      });
      setRootconcept(subTemp);
      // getRootConcept(id)
    }
    setToggleConfirmationRoot(false);
    setSelectedItem({});
  };

  const getSubConcept = async (id, autoSelect) => {
    return axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?pageSize=10&sortOrder=${sortOrder}&sortBy=${sortBy}&conceptId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const conceptList = res.data.data.data
          .map((data) => {
            if (subconcept.find((s) => s.id === data.id)) return;
            return { ...data, concept_id: id, isSelectedSubconcept: false };
          })
          .filter((s) => !!s);
        const subFiletr = subconcept?.length > 0 ? [...subconcept, ...conceptList.filter(_r => !subconcept.find(p => p.id === _r.id))] : conceptList
        setSubconcept((pre) => pre?.length > 0 ? [...pre, ...conceptList.filter(_r => !pre.find(p => p.id === _r.id))] : conceptList);
        // if (autoSelect) conceptList.forEach((_s) => { getRootConcept(_s.id, id); })
        return subFiletr;
      })
      .catch((err) => console.log(err));
  };

  const getConcept = (tt) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?search=${search.search}&subjectId=${subjectId}&pageSize=2500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.totalElements)
          setTotalConcept(res?.data.data.totalElements);
        if (tt && res?.data?.data?.data) {
          const tempConceptId = tt?.map((data) => data.conceptId);
          const tempConcept = [];
          res?.data?.data?.data?.forEach((data) => {
            if (!tempConceptId.includes(data.id.toString())) {
              tempConcept.push({
                ...data,
                isSelectedSearch: false,
                isSelectedConcept: false,
              });
            } else {
              tempConcept.push({
                ...data,
                isSelectedSearch: true,
                isSelectedConcept: true,
              });
            }
          });
          setAssociation(tt);
          setConceptAll(tempConcept);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRootConcept = (id, concept_id) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/all?pageSize=2500&sortOrder=${sortOrder}&sortBy=${sortBy}&subConceptId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // setRootconcept(res.data.data.data);
        setRootconcept((prev) =>
          prev?.length > 0
            ? [
              ...prev,
              ...res.data.data.data
                .filter(_r => !prev.find(p => p.id === _r.id))
                .map((data) => ({
                  ...data,
                  concept_id,
                  sub_concept_id: id,
                  isSelectRootConcept: false,
                })),
            ]
            : res.data.data.data.map((data) => ({
              ...data,
              concept_id,
              sub_concept_id: id,
              isSelectRootConcept: false,
            }))
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    APIcall();
    association_last_update_get(setLastUpdated);
  }, [sortOrder]);
  const APIcall = async () => {
    await getAssociateion((tt) => getConcept(tt));
    //  await  getConcept(search);
  };

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleConfirmationSub(false);
    setToggleConfirmationRoot(false);
    setToggleDetails({});
  };

  const subConceptWithFilter = useMemo(() => {
    const list = []
    subconcept
      .sort((a, b) => b.concept_id?.localeCompare(a.concept_id))
      .forEach(concept => {
        const subPresent = list.find(c => c.concept_id === concept.concept_id)
        if (!subPresent) {
          const C = conceptAll.find(c => c.id === concept.concept_id)
          list.push({ ...C, isParent: true })
        }
        list.push(concept)
      })
    return list
  }, [subconcept])

  const rootConceptWithFilter = useMemo(() => {
    const list = []
    rootconcept
      .sort((a, b) => b.sub_concept_id.localeCompare(a.sub_concept_id))
      .forEach(concept => {
        const rootParent = list.find(c => c.sub_concept_id === concept.sub_concept_id)
        if (!rootParent) {
          const C = subconcept.find(c => c.id === concept.sub_concept_id)
          list.push({ ...C, isParent: true })
        }
        list.push(concept)
      })
    return list
  }, [rootconcept])

  const spaceCount = useMemo(() => {

    return conceptAll.filter(c => c.isSelectedSearch).map((c) => {
      const s = subconcept.filter(s => s.concept_id === c.id)
        .map(sub => {
          const r = rootconcept.filter(r => r.sub_concept_id === sub.id)
          return {
             ...sub, root: r, rootLength: r.length }
        }).sort((a, b) => a.subConcept.localeCompare(b.subConcept))
      let rLength = 0
      s.forEach(_s => rLength += _s.rootLength)
      return { ...c, sub: s, subLength: s.length, rootLength: rLength, maxLength: s.length > rLength ? s.length : rLength }
    })
  }, [conceptAll, subconcept, rootconcept])

  useEffect(() => {
    const fetchSub = async () => {
      await Promise.all(conceptAll.map(async (_c) => {
        if (_c.isSelectedSearch) { return await getSubConcept(_c.id, true) }
        else return null
      }))
    }
    fetchSub()
  }, [conceptAll])

  const chapter = sessionStorage.getItem('chapter') || '';
  const maxLength = 20; // Set the maximum visible length

  let displayText = chapter;
  if (chapter.length > maxLength) {
    displayText = `${chapter.substring(0, maxLength)}...`;
  }
  const subject = sessionStorage.getItem('subject') || '';
  const maxlength = 20; // Set the maximum visible length

  let subjectText = subject;
  if (subject.length > maxlength) {
    subjectText = `${subject.substring(0, maxlength)}...`;
  }

  const grade = sessionStorage.getItem('grade') || '';
  const marginLeftStyle = {
    marginLeft: subjectText.length < 8 ? '20px' : '0', // Adjust the value as needed
  };

  const chapteStyle = {
    marginLeft: displayText.length < 8 ? '23px' : '0',
  }
  const gradeStyle = {
    marginLeft: grade.length < 8 ? '20px' : '0',
  }
  // useEffect(() => {
  //   subconcept.forEach(_s => {
  //     if (!_s.isSelectedSubconcept) {
  //       handleSelectSubconcept(undefined, _s)
  //     }
  //   })
  // }, [subconcept])

  const rowSpanCount = (item) => {
    let count = 0;
    item.subConcept?.map((sub) => {
      if (sub.rootConceptResponses) count += sub.rootConceptResponses.length
      else count += 1
    })
    return count;
  }

  const chapterRowCount = useMemo(() => {
    let count = 0;
    previewData.map((item) => {
      if (item.subConcept) {
        item.subConcept.map((sub) => {
          if (sub.rootConceptResponses) count += sub.rootConceptResponses.length
          else count += 1
        })
      }
      else count += 1
    })
    return count
  }, [previewData])

  const CustomIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
      color: 'orange',
    },
  }))

  return (
    <div style={{ width: "100%" }}>
      <div className="az_home_navigation_section" style={{ top: "67px" }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <p className="az_navigation_text"><span style={{ position: "relative" }}>
              <img style={{ position: "absolute", top: "1px" }}
                src={dashHome}
                alt="no img"
                width="18px"
                height="18px"

              /> </span>
              <span
                onClick={() => navigate("/dashboard/template")}
                className="az_navigation_content" style={{ marginLeft: "20px" }}>Home</span>
            </p>
          </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <span

            className="az_navigation_text" style={{ marginLeft: "20px" }}>Associate concepts</span>

          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>

          <p className="az_navigation_text"><span style={{ color: "#FFA500" }}>Details</span></p>

        </div>
        <p className="az_last_update_time">Last Update:  {lastUpdated.data}</p>
      </div>
      {/* <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <Link to="/dashboard/AssociateCptDetails">
            <span className={dashboardContentStyles.link_text}>
              Associate concepts
            </span>
          </Link>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Details</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>
            Last Update:
            {lastUpdated.data}
          </p>
        </div>
      </article> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "0.5vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <LibraryBooksRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Associate Concepts
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: "1rem",
            }}
          >
            {/* <TextField
              type={"text"}
              placeholder={"Search...."}
              sx={{
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  font: "normal normal normal 13px/20px Poppins !important",
                  opacity: 1,
                },
              }}
              // onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            /> */}
            {/* {!addChapterVisible && (
                <GreenAddButton onClick={() => setAddChapterVisible(true)}>
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add Chapter
                </GreenAddButton>
              )} */}
          </div>
        </div>
        <Paper>
          <div>
            <div>
              <div className={associateStyle.container}>
                <div className={associateStyle.box}>
                  <h3>*Board</h3>
                  <p style={{ marginLeft: "20px" }}>{sessionStorage.getItem("board")}</p>
                </div>
                <div className={associateStyle.box}>
                  <h3>*Grade</h3>
                  <p style={gradeStyle}>{grade}</p>
                </div>
                <div className={associateStyle.box}>
                  <h3>*Subject</h3>
                  {subject.length > maxlength ? (
                    <Tooltip title={subject} arrow>
                      <div
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <p style={marginLeftStyle}>{subjectText}</p>
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <p style={marginLeftStyle}>{subjectText}</p>
                    </div>
                  )}
                </div>
                <div className={associateStyle.box}>
                  <h3>*Chapter</h3>
                  {chapter.length > maxLength ? (
                    <Tooltip title={chapter} arrow>
                      <div
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <p>{displayText}</p>
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <p style={chapteStyle}>{displayText}</p>
                    </div>
                  )}
                </div>
              </div>
              <div>

              </div>
            </div>
            <div>
              <div
                style={{ margin: "50px 50px 0px -124px" }}
                className={associateStyle.flex_center}
              >
                <p>Concept search</p>
                <TextField
                  type={"text"}
                  name="search"
                  autoComplete="off"
                  onFocus={() => setShowSearch(true)}
                  value={search.search}
                  onChange={handleSearch}
                  placeholder={"Type concept name to find"}
                  sx={{
                    width: { sm: "40%", md: "40%" },
                    "& .MuiInputBase-root": {
                      width: "510px !important",
                      marginLeft: "10px",
                      height: "32px !important",
                      borderRadius: "50px !important",
                      background: "#fff !important",
                      lineHeight: "21px !important",
                      font: "normal normal normal 13px/20px Poppins !important",
                      opacity: 1,
                    },
                  }}
                  // onChange={onSearch}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                      >
                        <i className="fa fa-search"
                          style={{
                            fontSize: "18px", color: "orange",
                            ':hover': {
                              color: "orange !important",
                            }
                          }} aria-hidden="true"
                        ></i>

                        {/* <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        /> */}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div
                style={
                  showSearch
                    ? { display: "block", height: "230px", overflowY: "scroll" }
                    : { display: "none" }
                }
                className={associateStyle.searchOptBox}
              >
                <div style={{ width: "100%" }}>
                  <ul>
                    {conceptAll.length > 0
                      ? conceptAll
                        ?.filter((d) =>
                          search?.search && search?.search.length > 0
                            ? d.concept
                              .toLowerCase()
                              .includes(search?.search.toLowerCase())
                            : d
                        )
                        .map((elem) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                // borderBottom: "1px solid grey",
                              }}
                              className={associateStyle.Dd_itm_style}
                            >
                              <Checkbox
                                {...label}
                                sx={{
                                  color: `#111fee !important`,
                                  "&.Mui-checked": {
                                    color: blue[900],
                                  },
                                }}
                                // value={elem.concept}
                                onChange={(e) => checboxOnchange(e, elem.id)}
                                checked={elem.isSelectedSearch}
                              />
                              <li
                                style={{
                                  listStyleType: "none",
                                  padding: "8px",
                                }}
                              >
                                {elem.concept}
                              </li>
                            </div>
                          );
                        })
                      : ""}
                    <li
                      style={{
                        position: "relative",
                        listStyleType: "none",
                        padding: "8px",
                        color: "blue",
                      }}
                    >
                      <span style={{ position: "absolute", left: "5px" }}>
                        {conceptAll.length
                          ? conceptAll?.filter((d) =>
                            search?.search && search?.search.length > 0
                              ? d.concept
                                .toLowerCase()
                                .includes(search?.search.toLowerCase())
                              : d
                          ).length
                          : 0}{" "}
                        Results
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          right: "5px",
                          color: "blue",
                        }}
                      >
                        1 2 3 4 5
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginTop: "50px" }}>
              <div style={{ width: "100%" }} className={associateStyle.d_flex}>
                <div
                  style={{ borderRight: "2px solid lightgrey", width: "33%" }}
                  className={associateStyle.concept_col}
                >
                  <p>
                    Concepts
                    {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("concept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </p>
                </div>
                <div
                  style={{ borderRight: "2px solid lightgrey", width: "33%" }}
                  className={associateStyle.concept_col}
                >
                  <p>
                    Sub-concepts
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subConcept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </p>

                </div>
                <div
                  style={{ borderRight: "2px solid lightgrey", width: "33%" }}
                  className={associateStyle.concept_col}
                >
                  <p>
                    Root-Concepts
                    {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("rootConcept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>

            {spaceCount.map((_c) => <>
              <div style={{ width: "100%" }} className={associateStyle.d_flex}>
                <div
                  style={{ borderRight: "2px solid lightgrey", width: "33%" }}
                  className={associateStyle.concept_col}
                >
                  <div>
                    <ul>
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid grey",
                          backgroundColor: "#C9E9FD"
                        }}
                        className={associateStyle.Dd_itm_style}
                        key={_c.id}
                      >
                        <Checkbox
                          {...label}
                          sx={{
                            color: `#111fee !important`,
                            "&.Mui-checked": {
                              color: blue[900],
                            },
                          }}
                          onClick={(e) => {
                            setSelectedItem(_c);
                            if (_c.isSelectedConcept) {
                              ConfirmationAPI(_c);
                            }
                            else {
                              hanleSelctConcepts(e, _c);
                            }
                          }}
                          checked={_c.isSelectedConcept}
                        />
                        <li
                          style={{
                            listStyleType: "none",
                            padding: "8px",
                          }}
                        >
                          {_c.concept}
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div
                  style={{ borderRight: "2px solid lightgrey", width: "66%" }}
                  className={associateStyle.concept_col}
                >
                  <div>
                    <div>
                      <ul>
                        {/* <div
                          style={{
                            display: "flex",
                            borderBottom: "1px solid grey",
                            backgroundColor: "#C9E9FD"
                          }}
                          key={_c.id}
                          className={associateStyle.Dd_itm_style}
                        ><li
                          style={{
                            listStyleType: "none",
                            padding: "8px",
                          }}
                        >
                            {_c.concept}
                          </li>
                        </div> */}
                        {_c.sub.length > 0 && _c.isSelectedConcept
                          ? _c.sub.map((elem) => <>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid grey",
                                  }}
                                  key={elem.id}
                                  className={associateStyle.Dd_itm_style}
                                >
                                  <Checkbox
                                    {...label}
                                    sx={{
                                      color: `#111fee !important`,
                                      "&.Mui-checked": {
                                        color: blue[900],
                                      },
                                    }}
                                    onClick={(e) => {
                                      setSelectedItem(elem);
                                      if (elem.isSelectedSubconcept) {
                                        ConfirmationAPI('', elem, '');
                                      }
                                      else {
                                        handleSelectSubconcept(e, elem);
                                      }
                                    }}
                                    checked={elem.isSelectedSubconcept}
                                  />
                                  <li
                                    style={{
                                      listStyleType: "none",
                                      padding: "8px",
                                    }}
                                  >
                                    {elem.subConcept}
                                  </li>
                                </div>
                              </div>
                              <div style={{ width: '50%' }}>
                                {elem.root.length ?
                                  elem.root.map((_r) => <div
                                    style={{
                                      display: "flex",
                                      borderBottom: "1px solid grey",
                                      borderLeft: "1px solid grey",
                                      borderTop: "1px solid grey",
                                    }}
                                    key={_r.id}
                                    className={associateStyle.Dd_itm_style}
                                  >
                                    <Checkbox
                                      {...label}
                                      sx={{
                                        color: `#111fee !important`,
                                        "&.Mui-checked": {
                                          color: blue[900],
                                        },
                                      }}
                                      onClick={(e) => {

                                        setSelectedItem(_r);
                                        if (_r.isSelectRootConcept) {
                                          ConfirmationAPI('', '', _r);
                                        }
                                        else {
                                          handleSelectRootconcept(e, _r);
                                        }
                                      }}
                                      checked={_r.isSelectRootConcept}
                                    />
                                    <li
                                      style={{
                                        listStyleType: "none",
                                        padding: "8px",
                                      }}
                                    >
                                      {_r.rootConcept}
                                    </li>
                                  </div>)
                                  : null}
                              </div>
                            </div>
                          </>)
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{ borderRight: "2px solid lightgrey", width: "33%" }}
                  className={associateStyle.concept_col}
                >
                  <div>
                    <div>
                      <ul>
                        {_c.sub.length ?
                          _c.sub.map((_rs) => <>
                            {_rs.root.length ? <div
                              style={{
                                display: "flex",
                                borderBottom: "1px solid grey",
                                backgroundColor: "#C9E9FD"
                              }}
                              key={_rs.id}
                              className={associateStyle.Dd_itm_style}
                            ><li
                              style={{
                                listStyleType: "none",
                                padding: "8px",
                              }}
                            >
                                {_rs.subConcept}
                              </li>
                            </div> : null}
                            {_rs.root.length ?
                              _rs.root.map((_r) => <div
                                style={{
                                  display: "flex",
                                  borderBottom: "1px solid grey",
                                }}
                                key={_r.id}
                                className={associateStyle.Dd_itm_style}
                              >
                                <Checkbox
                                  {...label}
                                  sx={{
                                    color: `#111fee !important`,
                                    "&.Mui-checked": {
                                      color: blue[900],
                                    },
                                  }}
                                  onClick={(e) => {

                                    setSelectedItem(_r);
                                    if (_r.isSelectRootConcept) {
                                      ConfirmationAPI('', '', _r);
                                    }
                                    else {
                                      handleSelectRootconcept(e, _r);
                                    }
                                  }}
                                  checked={_r.isSelectRootConcept}
                                />
                                <li
                                  style={{
                                    listStyleType: "none",
                                    padding: "8px",
                                  }}
                                >
                                  {_r.rootConcept}
                                </li>
                              </div>)
                              : null}
                          </>)
                          : null}
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
              <div style={{ border: '1px solid grey', height: '40px', width: '99%' }} /></>
            )}

            <div style={{ position: "relative" }}>
              <div
                style={{ position: "absolute", right: "8px", margin: "10px" }}
              >
                <BlueButton onClick={createAssociation}>ASSOCIATE</BlueButton>
              </div>
            </div>
          </div>
        </Paper>
        <Modal
          open={AlertModal}
          onClose={closeSuccessModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <div
              style={{
                overflowY: "scroll",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
                // height: "500px",
                height: "700px",
                // width: "750px",
                width: "1000px",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              <h4
                style={{
                  padding: "25px",
                  color: "blue",
                  opacity: "0.8",
                  fontSize: "20px",
                }}
              >
                Associated Concept Tree - Preview
              </h4>
              <div style={{ padding: "0 10px", textAlign: "center" }}>
                <ForTable>
                  <thead>
                    <tr>
                      <Forth>Chapter</Forth>
                      <Forth>Concept</Forth>
                      <Forth>SubConcept</Forth>
                      <Forth>RootConcept</Forth>
                    </tr>
                  </thead>
                  <tbody>
                    {previewData.map((item, i) =>
                      item.subConcept ? (
                        item.subConcept.map((sub, j) =>
                          sub.rootConceptResponses ? (
                            sub.rootConceptResponses.map((root, l) => (
                              <tr>
                                {i === 0 && j === 0 && l === 0 && <Fortd rowSpan={chapterRowCount}>{chapterName}</Fortd>}
                                {j === 0 && l === 0 && (
                                  <Fortd rowSpan={rowSpanCount(item)}>{item.concept}</Fortd>
                                )}
                                {l === 0 && <Fortd rowSpan={sub.rootConceptResponses.length}>{sub.subConcept}</Fortd>}

                                <Fortd>{root.rootConcept}</Fortd>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              {i === 0 && j === 0 && <Fortd rowSpan={chapterRowCount}>{chapterName}</Fortd>}
                              {j === 0 &&
                                <Fortd rowSpan={rowSpanCount(item)}>{item.concept}</Fortd>}
                              <Fortd>{sub.subConcept}</Fortd>
                              <Fortd>-</Fortd>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          {i === 0 && <Fortd rowSpan={chapterRowCount}>{chapterName}</Fortd>}
                          <Fortd>{item.concept}</Fortd>
                          <Fortd>-</Fortd>
                          <Fortd>-</Fortd>
                        </tr>
                      )
                    )}
                  </tbody>
                </ForTable>
              </div>
              <center>
                <button
                  onClick={() => {
                    closeAlertModal();
                    closeSuccessModal();
                  }}
                  style={{
                    // padding: "15px",
                    padding: "5px 35px 5px 35px ",
                    // backgroundColor: "orange",
                    backgroundColor: "#FD8C00",
                    // borderRadius: "16px",
                    borderRadius: "14px",
                    border: "none",
                    color: "white",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  OK
                </button>
              </center>
            </div>
          </Box>
        </Modal>
      </div>
      <SuccessModal
        open={successModal}
        subtitle={sessionStorage.getItem("chapter") ?? ""}
        title={`(${sessionStorage.getItem("board") ?? ""} | ${sessionStorage.getItem("grade") ?? ""
          } | ${sessionStorage.getItem("subject") ?? ""})`}
        createHandler={close}
        close={closeSuccessModal}
      ></SuccessModal>
      <ConfirmationModal
        open={toggleConfirmation}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={hanleSelctConcepts}
      />
      <ConfirmationModal
        open={toggleConfirmationSub}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={handleSelectSubconcept}
      />
      <ConfirmationModal
        open={toggleConfirmationRoot}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={handleSelectRootconcept}
      />
    </div>
  );
};

export default AssociateCptDetails;

const ForTable = styled.table`
border-collapse:collapse;
caption-side:bottom;
margin-bottom: 15px;
margin-top: 15px;
border: solid 1px #000;
padding: 10px;
margin-left: auto;
margin-right: auto;
`;

const Forth = styled.th`
background: #726969;
color :#fff;
border: solid 1px #000;
padding: 10px;
`;

const Fortd = styled.td`
border: solid 1px #000;
padding: 10px;
word-break: break-all;
`;