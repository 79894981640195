import React, { useEffect, useState } from "react";
import DashboardBluePrintLayer from "../DashboardBluePrintLayer/DashboardBluePrintLayer";
import "./blueprintConfig.css";
import {
  tableColumns,
  getDataStructureFomat,
  validateFields,
  getDataStructureFomat2,
} from "../BluePrintDataHelpers";
import {
  fetchAllBluePrints,
  createBluePrints,
  bluePrintsToggle,
} from "../Apis/BluePrintApis";
import Switch from "../../../../components/common/ToggleSwitch";
import action__Edit from "../../../../img/VectorEdit.svg";
import ConfigurationSuccessModal from "../../../../components/common/ConfigurationSuccessModal";
import { hover } from "@testing-library/user-event/dist/hover";
import BluePrintErrorModal from "../../../../components/common/BluePrintErrorModal";
import { blue } from "@mui/material/colors";
import { data } from "jquery";

const BluePrintConfiguration = () => {
  const [bluePrintList, setBluePrintList] = useState([]);
  const [bluePrintInitialList, setBluePrintIntialList] = useState([]);
  const [bluePrintCount, setBluePrintCount] = useState("");
  const [bluePrintInitialCount, setBluePrintInitialCount] = useState("");
  const [generationCount, setGenerationCount] = useState(3);
  const [generationCountInitial, setGenerationCountInitial] = useState(3);
  // const [frequencyCount, setFrequencyCount] = useState(1);
  const [frequencyCountInitial, setFrequencyCountInitial] = useState(1);
  const [dataTrigger, setDataTrigger] = useState("");
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [isSelectedForEdit, setIsSelectedForEdit] = useState(false);

  const [errorCreateModal, setErrorCreateModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [cancelButtonShow, setCancelButtonShow] = useState(false);
  const [triggerButtonShow, setTriggerButtonShow] = useState(false);

  useEffect(() => {
    fetchBluePrintList();
    setTriggerButtonShow(true);
  }, []);

  useEffect(() => {
    let filterData = bluePrintList.filter((elem) => elem?.status === false);
    if (
      // parseInt(frequencyCount) > parseInt(frequencyCountInitial) ||
      parseInt(bluePrintCount) != parseInt(bluePrintInitialCount) ||
      parseInt(generationCount) > parseInt(generationCountInitial) ||
      bluePrintList.length !== bluePrintInitialList.length ||
      (filterData.length > 0 && triggerButtonShow)
    ) {
      setCancelButtonShow(true);
    } else {
      setCancelButtonShow(false);
    }
  }, [
    // frequencyCount,
    bluePrintCount,
    generationCount,
    bluePrintList,
    triggerButtonShow,
    bluePrintInitialCount,
  ]);

  const fetchBluePrintList = async () => {
    const response = await fetchAllBluePrints();
    setBluePrintList(response.levels || []);
    setBluePrintIntialList(response.levels || []);
    setBluePrintCount(response?.levelCount || 1);
    setGenerationCount(response?.questionCount || 3);
    // setFrequencyCount(response?.questionFrequency || 1);
    setBluePrintInitialCount(response?.levelCount || 1);
    setGenerationCountInitial(response?.questionCount || 3);
    setFrequencyCountInitial(response?.questionFrequency || 1);
  };

  const BluePrintData = async () => {
    let values = [...bluePrintInitialList];
    const updatedValues = values.slice(0, dataTrigger);
    if (updatedValues) {
      const formatted = getDataStructureFomat(
        dataTrigger,
        bluePrintCount,
        updatedValues
      );
      setBluePrintList(formatted);
      setBluePrintCount(dataTrigger);
      setDataTrigger("");
    }
  };

  useEffect(() => {
    if (dataTrigger) {
      BluePrintData();
    }
  }, [dataTrigger]);

  const createBluePrint = async () => {
    let data = {
      levelCount: bluePrintCount,
      questionCount: generationCount,
      // questionFrequency: frequencyCount,
      levels: bluePrintList,
      // bluePrintList?.map((i) => {
      //   return {
      //     id: i?.id || "",
      //     active: i.active,
      //     description: i.description,
      //     levelName: i.levelName,
      //     levelNumber: i.levelNumber,
      //   };
      // }),
    };
    const response = await createBluePrints(data);
    if (response?.success == true && !isSelectedForEdit) {
     
      setModalMessage(response?.message);
      fetchBluePrintList();
      setTimeout(() => {
        setSuccessCreateModal(true);
      }, 500);
    }
    if (response?.success == true && isSelectedForEdit) {
     setTimeout(() => {
      setSuccessCreateModal(true);
     }, 500);
      setModalMessage("Successfully updated the blueprint levels");
      fetchBluePrintList();
      setIsSelectedForEdit(false);
    }
    if (response?.success == false) {
      setModalErrorMessage("Level already exists");
      fetchBluePrintList();
      setTimeout(() => {
        setErrorCreateModal(true);
      }, 500);
    }
  };

  const validateSubmit = (bluePrintList, bluePrintCount, bluePrintInitialCount, isSelectedForEdit) => {
    if (!validateFields(bluePrintList)) {
      return false;
    }
    if (isSelectedForEdit) {
      return true;
    }
    return parseInt(bluePrintCount) > parseInt(bluePrintInitialCount);
  };

  const toggleBluePrint = async (objItem) => {
    const response = await bluePrintsToggle(objItem);
    if (response?.success == true) {
      setTimeout(() => {
        setSuccessCreateModal(true);
      }, 500);
      setModalMessage(response?.message);
    }
    if (response?.success == false) {
      alert(response?.message);
    }
  };

  const handleBluePrints = (e) => {
    if (e.target.value > 0) {
      let countValue = e.target.value;
      if (parseInt(countValue) >= parseInt(bluePrintCount)) {
        setDataTrigger(e.target.value);
      } else if (parseInt(e.target.value) < parseInt(bluePrintCount)) {
        const formatted = getDataStructureFomat2(
          countValue,
          bluePrintCount,
          bluePrintList
        );
        setBluePrintList(formatted);
        setBluePrintCount(e.target.value);
      }
    }
  };

  const handleQpgeneration = (e) => {
    if (e.target.value > 2) {
      setGenerationCount(e.target.value);
    }
  };

  // const handleQuestionFrequency = (e) => {
  //   if (e.target.value >= 1) {
  //     setFrequencyCount(e.target.value);
  //   }
  // };

  const handleInputs = (e, index) => {
    const values = [...bluePrintList];
    let inputValue = e.target.value;
    values[index][e.target.name] = inputValue;
    setBluePrintList(values);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (parseInt(bluePrintCount) < parseInt(bluePrintInitialCount)) {
  //     alert(
  //       `Blue print count should not be lower than ${bluePrintInitialCount} `
  //     );
  //     fetchBluePrintList();
  //   } else {
  //     createBluePrint();
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSelectedForEdit && parseInt(bluePrintCount) <= parseInt(bluePrintInitialCount)) {
      fetchBluePrintList();
    } else {
      createBluePrint();
      setIsSelectedForEdit(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    fetchBluePrintList();
  };

  const handleEdit = (e, index) => {
    setIsSelectedForEdit(true);
    const values = [...bluePrintList];
    let inputValue = false;
    values[index]["status"] = inputValue;
    setBluePrintList(values);
  };

  const handleToggle = (e, index) => {
    const values = [...bluePrintList];
    let inputValue = e.target.checked;
    values[index]["active"] = inputValue;
    setBluePrintList(values);
    let objItem = {
      id: values[index].id,
      active: inputValue,
    };
    // console.log(values[index].id);
    toggleBluePrint(objItem);
  };

  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };

  const closeErrorModal = () => {
    setErrorCreateModal(false);
  };

  return (
    <DashboardBluePrintLayer>
      <div
        style={{
          padding: 20,
          backgroundColor: "#fff",
          margin: "20px",
          borderRadius: "18px",
        }}
      >
        <div className="bp-configuration-count">
          <div>
            <span>Number Of Blueprints</span>
            <input
              className="bp-config-text"
              type="number"
              min={bluePrintInitialCount}
              value={bluePrintCount}
              onChange={handleBluePrints}
            />
          </div>
          <div>
            <span>QP Generation- No.of Rewritten</span>
            <input
              className="bp-config-text"
              type="number"
              min="3"
              value={generationCount}
              onChange={handleQpgeneration}
            />
          </div>
          {/* <div>
            <span>Question Frequency</span>
            <input
              className="bp-config-text"
              type="number"
              min="1"
              value={frequencyCount}
              onChange={handleQuestionFrequency}
            />
          </div> */}
        </div>
        <div className="bp-table">
          <div className="bp-table-head">
            {tableColumns.map((item) => (
              <div
                className="bp-table-head-cell"
                style={{ width: `${item.width}%` }}
                key={item.id}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className="bp-table-body">
            {bluePrintList
              .sort((a, b) => a.levelNumber - b.levelNumber)
              .map((item, index) => (
                <div className="bp-table-body-row" key={item?.index}>
                  <div
                    className={`bp-table-body-cell ${index === 0 ? "bp-table-first-row" : ""
                      } ${index === bluePrintList.length - 1
                        ? "bp-table-last-row"
                        : ""
                      }`}
                    style={{ width: "30%" }}
                  >
                    {item?.levelNumber}
                  </div>
                  <div
                    className={`bp-table-body-cell ${index === 0 ? "bp-table-first-row" : ""
                      } ${index === bluePrintList.length - 1
                        ? "bp-table-last-row"
                        : ""
                      }`}
                    style={{ width: "30%" }}
                  >
                    <input
                      style={{
                        border:
                          item?.status == false ? "1px Solid #7e7e7e" : "",
                        height: item?.status == false ? "30px" : "",
                        borderRadius: item?.status == false ? "5px" : "",
                        paddingLeft: "5px",
                        color: "#252525",
                        textAlign: "center",
                      }}
                      className="bp-table-text"
                      type="text"
                      name="levelName"
                      value={item?.levelName}
                      onChange={(e) => handleInputs(e, index)}
                      disabled={item?.status == false ? false : true}
                    />
                  </div>
                  <div
                    className={`bp-table-body-cell ${index === 0 ? "bp-table-first-row" : ""
                      } ${index === bluePrintList.length - 1
                        ? "bp-table-last-row"
                        : ""
                      }`}
                    style={{ width: "30%" }}
                  >
                    <input
                      style={{
                        border:
                          item?.status == false ? "1px Solid #7e7e7e" : "",
                        height: item?.status == false ? "30px" : "",
                        borderRadius: item?.status == false ? "5px" : "",
                        paddingLeft: "5px",
                        color: "#252525",
                        textAlign: "center",
                      }}
                      type="text"
                      name="description"
                      className="bp-table-text"
                      value={item?.description}
                      onChange={(e) => handleInputs(e, index)}
                      disabled={item?.status == false ? false : true}
                    />
                  </div>
                  <div
                    className={`bp-table-body-cell ${index === 0 ? "bp-table-first-row" : ""
                      } ${index === bluePrintList.length - 1
                        ? "bp-table-last-row"
                        : ""
                      }`}
                    style={{
                      width: "15%",
                      paddingLeft: 0,
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "30px",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Switch
                        id={`az-content-toggle-button-${index}`}
                        checked={item?.active}
                        onChange={(e) =>
                          item.id ? handleToggle(e, index) : ""
                        }
                      />
                      {item.id && (
                        <img
                          src={action__Edit}
                          id={`az-content-edit-button-${index}`}
                          alt="Edit-Btn"
                          onClick={(e) => (item.id ? handleEdit(e, index) : "")}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "end", margin: "10px" }}>
          <div style={{ marginRight: "2px", padding: "5px" }}>
            <button
              type="button"
              id={`az-content-cancel-button`}
              className="bp-cancel-btn"
              style={{
                backgroundColor: cancelButtonShow ? "red" : "",
                cursor: "pointer",
                color: cancelButtonShow ? "#ffff" : "",
              }}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
          {bluePrintList.length > 0 ? (
            <div style={{ padding: "5px" }}>
              {/* <button
                id={`az-content-submit-button`}
                style={{
                  backgroundColor: validateFields(bluePrintList) ? "#403e75" : "#cccc",
                  cursor: "pointer",
                }}
                type="button"
                className="bp-submit-btn"
                onClick={handleSubmit}
                disabled={validateFields(bluePrintList) ? false : true}
              >
                Submit
              </button> */}
              <button
                id="az-content-submit-button"
                style={{
                  backgroundColor: validateSubmit(bluePrintList, bluePrintCount, bluePrintInitialCount, isSelectedForEdit) ? "#403E75" : "#cccc",
                  cursor: "pointer",
                }}
                type="button"
                className="bp-submit-btn"
                onClick={handleSubmit}
                disabled={!validateSubmit(bluePrintList, bluePrintCount, bluePrintInitialCount, isSelectedForEdit)}
              >
                Submit
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <ConfigurationSuccessModal
        userType={modalMessage}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <BluePrintErrorModal
        userType={modalErrorMessage}
        open={errorCreateModal}
        handleOk={closeErrorModal}
      />
    </DashboardBluePrintLayer>
  );
};

export default BluePrintConfiguration;
