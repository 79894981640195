import axios from "axios"
import commonsort from "../../js/CommonSort";

async function fileStatus_approved_get(
  setChapters,
  rowsInput,
  page,
  setPageDetail,
  search,
  subjectInput,
  sortBy, sortOrder

) {
  let token = sessionStorage.getItem("token");

  var URL = `${process.env.REACT_APP_SWAGGER_URL
    }v1/api/content/concepts/sub-roots-mapping?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${subjectInput}&reviewStatus=APPROVED`;
  let result = await axios
    .get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (json) => {
      if (json.data.data) {
        // var sortedData = await commonsort(json.data.data.data || [], sortBy, sortOrder);
        setChapters(json.data.data.data || []);
        setPageDetail(json.data.data);
      }
    })
    .catch((err) => console.log(err));
}

export default fileStatus_approved_get;
