import { ConnectingAirportsOutlined } from '@mui/icons-material';
import axios from 'axios'

function teacher_paginated_content_get(setUpdatedata){
    let token = sessionStorage.getItem("token");
    let status = sessionStorage.getItem("status")
   if(status != null ){
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=50&sortBy=id&chapterId=${sessionStorage.getItem("chapterId")}&reviewStatus=${status}`,
    {
        headers:{
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setUpdatedata(res.data.data.data)
    })
    .catch(err => ConnectingAirportsOutlined.log(err))
}
else{
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=50&sortBy=id&chapterId=${sessionStorage.getItem("chapterId")}`,
    {
        headers:{
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setUpdatedata(res.data.data.data)
    })
    .catch(err => ConnectingAirportsOutlined.log(err))
}
}

export default teacher_paginated_content_get