import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tokenone from "./../css/token35.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import User_Token_get from "./../API/token-controller/User_Token_get";
import User_Token_get_Count from "./../API/token-controller/User_Token_get_Count";
import RolesControllerGetall from "./../API_CONTROLLER/role-controller/role_controller_getall";
import TokenController_post from "./../API/token-controller/User_Token_post";
import Pagination1 from "./pagination1";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id"; //branch list changer
import School_name from "./school_name";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import studentIcn from "../../../src/img/Group 1131.svg";
import coordinatorIcn from "../../../src/img/Group 1132.svg";
import teacherIcn from "../../../src/img/Group 1133.svg";
import principleIcn from "../../../src/img/Path 4915.svg";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";

import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  IconButton,
  TextField,
} from "@mui/material";



const Token = (props) => {
  const navigate = useNavigate();
  const [tokenInputs, setTokenInputs] = useState({
    expiaryDate: "",
    multiUser: "",
    numberOfTokens: "",
    numberOfUsersPerToken: "",
    roleId: "",
  });
 const multiUserConfirm = tokenInputs.multiUser
  console.log(multiUserConfirm,"mu")
const [multipleUser, setMultipleUser] = useState(false)
  const handlertokenInputs = (e) => {
    //Start - Added to correct the expirty date input format from yyyy-mm-dd to dd-mm-yyyy
    if ((e.target.id = "ExpiryDate")) {
      setTokenInputs({
        ...tokenInputs,
        [e.target.name]: e.target.value,
        // .split("-")
        // .reverse()
        // .join("-"),
      });  console.log(e.target.value,"usersnop")
      
    }
    //End - Added to correct the expirty date input format from yyyy-mm-dd to dd-mm-yyyy
    else {
      setTokenInputs({
        ...tokenInputs,
        [e.target.name]: e.target.value,
      });
    }
  };
  console.log(multipleUser,"usersnop")


  const [showError, setShowError] = useState("");
  const [isError, setIsError] = useState(false);

  const handleToken = (e) => {
    // e.preventDefault();

    for (const input in tokenInputs) {
      if (tokenInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all the required values");

        if(multipleUser===true && tokenInputs.numberOfUsersPerToken >= 1)
        {setIsError(true);
          setShowError("Please enter users greater than 1");
         }
        return;
      }
  
    }
    // other validation condtions

    setIsError(false);
    TokenController_post(tokenInputs).then((response) => {
      User_Token_get(setTokenDta, setPageDetail, page, rowsInput, search);
    });

    navigate("/dashboard/tokenList");
  };

  const handleCancel = () => {
    navigate("/dashboard/tokenList");

  }

  // search starts
  const [pageDetail, setPageDetail] = useState({});
  const [tokenData, setTokenDta] = useState([]);
  const [tokenCount, setTokenCount] = useState({});
  const [roleName, setRoleName] = useState({});

  const [search, setSearch] = useState({
    search: "",
  });
  const [isSearch, setisSearch] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
   setQuery("");
   setShowClear(false);
   User_Token_get(
    setTokenDta, 
    setPageDetail,
    page, 
    rowsInput,
    search
    );
 };

 const handleInputChange = (e) => {
   const inputValue = e.target.value.toLowerCase();
   if (inputValue) {
    let result = [...totalele];
   result = [...totalele]?.filter((data) => {
      return data?.role?.toLowerCase().includes(inputValue);
    });
    setTokenDta(result)
  }else {
    User_Token_get(
      setTokenDta, 
      setPageDetail,
      page, 
      rowsInput,
      search
      );
  }
   setQuery(inputValue);
   setShowClear(inputValue !== "");
 };
 useEffect(()=>{
  if(isSearch){
    searchingData()
  }
},[])

const searchingData =async ()=>{
  setisSearch(false);
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchid = sessionStorage.getItem("branchid");
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=0&pageSize=10&sortBy=id&branchId=${branchid}&schoolId=${schoolid}&search=${search.search}`,
  {
    headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    }
},)
const totlaele = result?.data?.data?.totalElements
let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=0&pageSize=${totlaele}&sortBy=id&branchId=${branchid}&schoolId=${schoolid}&search=${search.search}`,
{
  headers: {
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
  }
},)   
const totalData = response?.data?.data?.data    
setTotalelements(totalData);
}
  // search ends

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
    roleId: "",
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  console.log(page,"pp")
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // pagination ends
  const futureDate = new Date().toISOString().substring(0, 10);
  const expiryDate = new Date; // Example expiry date

  useEffect(() => {
    User_Token_get_Count(setTokenCount);
    RolesControllerGetall(setRoleName);

    return () => {};
  }, []);

  useEffect(() => {
    User_Token_get(setTokenDta, setPageDetail, page, rowsInput, search);

    return () => {};
  }, [page, rowsInput, sessionStorage.getItem("branchid")]);
  console.log(tokenData, "data");
  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => {};
  }, [schoolDetails]);

  //BranchController ends

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends
  console.log(rowsInput.rows, "inpt");
  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Registered Schools
          </span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Token Details</a>
        </div>
      </article>
      
      <SchoolAdminBreadcrumb 
        dashHome = {dashHome}
        sectionName = {'Registered Schools'}
        date = {modifiedDate}
        isSecondOne={true} 
        sectionLink={''}
        secondSectionName={'Token Details'}
      />
      <article className={tokenone.article_35}>
        <div className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown + ' ' + 'az_select'}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>
        <div className={tokenone.grid_container_header_plan_35}>
          <div
            className={tokenone.item_plan1_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={tokenone.item_plan2_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={tokenone.item_plan3_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={tokenone.item_plan4_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={tokenone.item_plan5_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={tokenone.item_plan6_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={tokenone.item_plan7_35 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        <div className={tokenone.grid_item_35}>
          <div className={tokenone.column_35}>
            <span className={tokenone.link_icon_item1_35}>
              <img
                src={teacherIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={tokenone.school_data_35}>
              <span>{tokenCount.teacherCount}</span>
              <span>Teachers</span>
            </span>
          </div>
          <div className={tokenone.column_35}>
            <span className={tokenone.link_icon_item1_35}>
              <img
                src={coordinatorIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={tokenone.school_data_35}>
              <span>{tokenCount.coordinatorCount}</span>
              <span>Coordinators</span>
            </span>
          </div>
          <div className={tokenone.column_35}>
            <span className={tokenone.link_icon_item1_35}>
              <img
                src={principleIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={tokenone.school_data_35}>
              <span>{tokenCount.principalCount}</span>
              <span> Principals</span>
            </span>
          </div>
          <div className={tokenone.column_35}>
            <span className={tokenone.link_icon_item1_35}>
              {" "}
              <img
                src={studentIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={tokenone.school_data_35}>
              <span>{tokenCount.studentCount}</span>
              <span>Students</span>
            </span>
          </div>
          <div className={tokenone.input_element_35}>
            <select
              className={tokenone.form_select_element_35 + ' ' + 'az_select'}
              name="roleId"
              id="roleId"
              value={rowsInput.roleId}
              onChange={handlerRowsInputs}
            >
              <option value="">Select</option>
              {roleName.length > 0 ? (
                roleName.map((roleName) => {
                  return <option Value={roleName.id}>{roleName.role}</option>;
                })
              ) : (
                <option value="">Loading....</option>
              )}
            </select>
            <label htmlFor="Role">*Role</label>
          </div>
          <div className={tokenone.input_element_35}>
            <select
              className={tokenone.form_select_element_35 + ' ' + 'az_select'}
              name="Used"
              id="Used"
            >
              <option value="">Select</option>
            </select>
            <label htmlFor="Used">*Used</label>
          </div>
        </div>

        <div className={tokenone.schoollist_35}>
          <div>
            <i class="fa-solid fa-house-user" aria-hidden="true"></i> Token Id List
          </div>

          <div className={tokenone.school_list_btn_container_35}>
            <div>
            <TextField
                      onChange={handleInputChange}
                      type={"text"}
                      value={query}
                      placeholder={"Search anything..."}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "247px !important",
                          height: "32px !important",
                          borderRadius: "50px !important",
                          fontFamily: "Poppins !important",
                          fontSize: "13px",
                          marginLeft: "250px !important",
                          background: "#FFFFFF",
                          
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showClear && (
                              <IconButton onClick={handleClear}>
                                <ClearIcon
                                  style={{ color: "#F05262", fontSize: "1vw" }}
                                />
                              </IconButton>
                            )}
                            {!showClear && (
                              <IconButton>
                                <img
                                  src={searchIcn}
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: "large",
                                  }}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />

            </div>
          </div>
        </div>
        <div className={tokenone.section_list_table}>
          <table className={tokenone.table_section + ' ' + 'az_table'}>
            <thead className={tokenone.thead_35 + ' ' + 'az_table_head'}>
              <tr className={tokenone.table_head_section}>
                {/* <th className={tokenone.cbse_checkbox_thead}><input type="checkbox" id="test2" />
                                            <label htmlFor="test2"></label></th> */}
                <th>
                  # <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                    Token ID <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                    Role <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                    Email <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                    Multi User <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                  No. of User
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                    Used User Id <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                  Date Created <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th>
                  Expiry Date <i className="fa-solid fa-arrows-up-down"></i>
                </th>
              </tr>
            </thead>
            <tbody  className="az_table_body">
              {tokenData.length > 0 ? (
                tokenData.map((tokenData, index) => {
                  return (
                    <tr className={tokenone.data_row} key={index}>
                      {/* <th className={tokenone.cbse_checkbox_thead}><input type="checkbox" id="test2" />
                                            <label htmlFor="test2"></label></th> */}
                      <td>{(page - 1) * rowsInput.rows + (index + 1)}</td>
                      <td>
                        {" "}
                        <div className={tokenone.name}>{tokenData.token}</div>
                      </td>
                      <td className={tokenone.type_of_school}>
                        <div className={tokenone.wrapdata_td}>
                          {tokenData.role}
                        </div>
                      </td>
                      <td className={tokenone.cbse_table_head1}>
                        <div className={tokenone.wrapdata_td}>
                          {tokenData.email}
                        </div>
                      </td>
                      <td>
                        <div className={tokenone.wrapdata_td}>
                          {tokenData.multiUser}
                        </div>
                      </td>
                      <td>
                        <div className={tokenone.wrapdata_td}>
                          {tokenData.noOfUsers}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className={tokenone.wrapdata_td}>
                          {tokenData.usedUserId}
                        </div>
                      </td>
                      <td>
                        {tokenData.dateCreated.substring(
                          0,
                          tokenData.dateCreated.indexOf(" ")
                        )}
                        {/* <br /> */}
                        {tokenData.dateCreated.substring(
                          tokenData.dateCreated.indexOf(" ") + 1
                        )}
                      </td>
                      <td>{tokenData.expiaryDate}</td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination container - Start */}
        <div className={tokenone.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={tokenone.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}

        <form
          className={tokenone.input_box}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={tokenone.grid_item_2_35} style={{marginBottom:"30px"}}>
            <div className={tokenone.input_element_35}>
              <select
                className={tokenone.form_select_element_35 + ' ' + 'az_select'}
                onChange={handlertokenInputs}
                name="roleId"
                id="roleId"
                Value={tokenInputs.roleId}
              >
                <option Value="Select">Select</option>
                {roleName.length > 0 ? (
                  roleName.map((roleName) => {
                    return <option Value={roleName.id}>{roleName.role}</option>;
                  })
                ) : (
                  <option value="">Loading....</option>
                )}
              </select>

              <label htmlFor="Role">*Role</label>
            </div>
            <div className={tokenone.input_element_35}>
              <input
                type="number"
                name="numberOfTokens"
                id="numberOfTokens"
                min={1}
                placeholder=" "
                value={tokenInputs.numberOfTokens}
                onChange={handlertokenInputs}
              ></input>
              <label htmlFor="numberOfTokens">*Number of Tokens</label>
            </div>

            <div className={tokenone.input_element_35}>
              <input
                name="expiaryDate"
                id="expiaryDate"
                type="date"
                min = {futureDate}
                onChange={handlertokenInputs}
                value={tokenInputs.expiaryDate}
              />

              <label htmlFor="ExpiryDate">*Expiry Date</label>
            </div>
            <div className={tokenone.input_element_35}>
              <select
                className={tokenone.form_select_element_35}
                name="multiUser"
                id="multiUser"
                value={tokenInputs.multiUser}
                onChange={handlertokenInputs}
              >
                <option value="">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <label htmlFor="MultiUsers">*Multi Users</label>
            </div>
          <div className={tokenone.input_element_35}>
              <input
                type="number"
                name="numberOfUsersPerToken"
                id="numberOfUsersPerToken"
                placeholder=" "
                value={tokenInputs.numberOfUsersPerToken}
                min={1}
                max={multiUserConfirm==="false" && 1}
                onChange={handlertokenInputs}
              ></input>
              <label htmlFor="numberOfUser">*No of Users</label>
            </div>
            <div className={tokenone.input_element_35}>
              <div
                className={tokenone.error_box}
                style={isError ? { display: "block" } : { display: "none" }}
              >
                {showError}
              </div>
            </div>

            <div className={tokenone.input_element_35}>
              {/* <button>hello</button>
              <button>bye</button> */}
            </div>
            <div className={tokenone.artical_button_container1_335}>
              <button style={{marginBottom:"28px",}}
              onClick={handleCancel}
                className={tokenone.artical_button1_335}
                id={tokenone.button_id1_335}
                name=""
              >
                Cancel
              </button>
              <button style={{marginBottom:"28px"}}
                className={tokenone.artical_button1}
                id={tokenone.button_id1}
                name=""
                onClick={handleToken}
              >
                Create
              </button>
            </div>
            {/* <div className={tokenone.artical_button_container1}>
              <button
                className={tokenone.artical_button1}
                id={tokenone.button_id1}
                name=""
                onClick={handleToken}
              >
                Create
              </button>
            </div> */}
          </div>
        </form>
        {/* <div
          className={tokenone.error_box}
          style={isError ? { display: "block" } : { display: "none" }}
        >
          {showError}
        </div> */}
      </article>
    </>
  );
};

export default Token;
