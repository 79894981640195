import React, { useState, useEffect } from "react";
import ChapterStyles from '../../../css/createChapter.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import axios from "axios";
import ModalImage from "react-modal-image";
import LazyLoad from 'react-lazyload';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
    FormControl,
    Grid,
    Box,
    Modal,
    styled,
    Button,
    Typography
} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "800px",
    height: "35vw",
    overflow: "scroll",
    bgcolor: 'background.paper',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};

const imageModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: "270px",
    overflow: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};

const CustomButton = styled(Button)(({ }) => ({
    color: "#FFFFFF",
    backgroundColor: '#FD8C00',
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    fontSize: '10px',
    borderRadius: '30px',
    width: 'auto',
    padding: "0.2vw 0.8vw",
    '&:hover': {
        backgroundColor: '#FD8C00',
    },
}));


export default function ImageListModal(props) {
    const [imageList, setImageList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    let token = sessionStorage.getItem("token");

    const get_Image_List = async () => {
        if (props?.isMaps) {
            await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map/all-map-url?pageNumber=1&pageSize=100`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.data.length !== 0) {
                        setImageList(response.data.data.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })


        } else {
            await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/use-from-repository`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.data.length !== 0) {
                        setImageList(response.data.data.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    useEffect(() => {
        get_Image_List();
    }, [props.openImageModal]);

    const onImageClick = (item) => {
        props.selectedImage(item);
        props.selectedImageName(item.split('/').pop())
        props.closeImageModal();
    }

    const onpenImageModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setModalOpen(true);
    };

    const closeImageModal = () => {
        setModalOpen(false);
    };

    return (
        <>
        <Modal
                open={props.openImageModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                    <IconButton
                        onClick={props.closeImageModal}
                        sx={{ position: 'absolute', top: 2, right: 4, color: "red", fontSize: "12px" }}
                    >
                        <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton>

                    <Grid spacing={2}>
                        <ImageList sx={{ width: "100%", height: "auto", }} cols={4} rowHeight={160}>
                        {imageList.map((item) => (
                            <ImageListItem key={item}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <LazyLoad>
                                        <img src={item}
                                            style={{
                                                height: "100px",
                                                width: "100px",
                                                objectFit: "contain"
                                            }}
                                            alt=""
                                            onClick={() => { onpenImageModal(item) }}
                                        />
                                        <Typography sx={{ display: "flex", justifyContent: "center" }}>
                                            <CustomButton onClick={() => onImageClick(item)}>Choose</CustomButton>
                                        </Typography>
                                    </LazyLoad>
                                </div>
                            </ImageListItem>
                        ))}
                    </ImageList>
                    </Grid>
                </Box>
            </Modal>

            {/*-------| FullScreen Image Open Modal |-------*/}
            <Modal open={modalOpen}>
                <Box sx={imageModalstyle}>
                    <IconButton
                        onClick={closeImageModal}
                        sx={{ position: 'absolute', top: 2, right: 4, color: "red", fontSize: "12px" }}
                    >
                        <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                    <LazyLoad>
                        <img src={selectedImage} alt="Modal" style={{ width: '100%' }} />
                    </LazyLoad>
                </Box>
            </Modal>
        </>
    );
}