import planstyles from "./../css/BranchPlan.module.css";
import { useNavigate } from "react-router-dom";

import React, { Component, useState, useEffect } from "react";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import BranchControllerIdGet from "../API/branch-controller/User_Branches_id";
import User_Branches_id_Plan from "../API/branch-controller/User_Branches_id_Plan";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/branch-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import axios from "axios";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";

function BranchPlan(props) {
  const navigate = useNavigate();

  const [toggleGradeVisibility, setToggleGradeVisibility] = useState([]);

  function showHide(e, index) {
    const newToggleGradeVisiblity = toggleGradeVisibility.slice();
    newToggleGradeVisiblity[index] = !newToggleGradeVisiblity[index];
    setToggleGradeVisibility(newToggleGradeVisiblity);
  }
  const [planId, setplanid] = useState("")
  const [planData, setPlanData] = useState([]);
  const [branchInputs, setbranchInputs] = useState("")
  
console.log(toggleGradeVisibility,"visibility")
  useEffect(() => {
    BranchControllerIdGet(setbranchInputs,setplanid);
  }, [sessionStorage.getItem("branchid")]);
 console.log(planId,"idPla")
  let planid = sessionStorage.getItem("planid");
  useEffect(() => {
    User_Branches_id_Plan(setPlanData);
    return () => {};
  }, [sessionStorage.getItem("branchid"),planid]);
  console.log(planid,"planid")
  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      // schoolName: e.target.id,
    });
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => {};
  }, [schoolDetails]);

  //BranchController ends

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends
  console.log(planData.length>0 , "length")
  console.log(planData,"data")
  return (
    <>
    <SchoolAdminBreadcrumb 
      dashHome = {dashHome}
      sectionName = {'Grade and Branch Plan'}
      date = {modifiedDate.data}
    />
      <article className={planstyles.article_add_school_plan16}>
        <div style={{marginTop:"10px"}} className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={planstyles.grid_container_header_plan16}>
          <div
            className={planstyles.item_plan116 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={planstyles.item_plan216 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={planstyles.item_plan316 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={planstyles.item_plan416 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={planstyles.item_plan516 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={planstyles.item_plan616 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/tokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={planstyles.item_plan716 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>
        <div className={planstyles.plan_links16}>
          <div className={planstyles.plan_links_container}>
            <ul className={planstyles.plan_links}>
              <div className={planstyles.plan_blank}>h</div>
              { planData?.planGrades?.map((planGrade, index) => {
                  return (
                    <>
                      <li
                        className={planstyles.plan_links_items}
                        id={`table${index + 1}`}
                        onClick={(e) => showHide(e, index)}
                        key={planGrade.gradeId}
                      >
                        <a className={planstyles.plan_container_link}>
                          <span className={planstyles.plan_link_text}>
                            {planGrade.grade}
                          </span>
                          <span className={planstyles.link_dropdown}>
                            <i
                              className={
                                toggleGradeVisibility[index]
                                  ? "fa-solid fa-angle-up"
                                  : "fa-solid fa-angle-down"
                              }
                              style={
                                planstyles.subMenus !== null
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                              id="toggle_btn_icon"
                            ></i>
                          </span>
                        </a>
                      </li>

                      { toggleGradeVisibility[index] ? (
                        <>
                          {
                            <div className={planstyles.table_plan_div}>
                              <table className={planstyles.table_plan + ' ' + 'az_table'}>
                                <thead className={'az_table_head'}>
                                  <tr className={planstyles.Table_head1}>
                                    <td
                                      id={planstyles.Table_head_items1}
                                      colspan="1"
                                    ></td>
                                    <td
                                      id={planstyles.Table_head_items2}
                                      colspan="8"
                                    >
                                      Teacher
                                    </td>
                                    <td
                                      id={planstyles.Table_head_items3}
                                      colspan="2"
                                    >
                                      Student
                                    </td>
                                  </tr>
                                </thead>
                                <thead className={'az_table_head'}>
                                  <tr className={planstyles.Table_head2}>
                                    <td className={planstyles.Table_head_items}>
                                      Subject
                                    </td>

                                    <td className={planstyles.Table_head_items}>
                                      Lesson Plan
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      Revision Module
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      RM lock/unlock visible
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      Assessment Module
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      Activities
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      WS download
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      WB Download
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      News & stories
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      Student booklet
                                    </td>
                                    <td className={planstyles.Table_head_items}>
                                      Revision module{" "}
                                    </td>
                                  </tr>
                                </thead>

                                {planGrade.planTemplates &&
                                  planGrade.planTemplates.length != 0 &&
                                  planGrade.planTemplates.map(
                                    (planTemplate) => {
                                      return (
                                        <>
                                          <tbody
                                            className={planstyles.plan_body}
                                          >
                                            <tr key={planTemplate.id}>
                                              <td
                                                className={planstyles.body_data}
                                              >
                                                {planTemplate.subject}
                                              </td>

                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.lessonPlan
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={planTemplate.teacherRevisionModule}
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={planTemplate.rmLock}
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.assessmentModule
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.activities
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.wsDownload
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.wbDownload
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.newsStories
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.studentBooklet
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  planstyles.checkbox_data
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    planTemplate.studentRevisionModule
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                    }
                                  )}
                              </table>
                            </div>
                          }
                        </>
                      ) : ''}
                    </>
                  );
                })}
            </ul>
          </div>
        </div>
      </article>
    </>
  );
}
export default BranchPlan;
