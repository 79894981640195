import React, { useState, useEffect, memo } from "react";
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import getSubQuestions from "../../../API_CONTROLLER/create-questions/subQuestions_get";
import { blue, orange } from "@mui/material/colors";
import Radio from '@mui/material/Radio';
import Checkbox from "@mui/material/Checkbox";

import {
    Grid,
    Box,
    Modal,
    styled,
    Button,
    Typography,
    TextField,
} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "45vw",
    overflow: "scroll",
    bgcolor: '#f8f4fc',
    borderRadius: '4px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// const CustomTextField = styled(TextField)(({ }) => ({
//     width: "96%",
//     boxShadow: "0px 3px 6px #00000029",
//     borderRadius: "2px !important",
//     "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
//         opacity: "1 !important",
//         background: "rgb(255 255 255 / 31%) !important",
//         border: "1px solid #B5B6B6",
//         borderRadius: "2px !important",
//     }
// }))

const CustomTextField = styled('div')(({ }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    overflow: "scroll",
    borderRadius: "2px !important",
    opacity: "1 !important",
    padding: "3%",
    background: "rgb(255 255 255 / 31%) !important",
    //     background: "rgb(255 255 255 / 31%) !important",
    // "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    //     opacity: "1 !important",
    //     background: "rgb(255 255 255 / 31%) !important",
    //     border: "1px solid #B5B6B6",
    //     borderRadius: "2px !important",
    // }
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "3px",
    color: "#FFFFFF",
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    backgroundColor: "#2AA571",
    fontSize: '10px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#056039',
    },
}));

function SubQuestionPreviewScroll(props) {
    // To store subquestions
    const [subQuestions, setSubQuestions] = useState([]);
    // Title and content of case study
    const title = sessionStorage.getItem("caseTitle");
    const questionPattern = sessionStorage.getItem("questionPattern");
    const QuestionType = sessionStorage.getItem("QuestionType");
    const caseStudyContent = sessionStorage.getItem("case");
    const imagePreviewURL = sessionStorage.getItem("logoUrl")
    // To score subquestions on initial render
    useEffect(() => {
        getSubQuestions(setSubQuestions);
    }, [props.openSubQuestionPreview]);

    // useEffect(() => {
    //     if (props.responseValue) {
    //         get_question(props.responseValue.id, setResponseValue);
    //     }
    // }, [props.previewFromModal]);

    // To erase the subquestions locally 
    useEffect(() => {
        setSubQuestions([]);
    }, [props.isCaseStudyReset]);

    return (
        <>
            <Modal
                open={props.openSubQuestionPreview}
                onClose={props.closeSubQuestionPreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#00BE66", font: "normal normal 600 16px/25px Poppins" }}>
                                {"CASE STUDY"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "right" }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                {`Total Sub Questions - ${subQuestions.length}`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ height: "37vw", overflowY: "scroll" }}>
                        <Grid item xs={12} md={6} lg={6} sx={{ height: "39vw", overflow: "auto", overflowY: "scroll", "&::-webkit-scrollbar": { width: "6px", backgroundColor: "white", position: "relative", display: "block" }, "&::-webkit-scrollbar-thumb": { borderRadius: "10px", backgroundColor: "#b4b4b4", }, "&::-webkit-scrollbar-track": { backgroundColor: "#f8f4fc" } }}>
                            <Grid container spacing={2} p={2}>
                                {/* To display Case study content and title */}
                                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left" }}>
                                    {questionPattern ? <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                    Total Marks : {title}
                                    </Typography> : <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                        {title}
                                    </Typography>}
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                    <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 13px/25px Poppins", textAlign: "left" }}
                                        dangerouslySetInnerHTML={{ __html: caseStudyContent }}
                                    >
                                    </Typography>
                                </Grid>
                                {/* To display uploaded case study image */}
                                {props.imagePreview || props.selectedImageUrl ?
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Uploaded Image
                                        </Typography>
                                        <Typography>
                                            <img src={props.imagePreview ? imagePreviewURL : props.selectedImageUrl} width="30%" height="200px" />
                                        </Typography>
                                    </Grid>
                                : null}
                                   {props.explanation ?
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Explanation
                                        </Typography>
                                        <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 13px/25px Poppins", textAlign: "left" }}
                                        dangerouslySetInnerHTML={{ __html: props.explanation }}
                                    >
                                    </Typography>
                                    </Grid>
                                : null}
                                {QuestionType === "MAP_PHYSICAL" || QuestionType === "MAP_POLITICAL" &&
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Uploaded Map
                                        </Typography>
                                        {/* <h3>{mapTitle}</h3>
                                        <img src={mapUrl} width="50%" height="240px" /> */}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{ maxHeight: "37vw", overflowY: "scroll", "&::-webkit-scrollbar": { width: "6px", backgroundColor: "white", position: "relative", display: "block" }, "&::-webkit-scrollbar-thumb": { borderRadius: "10px", backgroundColor: "#b4b4b4", }, "&::-webkit-scrollbar-track": { backgroundColor: "#f8f4fc" } }}>
                            {subQuestions.length > 0 &&
                                subQuestions.map((subQues, index) => {
                                    return (
                                        <>
                                            {/* To display uploaded sub question image and question*/}
                                            {subQues.imageUrl !== null ?
                                                <>
                                                    <Grid container>
                                                        < Grid item xs={10} md={10} lg={10} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                            <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 15px/25px Poppins", textAlign: "left" }}
                                                                dangerouslySetInnerHTML={{ __html: `Q${index + 1})${subQues.question}` }}
                                                            >
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} md={2} lg={2} sx={{ display: "flex", alignContent: "center", flexWrap: "wrap" }}>
                                                            <img src={subQues.imageUrl} width="100%" height="auto" />
                                                        </Grid>
                                                        < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                                Explanation
                                                            </Typography>
                                                            <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 15px/25px Poppins", textAlign: "left" }}
                                                                dangerouslySetInnerHTML={{ __html: subQues.explanation }}
                                                            >
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                        <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 15px/25px Poppins", textAlign: "left" }}
                                                            dangerouslySetInnerHTML={{ __html: `Q${index + 1})${subQues.question}` }}
                                                        >
                                                        </Typography>
                                                    </Grid>
                                                    {subQues.explanation &&
                                                        < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                                Explanation
                                                            </Typography>
                                                            <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 15px/25px Poppins", textAlign: "left" }}
                                                                dangerouslySetInnerHTML={{ __html: subQues.explanation }}
                                                            >
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                Answer
                                            </Typography>
                                            {/* To display answer content fields */}
                                            <Grid container spacing={4} sx={{ p: 2 }}>
                                                {subQues.options[0].length > 0 &&
                                                    subQues.options[0].map((value, ind) => {
                                                        return (
                                                            subQues.questionTypeCode === "FILL" ?
                                                                (
                                                                    <Grid item xs={12} mg={12} lg={12}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                                                {String.fromCharCode(ind + 65)}
                                                                            </Typography>
                                                                            <CustomTextField id="fullWidth" dangerouslySetInnerHTML={{ __html: value.htmlTag[0] }} />
                                                                            <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                                :
                                                                subQues.questionTypeCode === "TEXT" ?
                                                                    (
                                                                        <Grid item xs={12} mg={12} lg={12}>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                                                    {String.fromCharCode(ind + 65)}
                                                                                </Typography>
                                                                                <CustomTextField id="fullWidth" disabled />
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                    :
                                                                    subQues.questionTypeCode === "MCQ" ?
                                                                        (
                                                                            <Grid item xs={12} mg={12} lg={12}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                                                        {String.fromCharCode(ind + 65)}
                                                                                    </Typography>
                                                                                    <div style={{
                                                                                        width: "100%",
                                                                                        height: "auto",
                                                                                        overflow: "scroll",
                                                                                        border: "1px solid #dbdee6",
                                                                                        fontSize: "18px",
                                                                                        color: "#1b1b1b",
                                                                                        opacity: "0.9",
                                                                                        lineHeight: "1",
                                                                                        padding: "10px",
                                                                                        borderRadius: "5px"
                                                                                    }}
                                                                                    >
                                                                                        <CustomRadio
                                                                                            id="fullWidth"
                                                                                            sx={{
                                                                                                color: "white",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "rgba(0, 0, 0, 0.26)",
                                                                                                },
                                                                                            }}
                                                                                            checked={value.correctAnswer}
                                                                                        />
                                                                                        <p dangerouslySetInnerHTML={{ __html: value.htmlTag }}></p>
                                                                                    </div>
                                                                                    <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                                                </Box>
                                                                            </Grid>
                                                                        )
                                                                        :
                                                                        subQues.questionTypeCode === "MSQ" ?
                                                                            (
                                                                                <Grid item xs={12} mg={12} lg={12}>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                                                            {String.fromCharCode(ind + 65)}
                                                                                        </Typography>
                                                                                        <div style={{
                                                                                            width: "100%",
                                                                                            height: "auto",
                                                                                            overflow: "scroll",
                                                                                            border: "1px solid #dbdee6",
                                                                                            fontSize: "18px",
                                                                                            color: "#1b1b1b",
                                                                                            opacity: "0.9",
                                                                                            lineHeight: "1",
                                                                                            padding: "10px",
                                                                                            borderRadius: "5px"
                                                                                        }}
                                                                                        >
                                                                                            <Checkbox
                                                                                                id="fullWidth"
                                                                                                sx={{
                                                                                                    color: "#001AF3",
                                                                                                    "&.Mui-checked": {
                                                                                                        color: "rgba(0, 0, 0, 0.26)",
                                                                                                    },
                                                                                                }}
                                                                                                checked={value.correctAnswer}
                                                                                            />
                                                                                            <p dangerouslySetInnerHTML={{ __html: value.htmlTag }}></p>
                                                                                            <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                                                        </div>
                                                                                    </Box>
                                                                                </Grid>
                                                                            )
                                                                            :
                                                                            (
                                                                                <>
                                                                                    <p>There is no Sub Question</p>
                                                                                </>
                                                                            )
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid container m={1}>
                        < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton onClick={props.closeSubQuestionPreview}>Exit</CustomButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >

        </>
    )
}

export default memo(SubQuestionPreviewScroll);