import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Pagination1 from "./pagination1";
import dashHome from "../../../src/img/dashHome.svg";
import BreadCrumbs from "../js/RevisionAdmin/General/BreadCrumbs";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
import conceptlist_get from "../API_CONTROLLER/concept-controller/conceptlist_get";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
// import documentType_controller_getall from '../API_CONTROLLER/document-category-controller/documentType_controller_getall';
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import chapters_controller_put from "../API_CONTROLLER/chapters-controller/chapters_controller_put";
import rootConcept_controller_get from "../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import subConcept_controller_get from "../API_CONTROLLER/concept-controller/subConcept_controller_get";
import searchIcn from "../../../src/img/search.svg";

const Activities = () => {
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chapter, setChapter] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [isAddChapter, setIsAddChapter] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [success, setSuccess] = useState({});
  const [conceptlist, setConceptlist] = useState({});
  const [subconceptList, setSubconceptList] = useState({});
  const [rootConceptList, setRootConceptList] = useState({});
  const [subConceptInputs, setSubConceptInputs] = useState({
    subConceptId: "",
  });
  const [conceptInputs, setConceptInputs] = useState({
    conceptId: "",
    concept: "",
    rootConcept: "",
    subTopicId: "",
    subjectsId: "",
  });

  const [documenttype, setDocumenttype] = useState({});
  const [selectedImage, setSelectedImage] = useState("");

  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
  const [docType, setDoctype] = useState({});

  const navigate = useNavigate();
  const prevLocation = useLocation();

  const [chapterInputs, setChapterInputs] = useState({
    // selectedBoard : "",
    // selectedGrade : "",
    // selectedSubject: "",
    // selectedSubtopic:"",
    // selectedChapter:"",
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    // docType:"",
    // logoUrl:"",
    chapterStatus: "PENDING",
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();

  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");

  if (prevLocation.state === "edit") {
    setIsAddChapter(false);
    prevLocation.state = null;
  }

  const handleCreateChapter = (e) => {
    e.preventDefault();
    for (const input in chapterInputs) {
      if (chapterInputs[input] == "") {
        setIsError(true);
        setShowError("Please enter all required valules");
        return;
      }
    }

    chapters_controller_post(chapterInputs, setSuccess, setShowError);
    // setChapterInputs("")
    navigate("/dashboard/Upload%20Content");
    // window.location.reload(true)
  };

  const handleUpdateChapter = (e) => {
    e.preventDefault();
    for (const input in chapterInputs) {
      if (chapterInputs[input] == "") {
        setIsError(true);
        setShowError("Please enter all required valules");
        return;
      }
    }
    chapters_controller_put(chapterInputs);
    navigate("/dashboard/Upload%20Content");
  };
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  // const [page , setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends

  const handleConceptInputs = (e) => {
    e.preventDefault();
    setConceptInputs({
      ...conceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handlerChapterInputs = (e) => {
    e.preventDefault();
    console.log(e.target.value,"1222222",subject)
    setChapterInputs({
      ...chapterInputs,
      [e.target.name]: e.target.value,
    });
    
    const selectedSubject = subject.find(val => val.id === String(e.target.value));
    // console.log(selectedSubject)
    // console.log(selectedSubject?.hideSubtopics, selectedSubject.id)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("pac false");
    } else {
      // console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }
  };
  const [search, setSearch] = useState({
    search: "",
  });

  const handlesubConceptInputs = (e) => {
    e.preventDefault();
    setSubConceptInputs({
      ...subConceptInputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearch = (e) => {
    e.preventDefault();

    const search = {
      search: e.target.value,
    };
    chapters_controller_getall(
      setChapters,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  };

  const addChapterHandler = (
    chapter,
    boardId,
    gradeId,
    subjectId,
    subTopicName,
    file
  ) => {};

  const imageChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    chapters_file_uploader_post(
      setSelectedImage,
      setChapterInputs,
      chapterInputs,
      formData
    );
  };

  const removeSelectedImage = () => {
    setChapterInputs({
      ...chapterInputs,
      logoUrl: null,
    });
  };

  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => {};
    }
    if (success.success === true) {
      setIsError(false);
    }
  }, [success]);

  useEffect(() => {
    setChapterInputs({
      ...chapterInputs,
      logourl: sessionStorage.getItem("logourl"),
    });
    return () => {};
  }, [sessionStorage.getItem("logoUrl")]);

  useEffect(() => {
    if (search.search === "") {
      chapters_controller_getall(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    if (isAddChapter == false) {
      chapters_edit_getby_id(setChapterInputs);
      return () => {};
    }
  }, []);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  }, [rowsInput, page]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
    return () => {};
  }, [search, gradeInput, subjectInput, subTopicInput]);

  useEffect(() => {
    subConcept_controller_get(conceptInputs, setSubconceptList);
    subtopics_controller_getall(setSubtopic, conceptInputs);
  }, [conceptInputs]);

  useEffect(() => {
    rootConcept_controller_get(subConceptInputs, setRootConceptList);
  }, [subConceptInputs]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    // chapters_controller_getall(setChapters);
    conceptlist_get(setConceptlist);

    chapters_last_modified_get(setLastModifiedDate);
    // documentType_controller_getall(setDocumenttype);

    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
        <BreadCrumbs currentPage={"Activities"} />
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Activities</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {lastModifiedDate.data}
            </p>
          </div> */}
        </article>
        {/* create chapter */}
        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            //  marginLeft : '15px' ,
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={chapterStyles.chapter_container}>
              <div className={chapterStyles.flex} style={{ marginTop: "15px" }}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Board
                  </InputLabel>
                  <Select
                    name="gradeId"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    input={<OutlinedInput label="Tag" />}
                    value={chapterInputs.gradeId}
                    onChange={handlerChapterInputs}
                  >
                    {board.length > 0 ? (
                      board.map((board, i) => {
                        return (
                          <MenuItem key={i} value={board.board}
                          id={`az-revisionadmin-boarddropdown-${i}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                            <ListItemText primary={board.board} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Grade(s)
                  </InputLabel>
                  <Select
                    name="gradeId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    input={<OutlinedInput label="Tag" />}
                    value={chapterInputs.gradeId}
                    onChange={handlerChapterInputs}
                  >
                    {gradesName?.length > 0 ? (
                      gradesName.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}
                          id={`az-revisionadmin-gradesdropdown-${elem.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                            <ListItemText primary={elem.grade} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                    {/* {optionsRender(gradesName)} */}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Subject(s)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="subjectId"
                    id="demo-multiple-checkbox"
                    value={chapterInputs.subjectId}
                    onChange={handlerChapterInputs}
                    input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) => selected.join(", ")}
                  //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.id}
                          id={`az-revisionadmin-subjectdropdown-${subject.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {
                  showSubTopic &&

                  <FormControl
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    sx={{ width: 250 }}
                  >
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-multiple-checkbox-label"
                    >
                      *Subtopic(s)
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      name="subTopicId"
                      value={chapterInputs.subTopicId}
                      onChange={handlerChapterInputs}
                      input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                    >
                      {subtopic.length > 0 ? (
                        subtopic.map((subtopic, i) => {
                          return (
                            <MenuItem key={i} value={subtopic.id}
                            id={`az-revisionadmin-subtopicdropdown-${subtopic.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                              <ListItemText primary={subtopic.subTopic} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                }
              </div>
              <div className={chapterStyles.flex} style={{ marginTop: "15px" }}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Concept
                  </InputLabel>
                  <Select
                    name="boardId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={conceptInputs.conceptId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) => selected.join(", ")}
                  >
                    {/* {optionsRender(board)} */}
                    {conceptlist && conceptlist.length > 0 ? (
                      conceptlist.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}
                          id={`az-revisionadmin-conceptlistdropdown-${elem.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                            <ListItemText primary={elem.concept} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Sub-Concept
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple
                    name="subConceptId"
                    value={subConceptInputs.subConceptId}
                    onChange={handlesubConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                  >
                    {subconceptList && subconceptList.length > 0 ? (
                      subconceptList.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}
                          id={`az-revisionadmin-subconceptdropdown-${elem.id}`}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}>
                            <ListItemText primary={elem.subConcept} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                {/* upload img */}
                <div className={createschool.add_schools_btn}></div>

                <div
                  style={{
                    marginTop: "30px",
                    position: "absolute",
                    right: "35px",
                  }}
                >
                  <WhiteAddButton
                    onClick={() => {
                      navigate("/dashboard/upload%20content");
                    }}
                    style={{ marginRight: "12px" }}
                  >
                    BACK
                  </WhiteAddButton>
                  {isAddChapter ? (
                    <button
                      style={{ maxHeight: "35px", marginBottom:"10px" }}
                      onClick={handleCreateChapter}
                      className={chapterStyles.blue_btn}
                    >
                      CREATE
                    </button>
                  ) : (
                    <button
                      style={{ maxHeight: "50px" }}
                      onClick={handleUpdateChapter}
                      className={chapterStyles.blue_btn}
                    >
                      UPDATE
                    </button>
                  )}

                  {/* <BlueButton 
               style={{maxHeight : '50px'}}
               onClick={handleCreateChapter}
              
              >
              CREATE
            </BlueButton> */}
                </div>
              </div>
              <div
                style={
                  isError ? { visibility: "visible" } : { visibility: "hidden" }
                }
              >
                <p
                  style={{
                    display: "flex",
                    float: "right",
                    marginRight: "187px",
                    color: "red",
                  }}
                >
                  {showError}
                </p>
              </div>

              {/* search btn row */}
            </div>
          </div>

          {/* table */}
        </Paper>

        <Paper
          style={{
            marginTop: "240px",
            paddingRight: "33px",
            //  marginLeft : '15px' ,
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div style={{ margin: "15px", width: "100%", display: "flex" }}>
            <div style={{ width: "50%", margin: "10px" }}>
              <p
                style={{
                  boxShadow: "0px 3px 6px #00000029",
                  width: "100%",
                  border: "1px solid grey",
                  padding: "5px",
                  fontSize: "1vw",
                  borderRadius: "7px",
                }}
              >
                Name
              </p>
              <div style={{ width: "100%", marginTop: "16px" }}>
                <p style={{ fontSize: "1vw", color: "#354052 " }}>
                  Objective(500 character)
                </p>
                <div style={{ boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px" }}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid grey",
                      padding: "5px",
                      boxShadow: "0px 1px 1px #00000029",
                    }}
                  >
                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>B</button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-italic"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-sliders"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-regular fa-image"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-right"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-center"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-eye"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-check"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <textarea
                    id="w3review"
                    style={{ resize: "none", width: "100%" }}
                    name="w3review"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <div style={{ width: "100%", marginTop: "46px" }}>
                <p style={{ fontSize: "1vw", color: "#354052" }}>
                  Description(500 character)
                </p>
                <div style={{ boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px" }}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid grey",
                      padding: "5px",
                      boxShadow: "0px 1px 1px #00000029",
                    }}
                  >
                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>B</button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-italic"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-sliders"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-regular fa-image"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-right"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-center"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-eye"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-check"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <textarea
                    id="w3review"
                    style={{ resize: "none", width: "100%" }}
                    name="w3review"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: "15px", width: "100%", display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight: "50px" }}>
                <input type="radio" style={{ accentColor: "orange" }} />
                <p style={{ color: "#354052 " }}>Upload from my machine</p>
              </div>
              <div style={{ display: "flex" }}>
                <input type="radio" style={{ accentColor: "orange" }} />
                <p style={{ color: "#354052 " }}>Upload from Reposotory</p>
              </div>
            </div>
          </div>

          <div
            style={{
              margin: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ color: "#354052 " }}>*Upload Image</p>
            <button
              style={{
                border: "none",
                color: "white",
                width: "100px",
                padding: "6px",
                backgroundColor: "orange",
                borderRadius: "12px",
              }}
            >
              <i class="fa-regular fa-mountain-sun"></i>Choose file
            </button>
          </div>

          <div style={{ margin: "15px", width: "100%", display: "flex" }}>
            <div style={{ width: "50%", margin: "10px" }}>
              <div style={{ width: "100%", marginTop: "16px" }}>
                <p style={{ fontSize: "1vw", color: "#354052 " }}>
                  Objective(500 character)
                </p>
                <div style={{ boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px" }}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid grey",
                      padding: "5px",
                      boxShadow: "0px 1px 1px #00000029",
                    }}
                  >
                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>B</button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-italic"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-sliders"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-regular fa-image"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-right"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-center"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-eye"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-check"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <textarea
                    id="w3review"
                    style={{ resize: "none", width: "100%" }}
                    name="w3review"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <div style={{ width: "100%", marginTop: "16px" }}>
                <p style={{ fontSize: "1vw", color: "#354052" }}>
                  Description(500 character)
                </p>
                <div style={{ boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px" }}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid grey",
                      padding: "5px",
                      boxShadow: "0px 1px 1px #00000029",
                    }}
                  >
                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>B</button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-italic"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-sliders"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-regular fa-image"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-right"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-center"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>

                    <div style={{ marginInline: "6px" }}>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-eye"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-check"></i>
                      </button>
                      <button style={{ paddingInline: "10px" }}>
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <textarea
                    id="w3review"
                    style={{ resize: "none", width: "100%" }}
                    name="w3review"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "55px" , marginBottom: "40px", marginRight:"55px"}}>
            <div style={{ position: "absolute", right: "10px" }}>
              <button
                style={{
                  color: "#403e75",
                  backgroundColor: "white",
                  border: "1px solid #403e75",
                  maxHeight: "35px" 
                }}
                className={chapterStyles.blue_btn}
              >
                SUBMIT
              </button>
              <button
                style={{maxHeight: "35px", marginInline: "6px" }}
                className={chapterStyles.blue_btn}
              >
                SUBMIT
              </button>
              <button style={{marginRight: "35px", }} className={chapterStyles.white_btn}>PREVIEW</button>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Activities;
