import axios from 'axios'

function subConcept_coTree_get(setConceptlist,conceptId,subsearch){
    let token = sessionStorage.getItem("token")
    axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?pageSize=100&conceptId=${conceptId}&search=${subsearch.search}`,{
    headers:{
        "Content-Type":"application/json",
        Authorization: `Bearer ${token}`
    }
  })
  .then((res)=>{
    console.log(res.data.data);
    setConceptlist(res.data.data.data)
  })
  .catch(err => console.log(err))
}

export default subConcept_coTree_get