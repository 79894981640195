import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import axios from 'axios'
import queSelectionStyles from '../../../css/queSelectionStyles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { edit_selected_questions, select_question } from '../../../../actions/quizAction'
import { isNOTNullOrUndefined } from '../../../../utils/helper'

const SubConceptQuestionsFetched = ({ IsSubconceptExpanded, conceptId, chapterId, subConceptId, taxonomySelected, questiontypeId, search }) => {
  const [rootConceptsList, setrootConceptsList] = useState([])
  const local_data = JSON.parse(localStorage.getItem('quiz_data'))



  const boardId = local_data?.selected_board?.id
  const gradeId = local_data?.selected_grade?.id
  const subjectId = local_data?.selected_subject?.id

  const subTopicId = local_data?.selected_sub_topic?.id

  const dispatch = useDispatch()

  const { selected_questions } = useSelector(state => state.quiz)

  const TOKEN = sessionStorage.getItem('token')


  const showtaxonomy = () => {
    if (questiontypeId && questiontypeId.code === 'CASE') {
      return false
    } else if (isNOTNullOrUndefined(taxonomySelected)) {
      return true
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageSize=100&conceptId=${conceptId}&subConceptId=${subConceptId}&chapterId=${chapterId}&showCorrectAnswer=true${isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ''}${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ''
          }${isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ''
          }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''
          }${showtaxonomy() ? `&taxonomyId=${taxonomySelected?.id}` : ''
          }${isNOTNullOrUndefined(questiontypeId.id)
            ? `&questionTypeId=${questiontypeId.id}`
            : ''
          }&questionPattern=OBJECTIVE`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`
            }
          }
        )
        const data = response.data.data.data;
        let filteredData = data;
        if (search) {
          filteredData = data.filter((object) => {
            return object.question.toLowerCase().replace(/[\W_]+/g, '').includes(search.toLowerCase().replace(/[\W_]+/g, ''))
          })

        }
        setrootConceptsList(filteredData)
        let ViewQuizData = sessionStorage.getItem("questionIdView");
        if (ViewQuizData) {
          ViewQuizData = JSON.parse(ViewQuizData)
          const filterList = []
          response.data.data.data.forEach(q => {
            return ViewQuizData.quizQuestions.find(que => {
              if (que.questionId === q.id) filterList.push({ ...que, ...q })
            })
          })
          dispatch(edit_selected_questions(filterList))
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (IsSubconceptExpanded) {
      fetchData()
    }
  }, [conceptId, subConceptId, chapterId, IsSubconceptExpanded, taxonomySelected, questiontypeId, search])

  const selectTopic = selected => {
    let question = { ...selected, chapterId: chapterId }
    dispatch(select_question(question))
  }

  return (
    <AccordionDetails>
      {rootConceptsList.length > 0 &&
        rootConceptsList.map((rootElem, i) => {
          return (
            <p className={queSelectionStyles.root_topics_container} key={i}>
              <div className={queSelectionStyles.root_item_container}>
                <span className={queSelectionStyles.accordion_listitems}>
                  <div style={{display:'flex', gap:'10px'}}>                <p
                                  className="head_3"
                                  dangerouslySetInnerHTML={{ __html: rootElem?.question }}
                                ></p><span>{`(${rootElem?.marks})`}</span>
                                </div>

                  {/* {rootElem.question.replace(/<[^>]+>|(&nbsp;|&ldquo;|&rdquo;|&ndash;)/gi, '')} */}
                </span>
              </div>
              <button
                disabled={
                  selected_questions.some(e => e.id === rootElem.id)
                    ? true
                    : false
                }
                onClick={() => selectTopic(rootElem)}
                className={
                  selected_questions.some(e => e.id === rootElem.id)
                    ? queSelectionStyles.topic_selected_btn
                    : queSelectionStyles.topic_select_btn
                }
                href=''
              >
                {selected_questions.some(e => e.id === rootElem.id)
                  ? 'Selected'
                  : 'Select'}
              </button>
            </p>
          )
        })}
    </AccordionDetails>
  )
}

export default SubConceptQuestionsFetched
