import React, { useState, useEffect } from "react";
import SubConceptStyles from "./../css/subConceptTree.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import { SearchOutlined } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import conceptlist_get from "../API_CONTROLLER/concept-controller/conceptlist_get";
import subConcept_coTree_get from "../API_CONTROLLER/subConcept_controller/subConcept_get";
import rootConcept_controller_get from "../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import concept_last_modified_get from "../API_CONTROLLER/concept-controller/concept_last_modified_get";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import concept_controller_post from "../API_CONTROLLER/concept-controller/concept_controller_post";
import concept_table_get from "../API_CONTROLLER/concept-controller/concept_table_get";
import rootlist_searchable_get from "../API_CONTROLLER/root-concept-controller/rootlist_searchable_get";
import Pagination1 from "./pagination1";
import sortIcon from "../../../src/pages/img/sort_1.png";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import searchIcn from "../../../src/img/search.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import styled from "styled-components";
import BreadCrumbs from "./../js/RevisionAdmin/General/BreadCrumbs";

const ViewConceptTree = () => {
  const [showModalShare, setShowModalShare] = useState(false);
  const [subject, setSubject] = useState({});
  const [conceptlist, setConceptlist] = useState({});
  const [conceptTable, setConceptTable] = useState({});
  const [conceptId, setConceptId] = useState("");
  const [subconceptId, setSubconceptId] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const [clickCountSub, setClickCountSub] = useState(0);
  const [conceptInputs, setConceptInputs] = useState({
    conceptId: "",
    concept: "",
    rootConcept: "",
    subTopicId: "",
    subjectsId: "",
  });
  const [tempSubx, setTempsubx] = useState({});
  const [tempRootx, setTemprootx] = useState({});

  const [tempx, setTempx] = useState({});
  const [rootConceptList, setRootConceptList] = useState({});

  const [subConceptInputs, setSubConceptInputs] = useState({
    subConceptId: "",
  });
  const [subconceptList, setSubconceptList] = useState({});
  const [search, setSearch] = useState({
    search: "",
  });
  const [subsearch, setSubSearch] = useState({
    search: "",
  });
  const [rootSearch, setRoorSearch] = useState({
    search: "",
  });
  const [subjectId, setSubjectId] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1)
  };

  useEffect(() => {
    setConceptTable(tempx);
  }, [tempx]);
  useEffect(() => {
    setTempsubx(tempSubx);
  }, [tempSubx]);
  useEffect(() => {
    setTemprootx(tempRootx);
  }, [tempRootx]);

  // pagination ends

  const subConceptHandler = (id) => {
    setConceptId(id);
    setSubconceptId("");
    setClickCount(prevCount => prevCount + 1);
    clickCount % 2 === 1 && setConceptId("")
  };

  const rootConceptHandler = (id) => {
    setSubconceptId(id);
    setClickCountSub(prevCount => prevCount + 1);
    clickCountSub % 2 === 1 && setSubconceptId("")
  };

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  const handleSearchConcept = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    concept_table_get(
      setConceptTable,
      search,
      subjectId,
      rowsInput,
      page,
      setPageDetail
    );
  };

  const handleSearchSubConcept = (e) => {
    e.preventDefault();
    const subsearch = {
      search: e.target.value,
    };
    subConcept_coTree_get(setSubconceptList, conceptId, subsearch);
  };

  const handleRootSearch = (e) => {
    e.preventDefault();
    const rootSearch = {
      search: e.target.value,
    };
    rootlist_searchable_get(setRootConceptList, subconceptId, rootSearch);
  };

  const handleConceptInputs = (e) => {
    e.preventDefault();
    setConceptInputs({
      ...conceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  const createConceptHandler = (e) => {
    e.preventDefault();
    concept_controller_post(conceptInputs);
  };

  const handlesubConceptInputs = (e) => {
    e.preventDefault();
    setSubConceptInputs({
      ...subConceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    subConcept_coTree_get(setSubconceptList, conceptId, subsearch);
    return () => { };
  }, [conceptId]);

  useEffect(() => {
    rootlist_searchable_get(setRootConceptList, subconceptId, rootSearch);
    return () => { };
  }, [subconceptId]);

  useEffect(() => {
    subjects_controller_getall(setSubject);
    concept_last_modified_get(setLastUpdated);

    return () => { };
  }, []);

  useEffect(() => {
    if (subjectId) {
      concept_table_get(
        setConceptTable,
        search,
        subjectId,
        rowsInput,
        page,
        setPageDetail
      );
      setConceptId("");
      setSubconceptId("");
    }
  }, [subjectId, rowsInput, page]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
        <BreadCrumbs currentPage={"View Concept Tree"} />
          {/* <div style={{ fontSize: '1vw', margin: '3px 10px' }}>
            <span>
              <Icon>
                <HomeOutlinedIcon />
              </Icon>
            </span>
            <span className="az_menu_text">Home</span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>

            <a className="az_selected_menu_text">View Concept Tree</a>
          </div> */}
          {/* <div className={dashboardContentStyles.dashboard_link } >
          <span className={dashboardContentStyles.link_icon}>
            <img src={dashHome} alt= "no img"
                      width="15px" 
                      height="20px"
                      style={{verticalAlign: 'middle'}}
                      />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a >Dashboard</a>
        </div> */}
          {/* <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {lastUpdated.data}</p>
          </div> */}
        </article>

        {/* search row start  */}
        <div className={SubConceptStyles.flex} style={{ position: "relative" }}>
          <Paper
            style={{
              marginTop: "15px",
              paddingRight: "33px",
              position: "absolute",
              right: "15px",
              left: "15px",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: 186 }}
              style={{ margin: "auto 7px", padding: "12px" }}
            >
              <FormControl
                sx={{
                  "& .MuiInputBase-root": {
                    height: "48px",
                    width: "270px",
                    borderRadius: "5px  !important",
                  },
                }}
              >
                <InputLabel
                  //style={{ fontFamily: "poppins" }}
                  id="demo-simple-select"
                  style={{
                    fontFamily: "poppins",
                    color: 'orange'
                  }}
                >
                  *Subject
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: {
                      maxHeight: "300px",
                    },
                  }}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={subjectId}
                  label="Grade"
                  onChange={(e) => setSubjectId(e.target.value)}
                  style={{ background: "#fff" }}
                  sx={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    borderRadius: "25px",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    "& .css-10hburv-MuiTypography-root": {
                      fontSize: "12px !important",
                    },
                    "&.MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "1px solid #D6D5DF !important",
                      },
                    },
                    "&.MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "1px solid #272727 !important",
                      },
                    },
                    fontSize: "14px !important",
                  }}
                //   MenuProps={MenuProps}
                >
                  {subject.length > 0 ? (
                    subject.map((subject, i) => {
                      return (
                        <MenuItem
                          // key={i}
                          // value={subject.id}
                          // className={dashboardContentStyles.menu_item}
                          key={i}
                          value={subject.id}
                          id={`az-revisionreviewer-subjectdropdown-${subject.id.toLowerCase().replace(/ /g, "_")}`}
                          className={
                            dashboardContentStyles.menu_item
                          }
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText primary={subject.subject} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </div>
        {/* <TextField
              style={{ margin: 'auto 7px' }}
              type={"text"}
              placeholder={"Search...."}
              //   onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchOutlined style={{ color: "#FFA700" }} />
                  </IconButton>
                ),
              }}
            /> */}

        {/* search row end  */}
        <div
          style={{
            marginTop: "100px",
            display: "grid",
            gridTemplateColumns: "auto auto auto",
          }}
        >
          <TextField
            style={{ margin: "auto 14px" }}
            type={"search"}
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                opacity: 1,
                font: "normal normal normal 13px/20px Poppins !important",
              },
            }}
            disabled={!subjectId.length > 0}
            placeholder={"Search Concepts"}
            onChange={(e) => handleSearchConcept(e)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
          <TextField
            style={{ margin: "auto 14px" }}
            type={"search"}
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                opacity: 1,
                font: "normal normal normal 13px/20px Poppins !important",
              },
            }}
            disabled={subconceptList.length === 0}
            placeholder={"Search Sub Concepts"}
            onChange={(e) => handleSearchSubConcept(e)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
          <TextField
            style={{ margin: "auto 14px" }}
            type={"search"}
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                opacity: 1,
                font: "normal normal normal 13px/20px Poppins !important",
              },
            }}
            disabled={rootConceptList.length === 0}
            placeholder={"Search Root Concepts"}
            onChange={(e) => handleRootSearch(e)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
        </div>

        {/* content start  */}
        <Paper
          sx={{
            paddingRight: "33px",
            width: "100%",
            overflow: "hidden",
            marginTop: "15px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table
              style={{
                width: "100%",
                height: '100%'
              }}
            >
              <TableHead style={{ backgroundColor: "#403E75", height: "55px" }}>
                <div style={{ display: "flex" }}>
                  <TableCell
                    style={{ width: "33%" }}
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Concepts
                    <img
                      className="cursor-pointer"
                      width="12"
                      height="16"
                      style={{ verticalAlign: "middle", marginLeft: "40px" }}
                      src={sortIcon}
                      onClick={() => {
                        let tempData = JSON.parse(
                          JSON.stringify(conceptTable.reverse())
                        );

                        setTempx(tempData);
                        // setConceptTable(tempx);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: "33%" }}
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Sub Cocepts
                    <img
                      className="cursor-pointer"
                      width="12"
                      height="16"
                      style={{ verticalAlign: "middle", marginLeft: "40px" }}
                      src={sortIcon}
                      onClick={() => {
                        let tempSub = JSON.parse(
                          JSON.stringify(subconceptList.reverse())
                        );
                        setTempsubx(tempSub);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ width: "33%" }}
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Root Concepts
                    <img
                      className="cursor-pointer"
                      width="12"
                      height="16"
                      style={{ verticalAlign: "middle", marginLeft: "40px" }}
                      src={sortIcon}
                      onClick={() => {
                        let tempRoot = JSON.parse(
                          JSON.stringify(rootConceptList.reverse())
                        );
                        setTemprootx(tempRoot);
                      }}
                    />
                  </TableCell>
                </div>
              </TableHead>
              <div style={{ display: "flex", flexDirection: "column", overflowY: 'scroll', height: '100%' }}>
                {conceptTable && conceptTable.length > 0
                  && <p
                    className={SubConceptStyles.table_cell_cotree}
                    style={{ width: '33%', color: "orange", background: "transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box" }}
                  >
                    Concept
                  </p>}
                {conceptTable && conceptTable.length > 0
                  ? conceptTable.map((res, key) => {
                    return (
                      <>
                        <div style={{ width: "100%", display: "flex" }}>
                          <p
                            tabIndex="1"
                            onClick={() => subConceptHandler(res.id)}
                            className={SubConceptStyles.table_cell_cotree}
                            style={{ width: '33%', background: res.id === conceptId && "transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box", color: res.id === conceptId && "blue" }}
                          >
                            {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}{" "}
                            {"    "}
                            {res.concept}
                          </p>

                          {res.id === conceptId ?
                            <div style={{ display: "flex", flexDirection: "column", width: '66%' }}>
                              {subconceptList && subconceptList.length > 0 &&
                                <p
                                  className={SubConceptStyles.table_cell_cotree}
                                  style={{ width: '50%', color: subconceptList && subconceptList.length > 0 && "orange", background: subconceptList && subconceptList.length > 0 && "transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box" }}
                                >
                                  Sub Concept
                                </p>}
                              {subconceptList && subconceptList.length > 0
                                ? subconceptList.map((_s, key) => {
                                  return (
                                    <div style={{ width: "100%", display: "flex" }}>
                                      <p
                                        tabIndex="2"
                                        onClick={() => rootConceptHandler(_s.id)}
                                        className={SubConceptStyles.table_cell_cotree}
                                        style={{ width: '50%' }}
                                      >
                                        {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                        })}{" "}
                                        {"    "} {_s.subConcept}
                                      </p>
                                      {_s.id === subconceptId ?
                                        <div style={{ display: "flex", flexDirection: "column", width: '50%' }}>
                                          {rootConceptList && rootConceptList.length > 0 &&
                                            <p
                                              className={SubConceptStyles.table_cell_cotree}
                                              style={{ color: rootConceptList && rootConceptList.length > 0 && "orange", background: rootConceptList && rootConceptList.length > 0 && "transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box" }}
                                            >
                                              Root Concept
                                            </p>}
                                          {rootConceptList && rootConceptList.length > 0
                                            ? rootConceptList.map((_r, key) => {
                                              return (
                                                <p className={SubConceptStyles.table_cell_cotree}>
                                                  {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false,
                                                  })}{" "}
                                                  {"    "}
                                                  {_r.rootConcept}
                                                </p>
                                              );
                                            })
                                            : ""}
                                        </div>
                                        : null}
                                    </div>
                                  );
                                })
                                : ""}
                            </div>
                            : null}
                        </div >
                      </>
                    );
                  })
                  : ""}
              </div>
              {/* <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "97%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {conceptTable && conceptTable.length > 0
                      ? conceptTable.map((res, key) => {
                          return (
                            <p
                              tabIndex="1"
                              onClick={() => subConceptHandler(res.id)}
                              className={SubConceptStyles.table_cell_cotree}
                            >
                              {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}{" "}
                              {"    "}
                              {res.concept}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {subconceptList && subconceptList.length > 0
                      ? subconceptList.map((res, key) => {
                          return (
                            <p
                              tabIndex="2"
                              onClick={() => rootConceptHandler(res.id)}
                              className={SubConceptStyles.table_cell_cotree}
                            >
                              {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}{" "}
                              {"    "} {res.subConcept}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {console.log(rootConceptList, "0000")}
                    {rootConceptList && rootConceptList.length > 0
                      ? rootConceptList.map((res, key) => {
                          return (
                            <p className={SubConceptStyles.table_cell_cotree}>
                              {((page - 1) * rowsInput.rows + (key + 1)).toLocaleString("en-US", {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}{" "}
                              {"    "}
                              {res.rootConcept}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                </div>

              </div> */}
            </Table>
          </div>
        </Paper >

        {/* content ends */}
        {/* pagination container - Start */}
        <div className={SubConceptStyles.pagination} style={{ width: "100%" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={SubConceptStyles.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
        {/* own modal start  */}
        <div
          className={SubConceptStyles.flex_container}
          id={SubConceptStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={SubConceptStyles.main_container}
            id={SubConceptStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <center>
                <WarningAmberIcon
                  style={{ color: "#FD8C00", fontSize: "100px" }}
                />
                <h4 style={{ fontWeight: "100" }}>
                  You want to unlink with ------ concept ?
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <button
                    style={{
                      color: "grey",
                      padding: "8px",
                      backgroundColor: "white",
                      margin: "auto 8px",
                      border: "1px solid grey",
                      borderRadius: "21px",
                    }}
                    onClick={handleShareModalOk}
                  >
                    NOT NOW
                  </button>
                  <button
                    style={{
                      padding: "10px 32px",
                      borderRadius: "21px",
                      margin: "auto 8px",
                      backgroundColor: "#FD8C00",
                      color: "white",
                      border: "none",
                    }}
                  >
                    UNLINK
                  </button>
                </div>
              </center>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div >
    </>
  );
};



export default ViewConceptTree;

const Icon = styled.span`
	display: block;
	width: 20px;
	svg {
		color: #7a7a7a;
		width: 100%;
	}
`;
