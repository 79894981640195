import axios from 'axios'

function quiz_paginated_get(setQuiztable){
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/page?pageSize=10&sortBy=id` ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setQuiztable(res.data.data.data)
    })
    .catch(err => console.log(err))

}

export default quiz_paginated_get