import React, { useEffect, useState } from "react";
import axios from "axios";
import dashHome from "../../../src/img/dashHome.svg";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import AdminroleModal from "./AdminroleModal";
import BoardDetailsModal from "./BoardDetailsModal";
import PlanDetailsModal from "./PlanDetailsModal";
import {
  Checkbox,
} from "@mui/material";
import { async } from "q";

const MasterAdmin_Dashboard = () => {
  const [Adminroles, setAdminRoles] = useState();
  const [Boards, setBoards] = useState();
  const [AdminroleDetailsModal, setAdminroleDetailsModal] = useState(false);
  const [boardModal, setBoardDetailsModal] = useState(false);
  const [plansModal, setPlansModal] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState([]);
  const [UserDetails, setUserDetails] = useState([]);
  const [plans, setPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [chooseFeatures, setChooseFeatures] = useState([]);
  const [grades, setGrades] = useState([]);
  const [planlable, setPlanLable] = useState();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    AdminRoles();
    BoardsDetails();
    Plans();
    ChooseFeatures();
    Gradesall();
  }, []);

  const AdminRoles = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/dashboard/admin-role/user-details`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminRoles(response?.data?.data);
  };

  const BoardsDetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/dashboard/board-school/details`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBoards(res?.data.data);
  };

  const Plans = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setPlans(res?.data?.data?.data);
  };
  const showPlanDetails = async (value) => {
    setPlanLable(value?.plan)
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/feature-status/in-grades?planId=${value?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setPlanDetails(response?.data.data);
    setPlansModal(true);
  };
  const ChooseFeatures = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/misc/plan-feature/for-dashboard`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setChooseFeatures(res?.data?.data);
  };
  const Gradesall = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setGrades(res?.data.data);
  };

  const ShowAdminRoleDetails = (value) => {
    setAdminroleDetailsModal(true);
    setUserDetails(value);
  };
  const ShowBoardDetails = (value) => {
    setBoardDetailsModal(true);
    setSchoolDetails(value);
  };
  const [selectedfeatures, setSelectedfeatures] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [featureTemp, setfeaturetemp] = useState("");
  const [gradetemp, setGrdaetemp] = useState("");

  const selectgrades = async (e, value) => {
    if (e.target.checked) {
      setSelectedGrades([...selectedGrades, value]);
      const grades = [...selectedGrades, value];
      var temp = "";
      for (var i = 0; i < grades.length; i++) {
        temp =
          temp +
          (i != grades.length || i == 0
            ? `&gradeIds=${grades[i]}`
            : `gradeIds=${grades[i]}`);
      }
      setGrdaetemp(temp);
      if (temp || featureTemp) {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}&${featureTemp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      }
    } else if (!e.target.checked) {
      const grades = [...selectedGrades].filter((v) => v != value);
      setSelectedGrades([...grades]);
      var temp = "";
      for (var i = 0; i < grades.length; i++) {
        temp =
          temp +
          (i != grades.length || i == 0
            ? `&gradeIds=${grades[i]}`
            : `gradeIds=${grades[i]}`);
      }
      setGrdaetemp(temp);
      if (temp || featureTemp) {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}&${featureTemp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      }
    }
  };

  const selectFeatures = async (e, value) => {
    if (e.target.checked) {
      setSelectedfeatures([...selectedfeatures, value]);
      let api = [...selectedfeatures, value];
      var temp = "";
      for (var i = 0; i < api.length; i++) {
        temp =
          temp +
          (i != api.length || i == 0 ? `&${api[i]}=true` : `${api[i]}=true`);
      }
      setfeaturetemp(temp);
      if (temp || gradetemp) {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}&${gradetemp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      }
    } else if (!e.target.checked) {
      let k = [...selectedfeatures].filter((v) => v != value);
      setSelectedfeatures([...k]);
      var temp = "";
      for (var i = 0; i < k.length; i++) {
        temp =
          temp + (i != k.length || i == 0 ? `&${k[i]}=true` : `${k[i]}=true`);
      }
      setfeaturetemp(temp);
      if (temp || gradetemp) {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}&${gradetemp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-feature-grade?pageNumber=0&pageSize=500${temp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlans(res?.data?.data?.data);
      }
    }
  };

  return (
    <div className="Whole_DashPage">
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle", marginLeft: "14px" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* <span
            style={{ fontSize: "12px", marginLeft: "4px", color: "#7A7A7A" }}
          >
            Home ⨠
          </span> */}
          <span
            id={`az-breadscrum-home`}
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}

          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>

          {/* <span></span>
          <span
            style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
          >
            Dashboard
          </span> */}
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Dashboard
          </span>

        </div>
      </article>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "60px",
        }}
      >
        <div
          className="Num_of_Sec"
          style={{ width: "54%", overflow: "scroll" }}
        >
          <div>
            <h6 style={{ marginLeft: "50px" }}>Admin Roles</h6>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            {Adminroles?.map((value, i) => {
              return (
                <div
                  style={{
                    height: "25px",
                    width: "250px",
                    backgroundColor: "#d9dcfe",
                    borderRadius: "5px",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: "lighter",
                      fontSize: "smaller",
                      paddingLeft: "10px",
                      paddingRight: "5px",
                    }}
                  >
                    <span>{value?.roleName}</span>
                    <span
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        backgroundColor: "#968afb",
                        padding: "0px 4px",
                        cursor: "pointer",
                        fontWeight: "500"
                      }}
                      onClick={() => {
                        ShowAdminRoleDetails(value?.userDetails);
                      }}
                    >
                      {value?.userCount}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="Num_of_Stud"
          style={{ width: "38%", overflow: "scroll" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "40px",
            }}
          >
            <h6>Boards</h6>
            <h6 style={{ marginRight: "40px" }}>Count</h6>
          </div>
          <div style={{ fontWeight: "lighter" }}>
            {Boards?.map((value, i) => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <span
                    style={{
                      height: "25px",
                      width: "250px",
                      backgroundColor: "rgb(195 238 172 / 53%)",
                      borderRadius: "5px",
                      margin: "5px",
                      fontSize: "smaller",
                      paddingLeft: "10px",
                    }}
                  >
                    {value?.board}
                  </span>
                  <span
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                      backgroundColor: "rgb(163 244 120 / 79%)",
                      padding: "0px 6px",
                      cursor: "pointer",
                      fontWeight: "500"
                    }}
                    onClick={() => {
                      ShowBoardDetails(value?.schoolDetails);
                    }}
                  >
                    {value?.schoolCount}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}
      >
        <div>
          <h5>Plan Choosers</h5>
          <h6 style={{ fontWeight: "lighter" }}>Choose Features</h6>
        </div>

        <div
          className="Num_of_Sections"
          style={{
            width: "100%",
            height: "90px",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "lighter",
            fontSize: "smaller"
          }}
        >
          {chooseFeatures?.map((value, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "105px",
                  borderRight:
                    i != chooseFeatures.length - 1 && "0.5px solid #e7e0e0bf",
                }}
              >
                <span style={{ marginBottom: "15px" }} title={value?.name}>
                  {" "}
                  {value?.name?.length <= 7
                    ? value?.name
                    : value?.name?.slice(0, 7) + "..."}
                </span>
                <Checkbox size="small"
                  style={{ color: "#409def" }}
                  onChange={(event) => selectFeatures(event, value?.code)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}
      >
        <div>
          <h6 style={{ fontWeight: "lighter" }}>Choose Grade</h6>
        </div>

        <div
          className="Num_of_Sections"
          style={{
            width: "100%",
            height: "86px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            fontWeight: "lighter",
            overflowX: "scroll",
            fontSize: "smaller"
          }}
        >
          {grades?.map((v, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "90px",
                }}
              >
                <span style={{ marginBottom: "10px" }} title={v?.grade}>
                  {" "}
                  {v?.grade?.length <= 6
                    ? v?.grade
                    : v?.grade?.slice(0, 6) + "..."}
                </span>
                <Checkbox size="small"
                  style={{ color: "#409def" }}
                  onChange={(event) => selectgrades(event, v?.id)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}
      >
        <div>
          <h5>Plans</h5>
        </div>

        <div
          className="Num_of_Sections"
          style={{
            width: "100%",
            height: "130px",
            display: "flex",
            flexWrap: "wrap",
            overflow: "scroll",
          }}
        >
          {plans?.map((value, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "90px",
                  padding: "10px",
                  marginLeft: "20px"
                }}
              >
                <button
                  style={{
                    height: "40px",
                    width: "80px",
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "5px",
                    borderColor: "#409def",
                    cursor: "pointer",
                    fontWeight: "lighter",
                    marginBottom: "5px",
                  }}
                  onClick={() => {
                    showPlanDetails(value);
                  }}
                  title={value?.plan}
                >
                  {" "}
                  {value?.plan?.length <= 10
                    ? value?.plan
                    : value?.plan?.slice(0, 10) + "..."}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <AdminroleModal
        open={AdminroleDetailsModal}
        close={() => {
          setAdminroleDetailsModal(false);
        }}
        UserDetails={UserDetails}
      />
      <BoardDetailsModal
        open={boardModal}
        close={() => {
          setBoardDetailsModal(false);
        }}
        schoolDetails={schoolDetails}
      />
      <PlanDetailsModal
        open={plansModal}
        close={() => {
          setPlansModal(false);
        }}
        planDetails={planDetails}
        planlable={planlable}
      />
    </div>
  );
};

export default MasterAdmin_Dashboard;
