import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// Images & Icons Used
import dashHome from "../../../src/img/dashHome.svg";
import admin from "../../../src/img/Adminuser.svg";
import add_training from "../../../src/img/add_training.svg";
import teachIc from "../../../src/img/teach.svg";
import reportIc from "../../../src/img/Reports.svg";
import learningIcn from "../../../src/img/Learning.png";
import assignIcn from "../../../src/img/Masters.png";
import upSkillsIcn from "../../../src/img/Upskill.png";
import StudentIcn from "../../../src/img/Student report.png";

// Css
import navStyles from "./../css/navbar.module.css";
import "../css/Student/Student_SideNavbar.css";
import {
  ListItemText,
  Menu,
  MenuItem,
  ListSubheader
} from "@mui/material";


const CordinatorMenuItem =
  ({ elem, i, schoolSublinkVisible, navBarVis, handleLinkToggle }) => {

    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const [subMenuNameValue, setSubMenuNameValue] = useState("");

    let menuName = sessionStorage.getItem("sideNavMainMenuNames");
    let subNavMenuName = sessionStorage.getItem("sideNavSubMenuNames");

    const handleHighlight = (clicked) => {
      if (clicked === 'Reports' && !navBarVis) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
      sessionStorage.setItem("sideNavMainMenuNames", clicked);
      sessionStorage.removeItem("sideNavSubMenuNames");
    };

    const handleHighlightSubMenu = (clicked) => {
      setShowPopup(false);
      sessionStorage.setItem("sideNavSubMenuNames", clicked);
      // sessionStorage.setItem("sideNavMainMenuNames", "Reports");
    };

    const handleClose = (reportValue) => {
      console.log(">>>>>>>>>>>>>>>", reportValue);
      setSubMenuNameValue(reportValue);
      setShowPopup(false);
      if (reportValue !== "Formal Assessment") {
        navigate(`/dashboard/${encodeURIComponent(reportValue)}`);
      } else {
        navigate(`/dashboard/${encodeURIComponent("Formative Assessment")}`);
      }

    };


    // Rendering the Menu Icons
    const menuIcons = {
      Dashboard: dashHome,
      Teach: teachIc,
      AssignQuiz: assignIcn,
      FormalAssessment: "", // Import and Change the Icon Here
      StudentRevision: StudentIcn,
      LearningPlan: learningIcn,
      Upskills: upSkillsIcn,
      Subjectivepapers: add_training,
      Reports: reportIc,
    };
    const defaultIcon = admin;
    const selectedIcon = menuIcons[elem.menu.replace(/ /g, "")] || defaultIcon;

    return (
      <>
        <div>
          
          {/*-------| Side Navbar Menu Names & Navigation Starts |-------*/}
          {elem.menu === "Reports" || elem.menu === "Reading Passport" ? (
            <div
              id={`az-coordinator-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
              key={elem.menuId}
              className="sidenav_parent"
              onClick={elem.subMenus && handleLinkToggle}
            >
              <div
                id={`az-teacher-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                className={`icon_and_text ${menuName === elem.menu ? "" : "icon_and_text_active"
                  }`}
                onClick={() => handleHighlight(elem.menu)}
              >
                {/*-------| Side Navbar Icons|-------*/}
                <div
                  className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                    }`}
                >
                  <img src={selectedIcon} alt="no img" />
                </div>

                {menuName === elem.menu ? (
                  <div className="sidenav_text_selected">{elem.menu}</div>
                ) : (
                  <div>
                    <div className="sidenav_text">{elem.menu}</div>
                  </div>
                )}

                {/*-------| Side Navbar "Reports" Dropdown Arrow Icon|-------*/}
                <div className="report_arrow_icon">
                  <i
                    className={
                      schoolSublinkVisible && navBarVis && elem.menu === sessionStorage.getItem("sideNavMainMenuNames")
                        ? "fa-solid fa-angle-down"
                        : "fa-solid fa-angle-right"
                    }
                    style={
                      menuName === elem.menu ? { color: "#ffffff" } : { color: "#1b1b1b" }
                    }
                    id="toggle_btn_icon"
                  ></i>
                </div>
              </div>
            </div>
          ) : (
            <Link
              id={`az-coordinator-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
              style={{ textDecoration: 'none' }}
              to={`/cordinator/${elem.menu.toLowerCase().replace(/ /g, "-")}`}
            >
              <div
                key={elem.menuId}
                className="sidenav_parent"
                onClick={elem.subMenus && handleLinkToggle}
              >
                <div
                  className={`icon_and_text ${menuName === elem.menu ? "" : "icon_and_text_active"
                    }`}
                  onClick={() => handleHighlight(elem.menu)}
                >
                  {/*-------| Side Navbar Icons|-------*/}
                  <div
                    className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                      }`}
                  >
                    <img src={selectedIcon} alt="no img" />
                  </div>
                  {elem.subMenus ? (
                    <div className={
                      menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                    }>{elem.menu}</div>

                  ) : (
                    <div className={
                      menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                    }>{elem.menu}</div>

                  )}
                </div>
              </div>
            </Link>
          )}
          {/*-------| Side Navbar Menu Names & Navigation Ends |-------*/}

          {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Starts |-------*/}
          <div
            key={i}
            style={
              schoolSublinkVisible && elem.menu === sessionStorage.getItem("sideNavMainMenuNames") ? { display: "block" } : { display: "none" }
            }
          >
            <div>
              {elem.subMenus &&
                elem.subMenus.map((item) => {
                  return (
                    <div
                      // id="registered_schools_link"
                      key={item.subMenuId}

                      style={
                        navBarVis ? { display: "block" } : { display: "none" }
                      }
                    >
                      <div>
                        <Link
                          id={`az-coordinator-sidemenu-${item.subMenuName.toLowerCase().replace(/ /g, "_")}`}
                          style={{
                            textDecoration: "none",
                            // display: "inline-block",
                          }}
                          to={
                            item.subMenuName === 'Chapter Performance'
                              ? '/dashboard/Chapter%20performance'
                              : item.subMenuName === 'Student Report'
                                ? '/dashboard/Student%20Report'
                                : item.subMenuName === 'Progress Report'
                                  ? '/dashboard/Progress%20Report'
                                  :
                                  item.subMenuName === "Reading Passport List"
                                    ? "/cordinator/reading-passport"
                                    : item.subMenuName === "Monthly Avid Reader"
                                      ? "/cordinator/avid-reader" :
                                      item.subMenuName === "Performance Report"
                                        ? "/cordinator/passport-performance" :
                                        "/dashboard/Formative Assessment"
                          }
                        >
                          <div
                            key={item.subMenuId}
                            // className={`reports ${subNavMenuName === item.subMenuName
                            //   ? "" && "activeSubMenu"
                            //   : ""
                            //   }`}
                            className={`reports ${subNavMenuName === item.subMenuName ? "activeSubMenu" : ""}`}
                            onClick={() => handleHighlightSubMenu(item.subMenuName)}
                          >
                            {/*-------| "Reports" Icons Starts |-------*/}
                            <div className="reports_icons">
                              {item.subMenuName === "Chapter Performance" ? (
                                <span
                                  className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                    ? "subMenuIcon_active"
                                    : ""
                                    }`}
                                ></span>
                              ) : item.subMenuName === 'Student Report' ? (
                                <span
                                  className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                    ? "subMenuIcon_active"
                                    : ""
                                    }`}
                                ></span>
                              ) : (
                                <span
                                  className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                    ? "subMenuIcon_active"
                                    : ""
                                    }`}
                                ></span>
                              )}
                            </div>
                            {/*-------| "Reports" Icons Ends |-------*/}

                            <div className={
                              subNavMenuName === item.subMenuName
                                ? "reports_text_selected"
                                : "reports_text"
                            }> {item.subMenuName === 'Formal Report'
                              ? 'Formal Assessment'
                              : item.subMenuName
                              }</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Ends |-------*/}
          {showPopup && (
            <Menu
              id="basic-menu"
              open={showPopup}
              onClose={() => setShowPopup(false)}
              transformOrigin={{ horizontal: "left", vertical: "center" }}
              anchorOrigin={{ horizontal: "left", vertical: "center" }}
              PaperProps={{
                style: {
                  borderRadius: "20px", // Adjust the border radius as needed
                  paddingTop: "0px"
                },
              }}
            >
              <ListSubheader
                sx={{
                  typography: 'h8',
                  color: "#1b1b1b",
                  background: "#ffa500"
                }}>Reports</ListSubheader>


              {elem.subMenus &&
                elem.subMenus.map((item) => (
                  <MenuItem
                    key={item.subMenuId}
                    onClick={() => handleClose(item.subMenuName)}
                  >


                    <ListItemText
                      sx={{
                        // color : "#ff2309"
                        color: subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000",
                      }}>
                      {item.subMenuName === 'Formal Report'
                        ? 'Formal Assessment Report'
                        : item.subMenuName}
                    </ListItemText>
                  </MenuItem>
                ))}
            </Menu>
          )}
        </div >
      </>
    )
  }

export default CordinatorMenuItem;