import React, { useEffect, useState } from "react";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Checkbox,
	tooltipClasses,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import GreenAddButton from "../../components/common/GreenAddButton";
import Studentdynamic from "./../css/student32.module.css";
import regschool from '../css/RegisteredSchool.module.css'
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { MenuProps } from "./Data";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import { styled } from "@mui/material/styles";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import sub_map_Img from "../../../src/img/Masters.svg";
import add_icon from "../../../src/img/add-circle-outline.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
import ViewSubjectAndSubtopicModal from "./ViewSubjectAndSubtopicModal";
import CommonDeleteModal from "./CommonDeleteModal";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import Addbranchtcss from "./../css/addbranch.module.css";
//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import commonsort from "../js/CommonSort"
// input choose file label image
import Cf_img from "../../../src/img/csv.svg";
import C_img from "../../../src/img/image_icon.png";
import UpdatedModal from "./UpdatedModal";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	tooltip: {
		maxWidth: '190px',
		fontSize: '10px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	}
}));

const Chapter = () => {
	const token = sessionStorage.getItem("token");
	const maxLength = 30;
	const classes = useStyles();
	const [addChapterVisible, setAddChapterVisible] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [updatedData, setUpdatedData] = useState();
	const [filterValue, setFilterValue] = useState();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageSize, setPageSize] = useState(100);
	const [uploadedFile, setUploadedFile] = useState("");
	const [subjectList, setSubjectList] = useState([]);
	const [gradeList, setGradeList] = useState();
	const [boardList, setBoardList] = useState();
	const [subTopicData, setSubTopicData] = useState([]);
	const [selectedSubtopic, setSelectedSubtopic] = useState("");
	const [selectedBoard, setSelectedBoard] = useState("");
	const [selectedGrade, setSelectedGrade] = useState("");
	const [selectedSubject, setSelectedSubject] = useState("");
	const [chapterName, setChapterName] = useState("");
	const [visibleSubtopicModal, setVisibleSubtopicModal] = useState(false);
	const [selectedValue, setSelectedValue] = useState("");
	const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
	const [modifiedDate, setModifiedDate] = useState({});
	const [toggleMessage, settoggleMessage] = useState();
	const [toggleConfirmation, setToggleConfirmation] = useState(false);
	const [id, setId] = useState("");
	const [successModal, setSuccessModal] = useState(false);
	const [validation, setValidation] = useState(false);
	const [boardId, setBoardId] = useState();
	const [gradeId, setGradeId] = useState();
	const [subjectid, setSubjectId] = useState("");
	const [subtopicid, setSubtopicId] = useState("");
	const navigate = useNavigate();

	//sort
	const [sortOrder, setSortOrder] = useState(true);
	const [sortBy, setSortBy] = useState("id");

	useEffect(() => {
		GetLastModifiedAt(setModifiedDate);
		return () => { };
	}, []);
	const [selectedItem, setSelectedItem] = useState("");
	useEffect(() => {
		setFilterValue(updatedData);
	}, [updatedData]);
	const showSubjectAndSubtopic = async (id) => {
		setVisibleSubtopicModal(true);
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/${id}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response?.data?.data) {
			setSelectedValue(response?.data?.data);
		}
	};
	const hideAddChapter = () => {
		setValidation(false);
		setAddChapterVisible(false);
		setSelectedSubtopic([]);
		setSelectedSubject("");
		setSelectedGrade("");
		setSelectedBoard("");
	};
	const openDeleteModal = async (item) => {
		setId(item?.id);
		setVisibleDeleteModal(true);
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/confirmation-api?id=${item.id}&operationType=DELETE`,

			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);

		setSelectedItem({ ...item, chapter: response?.data?.data?.message });
	};
	const closeDeleteModal = () => {
		setVisibleDeleteModal(false);
		setSelectedItem("");
	};
	const hideSubjectAndSubtopic = () => {
		setVisibleSubtopicModal(false);
	};
	const LightTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
			fontSize: 13,
			fontWeight: 400,
		},
	}));

	const UploadButton = styled(Button)(() => ({
		color: "#fff",
		// width: 170,
		width: 130,
		height: 35,
		// backgroundColor: "#FFA700",
		backgroundColor: "#FD8C00",
		borderRadius: 150,
		border: "1px solid #FFA700",
		"&:hover": {
			color: "#fff",
			// backgroundColor: "#FFA700",
			backgroundColor: "#FD8C00",
			border: 0,
			// top: -2,
			boxShadow: "0px 3px 5px #FFA700",
		},
	}));
	const fetchData = async (pageSize) => {
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response?.data?.data) {
			const totalElement = response?.data?.data?.totalElements;
			if (totalElement >= pageSize) {
				setPageSize(totalElement);
			}
			setUpdatedData(response?.data?.data?.data);
		}
	};
	const fetchSubjectData = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/master/subject-mapping/subjects?boardId=${selectedBoard || boardId
			}&gradeId=${selectedGrade || gradeId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setSubjectList(response?.data?.data);
	};
	const fetchBoardData = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setBoardList(response?.data?.data);
		console.log("response?.data?.data", response?.data?.data);
	};

	useEffect(() => {
		selectedGrade && selectedBoard && selectedSubject && fetchSubtopic();
	}, [selectedSubject, subjectid]);

	const fetchSubtopic = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/master/subject-mapping/sub-topics?subjectId=${selectedSubject || subjectid
			}&boardId=${selectedBoard || boardId}&gradeId=${selectedGrade || gradeId
			}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setSubTopicData(response?.data?.data);
	};

	async function GetLastModifiedAt(ModifiedDate) {
		let token = sessionStorage.getItem("token");
		let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/last-modified-at`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((json) => {
				ModifiedDate(json.data)
			})
			.catch(err => console.log(err))
	}

	const fetchGradeData = async () => {
		let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/from-subject-mapping?boardId=${selectedBoard}`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				{
					setGradeList(response.data.data);
				}
			})
			.catch(err => {
				console.log(err);
			})
	};
	useEffect(() => {
		selectedGrade && selectedBoard && fetchSubjectData();
	}, [selectedGrade, selectedBoard, boardId, gradeId]);

	useEffect(() => {
		if (pageSize) {
			fetchData(pageSize);
		}
	}, [pageSize, sortOrder]);
	console.log(boardId, "boardid")

	useEffect(() => {
		if (selectedBoard) {
			fetchGradeData()
		}
	}, [selectedBoard]);

	useEffect(() => {
		fetchBoardData();
		// fetchGradeData();
		// fetchSubjectData();
		selectedGrade && selectedBoard && selectedSubject && fetchSubtopic();
	}, []);
	const uploadedFileHandler = (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		axios({
			method: "post",
			url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=CHAPTERS`,
			data: formData,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then(function (response) {
				if (response.data.data) {
					setUploadedFile(response?.data?.data);
				}
			})
			.catch(function (response) {
				// console.log("error ==> ", response);
			});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const onSearch = async (event) => {
		let value = event?.target?.value?.toLowerCase();
		let result = updatedData;
		if (value) {
			result = updatedData?.filter((data) => {
				return data?.chapter?.toLowerCase().includes(value);
			});
		}
		setFilterValue(result);
		setPage(0);
	};
	const handleSearchbtn = (e) => {
		e.preventDefault();
		fetchData(pageSize);
	};

	const selectSubjectHandler = (e) => {
		const id = e.target.value;
		// setSubjectId(id);
		setSubtopicId(null);
		setSelectedSubject(e.target.value);
		if (id !== "all") {
			if (boardId && gradeId) {
				const filterValue = updatedData?.filter(
					(item) =>
						item?.subjectId === id &&
						item?.gradeId === gradeId &&
						item?.boardId === boardId
				);
				setFilterValue(filterValue);
			} else {
				const filterValue = updatedData?.filter(
					(item) => item?.subjectId === id
				);
				setFilterValue(filterValue);
			}
		} else {
			setFilterValue(updatedData);
		}
		setPage(0);
	};
	const selectSubtopicHandler = (e) => {
		const id = e.target.value;
		setSubtopicId(id);
		if (id !== "all") {
			if (boardId && gradeId && subjectid) {
				const filterValue = updatedData?.filter(
					(item) =>
						item?.subjectId === subjectid &&
						item?.gradeId === gradeId &&
						item?.boardId === boardId
				);
				console.log(filterValue);
				const output = filterValue?.filter((x) => {
					return x?.subTopics?.every((y) => y?.subTopicId == id) && x;
				});
				console.log(output);
				setFilterValue(output);
			} else {
				const output = updatedData?.filter((x) =>
					x?.subTopics?.every((y) => y?.subTopicId?.includes(id))
				);
				setFilterValue(output);
			}
		} else {
			setFilterValue(updatedData);
		}
		setPage(0);
	};
	const [boardkey, setBoardkey] = useState("");
	const selectBoardHandler = (e) => {
		setSelectedBoard(e.target.value)
		const id = e.target.value;
		setBoardId(id);
		setBoardkey(Math.random() * 20);
		if (id !== "all") {
			const filterValue = updatedData?.filter(
				(item) => item?.boardId === id
			);
			setFilterValue(filterValue);
		} else {
			setFilterValue(updatedData);
		}
		setPage(0);
	};

	const selectGradeHandler = (e) => {
		const id = e.target.value;
		setSelectedGrade(id)
		setGradeId(id);
		if (id !== "all") {
			if (boardId) {
				const filterValue = updatedData?.filter(
					(item) => item?.gradeId === id && item?.boardId === boardId
				);
				setFilterValue(filterValue);
			} else {
				const filterValue = updatedData?.filter(
					(item) => item?.gradeId === id
				);
				setFilterValue(filterValue);
			}
		} else {
			setFilterValue(updatedData);
		}
		setPage(0);
	};
	const updateToggle = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${toggleDetails?.id}?active=${toggleDetails?.value}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					data: toggleDetails?.value,
				}
			)
			.then(function (response) {
				if (response?.data) {
					const find = updatedData?.find(
						(item) => item.id === toggleDetails?.id
					);
					find.active = toggleDetails?.value;
					const clone = [...updatedData];
					clone.forEach((data) => {
						data = find;
					});
					setFilterValue(clone);
					handleConfirmationClose();
				}
			})
			.catch(function (response) {
				// console.log(response);
			});
	};
	const [toggleDetails, setToggleDetails] = useState({});
	const switchHandler = async (id, e) => {
		setToggleDetails({});
		const value = e.target.checked;
		if (!value) {
			const response = await axios.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/confirmation-api?id=${id}&operationType=TOGGLE`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setToggleConfirmation(true);
			settoggleMessage(response?.data?.data?.message);
			setToggleDetails({ id, value });
		} else {
			axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${id}?active=${value}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						data: value,
					}
				)
				.then(function (response) {
					if (response?.data) {
						const find = updatedData?.find(
							(item) => item.id === id
						);
						find.active = value;
						const clone = [...updatedData];
						clone.forEach((data) => {
							data = find;
						});
						setFilterValue(clone);
						handleConfirmationClose();
					}
				})
				.catch(function (response) {
					// console.log(response);
				});
		}
	};
	const handleConfirmationClose = () => {
		setToggleConfirmation(false);
		setToggleDetails({});
	};
	const [chapterDisplayName, setChapterDisplayName] = useState("");
	const addChapterHandler = (
		chapter,
		boardId,
		gradeId,
		subjectId,
		subTopicName,
		file
	) => {
		// const subTopicsId = [];
		// subTopicName.forEach((topic) => {
		// 	const filterValue = subTopicData?.find(
		// 		(item) => item?.subtopic === topic
		// 	);
		// 	if (filterValue) {
		// 		subTopicsId.push(filterValue?.subtopicId);
		// 	}
		// });
		const newValue = {
			boardId: boardId,
			gradeId: gradeId,
			chapter: chapter,
			subTopicId: selectedSubtopic.length !== 0 ? selectedSubtopic : null,
			subjectId: subjectId,
			thumbnailPath: file,
			chapterStatus: "PENDING",
			active: true,
		};
		if (
			!newValue.boardId ||
			newValue.gradeId.length == 0 ||
			(selectedSubtopic.length === 0 && subTopicData.length > 0) ||
			newValue.subjectId.length == 0 ||
			newValue.chapter.length == 0 ||
			uploadedFile.length == 0
		) {
			setValidation(true);
		} else {
			setChapterDisplayName(chapter);
			axios({
				method: "post",
				url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters`,
				data: newValue,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then(function (response) {
					setChapterName("")
					setUploadedFile("")

					if (response.data.data) {
						setUpdatedData([...updatedData, response.data.data]);
					}
					hideAddChapter();
					publishSubjectCDN();
					setTimeout(() => {
						setSuccessCreateModal(true);
					}, 500);
				})
				.catch(function (response) {
					// console.log("error ==> ", response);
				});
		}
	};

	/// CDN publish call
	const publishSubjectCDN = async () => {
		const response = await axios.post(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/entity/upload-entity-json-s3?entityName=grade-subject`,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		//alert(response);
		//console.log("publishSubjectCDN >>> ", response);
	}

	const deleteHandler = () => {
		axios
			.delete(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/${id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				if (response?.data) {
					const find = updatedData?.filter((data) => data.id !== id);
					setFilterValue(find);
				}
			})
			.catch(function (response) {
				// console.log(response);
			});
		closeDeleteModal();
	};
	console.log(subTopicData, "data");
	const editHandler = (
		chapter,
		boardId,
		gradeId,
		subjectId,
		subTopicName,
		file,
		id
	) => {
		// const subTopics = [];
		// subTopicName.forEach((topic) => {
		// 	const filterValue = subTopicData?.find(
		// 		(item) => item?.subTopic === topic
		// 	);
		// 	if (filterValue) {
		// 		subTopics.push(filterValue);
		// 	}
		// });
		// const sunTopicId = subTopics.map((item) => item.id);
		const updatedValue = {
			boardId: boardId,
			gradeId: gradeId,
			chapter: chapter,
			subTopicId: selectedSubtopic.length > 0 ? selectedSubtopic : null,
			subjectId: subjectId,
			thumbnailPath: file,
			chapterStatus: "PENDING",
			active: true,
		};
		setChapName(updatedValue.chapter);
		axios
			.put(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/${id}`,
				updatedValue,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				fetchData(pageSize);
				setSuccessModal(true);
				setChapterName("");
				setSelectedSubtopic([]);
				setSelectedSubject("");
				setSelectedGrade("");
				setSelectedBoard("");
				setChapterName("")
				setAddChapterVisible(false);
			})
			.catch(function (response) {
				// console.log(response);
			});
	};
	const [chapName, setChapName] = useState("");
	const editClickHandler = (row) => {
		console.log(row, "ss");
		setAddChapterVisible(true);
		setIsEdit(true);
		setUploadedFile(row?.thumbnailPath);
		setSelectedBoard(row?.boardId);
		setChapterName(row?.chapter);
		setSelectedGrade(row?.gradeId);
		setSelectedSubject(row?.subjectId);
		// setSelectedSubtopic(
		// 	row.subTopics?.map((subtopic) => subtopic.subTopicId)
		// );
		setSelectedSubtopic(row.subTopicId)
		setSelectedItem(row);
	};
	console.log(subTopicData, "subtopic");
	console.log(filterValue, "filVal");

	const subjectChangeHandler = (e) => {
		setSelectedSubject(e.target.value);
		console.log(selectedSubject, "selll");
	};
	const closeModal = () => {
		setSuccessModal(false);
	};

	const [successCreateModal, setSuccessCreateModal] = useState(false);
	const closeSuccessModal = () => {
		setSuccessCreateModal(false);
	};


	const onSort = async (sortOrder, sortby) => {
		console.log(sortOrder, sortby)
		// var sorted = await commonsort(updatedData || [], sortby, sortOrder)
		// console.log(sortOrder, sortby, updatedData)
		setUpdatedData(updatedData);
	}

	const textfieldStyle = {
		fontSize: "14px !important",

		"& .MuiInputBase-root": {
			height: "48px !important",
			width: "240px !important",
			borderRadius: "150px !important",

			"& .MuiInputBase-input": {
				color: "#ffa500",

			}
		},

	}


	const filterStyle = {
		"&.MuiOutlinedInput-root": {
			"& > fieldset": {
				border: "1px solid #D6D5DF !important",
				fontSize: "13px !important",
			}
		},
		"&.MuiOutlinedInput-root.Mui-focused": {
			"& > fieldset": {
				border: "1px solid #272727 !important",

			}
		},
		fontSize: "14px !important",
		fontFamily: "Poppins",
	}

	const labelStyle = {
		fontSize: "14px",
		"&.Mui-focused": {
			color: "#ffa500",
			fontWeight: 600,
			fontFamily: "Poppins",
			fontSize: "13px",

		}
	}
	//   useEffect(() => {
	// 	fetchGradeData()
	// }, [selectedBoard]);
	console.log(gradeList, "grade")

	return (
		<>
			<article>
				<div className={dashboardContentStyles.dashboard_link}>
					<span
						className={dashboardContentStyles.link_icon}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
							sessionStorage.removeItem("sideNavSubMenuNames");
							navigate("/dashboard/template");
						}}
					>
						<img
							src={dashHome}
							alt="no img"
							// width="15px"
							// height="20px"
							// style={{ verticalAlign: "middle" }}
							className={dashboardContentStyles.az_menu_icon}
						/>
					</span>
					{/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
					<span
						className={dashboardContentStyles.az_menu_text}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
							sessionStorage.removeItem("sideNavSubMenuNames");
							navigate("/dashboard/template");
						}}
					>
						Home
					</span>

					<span>
						<i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
					</span>
					<span>
						<i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
					</span>
					{/* {"  "} */}
					<span className={dashboardContentStyles.az_menu_text}
						style={{ cursor: 'pointer' }}>
						Master
					</span>
					<span>
						<i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
					</span>
					<span>
						<i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
					</span>
					{/* {"  "}
					<a>Chapter</a> */}
					<span
						className={dashboardContentStyles.az_selected_menu_text}
					>
						Chapter
					</span>

				</div>
				<div className={dashboardContentStyles.dashboard_last_updated}>
					<p>Last Update: {modifiedDate.data}</p>
				</div>
			</article>
			<div style={{ width: "100%" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						padding: "20px",
						marginTop: "60px",
						gap: "10px",
					}}
				>
					{addChapterVisible && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "10px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									color: "#606060",
									columnGap: "10px",
								}}
							>
								<img src={sub_map_Img} alt="" />
								<div style={{ fontSize: "1vw", fontWeight: 600 }}>
									{isEdit ? "Edit Chapter" : "Add Chapter"}
								</div>
							</div>
							<Paper
								style={{
									padding: "20px 20px",
									display: "flex",
									flexDirection: "column",
									gap: "1rem",

								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										columnGap: "1rem",
										alignItems: "center",
									}}
								>
									{/* <FormControl>
                  <TextField
                    id="demo-helper-text-aligned-no-helper"
                    label="*Chapter Name"
                    placeholder=" "
                    autoComplete="off"
                    style={{ width: "270px", height: "60px" }}
                    value={chapterName}
                    onChange={(e) => setChapterName(e.target.value)}
                    sx={{
                      width: { sm: 200, md: 270 },
                      "& .MuiInputBase-root": {
                        height: 48,
                      },
                    }}
                  />
                  {validation && chapterName.length==0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                       Chaper Name is Required
                      </span>
                    )}
                    </FormControl> */}
									{/* <FormControl>
										<div
											className={
												Addbranchtcss.input_element
											}
										>
											<input
												style={{ width: "240px" }}
												className={
													Addbranchtcss.form_input_element
												}
												type="text"
												name="name"
												id="name"
												value={chapterName}
												placeholder=" "
												onChange={(e) =>
													setChapterName(
														e.target.value
													)
												}
											/>

											<label htmlFor="name">
												*Chapter Name
											</label>
										</div>
									</FormControl> */}
									<FormControl>
										<TextField
											sx={textfieldStyle}
											id="name"
											label="*Chapter Name"
											autoComplete="off"
											onChange={(e) =>
												setChapterName(
													e.target.value
												)
											}
											variant="outlined"
											value={chapterName}
										/>

									</FormControl>
									{validation && chapterName.length == 0 && (
										<span
											style={{
												color: "red",
												padding: "5px",
											}}
										>
											Chaper Name is Required
										</span>
									)}
								</div>
								<div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											columnGap: "1rem",
										}}
									>
										<FormControl>
											<div
												className={
													Addbranchtcss.input_element
												}
											>
												<select
													style={{ width: "240px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px" }}
													className={
														Addbranchtcss.form_select_element
													}
													name="cityId"
													id="cityId"
													value={selectedBoard}
													onChange={(e) =>
														setSelectedBoard(
															e.target.value
														)
													}
													placeholder=" "
												>
													<option
														className={
															Addbranchtcss.option
														}
														// disabled
														selected
														value=""
													>
														Select
													</option>

													{boardList.length > 0 ? (
														boardList.map(
															(item, index) => {
																return (
																	<option
																		className={
																			Addbranchtcss.option
																		}
																		// value={cityName.city}
																		value={
																			item.id
																		}
																		id={
																			item.id
																		}
																	>
																		{
																			item.board
																		}
																	</option>
																);
															}
														)
													) : (
														<option
															className={
																Addbranchtcss.option
															}
															// value=""
															disabled
															selected
															value="default"
														>
															Select
														</option>
													)}
												</select>
												{/* <label
                    htmlFor="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label> */}
												<label
													htmlFor="cityId"
													className={
														selectedBoard === ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Board
												</label>
												<label
													htmlFor="cityId"
													className={
														selectedBoard !== ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Board
												</label>
											</div>
											{validation &&
												selectedBoard.length == 0 && (
													<span
														style={{
															color: "red",
															padding: "5px",
														}}
													>
														Board is Required
													</span>
												)}
										</FormControl>

										{/* <FormControl sx={{ width: 367 }}>
                      <InputLabel id="demo-simple-select-label">Board</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={selectedBoard}
                        onChange={(e) => setSelectedBoard(e.target.value)}
                      >
                        {boardList?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <ListItemText primary={item.board} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
										{/* <FormControl
                      sx={{
                        width: { sm: 200, md: 270 },
                        "& .MuiInputBase-root": {
                          height: 48,
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        *Grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(e.target.value)}
                      >
                        {gradeList?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && selectedGrade.length==0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                       Grade is Required
                      </span>
                    )}
                    </FormControl> */}

										<FormControl>
											<div
												className={
													Addbranchtcss.input_element
												}
											>
												<select
													style={{ width: "240px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px" }}
													className={
														Addbranchtcss.form_select_element
													}
													name="cityId"
													id="cityId"
													value={selectedGrade}
													onChange={(e) =>
														setSelectedGrade(
															e.target.value
														)
													}
													placeholder=" "
												>
													<option
														className={
															Addbranchtcss.option
														}
														// disabled
														selected
														value=""
													>
														Select
													</option>

													{gradeList?.length > 0 ? (
														gradeList?.map(
															(item, index) => {
																return (
																	<option
																		className={
																			Addbranchtcss.option
																		}
																		// value={cityName.city}
																		value={
																			item.id
																		}
																		id={
																			item.id
																		}
																	>
																		{
																			item.grade
																		}
																	</option>
																);
															}
														)
													) : (
														<option
															className={
																Addbranchtcss.option
															}
															// value=""
															disabled
															selected
															value="default"
														>
															Select
														</option>
													)}
												</select>
												{/* <label
                    htmlFor="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label> */}
												<label
													htmlFor="cityId"
													className={
														selectedGrade === ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Grade
												</label>
												<label
													htmlFor="cityId"
													className={
														selectedGrade !== ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Grade
												</label>
											</div>
											{validation &&
												selectedGrade.length == 0 && (
													<span
														style={{
															color: "red",
															padding: "5px",
														}}
													>
														Grade is Required
													</span>
												)}
										</FormControl>

										{/* <FormControl
                      sx={{
                        width: { sm: 200, md: 270 },
                        "& .MuiInputBase-root": {
                          height: 48,
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        *Subject
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={selectedSubject}
                        onChange={(e) => {
                         subjectChangeHandler(e)
                        }}
                      >
                        {subjectList?.map((item) => (
                          <MenuItem key={item.subjectId} value={item.subjectId}>
                            <ListItemText primary={item.subject} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && selectedSubject.length==0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                       Subject is Required
                      </span>
                    )}
                    </FormControl> */}

										<FormControl>
											<div
												className={
													Addbranchtcss.input_element
												}
											>
												<select
													style={{ width: "240px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px" }}
													className={
														Addbranchtcss.form_select_element
													}
													name="cityId"
													id="cityId"
													value={selectedSubject}
													onChange={(e) =>
														subjectChangeHandler(e)
													}
													placeholder=" "
												>
													<option
														className={
															Addbranchtcss.option
														}
														// disabled
														selected
														value=""
													>
														Select
													</option>

													{subjectList.length > 0 ? (
														subjectList.map(
															(item, index) => {
																return (
																	<option
																		className={
																			Addbranchtcss.option
																		}
																		// value={cityName.city}
																		value={
																			item.subjectId
																		}
																		id={
																			item.subjectId
																		}
																	>
																		{
																			item.subject
																		}
																	</option>
																);
															}
														)
													) : (
														<option
															className={
																Addbranchtcss.option
															}
															// value=""
															disabled
															selected
															value="default"
														>
															Select
														</option>
													)}
												</select>
												{/* <label
                    htmlFor="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label> */}
												<label
													htmlFor="cityId"
													className={
														selectedSubject === ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Subject
												</label>
												<label
													htmlFor="cityId"
													className={
														selectedSubject !== ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Subject
												</label>
											</div>
											{validation &&
												selectedSubject.length == 0 && (
													<span
														style={{
															color: "red",
															padding: "5px",
														}}
													>
														Subject is Required
													</span>
												)}
										</FormControl>


										{/* <FormControl
											sx={{
												width: 300,
												".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
													{
														color: "#fd8c00 !important",
														backgroundColor: "#fff",
														fontSize:
															"16px !important",
														fontWeight:
															"500 !important",
														fontFamily: "Poppins",
													},
												".css-sk0lo5-MuiFormLabel-root-MuiInputLabel-root":
													{
														fontSize: "14px",
														fontFamily: "Poppins",
														color: "#B5B6B6",
														fontWeight:
															"500 !important",
													},
												".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root":
													{
														fontSize: "16px",
														fontFamily: "Poppins",
														color: "#fd8c00 !important",
														backgroundColor: "#fff",
														fontWeight:
															"500 !important",
													},
												".css-lxhl01-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
													{
														borderColor: "#000",
														borderWidth: 1,
													},
												".css-lxhl01-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
													{
														borderColor: "#808080",
													},
											}}
										>
											<InputLabel
												id="demo-multiple-checkbox-label"
												sx={{
													fontWeight: "bold",
													fontSize: "13px",
												}}
											>
												Subtopic
											</InputLabel>

											<Select
												sx={{
													borderRadius: "10px",
													borderColor: "black",
													borderWidth: "thick",
													width: "250px",
													height: "50px",
												}}
												labelId="demo-mutiple-checkbox-label"
												id="demo-mutiple-checkbox"
												multiple
												value={selectedSubtopic}
												name="first"
												onChange={(e) =>
													setSelectedSubtopic(
														e.target.value
													)
												}
												input={
													<OutlinedInput label="Tag" />
												}
												// renderValue={(selected) => selected.join(", ")}
												renderValue={(selected) =>
													selected
														?.map((obj) => {
															console.log(
																obj,
																"tty"
															);
															return subTopicData?.find(
																(item) => {
																	// console.log(item.branch,"kkj")
																	return (
																		item.subtopicId ===
																		obj
																	);
																}
															)?.subtopic;
														})
														.join(", ")
												}
											>
												{subTopicData?.map(
													(subtopic) => (
														<MenuItem
															key={
																subtopic.subtopicId
															}
															value={
																subtopic.subtopicId
															}
														>
															<Checkbox
																checked={
																	selectedSubtopic?.indexOf(
																		subtopic.subtopicId
																	) > -1
																}
															/>
															<ListItemText
																primary={
																	subtopic.subtopic
																}
															/>
														</MenuItem>
													)
												)}
											</Select>
											{validation &&
												selectedSubtopic.length === 0 &&
												subTopicData.length > 0 && (
													<span
														style={{
															color: "red",
															padding: "5px",
														}}
													>
														Subtopic is Required
													</span>
												)}
										</FormControl> */}

										<FormControl>
											<div
												className={
													Addbranchtcss.input_element
												}
											>
												<select
													style={{ width: "240px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px" }}
													className={
														Addbranchtcss.form_select_element
													}
													name="cityId"
													id="cityId"
													value={selectedSubtopic}
													onChange={(e) =>
														setSelectedSubtopic(
															e.target.value
														)
													}
													placeholder=" "
												>
													<option
														className={
															Addbranchtcss.option
														}
														// disabled
														selected
														value=""
													>
														Select
													</option>

													{subTopicData?.length > 0 ? (
														subTopicData?.map(
															(subtopic) => {
																return (
																	<option
																		className={
																			Addbranchtcss.option
																		}
																		// value={cityName.city}
																		value={
																			subtopic.subtopicId
																		}
																		id={
																			subtopic.subtopicId
																		}
																	>
																		{
																			subtopic.subtopic
																		}
																	</option>
																);
															}
														)
													) : (
														<option
															className={
																Addbranchtcss.option
															}
															// value=""
															disabled
															selected
															value="default"
														>
															Select
														</option>
													)}
												</select>
												{/* <label
                    htmlFor="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label> */}
												<label
													htmlFor="cityId"
													className={
														selectedSubject === ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Subtopic
												</label>
												<label
													htmlFor="cityId"
													className={
														selectedSubject !== ""
															? Addbranchtcss.std_cat
															: "NONE"
													}
												>
													*Subtopic
												</label>
											</div>
											{validation &&
												selectedSubtopic.length === 0 &&
												subTopicData.length > 0 && (
													<span
														style={{
															color: "red",
															padding: "5px",
														}}
													>
														Subtopic is Required
													</span>
												)}
										</FormControl>


									</div>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<label
										style={{
											display: "flex",
											flexDirection: "column",
											// gap: "10px",
											gap: "13px",
											// fontSize: "18px",
											fontSize: "13px",
											fontWeight: "bold",
											opacity: 1,
										}}
									>
										Upload Chapter Thumbnail
										<div>
											{uploadedFile ? (
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														gap: "5px",
													}}
												>
													<div
														style={{
															fontSize: "18px",
														}}
													>
														<span>
															<img
																src={
																	uploadedFile
																}
																alt="Thumb"
																width="75"
																height="75"
															/>
														</span>
													</div>
													<div
														onClick={() =>
															setUploadedFile("")
														}
														style={{
															display: "flex",
															fontSize: "15px",
															cursor: "pointer",
															color: "blue",
														}}
													>
														<span
															style={{
																borderBottom:
																	"1px solid blue",
															}}
														>
															Remove
														</span>
													</div>
												</div>
											) : (
												<>
													<UploadButton
														className="btn-choose"
														variant="outlined"
														component="span"
														style={{
															textTransform:
																"capitalize",
															fontSize: "14px",
														}}
													>
														{/* <UploadFileRoundedIcon /> */}
														<img
															src={C_img}
															alt="no-img"
															style={{
																marginRight:
																	"2px",
															}}
														/>
														Choose File
														<input
															type="file"
															accept={"image/*"}
															onChange={(e) =>
																uploadedFileHandler(
																	e
																)
															}
															hidden
														/>
													</UploadButton>
													{validation &&
														uploadedFile?.length ==
														0 && (
															<span
																style={{
																	color: "red",
																	padding:
																		"5px",
																}}
															>
																Thumbnail is
																Required
															</span>
														)}
												</>
											)}
										</div>
									</label>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											columnGap: "1rem",
										}}
									>
										<HoverGreyCancelButton
											variant="outlined"
											onClick={() => {
												hideAddChapter();
												setUploadedFile("");
												setIsEdit(false);
												setChapterName("")
											}}
										>
											Cancel
										</HoverGreyCancelButton>
										{isEdit ? (
											<BlueButton
												onClick={() =>
													editHandler(
														chapterName,
														selectedBoard,
														selectedGrade,
														selectedSubject,
														selectedSubtopic,
														uploadedFile,
														selectedItem?.id
													)
												}
											>
												Update
											</BlueButton>
										) : (
											<BlueButton
												onClick={() =>
													addChapterHandler(
														chapterName,
														selectedBoard,
														selectedGrade,
														selectedSubject,
														selectedSubtopic,
														uploadedFile
													)
												}
											>
												Create
											</BlueButton>
										)}
									</div>
								</div>
							</Paper>
						</div>
					)}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								color: "#606060",
								columnGap: "8px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									columnGap: "5px",
								}}
							>
								<img src={sub_map_Img} alt="" />
								<div
									style={{
										fontSize: "1vw",
										fontWeight: "600",
									}}
								>
									Chapter List
								</div>
							</div>
							<FormControl
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										fontSize: "14px !important",
										opacity: 0.9,
										lineHeight: 0.8,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "100px",
										height: "28px",
									},
								}}
							>
								<InputLabel id="board-select">
									Board
								</InputLabel>
								<Select
									labelId="board-select"
									id="board-select"
									sx={filterStyle}

									label="Board"
									value={selectedBoard}
									input={<OutlinedInput label="Board" />}
									onChange={selectBoardHandler}
									MenuProps={MenuProps}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props} sx={{ fontSize: "18px", }}
										/>
									)}
								>
									{boardList?.map((item) => (
										<MenuItem key={item.id} value={item.id}
											sx={{
												"&.Mui-selected": {
													backgroundColor: '#FFA500',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#FFA500',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}>
											<ListItemText
												sx={{ overflow: 'hidden !important', }}
												primary={item.board}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										fontSize: "14px !important",
										opacity: 0.9,
										lineHeight: 0.8,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "100px",
										height: "28px",
									},
								}}
							>
								<InputLabel id="select-grade">
									Grade
								</InputLabel>
								<Select
									size="small"
									labelId="select-grade"
									id="select-grade"
									sx={filterStyle}
									label="Grade"
									value={selectedGrade}
									input={<OutlinedInput label="Grade" />}
									onChange={selectGradeHandler}
									key={boardkey * 10}
									MenuProps={MenuProps}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props} sx={{ fontSize: "18px", }}
										/>
									)}
								>
									{gradeList?.map((item) => (
										<MenuItem key={item.id} value={item.id}
											sx={{
												"&.Mui-selected": {
													backgroundColor: '#FFA500',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#FFA500',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}>
											<ListItemText
												sx={{ overflow: 'hidden !important', }}
												primary={item.grade}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										fontSize: "14px !important",
										opacity: 0.9,
										lineHeight: 0.8,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "110px",
										height: "28px",
									},
								}}
							>
								<InputLabel id="select-subject">
									Subject
								</InputLabel>
								<Select
									labelId="select-subject"
									id="select-subject"
									sx={filterStyle}
									label="Subject"
									input={<OutlinedInput label="Subject" />}
									value={selectedSubject}
									onChange={selectSubjectHandler}
									key={boardkey * 20}
									MenuProps={MenuProps}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props} sx={{ fontSize: "18px", }}
										/>
									)}
								>
									{subjectList?.map((item) => (
										<MenuItem
											key={item.subjectId}
											value={item.subjectId}
											sx={{
												"&.Mui-selected": {
													backgroundColor: '#FFA500',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#FFA500',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}
										>
											<ListItemText
												sx={{ overflow: 'hidden !important', }}
												primary={item.subject}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										fontSize: "14px !important",
										opacity: 0.9,
										lineHeight: 0.8,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "110px",
										height: "28px",
									},
								}}
							>
								<InputLabel id="demo-select-small">
									Subtopic
								</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									sx={filterStyle}
									label="Subtopic"
									input={<OutlinedInput label="Subtopic" />}
									key={boardkey * 30}
									MenuProps={MenuProps}
									onChange={selectSubtopicHandler}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props} sx={{ fontSize: "18px", }}
										/>
									)}
								>
									{subTopicData?.map((item) => (
										<MenuItem
											key={item?.subtopicId}
											value={item?.subtopicId}
											sx={{
												"&.Mui-selected": {
													backgroundColor: '#FFA500',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#FFA500',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}
										>
											<ListItemText
												sx={{ overflow: 'hidden !important', }}
												primary={item?.subtopic}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								columnGap: "1rem",
							}}
						>
							{/* <TextField
                type={"seacrh"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              /> */}
							<div
								className={
									Studentdynamic.school_list_search_container_32
								}
							>
								<div
									className={
										Studentdynamic.school_list_search_container
									}
								>
									<input
										type="search"
										name="search"
										id="search"
										placeholder="Search..."
										onChange={onSearch}
										style={{
											width: "247px",
											height: "32px",
										}}
									/>
									<span>
										<img
											src={searchIcn}
											style={{
												verticalAlign: "top",
												fontSize: "large",
											}}
											aria-hidden="true"
											onClick={handleSearchbtn}
										/>
									</span>
								</div>
							</div>
							{!addChapterVisible && (
								<div
									className={dashboardContentStyles.add_Chapter_Btn}
									onClick={() => setAddChapterVisible(true)}
								>
									<img src={add_icon} />
									<div> Add Chapter</div>
								</div>
							)}
						</div>
					</div>
					<div className={regschool.school_list_table} style={{ maxHeight: "67vh" }}>
						<div className={regschool.tableContainer}>
							{/* <article className={regschool.main_container}> */}
							{/* <table className={`${regschool.table} ${regschool.table_h}`}> */}
							<table
								style={{
									width: '100%',
									height: 'auto',
									textAlign: 'center',
									borderRadius: '2px',
									borderCollapse: 'collapse',
									marginBottom: "90px"
								}}>
								<thead className={regschool.t_head} style={{ height: '50px' }}>
									<tr>
										<th scope="col" className={dashboardContentStyles.table_head} style={{ padding: "5px 10px" }}>
											#
										</th>
										<th scope="col" className={dashboardContentStyles.table_head} style={{ textAlign: "left" }}>
											Chapter Name{" "}
											<img
												style={{ verticalAlign: "middle", marginBottom: "2px" }}
												width="10"
												height="15"
												className="cursor-pointer"
												src={sortIcon}
												onClick={async () => {
													await setSortOrder(!sortOrder);
													await setSortBy("chapter");
													// await initData(!sortOrder, "id");
													await onSort(!sortOrder, "chapter");
												}}
											/>
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Board{" "}
											<img
												style={{ verticalAlign: "middle", marginBottom: "2px" }}
												width="10"
												height="15"
												className="cursor-pointer"
												src={sortIcon}
												onClick={async () => {
													await setSortOrder(!sortOrder);
													await setSortBy("board");
													await onSort(!sortOrder, "board")
												}}
											/>
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Grade{" "}
											<img
												style={{ verticalAlign: "middle", marginBottom: "2px" }}
												width="10"
												height="15"
												className="cursor-pointer"
												src={sortIcon}
												onClick={async () => {
													await setSortOrder(!sortOrder);
													await setSortBy("grade");
													await onSort(!sortOrder, "grade")
												}}
											/>
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Subject{" "}
											<img
												style={{ verticalAlign: "middle", marginBottom: "2px" }}
												width="10"
												height="15"
												className="cursor-pointer"
												src={sortIcon}
												onClick={async () => {
													await setSortOrder(!sortOrder);
													await setSortBy("subject");
													await onSort(!sortOrder, "subject")
												}}
											/>
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Subtopic{" "}
											<img
												style={{ verticalAlign: "middle", marginBottom: "2px" }}
												width="10"
												height="15"
												className="cursor-pointer"
												src={sortIcon}
												onClick={async () => {
													await setSortOrder(!sortOrder);
													await setSortBy("subTopic");
													await onSort(!sortOrder, "subTopic")
												}}
											/>
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Active{" "}
										</th>
										<th scope="col" className={dashboardContentStyles.table_head}>
											Action
										</th>
									</tr>
								</thead>
								<tbody >
									{filterValue?.length > 0 ? (
										filterValue?.slice(page * rowsPerPage,
											page * rowsPerPage + rowsPerPage)?.map((row, index) => {
												const backgroundColor = index % 2 === 0 ? '#ffffff' : '#f5f5f8';
												return (
													//  <tr className={`${regschool.data_row} ${regschool.data_tbody_row}`} key={index}

													//  >
													<tr style={{ backgroundColor, height: '50px' }} key={index}
														className={dashboardContentStyles.hover_row}
													>

														<td style={{ padding: "5px 10px", textAlign: "center" }}
															className={dashboardContentStyles.table_cell}

														>
															{page * rowsPerPage + (index + 1)}													</td>
														<td
														>
															<div
																key={index}
																className={dashboardContentStyles.table_celll}
																style={{ textAlign: "left" }}
															>
																{row?.chapter?.length > maxLength ? (
																	<Tooltip arrow title={row?.chapter} classes={{ tooltip: classes.tooltip }}>
																		<span>{`${row?.chapter.substring(0, maxLength)} ...`}</span>
																	</Tooltip>
																) : (
																	row?.chapter
																)}
															</div>
														</td>
														<td
															style={{ textAlign: "center" }}
														>
															<div
																key={index}
																className={dashboardContentStyles.table_celll}
															>
																{row.board}
															</div>
														</td>
														<td
															style={{ textAlign: "center" }}
														>
															<div
																key={index}
																className={dashboardContentStyles.table_celll}
															>
																{row.grade}
															</div>
														</td>
														<td
															style={{ textAlign: "center" }}
														>
															<div
																key={index}
																className={dashboardContentStyles.table_celll}
															>
																{row.subject}
															</div>
														</td>
														<td
															onClick={(e) => {
																e.preventDefault();
																showSubjectAndSubtopic(row.id)
															}}
															style={{ textAlign: "center" }}
														>
															<div
																key={index}
																className={dashboardContentStyles.table_celll}
															>
																{row.subTopic}
															</div>
														</td>
														<td style={{ textAlign: "Center" }}>
															<Switch
																checked={row?.active}
																onChange={(e) => {
																	switchHandler(row?.id, e);
																}}
															/>
														</td>
														<td
															className={dashboardContentStyles.table_celll}
															style={{ textAlign: "Center" }}
															onClick={() => {
																editClickHandler(row);
															}}
														>

															<Tooltip title="Edit" placement={"top"}

																arrow
																PopperProps={{
																	modifiers: [
																		{
																			name: 'arrow',
																			options: {
																				element: '[data-popper-arrow]',
																			},
																		},
																		{
																			name: 'offset',
																			options: {
																				offset: [0, -16],
																			},
																		}
																	],
																}}
																componentsProps={{
																	tooltip: {
																		sx: {
																			bgcolor: '#ffa500',
																			color: 'white',
																			boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
																			fontSize: '10px',
																			fontWeight: 500,
																			padding: '9px 20px',
																			borderRadius: '5px',

																		},
																	},
																	arrow: {
																		sx: {
																			color: '#ffa500',
																		},
																	},
																}}
															>
																{/* <EditOutlinedIcon style={{ fontSize: "1.7vw" }} /> */}
																<img
																	className={
																		dashboardContentStyles.aRoleActionBtn
																	}
																	src={action__Edit}
																	alt="Edit-Btn"
																	style={{ cursor: 'pointer' }}
																/>
															</Tooltip>
															{/* <img
																className={
																	dashboardContentStyles.aRoleActionBtn
																}
																src={
																	action__Edit
																}
																alt="Edit-Btn"
															/> */}
														</td>
													</tr>
												);
											})
									) : (
										<td
											// colSpan="10"
											style={{
												height: "15rem",
												display: "flex",
												alignItems: "start",
												textAlign: "center",
												position: "absolute",
												left: "40%",

											}}
										>
											NO DATA AVAILABLE
										</td>
									)}
								</tbody>
							</table>
						</div>
						{/* </article> */}
					</div>
					<TablePagination
						style={{
							bottom: "0",
							width: "82%",
							position: "fixed",
							backgroundColor: "white",
						}}
						rowsPerPageOptions={[5, 10, 25, 50]}
						component="div"
						count={filterValue?.length || 0}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPage}
						showFirstButton={true}
						showLastButton={true}
					/>
				</div>
				<ViewSubjectAndSubtopicModal
					open={visibleSubtopicModal}
					close={hideSubjectAndSubtopic}
					chapterData={selectedValue}
				/>
				<CommonDeleteModal
					open={visibleDeleteModal}
					close={closeDeleteModal}
					deleteTitle={"Chapter"}
					deleteHandler={deleteHandler}
					deleteId={selectedItem?.id}
					deleteName={selectedItem?.chapter}
				/>
				<ConfirmationModal
					open={toggleConfirmation}
					confirmationmessage={toggleMessage}
					close={handleConfirmationClose}
					updateToggle={updateToggle}
				/>
			</div>

			<Success_OK_Modal
				userType={"Chapter"}
				userName_Modal={chapterDisplayName}
				open={successCreateModal}
				handleOk={closeSuccessModal}
			/>

			<UpdatedModal
				userType={"Chapter"}
				userName_Modal={chapName}
				open={successModal}
				handleOk={closeModal}
				close={closeModal}
			/>
		</>
	);
};
export default Chapter;
