import React, { useEffect, useState } from "react";
import { Box, Modal, TablePagination } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { MenuProps } from "./Data";
import AssignHomeWork from "./AssignHomeWork";
import "./homeWork.css";
import HomeWorkListHead from "./HomeWorkListHead";
import { HomeWorkListBody } from "./HomeWorkListBody";
import { tableHead, tableHeadStudent } from "./Schema/schema";
import {
  deleteHomeWork,
  fetchHomeWorkAssignmentListView,
  toggleActiveStatus,
} from "./Apis/HomeWorkApis";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import ConfigurationSuccessModal from "../../../components/common/ConfigurationSuccessModal";
import BluePrintDeleteModal from "../../../components/common/BluePrintDeleteModal/BluePrintDeleteModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
  height: "600px",
  overflowY: "scroll",
};
const HomeWorkModal = ({
  open,
  close,
  boardId,
  gradeId,
  schoolId,
  branchId,
  subjectId,
  sectionId,
  subTopicId,
  chapterId,
  academicYearId,
  teacherId,
  role,
}) => {
  const [pageSize, setpageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [homeWorkListData, setHomeWorkListData] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState({});
  const [isCreated, setIsCreated] = useState(false);

  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("createdAt");

  const [successModalMessage, setsuccessModalMessage] = useState("");
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };

  const fetchHomeAssignment = async () => {
    setIsCreated(false);
    let pageNumber = page;
    const response = await fetchHomeWorkAssignmentListView(
      pageNumber,
      pageSize,
      boardId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      sectionId,
      subTopicId,
      sortBy,
      sortOrder,
      chapterId
    );
    setHomeWorkListData(response?.data);
    setTotalPage(response?.totalPages);
    setTotalElements(response?.totalElements);
    setCurrentPage(response?.pageNumber);
  };

  useEffect(() => {
    fetchHomeAssignment();
  }, [pageSize, page, isCreated, sortBy, sortOrder]);

  const handleToggle = async (e, id) => {
    const response = await toggleActiveStatus(e.target.checked, id);
    fetchHomeAssignment();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteHomeWork(selectedForDelete);
    setSelectedForDelete(null);
    setShowDeleteModal(false);
    fetchHomeAssignment();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const tableHeadData = role == "student" ? tableHeadStudent : tableHead;

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <div
            style={{
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
              padding: "15px",
              color: "#1912BF",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: role !== "student" ? "1px Solid #707070" : "",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            <div style={{ fontSize: 16 }}>Homework Assignment</div>
            <CloseOutlinedIcon
              sx={{ fontSize: "25px", cursor: "pointer" }}
              onClick={close}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "2rem",
            }}
          >
            <div />
            {role !== "student" ? (
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                }}
              >
                <AssignHomeWork
                  boardId={boardId}
                  gradeId={gradeId}
                  sectionId={sectionId}
                  schoolId={schoolId}
                  branchId={branchId}
                  subjectId={subjectId}
                  subTopicId={subTopicId}
                  chapterId={chapterId}
                  academicYearId={academicYearId}
                  teacherId={teacherId}
                  setSelectedForEdit={setSelectedForEdit}
                  selectedForEdit={selectedForEdit}
                  selectedDataForEdit={selectedDataForEdit}
                  setSelectedDataForEdit={setSelectedDataForEdit}
                  setsuccessModalMessage={setsuccessModalMessage}
                  setSuccessCreateModal={setSuccessCreateModal}
                  setIsCreated={setIsCreated}
                />
              </div>
            ) : null}
            <div
              style={{
                paddingTop: "10px",
                borderTop: "1px Solid #707070",
                marginRight: "20px",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              <table className="table-wrapper" style={{ marginTop: "15px" }}>
                <HomeWorkListHead
                  tableHeadData={tableHeadData}
                  setSortOrder={setSortOrder}
                  setSortBy={setSortBy}
                  sortOrder={sortOrder}
                />
                <HomeWorkListBody
                  pageSize={pageSize}
                  totalElements={totalElements}
                  currentPage={currentPage}
                  homeWorkListData={homeWorkListData}
                  handleToggle={handleToggle}
                  setShowDeleteModal={setShowDeleteModal}
                  setSelectedForDelete={setSelectedForDelete}
                  setSelectedForEdit={setSelectedForEdit}
                  setSelectedDataForEdit={setSelectedDataForEdit}
                  role={role}
                />
              </table>
              {homeWorkListData?.length > 0 && (
                <div style={{ marginTop: "5px" }}>
                  <span>
                    <TablePagination
                      style={{
                        bottom: "0",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={totalElements}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={pageSize}
                      onRowsPerPageChange={handleRowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Box>
        {showDeleteModal ? (
          <BluePrintDeleteModal
            userType={" Are you sure you want to delete ?"}
            open={showDeleteModal}
            handleOk={closeDeleteModal}
            onConfirm={onDeleteConfirm}
          />
        ) : null}
        <ConfigurationSuccessModal
          userType={successModalMessage}
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
      </>
    </Modal>
  );
};
export default HomeWorkModal;
