import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  fetchPlanDetails,
  fetchEnableAssignBtn,
} from "../../js/Cordinator/assignQuiz/api/fetchCalls";
import {
  isNOTNullOrUndefined,
  isNullOrUndefined,
} from "../../js/TeachValidate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Student_Image_Open_Modal from "../Student/Student_Image_Open_Modal";

const PassportPerformance = (props) => {
  // const [sortOrder, setSortOrder] = useState(false);
  // const [sortBy, setSortBy] = useState("id");
  const currentYear = new Date().getFullYear();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [performanceList, setPerformanceList] = useState();
  const [modifiedDate, setModifiedDate] = useState({});
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [gradeSection, setGradeSection] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedMonthYr, setSelectedMonthYr] = useState(`JAN-${currentYear}`);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isSelectedReview, setIsSelectedReview] = useState([]);
  const [modalContent, setModalContent] = useState([]);
  const [isNoReviewModal, setIsNoReviewModal] = useState(false);
  const [noReviewStudList, setNoReviewStudList] = useState([]);


  const navigate = useNavigate();

  const student_grade = sessionStorage.getItem("gradeName");

  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  var _month = 3;

  const handleChangeMonth = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);

    console.log("month >>> ", event.target.value, currentYear);

    switch (month) {
      case 1:
        _month = `JAN-${currentYear}`;
        console.log(">>>>>>>>>>>>>>.", _month);
        setSelectedMonthYr(`JAN-${currentYear}`);

        break;
      case 2:
        _month = `FEB-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 3:
        _month = `MAR-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 4:
        _month = `APR-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 5:
        _month = `MAY-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 6:
        _month = `JUN-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 7:
        _month = `JUL-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 8:
        _month = `AUG-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 9:
        _month = `SEP-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 10:
        _month = `OCT-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 11:
        _month = `NOV-${currentYear}`;
        setSelectedMonthYr(_month);

        break;
      case 12:
        _month = `DEC-${currentYear}`;
        setSelectedMonthYr(_month);

        break;

      default:
        console.log("Unknown month selected");
      // if(_month !=='' && _month.isNOTNullOrUndefined){
      //     fetchAvidList(_month);
      // }
    }
    console.log("selecc >>> ", selectedMonthYr);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder;
    setSortBy(field);
    setSortOrder(!isAsc);
  };

  const handleOpen = (data) => {
    setOpen(true);
    setModalContent(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewModal = (obj) => {
    setIsSelectedReview(obj)
    setIsView(!isView);
  };

  function countAndListStudentReviews(data) {
    let exactData = [];

    let item = {};

    let oneReviewCount = 0;
    let twoReviewCount = 0;
    let threePlusReviewCount = 0;
    let zeroReviewCount = 0;

    let studList = {};

    let oneReviewList = [];
    let twoReviewList = [];
    let threePlusReviewList = [];
    let zeroReviewStudents = [];
    let allReview = []
    let reviewObject = {};
    console.log(data, "main place to check")
    let noReviwStudList = data[0]?.studentNameList;
    item['sectionName'] = data[0]?.sectionName;

    data.forEach(student => {
      const reviews = student?.studentsReviews;
      item['gradeId'] = student?.gradeId;
      item['gradeName'] = student?.gradeName;
      item['sectionId'] = student?.sectionId;
      item['strength'] = reviews?.strength;
      item['studentId'] = student?.studentId;
      item['studentName'] = student?.studentName;
      let indexToRemove = '';

      // debugger
      if (reviews.oneReviews && reviews.oneReviews.count && reviews.oneReviews?.names?.length > 0) {
        // oneReviewCount += reviews?.oneReviews?.count;
        oneReviewCount += 1;
        reviews?.oneReviews?.names?.forEach(reviewObj => oneReviewList.push(reviewObj))
        studList['oneReviewStudents'] = student?.studentName;
        allReview.push(student?.studentName)
      } else if (reviews.twoReviews && reviews.twoReviews.count && reviews?.twoReviews?.names?.length > 0) {
        // twoReviewCount += reviews?.twoReviews?.count;
        twoReviewCount += 1;
        reviews?.twoReviews?.names?.forEach(reviewObj => twoReviewList.push(reviewObj))
        studList['twoReviewStudents'] = student?.studentName;
        allReview.push(student?.studentName)
      } else if (reviews.threePlusReviews && reviews.threePlusReviews.count && reviews?.threePlusReviews?.names?.length > 0) {
        // threePlusReviewCount += reviews?.threePlusReviews?.count;
        threePlusReviewCount += 1;
        reviews?.threePlusReviews?.names?.forEach(reviewObj => threePlusReviewList.push(reviewObj))
        studList['threePlusReviewStudents'] = student?.studentName;
        allReview.push(student?.studentName)
      } else {
        zeroReviewCount += 1;
        console.log(student?.studentName, "zero reviews");
        studList['zeroReviewStudents'] = student?.studentName || "";
        zeroReviewStudents.push(student?.studentName);
      }

      if (indexToRemove !== -1 && indexToRemove !== '') {
        noReviwStudList.splice(indexToRemove, 1);
      }

    });

    exactData.push({
      ...item,
      noReviwStudents: noReviwStudList,
      oneReview: {
        count: oneReviewCount,
        studentName: studList?.oneReviewStudents,
        reviewList: oneReviewList
      },
      twoReview: {
        count: twoReviewCount,
        studentName: studList?.twoReviewStudents,
        reviewList: twoReviewList
      },
      threePlusReview: {
        count: threePlusReviewCount,
        studentName: studList?.threePlusReviewStudents,
        reviewList: threePlusReviewList
      },
      zeroReview: {
        count: (item?.strength - (oneReviewCount + twoReviewCount + threePlusReviewCount)),
        reviewList: zeroReviewStudents,
        allReview: allReview
      },
    });

    return exactData || [];
  }

  // const reviewCounts = countAndListStudentReviews(dataObject);



  useEffect(() => {
    fetchPerformanceList();
  }, [
    selectedMonthYr,
    selectedSection,
    selectedGrade,
    selectedMonth,
    sortOrder,
    sortBy,
  ]);

  // const fetchPerformanceList = async () => {

  //   //  if (selectedMonth.isNOTNullOrUndefined) {
  //         publishPassportCDN();
  //     //}

  //     setTimeout(() => {

  //     }, 1000);
  //     const response = await axios.get(
  //         `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/getAvidReaderList?gradeId=${selectedGrade}&sectionId=${selectedSection}&month=${selectedMonthYr}&studentReviewFlag=true`,
  //         {
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //             },
  //         }
  //     );
  //     console.log('fetchAvidList >>>>', response?.data?.data.data);
  //     setPerformanceList(response?.data?.data.data);

  // };

  const fetchPerformanceList = async () => {
    //  if (selectedMonth.isNOTNullOrUndefined) {
    publishPassportCDN();
    //}

    setTimeout(async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/getAvidReaderList?gradeId=${selectedGrade}&sectionId=${selectedSection}&month=${selectedMonthYr}&studentReviewFlag=true&branchId=${sessionStorage.getItem("branchId")}`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/getAvidReaderList?month=${selectedMonthYr}&studentReviewFlag=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // console.log("fetchAvidList >>>>", response?.data?.data.data);
      // var currbranch = response?.data?.data?.data?.filter((v) => v.branchId === sessionStorage.getItem("branchId"))
      // console.log("currbranch", currbranch, response?.data?.data?.data, sessionStorage.getItem("branchId"), typeof sessionStorage.getItem("branchId"))
      let data = response?.data?.data?.data || [];

      data.sort((a, b) => {
        let valueA, valueB;


        if (
          ["noReviews", "oneReviews", "twoReviews", "threePlusReviews"].includes(
            sortBy
          )
        ) {
          valueA = a.studentsReviews[sortBy]
            ? a.studentsReviews[sortBy].count
            : 0;
          valueB = b.studentsReviews[sortBy]
            ? b.studentsReviews[sortBy].count
            : 0;
        } else if (sortBy === "grade" || sortBy === "section") {
          // Assuming gradeName and sectionName are the correct fields
          valueA = a[sortBy + "Name"];
          valueB = b[sortBy + "Name"];
        } else if (sortBy === "strength") {
          valueA = a?.studentsReviews?.strength;
          valueB = b?.studentsReviews?.strength;
        } else {
          // Fallback for other fields, including direct properties like 'dateOfSubmission'
          valueA = a[sortBy];
          valueB = b[sortBy];

          // Special handling for dates
          if (sortBy === "dateOfSubmission") {
            valueA = new Date(valueA);
            valueB = new Date(valueB);
          }
        }

        // Compare and return
        if (valueA < valueB) return sortOrder ? -1 : 1;
        if (valueA > valueB) return sortOrder ? 1 : -1;
        return 0;
      });

      const reviewCounts = countAndListStudentReviews(data);
      setPerformanceList((Array.isArray(data) && data.length === 0) ? data : reviewCounts);
    }, 1000);
  };

  /// CDN publish call
  const publishPassportCDN = async () => {
    const payload = {
      month: selectedMonth,
      year: currentYear,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/saveAvidReader`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    // alert(response);
    //console.log("publishSubjectCDN >>> ", response);
  };

  //auto populate filters start scripts
  const [isAutoPopulating, setIsAutoPopulating] = useState(false);
  useEffect(() => {
    if (isNOTNullOrUndefined(gradeSection) && gradeSection?.length) {
      startAutoPopulate();
    }
  }, [gradeSection]);

  const startAutoPopulate = async () => {
    fetchPlanDetails();
    fetchEnableAssignBtn();
    setIsAutoPopulating(true);
    setSelectedGrade(gradeSection[0]?.gradeId);
    setSelectedSection(gradeSection[0]?.sections[0]?.sectionId);
    console.log(
      "initial grade & section >>> ",
      gradeSection[0]?.gradeId,
      gradeSection[0]?.sections[0]?.sectionId
    );

    // alert("teach ");
    sessionStorage.setItem("gradeId", gradeSection[0]?.gradeId);
    sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0]?.sectionId);

    setIsAutoPopulating(false);
    // setTimeout(() => {
    //   setIsAutoPopulating(false);
    // }, 1000);
  };

  const fetchGrade = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    // const id = sessionStorage.getItem("teacherId");
    // const id = sessionStorage.getItem("userId");
    var id = sessionStorage.getItem("teacherId");
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/school-strength/${id}`
    if (localStorage.getItem("role") === "TEACHER") {
      id = sessionStorage.getItem("userId");
      URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/${id}`
    }
    // console.log("URLURL", URL, id)
    if (id && URL) {
      await axios
        .get(
          // `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
          // `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/${id}`
          URL,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response?.data?.data[0]?.grades)
          var sectionAvailable = response?.data?.data[0]?.grades;
          if (localStorage.getItem("role") === "TEACHER") {
            sectionAvailable = response?.data?.data?.access?.grades?.filter((v) => v.sections?.length > 0)
          }
          setGradeSection(sectionAvailable || [])
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    // fetchReadingBookData();
    GetLastModifiedAt(setModifiedDate);
  }, []);

  useEffect(async () => {
    const grade = await fetchGrade();
    const section = await fetchGrade();
    setSectionList(section);
    setGradeList(grade);
  }, []);

  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    sessionStorage.setItem("gradeId", e.target.value);
    setSelectedSection("");
    console.log("grade >>> ", e.target.value);
  };

  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGrade)) {
      const filtered = gradeSection?.find(
        (i) => i.gradeId === selectedGrade
      )?.sections;
      setSectionFilteredList(filtered);
    }
  }, [selectedGrade]);

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
    sessionStorage.setItem("sectionId", event.target.value);
    console.log("section >>> ", event.target.value);
    console.log("selecc >>> ", selectedMonthYr);
  };

  const handleNoReviewModal = (data) => {
    // console.log(data, "5133333", data?.noReviwStudents, data?.zeroReview?.allReview)
    // const resultArray = data?.noReviwStudents.filter(item => !data?.zeroReview?.allReview?.includes(item));
    // console.log(resultArray)
    isOpenNoReviewModal()
    setNoReviewStudList(data)
  }

  const isOpenNoReviewModal = () => {
    setIsNoReviewModal(!isNoReviewModal)
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div style={{ position: "relative", margin: "14px" }}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              id={`az-breadscrum-home`}
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/TeacherDashboard");
              }}
            >
              Home
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onClosePage();
                // props.pageRefresh();
                // props.useEffect();
              }}
            >
              Reading Passport
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span className={dashboardContentStyles.az_selected_menu_text}>
              Monthly - Performance
            </span>
          </div>

          {/* <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div> */}
        </article>

        <div
          style={{
            marginTop: "30px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedGrade && (
                    <InputLabel
                      id="az-teacher-gardes-select-label"
                      shrink={false}
                    >
                      Grades
                    </InputLabel>
                  )}

                  <Select
                    labelId="az-teacher-gardes-select-label"
                    id="az-teacher-gardes-select"
                    className={
                      selectedGrade
                        ? dashboardContentStyles.az_select_menu_selected
                        : dashboardContentStyles.az_select_menu
                    }
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedGrade ? "orange" : "black",
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "orange",
                        color: "#FFFFFF",
                        // border: "2px solid orange"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                        // border: "2px solid orange"
                      },
                    }}
                    size={"small"}
                    value={selectedGrade}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          // className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.grade
                            .toLowerCase()
                            .replace(/ /g, "_")}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "orange",
                              color: "#FFFFFF",
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: "#fcedc2",
                              color: "#000000",
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: "#fcedc2",
                              color: "#000000",
                            },
                          }}
                        >
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }}
                    className='dropDownLabel'
                    id="az-teacher-gardes-select-label"
                  >
                    Grade
                  </InputLabel>

                  <Select
                    labelId="az-teacher-gardes-select-label"
                    id="az-teacher-gardes-select"
                    input={<OutlinedInput label='Grade' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedGrade}
                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.gradeId}
                          key={item?.gradeId}
                          // className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.grade
                            .toLowerCase()
                            .replace(/ /g, "_")}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          {item.grade}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedSection && (
                    <InputLabel
                      id="az-teacher-section-select-label"
                      shrink={false}
                    >
                      Section
                    </InputLabel>
                  )}
                  <Select
                    labelId="az-teacher-section-select-label"
                    id="az-teacher-section-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "200px",
                      },
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "orange",
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: "#fcedc2",
                        color: "#000000",
                      },
                    }}
                    className={
                      selectedSection
                        ? dashboardContentStyles.az_select_menu_selected
                        : dashboardContentStyles.az_select_menu
                    }
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedSection ? "#ffa500" : "#000000",
                    }}
                    size={"small"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec.id}
                            key={sec.id}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.section
                              .toLowerCase()
                              .replace(/ /g, "_")}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "orange",
                                color: "#FFFFFF",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#fcedc2",
                                color: "#000000",
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: "#fcedc2",
                                color: "#000000",
                              },
                            }}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }}
                    className='dropDownLabel'
                    id="az-teacher-section-select-label"
                  >
                    Section
                  </InputLabel>
                  <Select
                    labelId="az-teacher-section-select-label"
                    id="az-teacher-section-select"
                    input={<OutlinedInput label='Section' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon
                        {...props}
                        sx={{ fontSize: "1.5vw" }}
                      />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec?.sectionId}
                            key={sec?.sectionId}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.section
                              .toLowerCase()
                              .replace(/ /g, "_")}`}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            {sec.section}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",
                    },
                  }}
                >
                  {!selectedMonth && (
                    <InputLabel id="month-select-label" shrink={false}>
                      Months
                    </InputLabel>
                  )}
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedMonth ? "#ffa500" : "#000000",
                    }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                  >
                    {months.map((month) => (
                      <MenuItem
                        key={month.id}
                        value={month.id}
                        id={`az-teacher-monthdropdown-${month.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "orange",
                            color: "#FFFFFF",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#fcedc2",
                            color: "#000000",
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: "#fcedc2",
                            color: "#000000",
                          },
                        }}
                      >
                        <ListItemText primary={month.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }} className='dropDownLabel' id="month-select-label" >
                    Month
                  </InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    input={<OutlinedInput label='Month ' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                          maxHeight: '190px',
                          '&::-webkit-scrollbar': {
                            width: '2px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px',
                          overflowY: 'auto',
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                  >
                    {months.map((month) => (
                      <MenuItem
                        key={month.id}
                        value={month.id}
                        id={`az-teacher-monthdropdown-${month.id}`}
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}
                      >
                        {month.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>

        <Paper
          style={{
            marginTop: "105px",
            width: "100%",
            padding: "10px",
          }}
        >
          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
              style={{ marginBottom: "50px" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade

                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Section

                    </TableCell>

                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Strength
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("strength")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      No Reviews
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("zeroReviews")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      1 Reviews
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("oneReviews")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      2 Reviews
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("twoReviews")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      3+ Reviews
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("threePlusReviews")}
                      />
                    </TableCell>
                  </TableRow>

                </TableHead>

                <TableBody sx={{}}>
                  {
                    console.log(performanceList, "performanceList")
                  }

                  {performanceList && performanceList?.length > 0 ? (
                    performanceList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((elem, i) => {
                        return (
                          <TableRow
                            // sx={{
                            //   "&:nth-child(even)": {
                            //     background: "#F5F5F8",
                            //   },
                            //   "&:hover": {
                            //     border: "10px solid red !important",
                            //     background: "#fff !important",
                            //   },
                            // }}
                            sx={{
                              // "&:nth-child(even)": {
                              //     background: "#F5F5F8"
                              // },
                              backgroundColor: i % 2 === 0 ? '#ffffff' : '#f5f5f8',
                              "&:last-child td, &:last-child th": {
                                borderBottom: "none !important",
                              },

                              "&:hover": {

                                "& td, & th": {
                                  borderTop: "1px solid #ff9702",
                                  borderBottom: "1px solid #ff9702",
                                },
                                " & th:first-of-type": {
                                  borderLeft: "1px solid #ff9702",
                                },

                                "& td:first-of-type": {

                                  borderLeft: "1px solid #ff9702",

                                },

                                "&:last-child td, &:last-child th": {
                                  borderBottom: "1px solid #ff9702 !important",
                                },

                                "& td:last-of-type": {
                                  borderRight: "1px solid #ff9702",

                                },

                                "& th:last-of-type": {
                                  borderRight: "0",
                                },
                              },

                              "& td, & th": {
                                border: "1px solid transparent",
                                borderTop: "1px solid transparent",
                                borderBottom: "1px solid transparent",
                                "&:not(:last-child)": {
                                  borderRight: "1px solid transparent",
                                },
                              },


                            }}

                          >
                            {/* index */}
                            <TableCell className={dashboardContentStyles.table_cell}>
                              {i + 1}
                            </TableCell>
                            {/* index */}
                            <TableCell className={dashboardContentStyles.table_cell}>
                              <Tooltip title={elem.gradeName} arrow>
                                <span>{elem.gradeName}</span>
                              </Tooltip>
                            </TableCell>
                            {/* index */}
                            <TableCell
                              className={dashboardContentStyles.table_cell}
                              align="center"
                            >
                              {elem.sectionName}
                            </TableCell>

                            {/* student strength */}
                            <TableCell
                              className={dashboardContentStyles.table_cell}
                              align="center"
                            >
                              {elem.strength}
                            </TableCell>
                            {/* 0 review */}
                            {elem.zeroReview !== null ? (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                                onClick={() => handleNoReviewModal(elem?.noReviwStudents)}
                                style={{ cursor: 'pointer' }}
                              >
                                {elem.zeroReview.count}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                              >
                                0
                              </TableCell>
                            )}

                            {/* 1+ review */}
                            {elem.oneReview !== null ? (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                                onClick={() => handleOpen(elem.oneReview?.reviewList)}
                                style={{ cursor: 'pointer' }}


                              >
                                {elem.oneReview.count}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                              >
                                0
                              </TableCell>
                            )}

                            {/* 2+ review */}
                            {elem.twoReview !== null ? (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                                onClick={() => handleOpen(elem.twoReview?.reviewList)}
                                style={{ cursor: 'pointer' }}

                              >
                                {elem.twoReview.count}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                              >
                                0
                              </TableCell>
                            )}

                            {/* 3+ review */}
                            {elem.threePlusReview !== null ? (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                                onClick={() => handleOpen(elem.threePlusReview?.reviewList)}
                                style={{ cursor: 'pointer' }}

                              >
                                {elem.threePlusReview.count}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={dashboardContentStyles.table_cell}
                                align="center"
                              >
                                0
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })

                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}

                </TableBody>

              </Table>
            </TableContainer>
            {/* <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={performanceList && performanceList?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            /> */}
          </Paper>
        </Paper>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
            Review Details
          </Typography>
          <Box id="modal-modal-description" sx={{ overflow: 'auto', maxHeight: 300, mt: 2 }}>
            {modalContent.length > 0 ? (
              <ul style={{ listStyleType: 'none', padding: 0, maxHeight: '300px', overflow: 'auto', scrollbarWidth: 'thin' }}>
                {modalContent.map((obj, index) => (<>
                  <li key={index} style={{ marginBottom: 8 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{`${index + 1}. ${obj?.names}`}</span>
                      <button className="view-btn" onClick={() => handleViewModal(obj?.bookDetails)}>view</button>
                    </div>
                  </li>
                </>
                ))}
              </ul>
            ) : (
              <Typography>No Names Available</Typography>
            )}
          </Box>
        </Box>
      </Modal>

      {isView && <StudentReviewListModal
        isView={isView}
        handleViewModal={handleViewModal}
        selectedReviewList={isSelectedReview} />}

      <NoReviewStudentListModal
        open={isNoReviewModal}
        handleClose={isOpenNoReviewModal}
        data={noReviewStudList}
      />
    </>
  );
};



export default PassportPerformance;


export const StudentReviewListModal = ({ isView, handleViewModal, selectedReviewList }) => {

  const keyToLabelMap = {

    characters: 'Characters ',
    favcharacters: 'Favourite Character ',
    rating: 'Star Value ',
    reviewtext: 'Review Text ',
    description: 'Describe the setting of the story ',
    summary: 'Summary of the Plot ',
    protagonist: 'Who is the protagonist? Describe the character ',
    antagonist: 'Who is the antagonist? Describe the character ',
    majorconflicts: 'What are the major conflicts within the story? ',
    minorconflicts: 'What are the minor conflicts within the story? ',
    turningpoint: 'What is the turning point or climax of the story? ',
    quote: 'Write a memorable quote/line/citation from the story and caption it ',
    motive: 'What is the motive that runs throughout the story? ',
    theme: 'What is the theme that runs throughout the story? ',
    storyend: 'How does the story end? ',
    recommendation: 'Would you recommend the book to somebody? Why or why not? ',
    comments: 'List five different words from the book and define them ',
    starRating: 'Rating ',
    storyFeedback: 'What I like/dislike about the story ',
    characterFeedback: 'What I like/dislike about the Main Character ',
    changes: 'What changes I want to make to the story ',
    maincharacters: 'Main Character ',
    otherCharacters: 'Other Characters '

  };

  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [indexValue, setIndexValue] = useState(0);

  const handlePrev = () => {
    setIndexValue(indexValue - 1);
  };

  const handleNext = () => {
    setIndexValue(indexValue + 1);
  };

  return <Dialog open={isView} onClose={handleViewModal}>
    <DialogTitle
      style={{
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "700",
        color: "#403e75",
        backgroundColor: "#f4f4f9",
        borderBottom: "1px solid #ccc",
        fontFamily: "Poppins, sans-serif",
        position: "relative",
      }}> Reading Passport Review
      <IconButton
        aria-label="close"
        onClick={handleViewModal}
        style={{
          color: "#403e75",
          padding: "8px",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      style={{
        margin: "20px",
        padding: "40px",
        height: "400px",
        overflowY: "auto",
        scrollbarWidth: "thin",
        backgroundColor: "#f4f4f9",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
          <span style={{ margin: "0 10px", backgroundColor: "#ffa500", color: "#fff", padding: "5px 10px", textTransform: "capitalize" }}>
            {selectedReviewList[indexValue]?.studentName.replace(/(?<!^)([A-Z])/g, ' $1').trim()}
          </span>
          <span style={{ margin: "0 10px", backgroundColor: "#ffa500", color: "#fff", padding: "5px 10px" }}>
            {selectedReviewList[indexValue]?.grade}
          </span>
          <span style={{ margin: "0 10px", backgroundColor: "#ffa500", color: "#fff", padding: "5px 10px" }}>
            {selectedReviewList[indexValue]?.section}
          </span>
        </div>

        <div style={{ borderTop: "3px solid #eee", paddingTop: "20px" }}>
          <form style={{ padding: "20px", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}>
            <Typography variant="h5" component="div" style={{ marginBottom: "10px", color: "#403e75", fontWeight: "bold" }}>
              {selectedReviewList[indexValue]?.titleOfBook || "No Title Available"}
            </Typography>

            {selectedReviewList[indexValue]?.author && (
              <Typography variant="subtitle1" component="div" style={{ marginBottom: "20px", color: "#555" }}>
                Author: {selectedReviewList[indexValue]?.author}
              </Typography>)}

            <Typography variant="h6" component="div" style={{ marginBottom: "20px", color: "#403e75" }} >
              Book Details
            </Typography>
            {selectedReviewList &&
              Object.entries({
                ...selectedReviewList[indexValue].bookDetails.book,
                ...selectedReviewList[indexValue].reviewRating.bookRating,
              }).filter(([key, value]) => value).length > 0 ? (
              Object.entries({
                ...selectedReviewList[indexValue].bookDetails.book,
                ...selectedReviewList[indexValue].reviewRating.bookRating,
              })
                .filter(([key, value]) => value)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      htmlFor={key}
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                        fontWeight: "bold",
                        // textTransform: "capitalize",
                      }}
                    >
                      {keyToLabelMap[key] || key.replace(/([A-Z])/g, " $1").trim()}:
                    </label>
                    <div
                      style={{
                        width: "100%",
                        minHeight: "50px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        color: "#666",
                        backgroundColor: "#f9f9f9",
                        overflowY: "auto",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {value}
                    </div>
                  </div>
                ))
            ) : (
              <Typography variant="body2" style={{ color: "#999" }}>
                No data available
              </Typography>
            )}
          </form>
        </div>

        {selectedReviewList[indexValue]?.imageUploaded && (
          <div style={{ textAlign: "center", marginTop: "20px", borderTop: "1px solid #eee", paddingTop: "20px" }}>
            <img src={selectedReviewList[indexValue]?.imageUploaded} alt="Uploaded Book"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                minHeight: '200px',
                maxHeight: '500px',
              }}
              onClick={() => {
                setSelectedImageUrl(selectedReviewList[indexValue]?.imageUploaded);
                setModalOpen(true);
              }}
            />
          </div>
        )}
        {indexValue !== 0 && <button className="button prev" onClick={handlePrev} style={{ left: '0px' }}>
          Prev
        </button>}
        {(selectedReviewList?.length - 1) > indexValue &&
          <button className="button next" onClick={handleNext} style={{ right: '0px' }}>
            Next
          </button>}
      </div>
      {/* )})} */}

    </DialogContent>
    <Student_Image_Open_Modal
      selectedImageUrl={selectedImageUrl}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
    />


    {/* <DialogActions>
            <Button
                onClick={handleCloseViewModal}
                sx={{
                    color: "#fff",
                    backgroundColor: "#5A4EAD",
                    "&:hover": {
                        backgroundColor: "#483D8B"
                    },
                }}
            >
                Close
            </Button>
        </DialogActions> */}
  </Dialog>
}


export const NoReviewStudentListModal = ({ open, handleClose, data }) => {

  return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: 300,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
        No Review Student List
      </Typography>
      <Box id="modal-modal-description" sx={{ overflow: 'auto', maxHeight: 300, mt: 2 }}>
        {data.length > 0 ? (
          <ul style={{ listStyleType: 'none', padding: 0, maxHeight: '210px', overflow: 'auto', scrollbarWidth: 'thin' }}>
            {data.map((obj, index) => (<>
              <li key={index} style={{ marginBottom: 8 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{`${index + 1}. ${obj}`}</span>
                </div>
              </li>
            </>
            ))}
          </ul>
        ) : (
          <Typography>No Names Available</Typography>
        )}
      </Box>
    </Box>
  </Modal>
}


