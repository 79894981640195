import axios from 'axios'

async function chapters_content_get(setChapterdetails) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageSize=100` ,
    {
        headers : {
            "Content-Type" : "appllication/json",
            Authorization : `Bearer ${token}`
    
        }
      })
      .then((res)=> {
        setChapterdetails(res.data.data.data)
      })
      .catch(err=> console.log(err))
    }
    
    export default chapters_content_get;