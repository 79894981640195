import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate } from "react-router-dom";
import teacherFilesStyles from "./../css/teacherFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import "./../css/switch.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import dashHome from "../../../src/img/dashHome.svg";
import wordIcon from "../../../src/img/word.png";
import excelIcon from "../../../src/img/excel.png";
import pdfIcon from "../../../src/img/pdfIcon.png";
import musicIcon from "../../../src/img/music-file.png";
import videoIcon from "../../../src/img/play.png";
import pptIcon from "../../../src/img/ppticon.png";
import openInNewTabIcon from "../../../src/img/resize.png";
// input choose file label image
import Cf_img from "../../../src/img/image_icon.png";
import { rightClickEnable, CDNBASEURL } from "../../../src/Serviceworker"
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import teacher_upload_chapter_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_upload_chapter_get";
import teacher_file_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_file_post";
import teacher_paginated_content_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_paginated_content_get";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Zoom,
  Fade,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import teacher_content_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_content_post";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ContentViewer from '../../components/common/ContentViewer';

const AddTeacherFiles = () => {
  const [chapters, setChapters] = useState({});
  const [docCategory, setDocCategory] = useState({});
  const [filterval, setFilterval] = useState({});
  const [updateddata, setUpdatedata] = useState({});
  const [chapterContent, setChapterContent] = useState({});
  const [isError, setIsError] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [success, setSuccess] = useState({});
  const [showError, setShowError] = useState("");
  const [teachercontent, setTeachercontent] = useState({});
  const [pageDetail, setPageDetail] = useState({});
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [chaptersList, setChaptersList] = useState({});
  const [teacherInputs, setTeacherInputs] = useState({
    active: "true",
    // chapterId: "",
    contentUrl: "",
    documentCategoryId: "",
    reviewStatus: "PENDING",
  });
  const [imgUploaderror, setImageUploadError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [row, setRow] = useState({});
  const [message, setMessage] = useState();
  const chapterId = sessionStorage.getItem("chapterId");
  //Screen responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent the default right-click behavior
  };

  const isLaptopScreen = windowWidth >= 1024 && windowWidth < 1440;

  const headingStyles = {
    width: isLaptopScreen ? '200%' : '249%',
    height: isLaptopScreen ? '630px' : '630px',
  };
  // Screen Responsive Done

  const navigate = useNavigate();

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };

  const handleTeacherInputs = (e) => {
    e.preventDefault();
    setTeacherInputs({
      ...teacherInputs,
      [e.target.name]: e.target.value,
    });
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/${selectedItem?.id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        teacher_paginated_content_get(setUpdatedata);
        hideDeleteModal();
      })
      .catch((err) => console.log(err));
    hideDeleteModal();

    // teacher_upload_chapter_get(setChapterContent);
  };

  const handleOpen = async (item) => {
    setOpenDeleteModal(true);
    // setRow(item);
    setSelectedItem(item)
    // const response = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/file-upload/confiramtion-api?id=${chapterId}`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //     },
    //   }
    // );
    // setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const uploadTeacherContent = async (e) => {
    e.preventDefault();
    for (const input in teacherInputs) {
      if (teacherInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all required Values");
        return;
      }
    }
    setIsError(false);

    await teacher_content_post(teacherInputs, setSuccess, () => {
      teacher_paginated_content_get(setUpdatedata);
      setTeacherInputs({
        active: "true",
        contentUrl: "",
        documentCategoryId: "",
        reviewStatus: "PENDING",
      });
    });
    setShow(true);
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: null,
    });
    sessionStorage.removeItem("contentUrl");
    publishCDN();
    // .catch(err => console.log(err))
    // navigate("/dashboard/upload%20content");
  };

  /// CDN publish call
  const publishCDN = async () => {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${CDNBASEURL}/v1/api/file/entity/upload-entity-json-s3?entityName=teacherchapter-content`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //alert(response);
    console.log("publishCDN >>> ", response);
  }



  const [fileName, setFileName] = useState("");
  const imageChange = (e) => {
    e.preventDefault();
    setFileName("");
    let file = null;
    const formData = new FormData();
    file = e.target.files[0];
    setFileName(file?.name);
    formData.append("file", file);
    teacher_file_post(
      setSelectedImage,
      formData,
      setTeacherInputs,
      teacherInputs,
      setImageUploadError
    );
  };

  const removeSelectedImage = (e) => {
    e.preventDefault();
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: null,
    });
    sessionStorage.removeItem("contentUrl");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => { };
    }
  }, [success]);

  const back = () => {
    navigate("/dashboard/Upload%20Content");
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: null,
    });
    sessionStorage.removeItem("contentUrl");
  };

  useEffect(() => {
    setFilterval(updateddata);
    if (updateddata && updateddata[0]?.documentTypes && updateddata[0]?.documentTypes[0].uploadContents.length && updateddata[0]?.documentTypes[0].uploadContents[0].contentUrl) {
      openIframe(updateddata[0]?.documentTypes[0].uploadContents[0].contentUrl);
    }
  }, [updateddata]);

  useEffect(() => {
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: sessionStorage.getItem("contentUrl"),
    });
    return () => { };
  }, [sessionStorage.getItem("contentUrl")]);

  useEffect(() => {
    teacher_paginated_content_get(setUpdatedata);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);
  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };

  useEffect(() => {
    // chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    teacher_upload_chapter_get(setChapterContent);
    chapters_list_getall(setChaptersList);
  }, []);
  console.log(updateddata, "chapter")
  return (
    <>
      <div style={{ width: "100%", marginRight: "15px" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => setShow(true)}

            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/upload%20content");
              }}
            >
              Content Admin - Upload Content
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Add Teacher Files
            </span>
          </div>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span
              onClick={() => setShow(true)}
              className={dashboardContentStyles.link_text}
            >
              Home
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <a>Content Admin</a>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Upload Content</a>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Add Teacher Files</a>
          </div> */}
        </article>

        {/* upload file row start  */}
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            paddingBottom: "60px",
            // minWidth: '1385px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
              marginTop: "69px",
            }}
          >
            <div className={teacherFilesStyles.training_container}>
              <div className={teacherFilesStyles.flex} style={{ width: "122%" }}>
                <div className={teacherFilesStyles.input_element}>
                  <select
                    className={teacherFilesStyles.form_input_element}
                    name="documentCategoryId"
                    id="cityName"
                    value={teacherInputs.documentCategoryId}
                    onChange={handleTeacherInputs}
                    placeholder=" "
                  >
                    <option className={teacherFilesStyles.option}>
                      select
                    </option>
                    {docCategory.length > 0 ? (
                      docCategory.map((docCategory, index) => {
                        return (
                          <option
                            className={teacherFilesStyles.option}
                            Value={docCategory.id}
                            id={docCategory.id}
                            placeholder=" "
                          >
                            {docCategory.documentType}
                          </option>
                        );
                      })
                    ) : (
                      <option className={teacherFilesStyles.option} value="">
                        Select
                      </option>
                    )}
                  </select>

                  <label style={{ fontWeight: "300" }} for="cityName">
                    *Document Category
                  </label>
                </div>

                <div className={createschool.upload_logo_container}>
                  <span style={{ fontSize: "14px" }}>*Upload File</span>
                  <div className={createschool.chooseFile_btn_wrapper_ATF}>
                    <img src={Cf_img} alt="img" />
                    <label
                      htmlFor="testId"
                      className={createschool.chooseFile_btn_ATF}
                    >
                      Choose File
                    </label>
                  </div>
                  <span className={createschool.custom_file_container}>
                    <input
                      type="file"
                      name="contentUrl"
                      style={{ width: "14rem", display: "none" }}
                      id="testId"
                      // id={sessionStorage.getItem("logoUrl")}
                      // filename={sessionStorage.getItem("logoUrl")}
                      Value={fileName}
                      // onError={setIsError(true)}
                      onChange={imageChange}
                      className={createschool.custom_file_input}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />

                    {teacherInputs.contentUrl ? (
                      <span>
                        {/* <img
                          src={teacherInputs.contentUrl}
                          alt="Thumb"
                          width="75"
                          height="75"
                        /> */}
                        {teacherInputs.contentUrl.endsWith(".jpg") ||
                          teacherInputs.contentUrl.endsWith(".jpeg") ||
                          teacherInputs.contentUrl.endsWith(".svg") ||
                          teacherInputs.contentUrl.endsWith(".png") ? (
                          <img
                            src={teacherInputs.contentUrl}
                            alt="Thumb"
                            width="75"
                            height="75"
                          />
                        ) : teacherInputs.contentUrl.endsWith(".doc") ||
                          teacherInputs.contentUrl.endsWith(".docx") ? (
                          <img src={wordIcon} width="34px" height="34px" />
                        ) : teacherInputs.contentUrl.endsWith(".xls") ||
                          teacherInputs.contentUrl.endsWith(".xlsx") ? (
                          <img src={excelIcon} width="34px" height="34px" />
                        ) : teacherInputs.contentUrl.endsWith(".pdf") ? (
                          <img src={pdfIcon} width="34px" height="34px" />
                        ) : teacherInputs.contentUrl.endsWith(".mp3") ||
                          teacherInputs.contentUrl.endsWith(".wav") ||
                          teacherInputs.contentUrl.endsWith(".aiff") ||
                          teacherInputs.contentUrl.endsWith(".aif") ||
                          teacherInputs.contentUrl.endsWith(".flac") ||
                          teacherInputs.contentUrl.endsWith(".ogg") ||
                          teacherInputs.contentUrl.endsWith(".wma") ||
                          teacherInputs.contentUrl.endsWith(".aac") ? (
                          <img src={musicIcon} width="34px" height="34px" />
                        ) : teacherInputs.contentUrl.endsWith(".mp4") ||
                          teacherInputs.contentUrl.endsWith(".mov") ||
                          teacherInputs.contentUrl.endsWith(".wmv") ||
                          teacherInputs.contentUrl.endsWith(".avi") ||
                          teacherInputs.contentUrl.endsWith(".flv") ||
                          teacherInputs.contentUrl.endsWith(".avchd") ||
                          teacherInputs.contentUrl.endsWith(".mkv") ||
                          teacherInputs.contentUrl.endsWith(".webm") ? (
                          <img src={videoIcon} width="34px" height="34px" />
                        ) : teacherInputs.contentUrl.endsWith(".pptx") ||
                          teacherInputs.contentUrl.endsWith(".pptm") ||
                          teacherInputs.contentUrl.endsWith(".ppt") ? (
                          <img src={pptIcon} width="34px" height="34px" />
                        ) : (
                          ""
                        )}
                        <span>
                          <a>
                            {teacherInputs.contentUrl.substring(
                              teacherInputs.contentUrl.lastIndexOf("_") + 1,
                              teacherInputs.contentUrl.length
                            )}
                          </a>
                          <br></br>
                          <br></br>
                          <a
                            onClick={removeSelectedImage}
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                          >
                            Remove This Image
                          </a>
                        </span>
                      </span>
                    ) : imgUploaderror ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {imgUploaderror}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                {/* </div> */}

                {/* btn */}
              </div>
            </div>
          </div>
          <div
            className={teacherFilesStyles.flex}
            style={{ position: "absolute", right: "50px" }}
          >
            <p
              style={
                isError
                  ? { display: "block", color: "red", paddingRight: "10px" }
                  : { display: "none" }
              }
            >
              {showError}
            </p>
            <BlueButton onClick={uploadTeacherContent}>UPLOAD</BlueButton>
          </div>
        </Paper>

        {/* upload file row start  */}

        {/* backbtn row start  */}
        <div
          className="teacherFilesStyles.flex"
          style={{ margin: "20px auto" }}
        >
          <div style={{ position: "absolute", right: "50px" }}>
            <WhiteAddButton onClick={back}> BACK</WhiteAddButton>
          </div>
        </div>
        {/* backbtn row start  */}

        {/* content start */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 75px",
            marginLeft: "15px",
          }}
          style={{ display: "flex" }}
        >
          <div
            style={{
              // width: "40%",
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table
              style={{
                width: "68%",
              }}
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={teacherFilesStyles.table_head}
                    align="left"
                    style={{
                      // margin: "8px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "30px", // set a maximum width for the cell
                      color: "#403e75"
                    }}
                  >
                    {/* {updateddata[0]?.chapterName} */}

                    {updateddata[0]?.chapterName.length > 30 ? (
                      <Tooltip style={{ color: "#403e75" }} title={updateddata[0]?.chapterName} arrow>
                        {updateddata[0]?.chapterName.slice(0, 30) + "..."}
                      </Tooltip>
                    ) : (
                      updateddata[0]?.chapterName
                    )}

                    {sessionStorage.getItem("chapterName")}

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      overflowY: "scroll",
                      height: "600px",
                      borderRight: "1px solid lightgrey",
                      position: "relative",
                    }}
                  >
                    {filterval[0]?.documentTypes &&
                      filterval[0]?.documentTypes.length > 0 ? (
                      filterval?.[0].documentTypes.map((item, i) => {
                        return (
                          <>
                            <Accordion defaultExpanded={true}>
                              <AccordionSummary
                                style={{
                                  backgroundColor: "#92A2E8",
                                  width: "100%",
                                }}
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography
                                  style={{ color: "white", marginLeft: "10px" }}
                                >
                                  {item.documentType} -{" "}
                                  {item.uploadContents?.length}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {item?.uploadContents ? (
                                  item.uploadContents.map((elem) => {
                                    return (
                                      <Accordion>
                                        <AccordionSummary
                                          style={{
                                            width: "100%",
                                            position: "relative",
                                          }}
                                        // expandIcon={<ExpandMoreIcon />}
                                        >
                                          <div
                                            style={{
                                              marginTop: "-13px",
                                              width: "332px",
                                              whiteSpace: "nowrap",
                                              overflow: "auto",
                                            }}
                                          >
                                            <Tooltip
                                              title={elem.contentWithIndux.length > 20 ? elem.contentWithIndux : null}
                                            >
                                              <a
                                                // href={elem.contentUrl}
                                                // download={elem.contentWithIndux}
                                                onClick={() => {
                                                  openIframe(elem?.contentUrl);
                                                }}
                                                style={{
                                                  textDecoration: "none",
                                                  fontSize: "1vw",
                                                  color: "grey",
                                                  left: "0px",
                                                  position: "absolute",
                                                  width: "250px",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  textAlign: "left",
                                                  marginLeft: "5px"

                                                }}
                                              >
                                                {elem.contentWithIndux.length > 20 ?
                                                  elem.contentWithIndux.slice(0, 20) + "..."
                                                  :
                                                  elem.contentWithIndux
                                                }
                                              </a>
                                            </Tooltip>
                                            <span
                                              style={{
                                                position: "absolute",
                                                right: "0",
                                              }}
                                            >
                                              <DeleteIconButton
                                                // disabled={elem.reviewStatus === "APPROVED"}
                                                onClick={() => {
                                                  // showDeleteModal(true);
                                                  // setSelectedItem(elem);
                                                  handleOpen(elem);
                                                }}
                                              >
                                                <Tooltip
                                                  title="Remove"
                                                  placement={"top"}
                                                >
                                                  <DeleteOutlinedIcon
                                                    style={{
                                                      fontSize: "1.7vw",
                                                    }}
                                                  />
                                                </Tooltip>
                                              </DeleteIconButton>
                                              {/* {elem.reviewStatus === "APPROVED" ?
                                                <DeleteIconButton style={{ marginRight: "0px" }}
                                                  disabled={elem.reviewStatus === "APPROVED"}
                                                >
                                                  <DeleteOutlinedIcon
                                                    style={{ fontSize: "25px" }}
                                                  />
                                                </DeleteIconButton> :
                                                <DeleteIconButton
                                                  disabled={elem.reviewStatus === "APPROVED"}
                                                  onClick={() => {
                                                    showDeleteModal(true);
                                                    setSelectedItem(elem);
                                                  }}
                                                >
                                                  <Tooltip title="Remove" placement={"top"}>
                                                    <DeleteOutlinedIcon
                                                      style={{ fontSize: "25px" }}
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              } */}
                                            </span>
                                            {/* <i style={{position:"absolute",right:"10px"}} class="bi bi-download"></i> */}
                                          </div>
                                        </AccordionSummary>
                                      </Accordion>
                                    );
                                  })
                                ) : (
                                  <p>-</p>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </TableBody>
            </Table>
          </div>
          <div>
            {url && (<ContentViewer contentstyle={{ height: "700px", width: "650px" }}
              url={url} />)}


            {/* {url && (
              url.includes(".pdf") ? (
               
                  <div style={{ height: "700px", width: "650px" }} onContextMenu={handleRightClick}>
                    <Viewer
                      fileUrl={{url}}
                      plugins={[defaultLayoutPluginInstance]}
                      defaultScale={SpecialZoomLevel.PageFit}
                    />
                  </div>
               
              ) : (
                <iframe
                  src={url}
                  style={headingStyles}
                ></iframe>
              )
            )} */}
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="New Tab"
              placement="right"
              arrow
            >
              <IconButton>
                <img
                  onClick={() => window.open(url)}
                  src={openInNewTabIcon}
                  height="20px"
                  width="20px"
                />
              </IconButton>
            </Tooltip>
          </div>
        </Paper >

        {/* content end */}
        < CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Content"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div >
    </>
  );
};

export default AddTeacherFiles;
