import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,

} from "@mui/material";
// import { MenuProps } from "./Data";
import { MenuProps } from "../../js/Data";

import { SearchOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import GetLastModifiedAt from "../../API/token-controller/GetLastModifiedAt";
import Principal_ViewContent from "./Principal_ViewContent";
import TopBar from "../../../components/common/TopBar";
import searchIcn from "../../../../src/img/search.svg";
// import dashboardContentStyles from "./../css/dashboardContent.module.css";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../../js/TeachValidate";//../js/TeachValidate
import ViewContentPage from "../../js/ViewContentPage";//./ViewContentPage
import dashHome from "../../../../src/img/dashHome.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const Principal_Teach = () => {
  const [showDetail, setShowDetail] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [showSubTopic, setShowSubTopic] = useState(true);
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [boardPageSize, setBoardPageSize] = useState(100);
  const [isError, setIsError] = useState(false);
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [query, setQuery] = useState("");
  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [teacherId, setTeacherId] = useState("");
  const [gradeSection, setGradeSection] = useState([]);

  useEffect(() => {
    // console.log("prin teach")
    fetchTeacherData(userName, role);
    const teacherData = fetchTeacherData(userName, role);
    setTeacherDetail(teacherData);
    GetLastModifiedAt(setModifiedDate);

    return () => {};
  }, []);

  // Student, teacher,only have access

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);

    setTeacherId(res.data.data.teacher);
  };

  // grade - section API

  const fetchGrade = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const id = teacherId.id;

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeSection(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  // subject - subtopic API

  const fetchSubject = async (selectedSection) => {
    // console.log("fetch subject called")
    const id = teacherId.id;
    // const id = '4028928886f00aec0186fda2e0a70004'

    await axios
      .get(
        `${
          process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${selectedGrade}${
          selectedSection ? `&sectionId=${selectedSection}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSubjectList(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  // Academic year api

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data || [];
  };

  const fetchChapterData = async (subjectId, gradeId) => {
    console.log(subjectId, gradeId)
    const bordId = sessionStorage.getItem("boardId");
    const response = await axios.get(
      `${
        process.env.REACT_APP_SWAGGER_URL
      }v1/api/master/chapters/all-chapters?subjectId=${subjectId}&gradeId=${gradeId}&boardId=${bordId}${
        isNOTNullOrUndefined(selectedSubTopic)
          ? `&subTopicId=${selectedSubTopic}`
          : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data || [];
  };

  const searchHandler = async (event) => {
    const value = await event?.target?.value?.toLowerCase();
    if (value?.length !== 0) {
      if (value?.length >= 3) {
        setIsError(false);
        const search = await fetchChapterBySearch(value);
        setFilterValue(search);
      } else {
        setIsError(true);
      }
    } else {
      setIsError(false);
      setFilterValue(updatedData);
    }
  };
  const fetchChapterBySearch = async (value) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=${pageSize}&sortOrder=true&search=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data?.data || [];
  };

  const fetchBoardData = async (pageSize) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/page?pageSize=100&sortOrder=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data?.data?.totalElements > pageSize) {
      setBoardPageSize(response?.data?.data?.totalElements);
    }
    return response?.data?.data?.data || [];
  };

  const fetchDataUsingChapterId = async (chapterId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=100&sortOrder=true&chapterId=${chapterId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.data || [];
  };

  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchDataUsingChapterId(selectedChapterId);
      if (menuData?.length !== 0) {
        setShowDetail(menuData[0]?.documentTypes);
        setShowContentModal(true);
      } else {
        setShowDetail([]);
      }
    }
  }, [selectedChapterId]);

  useEffect(async () => {
    if (selectedSubject && selectedGrade) {
      const chapterData = await fetchChapterData(
        selectedSubject,
        selectedGrade,
        selectedBoard,
        selectedSubTopic
      );
      setUpdatedData(chapterData);
      setFilterValue(chapterData);
    } else if (!selectedSubject && !selectedBoard && !selectedGrade) {
    }
  }, [
    selectedSubject,
    selectedSubTopic,
    selectedBoard,
    selectedGrade,
    pageSize,
  ]);

  useEffect(async () => {
    // const subject = await fetchSubject();
    const grade = await fetchGrade();
    // const grade = await fetchGradeData();
    const board = await fetchBoardData(boardPageSize);
    const section = await fetchGrade();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);
    setSectionList(section);
    setBoardList(board);
    setGradeList(grade);
    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
    // setSubjectList(subject);
  }, [teacherId.id]);
  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGrade)) {
      const filtered = gradeSection?.find(
        (i) => i.id === selectedGrade
      )?.sections;
      setSectionFilteredList(filtered);
    }
  }, [selectedGrade]);
  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    setSelectedSubject("");
    setSelectedSection("");
    setSelectedSubTopic("");
    fetchSubject(selectedSection);
    sessionStorage.setItem("gradeId", e.target.value);
  };

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
    fetchSubject(event.target.value);
    sessionStorage.setItem("sectionId", event.target.value);
  };

  const handleChangeSubject = (event) => {
    setSelectedSubject(event.target.value);
    console.log(event.target.value)
    const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
    console.log(selectedSubject)
    // Assuming selectedSubject has a hideSubtopics property that determines whether to show subtopics
    console.log(selectedSubject?.hideSubtopics,selectedSubject.subjectId)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("------false");
    } else {
      setShowSubTopic(true);
      // console.log("true")
      // alert("------true");
    }
    sessionStorage.setItem("subjectId", event.target.value);
  };

  const handleChangeSubTopic = (event) => {
    setSelectedSubTopic(event.target.value);
    sessionStorage.setItem("subTopicId", event.target.value);
  };

  const handleChangeAcademicYear = (event) => {
    setSelectedAcademicYear(event.target.value);
    sessionStorage.setItem("academicYearId", event.target.value);
  };

  // useEffect(() => {
  //   if(isNOTNullOrUndefined(selectedSubject){
  //     fetch
  //   }
  // }, [selectedSubject])

  return (
    <>
      {!showContentModal ? (
        <div style={{ width: "100%" }}>
          <TopBar
            title={"Home"}
            subSubTitle={"Teach"}
            modifiedDate={modifiedDate}
          />
          <div
            style={{
              marginTop: "10px",
              paddingRight: "33px",
              position: "absolute",
              right: "15px",
              left: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Grades</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                    }}
                    size={"small"}
                    value={selectedGrade}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                        >
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                    }}
                    size={"small"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec.id}
                            key={sec.id}
                            className={dashboardContentStyles.menu_item}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Subjects
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                    }}
                    size={"small"}
                    value={selectedSubject}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    // onChange={(e) => setSelectedSubject(e.target.value)}
                    onChange={handleChangeSubject}
                  >
                    {subjectList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.subjectId}
                          key={item.subjectId}
                          className={dashboardContentStyles.menu_item}
                        >
                          <ListItemText primary={item.subject} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
               {
                showSubTopic&&
                 <FormControl
                 sx={{
                   minWidth: "7.8vw",
                   background: "white",
                   borderRadius: 150,
                 }}
               >
                 <InputLabel id="demo-simple-select-label">
                   SubTopic
                 </InputLabel>
                 <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   input={<OutlinedInput label="Tag" />}
                   MenuProps={MenuProps}
                   style={{
                     borderRadius: "150px",
                     padding: "4px",
                   }}
                   size={"small"}
                   value={selectedSubTopic}
                   IconComponent={(props) => (
                     <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                   )}
                   onChange={handleChangeSubTopic}
                 >
                   {subjectList?.map((item) => {
                     return item.subTopics?.map((sub) => {
                       if (sub.hasOwnProperty("subTopic")) {
                         return (
                           <MenuItem
                             value={sub.id}
                             key={sub.id}
                             className={dashboardContentStyles.menu_item}
                           >
                             <ListItemText primary={sub.subTopic} />
                           </MenuItem>
                         );
                       }
                     });
                   })}
                 </Select>
               </FormControl>
               }
                <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                    }}
                    size={"small"}
                    value={selectedAcademicYear}
                    disabled
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeAcademicYear}
                  >
                    {academicYearList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                        >
                          <ListItemText primary={item.academicYear} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <TextField
                type={"text"}
                placeholder={"Search anything...."}
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        alt="searchIcon"
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
                size={"small"}
                sx={{
                  background: !isError && "#fff",
                  borderRadius: "150px",
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                  },
                }}
                error={isError}
                helperText={isError && "Please Enter min 3 character."}
              />
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: 700,
                width: "100%",
                padding: "25px 20px",
                overflow: "auto",
                scrollbarWidth:"thin"
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {selectedGrade && selectedSection && selectedSubject ? (
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {updatedData?.length > 0 ? (
                          updatedData
                            ?.filter((itm) =>
                              itm.chapter.toLowerCase().includes(query)
                            )
                            .map((item, i) => (
                              <Grid
                                item
                                xs={12}
                                md={2.4}
                                sm={4}
                                key={item.id}
                                onClick={() => {
                                  setSelectedChapterId(item?.id);
                                }}
                                // localStorage.setItem("slider", true);
                              >
                                <Item
                                  key={item?.id}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      minHeight: "166px",
                                    }}
                                    key={item?.id}
                                  >
                                    <img
                                      src={item?.thumbnailPath}
                                      width={110}
                                      style={{ maxHeight: "138px" }}
                                      alt={""}
                                    />
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        color: "#423d5c",
                                      }}
                                    >
                                      {item?.chapter}
                                    </div>
                                  </div>
                                </Item>
                              </Grid>
                            ))
                        ) : (
                          <div>No data for the selected filters</div>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    <div>Please Select Grade , Section and Subject</div>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <Principal_ViewContent
          menuType={"teach"}
          studyData={showDetail}
          closeModal={() => {
            setShowContentModal(false);
            setSelectedChapterId("");
            setShowDetail([]);
          }}
          selectedChapterId={selectedChapterId}
          pageName={"Teach"}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setSelectedAcademicYear={setSelectedAcademicYear}
          selectedAcademicYear={selectedAcademicYear}
        />
      )}
    </>
  );
};
export default Principal_Teach;
