import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
// Chart
import { Bar } from "react-chartjs-3";
import { Doughnut } from "react-chartjs-3";

//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import thumb_star from "../../../../src/img/thumbStar1.png";
import clock from "../../../../src/img/clockOrigibal.svg";
import msgIcon from "../../../../src/img/message-text.svg";

//Css Pages
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import "../../css/Student/Student_Reports_CR.css";

// Mui
import {
  Box,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Divider,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import ChapterQuizKeyModal from "./ChapterQuizKey/ChapterQuizKeyModal";
import Rating from '@mui/material/Rating';
import { styled } from '@mui/system';
import { log } from "util";
import CommonSort from "../../js/CommonSort"
function Student_Reports_CR() {
  //---------------| Last Update Time Starts |--------------------
  const navigate = useNavigate();
  const previousSelectedChapter = useRef("");
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const studentId = sessionStorage.getItem("studentId");
  const branchId = sessionStorage.getItem("branchId");
  const userName = localStorage.getItem("userName");
  const gradeId = sessionStorage.getItem("gradeId");
  const planId = sessionStorage.getItem("planId");
  const role = localStorage.getItem("role");
  const sectionId = sessionStorage.getItem("sectionId");

  const [subjectsList, setSubjectsList] = useState([]);
  const [subTopicsList, setSubTopicList] = useState([]);
  const [chapterNames, setChapterNames] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubTopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [noQuizMsg, setNoQuizMsg] = useState("");
  const [rating, setRating] = useState(0);

  const [realTimeQuizData, setRealTimeQuizData] = useState();
  const [doughtnutData, setDataForDoughnut] = useState();
  const [barLabels, setBarLabels] = useState();
  const [barPercentage, setBarPercentage] = useState();

  const [showModal, setShowModal] = useState(false);
  const [chapterKeyParams, setChapterKeyParams] = useState({});

  const StyledRating = styled(Rating)({
    '.MuiRating-decimal:first-of-type': {
      fontSize: 15,
      transform: 'translate(3px, 15px)',
    },
    '.MuiRating-decimal:nth-child(2)': {
      fontSize: 15,
      transform: 'translate(0px, 3px)',
    },
    '.MuiRating-decimal:nth-child(3)': {
      fontSize: 15,
      transform: 'translate(0px, -3px)',
    },
    '.MuiRating-decimal:nth-child(4)': {
      fontSize: 15,
      transform: 'translate(0px, 3px)',
    },
    // '.css-34he1w-MuiRating-decimal:nth-child(5)': {
    '.MuiRating-decimal:nth-child(5)': {
      fontSize: 15,
      transform: 'translate(-3px, 15px)',
    },
  })

  const handleChapterQuizKey = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getSubjectsList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data || [];
  }

  const fetchSubTopicData = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${id}`,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchChapterNames = async ({ subjectId, subTopicId }) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters-details?boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&subtopicId=${subTopicId}&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}&studentId=${studentId}`;

    if (subTopicId === undefined || subTopicId === null || subTopicId === '') {
      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters-details?boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}&studentId=${studentId}`;
    }
    const res = await axios.get(
      url,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    res.data.data = await CommonSort(res?.data?.data || [], "chapterName", false);
    return res?.data?.data || [];
  }

  const fetchChapterReport = async () => {
    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/real-time-quiz-graph?schoolId=${schoolId}&branchId=${branchId}&gradeId=${"4028928883193d8701831d2d8b850010"}&subjectId=${selectedSubject}&studentId=${studentId}&chapterId=${selectedChapter}`;
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/real-time-quiz-graph?schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${selectedSubject}&studentId=${studentId}&chapterId=${selectedChapter}&reportPage=true`
    const res = await axios.get(
      url,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data || [];
  }

  const handleSubject = (value) => {
    setChapterNames([]);
    setSubTopicList([]);
    setSelectedSubTopic('');
    setSelectedChapter('');
    setSelectedSubject(value);
    const subjects = subjectsList.filter(x => x?.subjectId == value);
    const subtopics = subjects?.length ? subjects[0]?.subTopics : [];
    setSubTopicList(subtopics?.length ? subtopics : []);
    setSelectedSubTopic(subtopics?.length ? subtopics[0]?.id : "");

    const fetchChapters = async () => {
      try {
        const chapterParams = {
          subjectId: value,
          subTopicId: subtopics?.length ? subtopics[0]?.id : ""
        }
        const chapters = await fetchChapterNames(chapterParams);
        if (chapters?.length > 0) {
          setChapterNames(chapters);
          setSelectedChapter(chapters?.length ? chapters[0]?.chapterId : '');
          setNoQuizMsg("");
        } else {
          setSelectedChapter('');
          setChapterNames([]);
          setNoQuizMsg("NO CHAPTERS ARE AVAILABLE");
        }
      } catch (error) {
        setSelectedChapter('');
        setChapterNames([]);
        setNoQuizMsg("ERROR FETCHING CHAPTER NAMES");
      }
    }
    fetchChapters();
  }

  const handleSubtopic = async value => {
    setSelectedSubTopic(value);

    try {
      const chapterParams = {
        subjectId: selectedSubject,
        subTopicId: value
      }
      const chapters = await fetchChapterNames(chapterParams);
      if (chapters?.length > 0) {
        setChapterNames(chapters);
        setSelectedChapter(chapters?.length ? chapters[0]?.chapterId : '');
        setNoQuizMsg("");
      } else {
        setSelectedChapter('');
        setChapterNames([]);
        setNoQuizMsg("NO CHAPTERS ARE AVAILABLE");
      }
    } catch (error) {
      setSelectedChapter('');
      setChapterNames([]);
      setNoQuizMsg("ERROR FETCHING CHAPTER NAMES");
    }
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    const fetchSubjectData = async () => {
      var subjects = await getSubjectsList();
      console.log("4-7-24 b4", subjects)
      subjects = subjects?.filter(v => v.skilledSubject === true) || [];
      console.log("aftr", subjects)
      setSubjectsList(subjects);
      setSelectedSubject(subjects?.length ? subjects[0]?.subjectId : '');

      const subtopics = subjects?.length ? subjects[0]?.subTopics : [];
      setSubTopicList(subtopics?.length ? subtopics : []);
      setSelectedSubTopic(subtopics?.length ? subtopics[0]?.id : "");

      try {
        const chapterParams = {
          subjectId: subjects?.length ? subjects[0]?.subjectId : '',
          subTopicId: subtopics?.length ? subtopics[0]?.id : ""
        }
        const chapters = await fetchChapterNames(chapterParams);
        if (chapters?.length > 0) {
          setChapterNames(chapters);
          setSelectedChapter(chapters?.length ? chapters[0]?.chapterId : '');
          setNoQuizMsg("");
        } else {
          setSelectedChapter('');
          setChapterNames([]);
          setNoQuizMsg("NO CHAPTERS ARE AVAILABLE");
        }
      } catch (error) {
        setSelectedChapter('');
        setChapterNames([]);
        setNoQuizMsg("ERROR FETCHING CHAPTER NAMES");
      }
    }

    fetchSubjectData();
    return () => { };
  }, []);

  {/*
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const chapters = await fetchChapterNames();
        if(chapters?.length > 0) {
          setChapterNames(chapters);
          setSelectedChapter(chapters?.length ? chapters[0]?.chapterId : '');
          setNoQuizMsg("");
        } else {
          setSelectedChapter('');
          setChapterNames([]);
          setNoQuizMsg("NO CHAPTERS ARE AVAILABLE");
        }
      } catch (error) {
        setSelectedChapter('');
        setChapterNames([]);
        setNoQuizMsg("ERROR FETCHING CHAPTER NAMES");
      }
    }
    fetchChapters();

  }, [selectedSubject, selectedSubtopic]);
  */}

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSubject && selectedChapter) {
        try {
          const paramData = {
            chapterId: selectedChapter,
            subjectId: selectedSubject,
            formal_assessment: false
          };
          setChapterKeyParams(paramData);
          const realTimeQuizGraphFetchData = await fetchChapterReport();

          if (!realTimeQuizGraphFetchData) {
            // Handle the case where the fetched data is undefined or empty.
            setNoQuizMsg("No data available");
            return;
          }
          setRealTimeQuizData(realTimeQuizGraphFetchData);
          setRating(realTimeQuizGraphFetchData?.rating);
          // Handle data for the chart
          const accuracyData = realTimeQuizGraphFetchData?.accuracyAnalysis || [];
          const difficultyData = realTimeQuizGraphFetchData?.difficultyAnalysis || [];

          setDataForDoughnut(
            accuracyData.map((item) => item?.accuracyPercentage || 0)
          );

          setBarLabels(
            difficultyData.map((item) => item?.taxonomyCategory || "Unknown")
          );

          setBarPercentage(
            difficultyData.map((item) => item?.percentage || 0)
          );
          setNoQuizMsg("");
        } catch (error) {
          console.error("Error fetching data:", error);
          setNoQuizMsg("UNABLE TO FETCH QUIZ REPORT");
        }
      } else {
        setNoQuizMsg("NO QUIZZES ARE RELEASED");
      }
    }

    if (selectedChapter !== previousSelectedChapter.current) {
      fetchData();
    } else {
      setNoQuizMsg("NO QUIZZES ARE RELEASED");
    }
    // Update the previousSelectedChapter ref with the current selectedChapter
    previousSelectedChapter.current = selectedChapter;
  }, [selectedSubject, selectedSubtopic, selectedChapter]);

  //---------------| Last Update Time Ends |--------------------
  //-------------Doughnut Chart Dummy--------------------------
  const data = {
    labels: ["Red", "Green", "Yellow"],
    datasets: [
      {
        // label: "My First Dataset",
        data: doughtnutData,
        backgroundColor: ["#00CE95", "#EB3E68", "#636A86AD"],
        borderWidth: 0,
      },
    ],
    text: "",
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "My Doughnut Chart",
      },
      position: "start",
      datalabels: false,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    cutoutPercentage: 60,
    tooltips: false,
  };
  //-------------Doughnut Chart Dummy Datas And Style--------------------------

  //--------------------Bar Chart Dummy Datas and Style--------------------------
  const dataBar = {
    labels: barLabels,
    datasets: [
      {
        // label: "Difficulty",
        data: barPercentage,
        backgroundColor: ["#58cced", "#3895d3", "#1261a0", "#346AFF", "#0091A5", "#62BB46"],
        barPercentage: barLabels?.length < 3 ? 0.5 : 1.2,
        categoryPercentage: 0.4,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: false,
          ticks: {
            min: 0,
            max: 100,
            stepSize: 25,
            fontColor: "#4988A6",
            padding: 10
          },
          gridLines: {
            drawTicks: false,
            color: "#9ac6d6",
            drawOnChartArea: false,
            lineWidth: 2
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            fontSize: 13,
            fontFamily: "Helvetica, Regular",
            fontColor: "#4988A6",
            padding: 10
          },
          gridLines: {
            color: "#9ac6d6",
            drawOnChartArea: false,
            lineWidth: 2,
            drawTicks: false
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            return Math.round(value) + "%";
          }
        },
        display: true,
        color: "#FFFFFF",
        align: "center",
        anchor: "center",
        font: { size: "13", weight: 600 },
      },
    },
    legend: {
      display: false,
    },
  };
  //--------------------Bar Chart Dummy Datas and Style--------------------------

  const simplifiedTime = (time) => {
    const parts = time?.split(' ');

    let hours = null;
    let minutes = null;
    let seconds = null;

    if (parts?.length) {
      for (const part of parts) {
        if (part.endsWith('Sec')) {
          seconds = part;
        } else if (part.endsWith('Mins') && part !== '00Mins') {
          minutes = part;
        } else if (part.endsWith('Hrs') && part !== '00Hrs') {
          hours = part;
        }
      }
    }


    return `${hours ? hours : ''} ${minutes ? minutes : ''} ${seconds}`
  }

  return (
    <>
      {/*---------------------------| Common Items Across The Project Starts Here |---------------------------*/}
      <article>
        <div style={{ position: 'absolute', margin: '14px' }}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
          >
            Reports
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Chapter Reports
          </span>
        </div>
        {/* <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate?.data}</p>
        </div> */}
      </article>
      {/*---------------------------| Common Items Across The Project Ends Here |---------------------------*/}

      <div className="Wrapper">
        {/*---------------------------| DropDown Division Starts Here |---------------------------*/}
        <div className="dropdown__div">
          {/*---------------------------| DD-1 |---------------------------*/}
          <div>
            <Box sx={{ minWidth: 120 }}>
              {/* <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#FD8C00 !important",
                    fontSize: "14px !important",
                    opacity: 0.9,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50px",
                    width: "148px",
                    height: "32px",

                  },

                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    top: '-10px',
                    '&.Mui-focused, &.MuiFormLabel-filled': {
                      top: 0,
                      color: '#FD8C00'
                    },
                    fontSize: '14px'
                  }}
                >
                  Select Subjects
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className={selectedSubject ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
                      style={{
                        borderRadius: "150px",
                        padding: "4px",
                        color: selectedSubject ? "orange" : "black",
                      }}
                  sx={{
                    "& .css-10hburv-MuiTypography-root": {
                      font: "normal normal normal 15px/15px Poppins !important",
                    },
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF",
                      border: "2px solid orange"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000",
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                      border: "2px solid orange"
                    },
                  }}
                  input={
                    <OutlinedInput
                      label="Tag"
                      sx={{
                        '& legend': {
                          fontSize: '2.15em'
                        }
                      }}
                    />
                  }
                 
                  size={"small"}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  value={selectedSubject}
                  onChange={(e) => { handleSubject(e.target.value) }}
                >
                  {subjectsList?.map((item) => {
                    return (
                      <MenuItem
                        value={item?.subjectId}
                        key={item?.subjectId}
                        //className={dashboardContentStyles.menu_item}
                        id={`az-student-subject-dropdown-${item.subjectId}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                      >
                        <ListItemText
                          primary={item?.subject}
                          sx={{
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis !important',
                            '& span': {
                              fontSize: '14px',
                              textOverflow: 'ellipsis !important',
                            }
                          }}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}

              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} className='dropDownLabel'
                  id="demo-simple-select-label"
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label='Subjects' />}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '150px',
                        marginTop: '2px',
                        maxHeight: '190px',
                        '&::-webkit-scrollbar': {
                          width: '2px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#555',
                        },
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: '0px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                  IconComponent={props => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                  )}
                  sx={{
                    width: '150px',
                    height: '30px',
                    padding: '10px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e7ab3c',
                    }
                  }}
                  className='dropdownSelect'
                  value={selectedSubject}
                  onChange={(e) => { handleSubject(e.target.value) }}
                >
                  {subjectsList?.map((item) => {
                    return (
                      <MenuItem
                        value={item?.subjectId}
                        key={item?.subjectId}
                        id={`az-student-subject-dropdown-${item.subjectId}`}
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}
                      >
                        {item?.subject}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          {/*---------------------------| DD-2 |---------------------------*/}
          {/* changes because subtopic should show irrespective of checkbox in admin- always should */}
          {/* {selectedSubtopic && <div> */}
          <div>
            <Box sx={{ minWidth: 120 }}>
              {/* <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#7A7A7A !important",
                    fontSize: "14px !important",
                    opacity: 0.9,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50px",
                    width: "100%",
                    height: "32px",
                  },
                  background: "white",
                  borderRadius: 150,
                  width: '200px',
                  height: '32px'
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    top: '-10px',
                    '&.Mui-focused, &.MuiFormLabel-filled': {
                      top: 0,
                      color: '#FD8C00'
                    },
                    fontSize: '14px'
                  }}
                >
                  Select SubTopic
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{
                    "& .css-10hburv-MuiTypography-root": {
                      font: "normal normal normal 15px/15px Poppins !important",
                    },
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF",
                      border: "2px solid orange"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000",
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                      border: "2px solid orange"
                    },
                  }}
                  input={
                    <OutlinedInput
                      label="Tag"
                      sx={{
                        '& legend': {
                          fontSize: '2.15em'
                        }
                      }}
                    />}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                    background: "white",
                    height: "32px",
                    color: '#FD8C00'
                  }}
                  size={"small"}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  value={selectedSubtopic}
                  onChange={(e) => { handleSubtopic(e.target.value) }}
                >
                  {subTopicsList?.map((item) => {
                    return (
                      <MenuItem
                        value={item?.id}
                        key={item?.id}
                       // className={dashboardContentStyles.menu_item}
                       id={`az-student-subtopic-dropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                      >
                        <ListItemText
                          primary={item?.subTopic}
                          sx={{
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis !important',
                            '& span': {
                              fontSize: '14px',
                              textOverflow: 'ellipsis !important',
                            }
                          }}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}

              <FormControl fullWidth >
                <InputLabel
                  sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} className='dropDownLabel'
                  id="demo-simple-select-label"> Sub Topic
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label='Sub Topic' />}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '150px',
                        marginTop: '2px',
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: '0px'
                      },
                    },
                  }}
                  IconComponent={props => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                  )}
                  sx={{
                    width: '150px',
                    height: '30px',
                    padding: '10px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e7ab3c',
                    }
                  }}
                  className='dropdownSelect'
                  value={selectedSubtopic}
                  onChange={(e) => { handleSubtopic(e.target.value) }}
                >
                  {subTopicsList?.map((item) => {
                    return (
                      <MenuItem
                        value={item?.id}
                        key={item?.id}
                        className={dashboardContentStyles.menu_item}
                        id={`az-student-subtopic-dropdown-${item.id}`}
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}
                      >
                        {item?.subTopic}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          {/* } */}
        </div>
        {/*---------------------------| DropDown Division Ends Here |---------------------------*/}

        <div className="u__line"></div>
        {/*---------------------------| CR Score-Card & Charts Div starts Here |---------------------------*/}
        <div className="CR_Wrapper">
          {!(noQuizMsg === 'NO CHAPTERS ARE AVAILABLE' || noQuizMsg === 'ERROR FETCHING CHAPTER NAMES') &&
            <div className="Score_and_Charts">
              {/*----------------------------| Score-Cards Div Starts Here |------------------------*/}
              <div className="Score_Cards">
                {/*----------------------------| Score-Card-1 |------------------------*/}
                {
                  chapterNames?.map(items => (
                    <div className="Score_Card" key={items?.chapterId}>
                      {
                        console.log("items", items, chapterNames)
                      }
                      <div style={{ textTransform: 'capitalize', fontFamily: 'poppins' }}>
                        {items?.chapterName}
                      </div>

                      <p className="score">You Scored</p>
                      <div
                        className="Progress_Bar_div"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={items?.mark}
                          style={{ flexGrow: 1, marginRight: "10px" }}
                          sx={{
                            width: "120px",
                            height: "6px",
                            borderRadius: "10px",
                            bgcolor: "#e0e0e0",
                            "& .MuiLinearProgress-bar": {
                              borderRadius: "10px",
                              bgcolor: items?.mark >= 80
                                ? "#00dafc"
                                : items?.mark >= 40
                                  ? "#00CB81"
                                  : items?.mark >= 35
                                    ? "#9977d7"
                                    : "#2b88f9"
                            },
                          }}
                        />
                        <span>
                          {`${Math.round(items?.mark)}%`}
                        </span>
                      </div>
                      <div
                        className="viewDetailsBtn"
                        id={`az-student-viewdetails-${items.chapterId}`}
                        onClick={e => setSelectedChapter(items?.chapterId)}
                      >
                        View Details
                      </div>
                    </div>
                  ))
                }
              </div>
              {/*----------------------------| Score-Cards Div Ends Here |------------------------*/}
              <div className={'h__line'}></div>
              {/*----------------------------| CR Charts Div Starts Here |------------------------*/}
              {!(noQuizMsg === 'UNABLE TO FETCH QUIZ REPORT' ||
                noQuizMsg === 'NO QUIZZES ARE RELEASED' ||
                noQuizMsg === 'No data available') &&
                <div className="CR_Charts_div">
                  <div>
                    {/*-----------| Header div |---------*/}
                    <div className="CR_Header_div">
                      <h5
                        style={{ textTransform: 'capitalize' }}
                      >
                        {realTimeQuizData && realTimeQuizData?.chapter}
                      </h5>

                      <div className="CR_Header_Img_Time_Score">
                        <div className="CR_Img_Score_div">
                          {/* <img src={thumb_star} width="54px" height="54px" /> */}

                          <div className="thumbsUP">
                            {/* <div className="stars" style={{display: 'flex'}}>
                              <span className="star1">
                                <StarIcon
                                  style={{
                                    fontSize: '12px',
                                    color:
                                      realTimeQuizData?.totalMarkPercentage >=10 ? '#ffa500' : '#9699ac'
                                  }}
                                />
                              </span>
                              <span className="star2">
                                <StarIcon
                                  style={{
                                    fontSize: '14px',
                                    color: realTimeQuizData?.totalMarkPercentage >=21 ? '#ffa500' : '#9699ac'
                                  }}
                                />
                              </span>
                              <span className="star3">
                                <StarIcon
                                  style={{
                                    fontSize: '16px',
                                    color: realTimeQuizData?.totalMarkPercentage >= 41 ? '#ffa500' : '#9699ac'
                                  }}
                                />
                              </span>
                              <span className="star4">
                                <StarIcon
                                  style={{
                                    fontSize: '14px',
                                    color: realTimeQuizData?.totalMarkPercentage >= 61 ? '#ffa500' : '#9699ac'
                                  }}
                                />
                              </span>
                              <span className="star5">
                                <StarIcon
                                  style={{
                                    fontSize: '12px',
                                    color: realTimeQuizData?.totalMarkPercentage >= 80 ? '#ffa500' : '#9699ac'
                                  }}
                                />
                              </span>
                          </div> */}
                            <h6 style={{ fontSize: 12 }}>
                              <StyledRating name="half-rating-read" value={rating} precision={0.25} readOnly size="small" />
                            </h6>
                            <div
                              className="like"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '-5px',
                                marginBottom: '5px'
                              }}
                            >
                              <img src={thumb_star} width="20px" height="20px" />
                            </div>
                          </div>
                          <h6>Score:{" "}
                            {/* {realTimeQuizData && realTimeQuizData?.totalMarkPercentage}% */}
                            {realTimeQuizData && realTimeQuizData?.accuracyAnalysis[0]?.accuracyPercentage}%
                          </h6>
                        </div>

                        <div className="CR_clock_img">
                          <img src={clock} width="34px" height="34px" />
                        </div>

                        <div
                          className="CR_Time_div"
                          style={{
                            width: '280px',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <h6> Time Taken:{" "}
                            {realTimeQuizData && simplifiedTime(realTimeQuizData?.timeTaken)}</h6>
                          <h6>Total Time:{" "}
                            {realTimeQuizData && realTimeQuizData?.duration}Mins</h6>
                        </div>
                      </div>
                    </div>

                    <Divider sx={{ width: "100%" }} />

                    {/*-----------| Charts div |---------*/}
                    <div className="CR_Chart_div">
                      <div>
                        <h6
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Accuracy Analysis
                        </h6>
                        <div className="CR_DNut_details">
                          <div className="CR_DnutChart">
                            <Doughnut
                              data={data}
                              legend={false}
                              options={options}
                              width="180px"
                              height="200px"
                            />
                          </div>
                          <div className="CR_details">
                            {realTimeQuizData &&
                              realTimeQuizData?.accuracyAnalysis &&
                              realTimeQuizData?.accuracyAnalysis.map((item, index) => {
                                return (
                                  <div>
                                    <h5>{item?.accuracyPercentage}%</h5>
                                    <h6>
                                      <span class={`dot${index + 1}`}></span>
                                      {item?.accuracyStatus} | {item?.accuracyCount} out
                                      of {realTimeQuizData?.totalMarks}
                                    </h6>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div>
                        <h6
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          Difficulty Analysis
                        </h6>
                        <Bar
                          data={dataBar}
                          options={optionsBar}
                          width={320}
                          height={195}
                        />
                      </div>
                    </div>
                    {
                      console.log("accuracy analysis", realTimeQuizData)
                    }
                    {realTimeQuizData && realTimeQuizData?.caseStudyAnalysis && (
                      <div className="Analysis_div_parent">
                        {realTimeQuizData &&
                          realTimeQuizData?.caseStudyAnalysis &&
                          realTimeQuizData?.caseStudyAnalysis?.map((item, index) => {
                            return (
                              <div className="Analysis_div">
                                <div className="Analysis_head">
                                  <h6>
                                    {item.caseStudyTitle} - Analysis{" "}
                                    <span className="info">&#9432;</span>
                                  </h6>
                                </div>

                                <div className="Analysis_Details">
                                  {item?.subQueByTaxonomy &&
                                    item?.subQueByTaxonomy?.map((itm, ind) => {
                                      return (
                                        <div className="detail_1">
                                          <h6>
                                            {itm?.taxonomy} - {itm?.attendanceCount} out
                                            of {itm?.actualSubQuesCount}
                                          </h6>
                                          <h6>{itm?.attendancePercentage}%</h6>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {/*-----------| Button div |---------*/}
                    <div className="CR_Analysis_Btn">
                      <button
                        id={`az-student-chapterquizkey`}
                        style={{ cursor: "pointer" }}
                        onClick={() => { handleChapterQuizKey() }}>
                        Chapter Quiz Key
                      </button>
                    </div>
                  </div>
                </div>
              }
              {/*----------------------------| CR Charts Div Ends Here |------------------------*/}
              {(noQuizMsg === 'UNABLE TO FETCH QUIZ REPORT' ||
                noQuizMsg === 'NO QUIZZES ARE RELEASED' ||
                noQuizMsg === 'No data available') &&
                <div
                  className={`${dashboardContentStyles.quizErrorMsg}`}
                  style={{ width: '73%' }}
                >
                  {noQuizMsg}
                </div>
              }
            </div>
          }
          {(noQuizMsg === 'NO CHAPTERS ARE AVAILABLE' || noQuizMsg === 'ERROR FETCHING CHAPTER NAMES') &&
            <div
              className={`
                ${dashboardContentStyles.quizErrorMsg}
                `}
            // ${dashboardContentStyles.quizErrorMsgPosition}
            >
              {noQuizMsg}
            </div>}
        </div>
        {/*---------------------------| CR Score-Card & Charts Div Ends Here |---------------------------*/}
        {/*---------------------------| Message Icon Division Starts Here |---------------------------*/}
        <div className="msgOuterDiv">
          <div className="msg__Icon_style">
            <img src={msgIcon} alt="msg_Icon" />
          </div>
        </div>
        {/*---------------------------| Message Icon Division Ends Here |---------------------------*/}
      </div>
      {showModal && (
        <ChapterQuizKeyModal showModal={showModal} closeModal={closeModal} paramsData={chapterKeyParams} />
      )}
    </>
  );
}

export default Student_Reports_CR;
