import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import users_forgotpassword_Email from "../API/user-controller/users_forgotpassword_Email";
import users_forgotpassword_Mobile from "../API/user-controller/users_forgotpassword_mobile";
import Addbranchtcss from "./../css/addbranch.module.css";
import axios from "axios";
import styles from "./../css/ForgetPassMob.module.css";
import Success_OK_Modal_SubjectMapping from "./Success_OK_Modal_SubjectMapping";
import {
  Alert,
  IconButton
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const ForgetPass = () => {
  // const [inputType, setInputType] = useState(true);
  const [mobile, setMobile] = useState("");

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isMobile, setIsMobile] = useState(true);
  const [showSend, setShowSend] = useState(false);
  const [extraction, setextraction] = useState(true)
  const [disableInput, setDisableInput] = useState(false);
  const [disableOtp, setDisableOtp] = useState(false)
  const [userEmail, setUserEmail] = useState([])
  const [otp, setOtp] = useState("");
  const [showLink, SetShowVLink] = useState(false);
  const [verfLink, SetVerfLink] = useState("");
  const [enteredOtpWrong, setEnteredOtpWrong] = useState(false);
  const [errorCreateModal, setErrorCreateModal] = useState(false);
  const [errorCreateMessage, setErrorCreateMessage] = useState("");
  const [enteredOtpMessage, setEnteredOtpMessage] = useState("");


  const token = sessionStorage.getItem("token");


  const navigate = useNavigate();

  const handleRadioMobile = () => {
    setShowSend(false)
    setOtp("")
    setEnteredOtpWrong(false)
    setEnteredOtpMessage("")
    setIsMobile(true);
    setMobile("");
    setEmail("");
    setError("");
    setextraction(true)
    setDisableInput(false)
    setUserEmail([])
  };
  const handleRadioEmail = () => {
    setShowSend(false)
    setOtp("")
    setEnteredOtpWrong(false)
    setEnteredOtpMessage("")
    setIsMobile(false);
    setEmail("");
    setMobile("");
    setError("");
    setextraction(true)
    setDisableInput(false)
    setUserEmail([])
  };

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }
  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }
  const verifyOTP = () => {
    console.log(verfLink)
    window.open(verfLink, '_blank');

  }
  const handleSubmitOTP = async (e) => {
    console.log("handlesubmit otp", e.target.value, otp, otp?.length);
    e.preventDefault();
    if (!otp?.length) {
      setEnteredOtpWrong(false)
    } else if (otp?.length < 4) {
      setEnteredOtpWrong(true);
      setEnteredOtpMessage("OTP not matching")
    } else if (!userEmail.length) {
      setError("Select user to proceed")
    } else if (otp) {
      var istrue = false;
      var item = { otp: otp, userName: userEmail };
      let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/forgot-password/verify-otp`, item, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => {
        console.log(response);
        console.log(response.data.data)

        if (response.data.data === "OTP not matching") {
          setEnteredOtpWrong(true);
          setEnteredOtpMessage(response.data.data)
        }
        else {
          const parts = response.data.data.split('#');
          if (parts.length === 2) {
            const userId = parts[0];
            const message = parts[1];
            sessionStorage.setItem("selectedUserID", userId)
            console.log(userId, message, parts)
            navigate("/setPass");
          } else {
            console.log(parts)
          }
        }

      }).catch(err => {
        console.log(err, err.response?.data?.message,);
        setEnteredOtpWrong(true);
        setEnteredOtpMessage(err.response?.data?.message)
      })
    } else {
      console.log(otp, userEmail, "=========")
    }
  }
  const handleSubmitMobile = (event) => {
    console.log(event)
    event?.preventDefault()
    setOtp("")
    if (!isMobile) {
      if (!validateEmail(email)) {
        // setError("Please enter a valid email address");
        return;
      }
    } else {
      if (!validateMobile(mobile)) {
        setError("please enter a valid mobile number");
        return;
      }
      users_forgotpassword_Mobile(mobile, userEmail).then(() => {
        setSuccessCreateModal(true)
      }).catch((error) => {
        console.error('Error:', error.response.data.message);
        setError(error.response.data.message);
        return;
      });
    }
  };

  const handleSubmitMobileSingleUser = (user) => {
    console.log(user)
    setOtp("")
    if (!isMobile) {
      if (!validateEmail(email)) {
        // setError("Please enter a valid email address");
        return;
      }
    } else {
      if (!validateMobile(mobile)) {
        setError("please enter a valid mobile number");
        return;
      }
      users_forgotpassword_Mobile(mobile, user).then(() => {
        setSuccessCreateModal(true)
      }).catch((error) => {
        console.error('Error:', error.response.data.message);
        setError(error.response.data.message);
        return;
      });
    }
  };

  const handleSubmitSelect = (event) => {
    event.preventDefault();
    if (!isMobile) {
      if (!validateEmail(email)) {
        // setError("Please enter a valid email address");
        return;
      }
    } else {
      if (!validateMobile(mobile)) {
        setError("please enter a valid mobile number");
        setDisableInput(false)
        return;
      }
      // navigate("/mobileotp");
      !isMobile && extractor(email)
      isMobile && extractor(mobile)
      setDisableInput(true)
      setError("")
    }
  };

  const [success, setSuccess] = useState({});
  const [dataPassword, setDataPassword] = useState({});
  const [userLink, setUserLink] = useState("")
  const handleSubmitEmail = (event) => {
    console.log(event)
    event.preventDefault();
    if (!isMobile) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address");
        return;
      }
      users_forgotpassword_Email(email, userEmail, setSuccess, setDataPassword, setUserLink)
        .then(() => {
          // userLink && window.open(userLink,  "noreferrer");
          setSuccessCreateModal(true)
        }).catch((error) => {
          console.error('Error:', error);
          setError(error.response.data.message);
        });

      // window.location = "/setPass";
      // navigate("/setPass");
    } else {
      if (!validateMobile(mobile)) {
        // setError("please enter a valid mobile number");
        return;
      }
      // window.location = "/mobileotp";
    }
  };
  const handleSubmitEmailSingleUSer = (User) => {
    console.log(User)
    // console.log(event)
    // event.preventDefault();
    if (!isMobile) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address");
        return;
      }
      users_forgotpassword_Email(email, User, setSuccess, setDataPassword, setUserLink)
        .then(() => {
          // userLink && window.open(userLink,  "noreferrer");
          setSuccessCreateModal(true)
        }).catch((error) => {
          console.error('Error:', error);
          setError(error.response.data.message);
        });

      // window.location = "/setPass";
      // navigate("/setPass");
    } else {
      if (!validateMobile(mobile)) {
        // setError("please enter a valid mobile number");
        return;
      }
      // window.location = "/mobileotp";
    }
  };

  const handleSubmitSelectEmail = (event) => {
    setDisableInput(true)
    event.preventDefault();
    if (!isMobile) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address");
        return;
      }
      extractor(email)
      // window.location = "/setPass";
      // navigate("/setPass");
    } else {
      if (!validateMobile(mobile)) {
        // setError("please enter a valid mobile number");
        return;
      }
      // window.location = "/mobileotp";
    }
  };

  useEffect(() => {
    if (success.success === true) {
      setError(success.message);
    }
  }, [success]);
  const [data, setData] = useState([])

  function extractor(email) {

    let token = sessionStorage.getItem("token");
    if (email) {
      let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/extracting-user-name?extractor=${!mobile ? email.toLowerCase() : mobile}`,
        {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          // res.data.data = []
          console.log(res.data.data, "res.data.data")
          if (res?.data?.data?.length == 1) {
            setextraction(false)
            setError("")
            setUserEmail(res.data.data[0]);
            setTimeout(() => {
              if (isMobile) {
                handleSubmitMobileSingleUser(res.data.data[0])
              } else {
                handleSubmitEmailSingleUSer(res.data.data[0])
              }
            }, 500);
          } else if (res?.data?.data?.length == 0) {
            setextraction(true)
            var mode=isMobile ? "mobile." : "email."
            setError("No user associated with this " +mode)
          }
          else {
            setError("")
            setShowSend(true)
            setextraction(false)

          }
          setData(res.data.data)
        })
        .catch(err => console.log(err))
    }
  }
  // useEffect(() => {
  //   extractor()
  // }, [email,mobile])
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };

  const handleMobile = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setMobile(result)
    setextraction([])
  }

  const handleOtp = (e) => {
    console.log(e.target.value)
    const result = e.target.value;
    setOtp(result);
  }

  const handleEmail = (e) => {
    // const lowercaseValue = e.target.value.toLowerCase()
    setEmail(e.target.value)
    setextraction([])
  }


  const mobileStyle = {
    top: "15px",
    left: "57px"
  }

  const emailStyle = {
    left: "15px",
    top: "25px"
  }

  return (
    <>
      <div className={styles.main}>
        <div className={styles.navbar}>
          <div className={styles.icon}>
            <h2 className={styles.logo}>
              <img src="/img/Azvasa_logo.png" />
            </h2>
          </div>
        </div>
        <div className={styles.content}>
          <img src="/img/ForgotPassword.png" className={styles.login_img} />
          <form className={styles.login_form}>
            <div className={styles.login_form_title}>
              <h2>Forgot Password</h2>
              <h3>Enter your registered Email/Mobile No.</h3>
            </div>
            <div className={styles.mobile_email} style={{ paddingBottom: "10px" }}>
              <span className={styles.radio_funcionality}>
                <input
                  type="radio"
                  name="userInput"
                  id="userMobile"
                  value="Mobile"
                  checked="checked"
                  onChange={handleRadioMobile}
                  className={isMobile && styles.radio_active}
                />
                <label>&nbsp;&nbsp; Mobile</label>
              </span>
              <span className={styles.radio_funcionality}>
                <input
                  type="radio"
                  name="userInput"
                  id="userEmail"
                  value="Email"
                  onChange={handleRadioEmail}
                  className={!isMobile && styles.radio_active}
                />
                <label>&nbsp;&nbsp;Email</label>
              </span>
            </div>
            <div className={styles.mobile_email}>
            </div>

            <div className={styles.password_container}>
              <legend style={isMobile ? mobileStyle : emailStyle} id="mobOrEmail">{isMobile ? "Mobile" : "Email"}</legend>
              {isMobile ? (<div style={{ display: "flex", justifyContent: "space-around" }}>
                <select disabled style={{ width: "60px", height: "48px", marginRight: "2px" }} value={'+91'}>
                  <option value="+91">+91</option>
                </select>
                <input
                  type="tel"
                  name="userMobile"
                  className={styles.input_elementx}
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  required
                  id="mobOrEmail2"
                  placeholder="1234567890"
                  disabled={false}//disableInput
                  maxLength={10}
                  value={mobile}
                  onChange={(e) => handleMobile(e)}
                /></div>
              ) : (
                <input
                  type="email"
                  name="userEmail"
                  className={styles.input_element}
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  required
                  id="mobOrEmail2"
                  placeholder="example@gmail.com"
                  value={email}
                  disabled={false}//disableInput
                  onChange={(e) => handleEmail(e)}
                />
              )}
              {/* <p className="test_denger p-2">{error}</p> */}
              <p className={styles.verification_link} id="mobOrEmail3" style={{ marginBottom: "40px" }}>
                {isMobile
                  ? "The OTP will be sent to your Registered Mobile Number"
                  : "The OTP will be sent to your Registered Email address"}
              </p>
              {(!extraction && data?.length >1) &&
                <div style={{ marginTop: "10px", cursor: "pointer" }}
                  className={
                    Addbranchtcss.input_element
                  }
                >
                  <select
                    style={{ width: "100%", cursor: "pointer" }}
                    className={
                      Addbranchtcss.form_select_element
                    }
                    name="cityId"
                    id="cityId"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(
                        e.target.value
                      );
                      setOtp("");
                      localStorage.setItem("selectedUserInfo", e.target.value)
                    }
                    }
                    placeholder=" "
                  >
                    {
                      data?.length > 1 &&
                      <option
                        className={
                          Addbranchtcss.option
                        }
                        // disabled
                        selected
                        value=""
                      >
                        Select
                      </option>
                    }

                    {data?.length > 0 && (
                      data?.map(
                        (data) => {
                          return (
                            <option
                              className={
                                Addbranchtcss.option
                              }
                            >
                              {
                                data
                              }
                            </option>
                          );
                        }
                      )
                    )}
                  </select>

                  <label
                    htmlFor="cityId"
                    className={
                      data === ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Users
                  </label>
                  <label
                    htmlFor="cityId"
                    className={
                      data !== ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Users
                  </label>
                </div>}
              <legend
                style={{
                  color: "red",
                  position: "absolute",
                  top: "140px",
                  left: "-5px",
                }}
              >
                {error}
              </legend>
            </div>
            {(!extraction && showSend && ( mobile || email)) && <div className={styles.login_container} style={{ padding: "10px" }}>
              {isMobile ? (
                <button style={{ cursor: "pointer" }} id="send1"
                  className={styles.login_btn}
                  onClick={handleSubmitMobile}
                >
                  {/* <Link to='/mobileotp'> <a>  </a></Link> */}
                  Send
                </button>
              ) : (
                <button style={{ cursor: "pointer" }} id="send2"
                  className={styles.login_btn}
                  onClick={handleSubmitEmail}
                >
                  Send
                  {/* <Link to={userLink}><a>Send</a></Link> */}
                </button>
              )}
            </div>}

            {extraction &&
              <div className={styles.login_container} style={{ padding: "10px" }}>
                {isMobile ? (
                  <button style={{ cursor: "pointer", padding: "5px" }}
                    className={styles.login_btn}
                    onClick={handleSubmitSelect}
                  >
                    Select
                  </button>
                ) : (
                  <button style={{ cursor: "pointer", padding: "5px" }}
                    className={styles.login_btn}
                    onClick={handleSubmitSelectEmail}
                  >
                    Select
                  </button>
                )}
              </div>
            }

            <div className={styles.password_container}>
              <legend style={isMobile ? mobileStyle : emailStyle} id="mobOrEmail">{"OTP"}</legend>
              <input
                type="otp"
                name="otp"
                className={styles.input_elementx}
                // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                required
                id="pwd-otp"
                placeholder="OTP"
                disabled={disableOtp}
                minLength={4}
                maxLength={6}
                value={otp}
                onChange={(e) => handleOtp(e)}
              />
              <p className={enteredOtpMessage ? styles.verification_link_danger : styles.verification_link} id="Otp-message">
                {enteredOtpWrong ? enteredOtpMessage : "Please enter the OTP once you receive"}
              </p>
            </div>
            <button
              className={styles.verify_btn}
              onClick={handleSubmitOTP}
            >
              <a> Verify OTP </a>
            </button>

            {/* {
              showLink &&
              <div>
                <a onClick={verifyOTP}
                  className="verify_url"
                  style={{ cursor: "pointer", color: "black", textDecoration: "underline" }}
                >Click to Verify </a>
              </div>
            } */}


          </form>
        </div>


        <Success_OK_Modal_SubjectMapping
          userType=
          {isMobile
            ? "OTP sent to Mobile Number"
            : "OTP sent to E-mail"}
          // userName_Modal={
          //   "Subject Mapping"
          // }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
      </div>
    </>
  );
};

export default ForgetPass;
