import React, { useEffect, useState } from "react";
import mainContainerStyles from "../../../css/mainContainer.module.css";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Avatar,
	Badge,
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
  } from "@mui/material";

const MainContainer = (props) => {
	const [contentDevOrAdmin, setContentDevOrAdmin] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [topTitle, setTopTitle] = useState("Dashboard");
	const open = Boolean(anchorEl);
	const location = useLocation();
	useEffect(() => {
		if (location.pathname) {
			const path = location.pathname.split("/");
			const title = path.includes("ConceptTreeView")
				? path[path.length - 2].replaceAll("-", " ")
				: path[path.length - 1].replaceAll("-", " ");
			setTopTitle(
				title === "template"
					? "Dashboard"
					: title === "content approval"
					? "Content Approval"
					: title === "template"
					? "Dashboard"
					: title === "global training approval"
					? "Global Training Approval"
					: title === "template"
					? "Dashboard"
					: title === "concept tree approval"
					? "Concept Tree Approval"
					: title === "template"
					? "Dashboard"
					: title === "view concept tree"
					? "View Concept Tree"
					: title === "template"
					? "Dashboard"
					: title === "csp"
					? "Change Student Profile"
					: title === "template"
					? "Dashboard"
					: title === "branchplan"
					? "Plan"
					: title === "template"
					? "Dashboard"
					: title === "branchsection"
					? "Sections"
					: title === "template"
					? "Dashboard"
					: title === "teacherId"
					? "Academic Staff"
					: title === "template"
					? "Dashboard"
					: title === "student"
					? "Students"
					: title === "template"
					? "Dashboard"
					: title === "tokenList"
					? "Tokens"
					: title === "template"
					? "Dashboard"
					: title === "logo"
					? "Branch Logo"
					: title === "template"
					? "Dashboard"
					: title === "TokenList"
					? "Tokens"
					: title === "template"
					? "Dashboard"
					: title === "admin users"
					? "Admin Users"
					: title === "template"
					? "Dashboard"
					: title === "upload content"
					? "Upload Content"
					: title === "template"
					? "Dashboard"
					: title === "global training"
					? "Global Training"
					: title === "template"
					? "Dashboard"
					: title === "associate concepts"
					? "Associate Concepts"
					: title === "template"
					? "Dashboard"
					: title === "blue print"
					? "Blue Print"
					: title === "template"
					? "Dashboard"
					: title === "CreateManagement"
					? "Create Management"
					: title === "template"
					? "Dashboard"
					: title === "add Management"
					? "Add Management"
					: title === "template"
					? "Dashboard"
					: title === "academicid"
					? "Academic Id"
					: title === "template"
					? "Dashboard"
					: title === "createschool"
					? "Create School"
					: title === "template"
					? "Dashboard"
					: title === "CreateChapter"
					? "Create Chapter"
					: title === "template"
					? "Dashboard"
					: title === "AddTeacherFiles"
					? "Add Teacher Files"
					: title === "template"
					? "Dashboard"
					: title === "CreateTrainingCategory"
					? "Create Training Category"
					: title === "template"
					? "Dashboard"
					: title === "CreateTraining"
					? "Create Training"
					: title === "template"
					? "Dashboard"
					: title === "addBranch"
					? "Branches"
					: title === "template"
					? "Dashboard"
					: title === "addbranch"
					? "Branches"
					: title === "template"
					? "Dashboard"
					: title === "GlobalTrainingApprovalApproved"
					? "Global Training Approval "
					: title === "template"
					? "Dashboard"
					: title === "Edit Concept"
					? "Create/Edit Concept"
					: title === "template"
					? "Dashboard"
					: title === "StudentFileApproval"
					? "Student File Approval"
					: title === "template"
					? "Dashboard"
					: title === "TeacherFileApproval"
					? "Teacher File Approval"
					: title === "template"
					? "Dashboard"
					: title === "Content Approval"
					? "Content Approval"
					: title === "template"
					? "Dashboard"
					: title === "GlobalTrainingApprovalRejected"
					? "Global Training Approval "
					: title === "template"
					? "Dashboard"
					: title === "ConceptTreeApprovalRejected"
					? "Concept Tree Approval "
					: title === "template"
					? "Dashboard"
					: title === "ConceptTreeApprovalPending"
					? "Concept Tree Approval "
					: title === "template"
					? "Dashboard"
					: title === "GlobalTrainingApprovalApproved"
					? "Global Training Approval "
					: title === "template"
					? "Dashboard"
					: title === "ContentApprovalRejected"
					? "Content Approval "
					: title === "template"
					? "Dashboard"
					: title === "ContentApprovalApproved"
					? "Content Approval "
					: title === "template"
					? "Dashboard"
					: title === "CreateTeacher"
					? "Add Academic Staff"
					: title === "template"
					? "Dashboard"
					: title === "Edit Sub & Root Concepts"
					? "Create/Edit Sub & Root Concepts"
					: title === "template"
					? "Dashboard"
					: title === "contentapprovalviewfile"
					? "Content Approval"
					: title === "template"
					? "Dashboard"
					: title === "ContentApprovalTeacherViewFile"
					? "Content Approval"
					: title === "template"
					? "Dashboard"
					: title === "study"
					? "Study"
					: title === "template"
					? "Dashboard"
					: title === "enrich"
					? "Enrich"
					: title === "template"
					? "Dashboard"
					: title === "teacherid"
					? "Academic Staff"
					: title === "quiz-approval"
					? "Quiz Approval"
					: title
			);
		}
	}, [location.pathname]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (e, iconValue) => {
		//PrincipalProfile
		const value = e.target.innerText;
		if (localStorage.getItem("role") === "TEACHER") {
			if (value || iconValue) {
				if (value === "Profile" || iconValue === "Profile") {
					navigate("/dashboard/TeacherProfile", {
						state: { id: 1, value: "profile" },
					});
				} else if (
					value === "Current Access" ||
					iconValue === "Current Access"
				) {
					navigate("/dashboard/TeacherProfile", {
						state: { id: 2, value: "currentAccess" },
					});
				}
			}
		} else if (localStorage.getItem("role") == "PRINCIPAL") {
			if (value === "Profile" || iconValue === "Profile") {
				navigate("/dashboard/PrincipalProfile", {
					state: { id: 1, value: "profile" },
				});
			} else if (
				value === "School Access" ||
				iconValue === "School Access"
			) {
				navigate("/dashboard/PrincipalProfile", {
					state: { id: 3, value: "School Access" },
				});
			} else if (
				value === "Teacher Access" ||
				iconValue === "Teacher Access"
			) {
				navigate("/dashboard/PrincipalProfile", {
					state: { id: 2, value: "Teacher Access" },
				});
			}
			// } else if (localStorage.getItem("role") == "STUDENT") {
			//   if (value === "Profile" || iconValue === "Profile") {
			//     navigate("/dashboard/StudentAdmin_StudentProfile", {
			//       state: { id: 1, value: "profile" },
			//     });
			//   }
			// else if (
			//   value === "Current Access" ||
			//   iconValue === "Current Access"
			// )
			// {
			//   navigate("/dashboard/StudentAdmin_StudentProfile", {
			//     state: { id: 3, value: "currentAccess" },
			//   });
			// } else if (
			//   value === "Teacher Access" ||
			//   iconValue === "Teacher Access"
			// ) {
			//   navigate("/dashboard/StudentAdmin_StudentProfile", {
			//     state: { id: 2, value: "Teacher Access" },
			//   });
			// }
		}
		setAnchorEl(null);
	};

	const navigate = useNavigate();
	const tempMenu = localStorage.getItem("menus");
	const Navmenu = JSON.parse(tempMenu);

	const getTextShortForm = (text) => {
		const words = text.split("_");
		if (words.length === 1) {
		  return words[0][0];
		} else {
		  return words[0][0] + words[1][0];
		}
	  };

	return (
		<div className={mainContainerStyles.main_container}>
			<header
				style={{ marginLeft: "-15px", paddingLeft: "15px" }}
				className={mainContainerStyles.header}
			>
			  <>
          <h3 className={mainContainerStyles.header_title}>
            {/* Welcome to {firstName} {lastName} */}
          </h3>
          <div className={mainContainerStyles.header_teacher}>
           
           
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                columnGap: "10px",
                padding: "10px",
              }}
            >
              <Avatar
                sx={{
                  background: "orange",
                  width: "2.8vw",
                  height: "2.8vw",
                  fontSize: "1.4vw",
                }}
              >
                {localStorage.getItem("firstName")[0]?.toUpperCase()}
              </Avatar>
              <div className={mainContainerStyles.profile_Name}>
              <div style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                  font: "13px"
                }}>
                  Welcome
                </div>
                <div className={mainContainerStyles.teacher_name}>
                  {localStorage.getItem("firstName")} {localStorage.getItem("lastName")} {"("+getTextShortForm(localStorage.getItem("role"))+")"}
                </div>
               
              </div>
              
            </Button>
           
          </div>
        </>
			</header>
			{/* dashboard content for various pages - start */}
			<div className={dashboardContentStyles.dashboard}>
				{props.children}
			</div>
			{/* dashboard content for various pages - end */}
		</div>
	);
};

export default MainContainer;
