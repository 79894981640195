import React, { useState, useEffect, useRef } from "react";
import CKEditor from "ckeditor4-react";

export default function TextEditor(props) {
  const [content, setContent] = useState("");
  const editorRef = useRef(null);

  CKEditor.editorUrl = "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js";

  useEffect(() => {
    setContent("");
  }, [props.clearContent]);

  useEffect(() => {
    if (props.initialValue !== content) {
      setContent(props.initialValue);
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current;
  
      editorInstance.on('contentDom', function () {
        editorInstance.editable().on('paste', function (event) {
          console.log("Editor paste event triggered");
  
          // Access clipboard data
          const clipboardData = event.data.$.clipboardData || window.clipboardData;
          const pastedData = clipboardData.getData('Text');
  
          console.log("Pasted Data:", pastedData); // Debugging line
  
          // Check if the pasted content is a URL
          const urlPattern = /^(https?:\/\/[^\s]+)/g;
  
          if (urlPattern.test(pastedData)) {
            console.log("Pasted URL detected:", pastedData);
  
            // Regular expression to check if the string is an image URL
            const imageUrlPattern = /\.(jpeg|jpg|gif|png)$/i;
  
            if (imageUrlPattern.test(pastedData)) {
              event.cancel(); // Prevent default paste behavior
              const imageTag = `<img src="${pastedData}" alt="Image" onerror="this.onerror=null;this.src='error_image_url.jpg';" />`;
              editorInstance.insertHtml(imageTag);
            } else {
              console.log("Not an image URL:", pastedData); // Debugging line
            }
          }
        });
      });
    }
  }, [editorRef.current]);

  return (
    <div className="App">
      <CKEditor
        id={props.id}
        name={props.name}
        activeClass="p10"
        data={content}
        initialValue={props.initialValue || ""}
        onChange={(e) => {
          const newContent = e.editor.getData();
          setContent(newContent);
          props.onEditorContentChange(newContent);
        }}
        config={{
          extraPlugins: "image",
          removePlugins: "filetools,uploadimage,uploadwidget,uploadfile,filebrowser,easyimage",
          allowedContent: true,
          pasteFromWordRemoveFontStyles: false,
          pasteFromWordRemoveStyles: false,
          on: {
            instanceReady: function (event) {
              editorRef.current = event.editor; // Store the editor instance
            }
          }
        }}
        outputFormat={props.format || 'html'}
        disabled={!!props.isDisabled}
      />
    </div>
  );
}




// import React, { useState, useEffect, useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import CKEditor from 'react-ckeditor-component';

// export default function TextEditor(props) {
//   const [content, setContent] = useState("");
//   const [clearTheContent, setClearTheContent] = useState(false);

//   const editorRef = useRef(null);

//   useEffect(() => {
//     setContent("");
//   }, [props.clearContent === true]);

//   if (props.clearContent === true) {
//     setContent({ content: "" });
//   }

//   useEffect(() => {
//     if (props.initialValue !== content) {
//       setContent(props.initialValue)
//     };
//   }, [props.initialValue]);

//   const onChange = (evt) => {
//     console.log("onChange fired with event info: ", evt);
//     var newContent = evt.editor.getData();
//     setContent(newContent);
//     console.log('new value >> ', newContent);
//     props.onEditorContentChange(newContent);
//   };

//   const onBlur = (evt) => {
//     console.log("onBlur event called with event info: ", evt);
//   };

//   const afterPaste = (evt) => {
//     console.log("afterPaste event called with event info: ", evt);
//   };

//   useEffect(() => {
//     // Code to run after each render (componentDidUpdate)
//     // You can perform data fetching or side effects here if needed
//   }, [content]); // Ensure to include dependencies if necessary

//   const handleMathTypeEvent = (event) => {
//     // Handle WIRIS/MathType event
//   };

//   return (

//     <div className="App">

//       <CKEditor
//         id={props.id}
//         name={props.name}
//         activeClass="p10"
//         content={content}
//         events={{
//           blur: onBlur,
//           afterPaste: afterPaste,
//           change: onChange
//         }}
//         config={{
//           extraPlugins: 'mathjax',
//           mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js',
//           mathJaxClass: 'equation',
//         }}
//       />
//     </div>
//   )
// }