import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import teacherFilesStyles from "./../css/teacherFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import "./../css/switch.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
// take this css copy in original file
import Pagination1 from "./pagination1";
import ApprovalModal from "./ApprovalModal";
import ContentRejectedModal from "./ContentRejectedModal";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
//import teacher_upload_chapter_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_upload_chapter_get";
import teacher_file_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_file_post";
import teacher_paginated_content_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_paginated_content_get";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import teacher_content_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_content_post";
import fileApprovalStyles from "../css/fileApproval.module.css";
import RejectedModal from "./RejectedModal";
import dashHome from "../../../src/img/dashHome.svg";
import action__Delete from "../../../src/img/Delete.svg";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import iconcustom from "../../../src/img/checkbox-plain.svg"
import { styled } from '@mui/material/styles';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';



const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  border: "2px solid blue",
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto blue',
    outlineOffset: 2,
  },


}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'blue',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

});


const TeacherFileApproval = (props) => {
  const [chapters, setChapters] = useState({});
  const [docCategory, setDocCategory] = useState({});
  const [filterval, setFilterval] = useState({});
  const [updateddata, setUpdatedata] = useState({});
  const [chapterContent, setChapterContent] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [success, setSuccess] = useState({});
  const [showError, setShowError] = useState("");
  const [show, setShow] = useState(false);
  const token = sessionStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [modifiedDate, setModifiedDate] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [approveModel, setapproveModal] = useState(false);
  const [rejectmsdModal, setrejectmsgModal] = useState(false);
  const [rejecteModel, setrejecteModal] = useState(false);
  const [rejectComment, setrejecetComment] = useState("");
  const [mess, setMess] = useState("");
  const role = localStorage.getItem("role");
  const [teacherInputs, setTeacherInputs] = useState({
    active: "true",
    // chapterId: "",
    contentUrl: "",
    documentCategoryId: "",
    reviewStatus: "PENDING",
  });
  const [selectedImage, setSelectedImage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLaptopScreen = windowWidth >= 1024 && windowWidth < 1440;

  const headingStyles = {
    width: isLaptopScreen ? '200%' : '249%',
    height: isLaptopScreen ? '630px' : '630px',
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const handleTeacherInputs = (e) => {
    e.preventDefault();
    setTeacherInputs({
      ...teacherInputs,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const [ids, setIds] = useState([]);
  const handleChapterlist = (event, elem) => {
    if (event.target.checked) {
      setIds([...ids, elem?.id]);
    } else {
      const temp_id = ids.filter((v) => v != elem?.id);
      setIds(temp_id);
    }
  };

  const handleallChapterlist = (event) => {
    if (event.target.checked) {
      var temp = filterval[0]?.documentTypes.reduce((current, next) => {
        var t = next.uploadContents.map((v) => v?.id);
        return [...current, ...t];
      }, []);
      temp = temp ?? [];
      setIds([...temp]);
    } else {
      setIds([]);
    }
  };
  const [message, setMessage] = useState("");
  const approvefiles = () => {
    const value = {
      chapterId: filterval[0]?.chapterId,
      reviewStatus: "APPROVED",
      uploadContents: ids,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/review-status`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setMessage(response.data.message);
        setTimeout(() => {
          setapproveModal(true);
        }, 500);
        setIds([]);
      })
      .catch((err) => console.log(err));
  };

  const closeapproval = () => {
    setapproveModal(false);
    teacher_paginated_content_get(setUpdatedata);
  };

  const rejecteModal = () => {
    setMess("Content Rejected");
    setrejecteModal(true);
  };
  const closerejectmodel = () => {
    setrejecteModal(false);
    setrejecetComment("");
    setError(false);
  };
  const [error, setError] = useState(false);
  const rejecte = () => {
    if (rejectComment.length == 0) {
      setError(true);
      return;
    }
    setError(false);
    const value = {
      chapterId: filterval[0]?.chapterId,
      reason: rejectComment,
      reviewStatus: "REJECTED",
      uploadContents: ids,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/review-status`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        closerejectmodel();
        setMessage(response.data.message);
        setrejectmsgModal(true);
        setIds([]);
      })
      .catch((err) => console.log(err));
  };

  const closeModal = () => {
    setrejectmsgModal(false);
    teacher_paginated_content_get(setUpdatedata);
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/${selectedItem?.id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setUrl(null);
        teacher_paginated_content_get(setUpdatedata);
      })
      .catch((err) => console.log(err));
    hideDeleteModal(false);

    // teacher_upload_chapter_get(setChapterContent);
  };

  const [chapterName, setChapterName] = useState("");
  useEffect(() => {
    setFilterval(updateddata);
    setChapterName(updateddata[0]?.chapterName);
  }, [updateddata]);

  useEffect(() => {
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: sessionStorage.getItem("contentUrl"),
    });
    return () => { };
  }, [sessionStorage.getItem("contentUrl")]);

  useEffect(() => {
    teacher_paginated_content_get(setUpdatedata);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);
  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };

  const [uptdata, setUptDta] = useState([]);
  const [isDisabled, setDisabled] = useState();
  useEffect(() => {
    student_upload_chapter_get(setUptDta);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    if (uptdata.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [uptdata]);

  useEffect(() => {
    // chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    teacher_paginated_content_get(setUpdatedata);
    chapters_list_getall(setChaptersList);
  }, []);

  return (
    <>
      <div style={{ width: "100%", marginRight: "15px" }}>
        <article>

          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <span className={dashboardContentStyles.link_text}>
              Content Approval
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Teacher Files</a>
            {"  "}
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span className={dashboardContentStyles.az_menu_text}>
              Content Approval
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Teacher Files
            </span>
          </div>

          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article>
        <div
          style={{ marginTop: "65px", position: "relative" }}
          className={fileApprovalStyles.flex}
        >
          <div 
          id={`az-content-backarrow`}
          className={fileApprovalStyles.left_row}>
            <i
              onClick={(e) => {
                if (
                  filterval[0]?.documentTypes.length > 0 &&
                  sessionStorage.getItem("isStatus") == "true"
                ) {
                  navigate(
                    `/dashboard/Content%20Approval?chapterId=${updateddata[0].chapterId}`
                  );
                } else if (searchParams.get("chapterId")) {
                  navigate(
                    `/dashboard/Content%20Approval?chapterId=${searchParams.get(
                      "chapterId"
                    )}`
                  );
                } else {
                  navigate("/dashboard/Content%20Approval");
                }
              }}
              class="fa-solid fa-arrow-left"
            ></i>
            <i
              style={{ color: "grey", fontSize: "24px", margin: "10px" }}
              class="fa-solid fa-user-gear"
            ></i>
            <p>Content Approval</p>
          </div>
          <div style={{ position: "absolute", right: "15px" }}>
            <button
            id={`az-content-student-btn`}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/StudentFileApproval");
                sessionStorage.setItem("status", "PENDING");
              }}
              className={
                !isDisabled
                  ? fileApprovalStyles.orange_btn
                  : fileApprovalStyles.orange_btn_disabled_grey
              }
              disabled={isDisabled}
            >
              Student
            </button>
            <button
            id={`az-content-teacher-btn`}
              style={{
                color: "white",
                backgroundColor: "#FD8C00",
                cursor: "pointer",
              }}
              className={fileApprovalStyles.orange_btn}
            >
              Teacher
            </button>
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: "15px",
            marginLeft: "15px",
          }}
          // style={{ display: "flex" }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            // style={{ height: "667px", backgroundColor: "white" }}
            style={{ flexGrow: "1", backgroundColor: "white" }}
          >
            <Table
              // style={{
              //   width: "68%",
              // }}
              style={{
                width: "32%",
              }}

            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={teacherFilesStyles.table_head}
                    align="left"

                    style={{ left: "20px" }}
                  >
                    {" "}
                    {updateddata?.length > 0 ? (
                      <Checkbox
                      id={`az-content-checkbox`}
                        sx={{
                          '&:hover': { bgcolor: 'transparent' },
                          right: "10px",
                          marginBottom: "20px"
                        }}
                        disableRipple
                        color="default"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        {...props}
                        onChange={handleallChapterlist}
                      />

                    ) : null}

                    <Tooltip title={chapterName} arrow>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '300px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          marginLeft: '-13px', // Adjust the margin as needed
                        }}
                      >
                        {chapterName}
                      </span>
                    </Tooltip>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <div style={{ display: "flex", width: "110%" }}> */}
                <div style={{ display: "flex", flexDirection: "column", height: "667px" }}> 
                  <div
                    style={{
                      //width: "91%",
                      //overflowY: "scroll",
                      //height: "450px",
                      borderRight: "1px solid lightgrey",
                     // position: "relative",
                      overflowY: "auto",
                      flexGrow: 1, 
                    }}
                  >
                    {filterval[0]?.documentTypes &&
                      filterval[0]?.documentTypes.length > 0 ? (
                      filterval?.[0].documentTypes.map((item, i) => {
                        return (
                          <>
                            <Accordion>
                              <AccordionSummary
                                style={{
                                  backgroundColor: "#92A2E8",
                                  width: "100%",
                                }}
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography
                                  style={{ color: "white", marginLeft: "10px" }}
                                >
                                  {item.documentType} -{" "}
                                  {item.uploadContents?.length}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {item?.uploadContents ? (
                                  item.uploadContents.map((elem) => {
                                    return (
                                      <Accordion>
                                        <AccordionSummary
                                          style={{
                                            width: "100%",
                                            position: "relative",
                                          }}
                                        // expandIcon={<ExpandMoreIcon />}
                                        >
                                          <div
                                            style={{
                                              marginTop: "-13px",
                                              width: "332px",
                                              whiteSpace: "nowrap",
                                              overflow: "auto",
                                            }}
                                          >
                                            {role !== "CONTENT_ADMIN" ? (
                                              <Checkbox
                                              id={`az-content-checkbox-${elem.id}`}
                                                style={{
                                                  color: "#fb9d2a",
                                                  width: "10px",
                                                  position: "absolute",
                                                  left: "8px",
                                                  top: "2px",
                                                }}
                                                onChange={(event) =>
                                                  handleChapterlist(event, elem)
                                                }
                                                onClick={(event) => event.stopPropagation()}
                                                checked={
                                                  ids.includes(elem?.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                            ) : null}
                                            <Tooltip
                                              title={elem.contentWithIndux.length > 20 ? elem.contentWithIndux : null}
                                            >
                                              <a
                                                // href={elem.contentUrl}
                                                // download={elem.contentWithIndux}
                                                onClick={(event) => {
                                                  event.stopPropagation(); {/* Stop event propagation */ }
                                                  openIframe(elem?.contentUrl);
                                                }}
                                                style={{
                                                  textDecoration: "none",
                                                  fontSize: "1vw",
                                                  color: "grey",
                                                  left: "80px",
                                                  position: "absolute",
                                                  width: "332px",
                                                  whiteSpace: "nowrap",
                                                  overflow: "auto",
                                                  textOverflow: "ellipsis",
                                                  // word wrap style (...)
                                                  inlineSize: "200px",
                                                  overflowWrap: "break-word",
                                                  hyphens: "manual",
                                                }}
                                                data-toggle="tooltip"
                                              >
                                                {elem.contentWithIndux.length > 20
                                                  ? elem.contentWithIndux.slice(
                                                    0,
                                                    15
                                                  ) + "...."
                                                  : elem.contentWithIndux}
                                              </a>
                                            </Tooltip>
                                            <span
                                              style={{
                                                position: "absolute",
                                                right: "-17px",
                                                marginTop: "-7px",
                                              }}
                                            >
                                              <DeleteIconButton
                                              id={`az-content-delete-btn-${elem.id}`}
                                                onClick={() => {
                                                  showDeleteModal(true);
                                                  setSelectedItem(elem);
                                                }}
                                              >
                                                <Tooltip
                                                  title="Remove"
                                                  placement={"top"}
                                                >
                                                  <img
                                                    className={
                                                      dashboardContentStyles.aRoleActionBtn
                                                    }
                                                    style={{
                                                      width: "38px",
                                                    }}
                                                    src={action__Delete}
                                                    alt="Dlt-Btn"
                                                  />
                                                </Tooltip>
                                              </DeleteIconButton>
                                            </span>
                                            {/* <i style={{position:"absolute",right:"10px"}} class="bi bi-download"></i> */}
                                          </div>
                                        </AccordionSummary>
                                      </Accordion>
                                    );
                                  })
                                ) : (
                                  <p>-</p>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}
                    {role !== "CONTENT_ADMIN" ? (
                      <div
                        // style={{
                        //   position: "absolute",
                        //   left: "45px",
                        //   bottom: "-225px",
                        // }}
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center", 
                          gap: "10px", 
                        }}
   
                      >
                        {updateddata?.length > 0 ? (
                          <>
                            <button
                            id={`az-content-reject-btn`}
                              className={fileApprovalStyles.orange_btn}
                              onClick={(e) => {
                                rejecteModal();
                              }}
                            >
                              REJECT
                            </button>
                            <button

                              id={`az-content-approve-btn`}
                              style={{
                                color: "white",
                                backgroundColor: "#FD8C00",
                              }}
                              className={fileApprovalStyles.orange_btn}
                              onClick={(e) => {
                                approvefiles();
                              }}
                            >
                              APPROVE
                            </button>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </TableBody>
            </Table>
          </div>
          <div onContextMenu={handleRightClick}>
          {url && (
          <ContentViewer contentstyle={{ height: "700px", width: "750px" }}
              url={url} />
          )}

            {/* {url && (
              // <Worker 
              //   workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div
                  style={headingStyles}>
                  <Viewer
                    fileUrl={'https://cors-anywhere.herokuapp.com/' + url}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              // </Worker>

              
              // <iframe
              //   src={url}
              //   style={headingStyles}
              // ></iframe>
            )} */}
          </div>
        </Paper>

        {/* content end */}
        <ApprovalModal
          open={approveModel}
          message={message}
          close={closeapproval}
        />
        <ContentRejectedModal
          open={rejectmsdModal}
          message={message}
          close={closeModal}
        />
        <RejectedModal
          open={rejecteModel}
          close={closerejectmodel}
          rejecte={rejecte}
          error={error}
          message={mess}
        >
          <TextField
            label="Reason"
            rows={5}
            multiline
            style={{ width: 450 }}
            value={rejectComment}
            onChange={(event) => setrejecetComment(event.target.value)}
          />
        </RejectedModal>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          // deleteTitle={"this"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div>
    </>
  );
};

export default TeacherFileApproval;