import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Images & Icons Used
import dashHome from "../../../../src/img/dashHome.svg";
import admin from "../../../../src/img/Adminuser.svg";
import add_training from "../../../../src/img/add_training.svg";
import teachIc from "../../../../src/img/teach.svg";
import reportIc from "../../../../src/img/Reports.svg";
import learningIcn from "../../../../src/img/Learning.png";
import assignIcn from "../../../../src/img/Masters.png";
import upSkillsIcn from "../../../../src/img/Upskill.png";
import StudentIcn from "../../../../src/img/Student report.png";

// Css
import "../../css/Student/Student_SideNavbar.css";
import navStyles from "../../css/navbar.module.css";
import {
  ListItemText,
  Menu,
  MenuItem,
  ListSubheader
} from "@mui/material";

const Principal_SideNav_Menu = ({
  elem,
  i,
  schoolSublinkVisible,
  navBarVis,
  handleLinkToggle,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [subMenuNameValue, setSubMenuNameValue] = useState("");

  let menuName = sessionStorage.getItem("sideNavMainMenuNames");
  let subNavMenuName = sessionStorage.getItem("sideNavSubMenuNames");


  useEffect(() => {
    // console.log(menuName, subNavMenuName)
    if (menuName == "Reports" && subNavMenuName === null) {
      // console.log(navBarVis, "navBarVis")
      setShowPopup(false);
      sessionStorage.setItem("sideNavMainMenuNames", menuName);
      sessionStorage.setItem("sideNavSubMenuNames", "Chapter Performance");
    }
  }, [menuName, subNavMenuName])

  const handleHighlight = (clicked) => {
    // console.log("handleHighlight", clicked, navBarVis)
    if (clicked === 'Reports' && !navBarVis) {
      console.log(">>side R.menu>>", clicked);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    sessionStorage.setItem("sideNavMainMenuNames", clicked);
    sessionStorage.removeItem("sideNavSubMenuNames");
  };

  const handleHighlightSubMenu = (clicked) => {
    // console.log("clicked", clicked)
    setShowPopup(false);
    sessionStorage.setItem("sideNavSubMenuNames", clicked);
    // sessionStorage.setItem("sideNavMainMenuNames", "Reports");
  };

  const handleClose = (reportValue) => {
    console.log(">>>>>>>>>>>>>>>", reportValue);
    setSubMenuNameValue(reportValue);
    setShowPopup(false);
    if (reportValue !== "Formal Assessment") {
      navigate(`/dashboard/${encodeURIComponent(reportValue)}`);
    } else {
      navigate(`/dashboard/${encodeURIComponent("Formative Assessment")}`);
    }

  };

  // Rendering the Menu Icons
  const menuIcons = {
    Dashboard: dashHome,
    Teach: teachIc,
    AssignQuiz: assignIcn,
    FormalAssessment: "", // Import and Change the Icon Here
    StudentRevision: StudentIcn,
    LearningPlan: learningIcn,
    Upskills: upSkillsIcn,
    Subjectivepapers: add_training,
    Reports: reportIc,
  };

  const defaultIcon = admin;
  const selectedIcon = menuIcons[elem.menu.replace(/ /g, "")] || defaultIcon;

  return (
    <>
      <div>
        {/*-------| Side Navbar Menu Names & Navigation Starts |-------*/}
        {elem.menu === "Reports" || elem.menu === "Reading Passport" ? (
          <div
            key={elem.menuId}
            id={`az-principal-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
            className="sidenav_parent"
            onClick={elem.subMenus && handleLinkToggle}
          >
            <div
              className={`icon_and_text ${menuName === elem.menu ? "" : "icon_and_text_active"
                }`}
              onClick={() => handleHighlight(elem.menu)}
            >
              {/*-------| Side Navbar Icons|-------*/}
              <div
                className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                  }`}
              >
                <img src={selectedIcon} alt="no img" />
              </div>

              {menuName === elem.menu ? (
                <div className="sidenav_text_selected">{elem.menu}</div>
              ) : (
                <div>
                  <div className="sidenav_text">{elem.menu}</div>
                </div>
              )}

              {/*-------| Side Navbar "Reports" Dropdown Arrow Icon|-------*/}
              <div className="report_arrow_icon">
                <i
                  className={
                    schoolSublinkVisible && navBarVis && elem.menu === sessionStorage.getItem("sideNavMainMenuNames")
                      ? "fa-solid fa-angle-down"
                      : "fa-solid fa-angle-right"
                  }
                  style={
                    menuName === elem.menu ? { color: "#ffffff" } : { color: "#1b1b1b" }
                  }
                  id="toggle_btn_icon"
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            id={`az-principal-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
            to={`/dashboard/principal/${elem.menu.toLowerCase()}`}
          >
            <div
              key={elem.menuId}
              className="sidenav_parent"
              onClick={elem.subMenus && handleLinkToggle}
            >
              <div
                className={`icon_and_text ${menuName === elem.menu ? "" : "icon_and_text_active"
                  }`}
                onClick={() => handleHighlight(elem.menu)}
              >
                {/*-------| Side Navbar Icons|-------*/}
                <div
                  className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                    }`}
                >
                  <img src={selectedIcon} alt="no img" />
                </div>
                {elem.subMenus ? (
                  <div className={
                    menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                  }>{elem.menu}</div>
                ) : (
                  <div className={
                    menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                  }>{elem.menu}</div>
                )}
              </div>
            </div>
          </Link>
        )}
        {/*-------| Side Navbar Menu Names & Navigation Ends |-------*/}

        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Starts |-------*/}
        <div
          key={i}
          style={
            schoolSublinkVisible && elem.menu === sessionStorage.getItem("sideNavMainMenuNames") ? { display: "block" } : { display: "none" }
          }
        >
          <div>
            {elem.subMenus &&
              elem.subMenus.map((item) => {
                return (
                  <div
                    id="registered_schools_link"
                    key={item.subMenuId}
                    style={
                      navBarVis ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div>
                      <Link
                        id={`az-principal-sidemenu-${item.subMenuName.toLowerCase().replace(/ /g, "_")}`}

                        style={{
                          textDecoration: "none",
                          // display: "inline-block",
                        }}
                        to={
                          item.subMenuName === "Chapter performance"
                            ? "Chapter%20performance"
                            : item.subMenuName === "Student Report"
                              ? "Student%20Report"
                              : item.subMenuName === "Formal Assessment"
                                ? "Formative%20Assessment"

                                : item.subMenuName === "Reading Passport List"
                                  ? "principal/Reading Passport"
                                  : item.subMenuName === "Monthly Avid Reader"
                                    ? "avid%20reader" :
                                    item.subMenuName === "Performance Report"
                                      ? "passport%20performance" :

                                      item.subMenuName
                        }
                      >
                        <div
                          key={item.subMenuId}
                          // className={`reports ${subNavMenuName === item.subMenuName
                          //     ? "" && "activeSubMenu"
                          //     : ""
                          //   }`}
                          className={`reports ${subNavMenuName === item.subMenuName ? "activeSubMenu" : ""}`}
                          onClick={() => handleHighlightSubMenu(item.subMenuName)}
                        >
                          {/*-------| "Reports" Icons Starts |-------*/}
                          <div className="reports_icons">
                            {item.subMenuName === "Chapter Performance" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : item.subMenuName === "Student Report" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : item.subMenuName === "Formal Assessment" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : (
                              ""
                            )}
                          </div>
                          {/*-------| "Reports" Icons Ends |-------*/}

                          <div className={
                            subNavMenuName === item.subMenuName
                              ? "reports_text_selected"
                              : "reports_text"
                          }>{item.subMenuName === 'Formative Assessment' ? 'Formal Assessment' : item.subMenuName}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Ends |-------*/}
        {showPopup && (
          <Menu
            id="basic-menu"
            open={showPopup}
            onClose={() => setShowPopup(false)}
            transformOrigin={{ horizontal: "left", vertical: "center" }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            PaperProps={{
              style: {
                borderRadius: "20px", // Adjust the border radius as needed
                paddingTop: "0px"
              },
            }}
          >
            {elem.subMenus &&
              elem.subMenus.map((item) => (
                <MenuItem
                  key={item.subMenuId}
                  onClick={() => handleClose(item.subMenuName)}
                >

                  <ListSubheader
                    sx={{
                      typography: 'h8',
                      color: "#1b1b1b",
                      background: "#ffa500"
                    }}>Report</ListSubheader>

                  <ListItemText sx={{
                    // color : "#ff2309"
                    color: subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000",
                  }}>
                    {item.subMenuName === 'Formal Report'
                      ? 'Formal Assessment Report'
                      : item.subMenuName}
                  </ListItemText>
                </MenuItem>
              ))}
          </Menu>
        )}
      </div>
    </>
  );
};

export default Principal_SideNav_Menu;