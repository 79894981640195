import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import React, { useEffect, useState } from "react";
import { documentCategoryData, MenuProps, roleListData } from "./Data";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import { styled } from "@mui/material/styles";
import CommonDeleteModal from "./CommonDeleteModal";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";
//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";

// input choose file label image
import Cf_img from "../../../src/img/csv.svg";
import C_img from "../../../src/img/image_icon.png";
import { debounce } from 'lodash';
import commonsort from "../js/CommonSort"

const DocumentCategory = () => {
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState([]);
  const [addDocumentCategory, setAddDocumentCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [shortName, setShortName] = useState("");
  const [selectedRoleList, setSelectedRoleList] = useState([]);
  const [roleList, setRoleList] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [uploadedFile, setUploadedFile] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [validation, setValidation] = useState(false);
  const navigate = useNavigate();

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("displayOrder");

  const [search, setSearch] = useState({
    search: " ",
  });

  const [data, setData] = useState([
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' },
    { id: 4, name: 'Item 4' },
  ]);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const debouncedSetFilterValue = debounce((newFilterValue) => {
    setFilterValue(newFilterValue);
  }, 200); // Adjust the debounce delay as needed
  const handleDragStart1 = (e, index) => {
    console.log("dragstart", e, index)
    e.dataTransfer.setData('text/plain', String(index));
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    console.log("handleDragOver", e, index)
    e.preventDefault();
    // Get the index of the dragged item from the dataTransfer object
    const draggedIndex = Number(e.dataTransfer.getData('text/plain'));

    // Create a new array with the dragged item moved to the new position
    const newData = [...data];
    newData.splice(draggedIndex, 1);
    newData.splice(index, 0, data[draggedIndex]);
    console.log("newData", newData)
    setData(newData);
  };
  const handleDragStart = (e, index) => {
    console.log("handleDragStart", e, index)
    // Set the data to the index of the dragged item
    e.dataTransfer.setData('text/plain', String(index));
  };

  const handleDragOver1 = (e, index) => {
    console.log("handleDragOver", e, index)
    e.preventDefault();
    const droppedIndex = Number(e.dataTransfer.getData('text/plain'));
    const newFilterValue = [...filterValue];
    const [draggedItem] = newFilterValue.splice(draggedIndex, 1);
    newFilterValue.splice(index, 0, draggedItem);
    console.log("newFilterValue", newFilterValue);
    sessionStorage.setItem("filterValue", JSON.stringify(newFilterValue))
    debouncedSetFilterValue(newFilterValue);

  };
  const updateAllCategory = async (newFilterValue) => {
    // const newArray = newFilterValue.map((item, index) => ({
    //   id: item.id,
    //   index: index,
    // }));
    // var item = {
    //   data: newArray
    // }
    // console.log("item", item)
    // let result = await axios.put(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/updateCategory`,
    //   item, // Send the entire updated array
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((json) => {
    //     console.log(json)
    //   })
    //   .catch(err => console.log(err))

    const updateItem = async (item, index) => {
      console.log("item", item, index);
      try {
        const result = await axios.put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/${item.id}`,
          {
            displayOrder: index,
            // sortOrder:index,
            documentType: item.documentType,
            roles: item.roles.map(item => item.roleId),
            accessibleForAl: false,
            referenceUrl: item.referenceUrl,
            shortName: item.shortName

          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(result.data); // Assuming the API response contains the updated data
      } catch (error) {
        console.error(error);
      }
    };

    const updateItems = async (newFilterValue) => {
      const updatePromises = newFilterValue.map((item, index) => {
        return updateItem(item, index);
      });

      try {
        await Promise.all(updatePromises);
        console.log("All items updated successfully");
      } catch (error) {
        console.error("Error updating items:", error);
        alert("error is Update");
      }
    };
    // Call the function to update items
    updateItems(newFilterValue);

  };
  const handleDrop = () => {
    console.log("dropped", filterValue, JSON.parse(sessionStorage.getItem("filterValue")));
    updateAllCategory(JSON.parse(sessionStorage.getItem("filterValue")))
  };

  let token = sessionStorage.getItem("token");

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    intialData(search);
    return () => { };
  }, [sortOrder]);

  const handleSearch = (e) => {
    const search = {
      search: e.target.value,
    };
    intialData(search);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const intialData = (search) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/content/document-category?pageSize=2500&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search || ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        res.data.data.data = await commonsort(res.data?.data?.data || [], sortBy, sortOrder)
        setUpdatedData(res.data?.data?.data);
        // setPage(0);
      })
      .catch((err) => console.log(err));
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/academic-persona`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRoleList(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const openDocumentCategory = () => {
    setAddDocumentCategory(true);
  };
  const [message, setMessage] = useState("");
  const showDeleteModal = async (item) => {
    setOpenDeleteModal(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/confirmation-api?id=${item.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  const closeDocumentCategory = () => {
    setValidation(false)
    setEdit(false);
    setShortName("");
    setCategoryName("");
    setSelectedRoleList([]);
    setUploadedFile("");
    setAddDocumentCategory(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const UploadButton = styled(Button)(() => ({
    color: "#fff",
    // width: 190,
    width: 130,
    height: 35,
    // backgroundColor: "#FFA700",
    backgroundColor: "#FD8C00",
    borderRadius: 150,
    border: "1px solid #FFA700",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#FD8C00",
      // backgroundColor: "#FFA700",
      border: 0,
      top: -2,
      boxShadow: "0px 3px 5px #FFA700",
    },
  }));
  const uploadedFileHandler = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=CHAPTERS`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setUploadedFile(response.data.data);
        }
      })
      .catch(function (response) {
        // console.log("error ==> ", response);
      });
  };
  const roleHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoleList(typeof value === "string" ? value.split(",") : value);
  };
  console.log(selectedRoleList, "list")
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.documentCategory?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
  };
  const [SuccessDisplayName, setSuccessDisplayName] = useState("")
  const addDocumentHandler = () => {
    const newRoles = selectedRoleList.map((v) => {
      return roleList.find((value) => value.role == v).id;
    });

    const data = {
      accessibleForAll: false,
      documentType: categoryName,
      referenceUrl: uploadedFile,
      roles: [...newRoles],
      shortName: shortName,
    };
    if (
      !data.documentType ||
      data.roles.length == 0 ||
      uploadedFile.length == 0
    ) {
      setValidation(true);
      return;
    }
    setSuccessDisplayName(data.documentType)

    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setValidation(false)
        intialData();
        setTimeout(() => {
          setSuccessCreateModal(true)
        }, 500);
      })
      .catch((err) => console.log(err));

    closeDocumentCategory();
  };
  const [updatedName, setUpdatedName] = useState("")
  const editHandler = (id) => {
    const newRoles = selectedRoleList.map((v) => {
      return roleList.find((value) => value.role == v).id;
    });

    const data = {
      accessibleForAll: false,
      documentType: categoryName,
      referenceUrl: uploadedFile,
      roles: [...newRoles],
      shortName: shortName,
    };
    setUpdatedName(data.documentType)
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {

        intialData();
        setTimeout(() => {
          setSuccesUpdatesModal(true)
        }, 500);
      })
      .catch((err) => console.log(err));

    closeDocumentCategory();
  };
  const updateToggle = () => {
    const find = updatedData?.find((item) => item.id === toggleDetails?.id);
    find.active = toggleDetails?.value;
    const clone = [...updatedData];
    clone.forEach((data) => {
      data = find;
    });
    setFilterValue(clone);
    handleConfirmationClose();

    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        intialData();
      })
      .catch((err) => console.log(err));
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/${id}/active?active=${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          intialData();
          // hideDeleteModal();
        })
        .catch((err) => console.log(err));
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/${selectedItem?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        intialData();
        hideDeleteModal();
      })
      .catch((error) => {
        hideDeleteModal();
      });
  };
  const editClickHandler = (item) => {
    const roles = item && item.roles.length && item.roles.map((v) => v.role);
    openDocumentCategory();
    setSelectedItem(item);
    setEdit(true);
    setShortName(item.shortName);
    setCategoryName(item?.documentType);
    setSelectedRoleList([...roles]);
    setUploadedFile(item?.referenceUrl);
  };

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "}
          <a>Document Category</a> */}
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Document Category
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addDocumentCategory && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ArticleRoundedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Document Category" : "Add Document Category"}
                </div>
              </div>
              <Paper
                style={{
                  padding: "20px 20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <FormControl >
                      <TextField
                        id="demo-helper-text-aligned-no-helper"
                        label="*Document Category Name"
                        autoComplete="off"
                        sx={{
                          fontSize: "14px !important",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              border: "1px solid #272727 !important"
                            }
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "orange !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#B5B6B6 !important",
                            fontSize: "14px"
                          },
                          "& .MuiInputBase-root": {
                            height: "48px !important",
                            width: "270px !important",
                            borderRadius: "150px !important",
                          },

                          "& .MuiInputBase-input": {
                            color: "#ffa500",

                          }
                        }}
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                      {validation && !categoryName && (
                        <span style={{ color: "red", padding: "5px", fontSize: "15px" }}>
                          Document Category Name is Required
                        </span>
                      )}</FormControl>
                    <FormControl sx={{ width: "270px", height: "48px" }}>
                      <InputLabel sx={{
                        fontSize: "14px",
                        marginBottom: 6,
                        "&.Mui-focused": {
                          color: "orange"
                        }
                      }} id="demo-multiple-checkbox-label">
                        *For Which Role
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        style={{ height: "100%" }}
                        value={selectedRoleList ?? []}
                        sx={{
                          "& .css-10hburv-MuiTypography-root": {
                            fontSize: "12px !important",
                          },
                          "&.MuiOutlinedInput-root": {
                            "& > fieldset": {
                              border: "1px solid #D6D5DF !important"
                            }
                          },
                          "&.MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              border: "1px solid #272727 !important"
                            }
                          },
                          fontSize: "14px !important",
                          borderRadius: "150px"
                        }}
                        onChange={roleHandleChange}
                        input={<OutlinedInput label="*For Which Role" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {roleList?.map((item) => {
                          return (
                            <MenuItem value={item?.role} key={item.id}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: '#FFA500',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#FFA500',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedRoleList?.indexOf(item.role) > -1
                                }
                              />
                              <ListItemText primary={item?.role} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {validation && selectedRoleList.length == 0 && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Role is Required
                        </span>
                      )}
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="demo-helper-text-aligned-no-helper"
                        label="Short Name"
                        autoComplete="off"
                        sx={{
                          fontSize: "14px !important",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              border: "1px solid #272727 !important"
                            }
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "orange !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#B5B6B6 !important",
                            fontSize: "14px"
                          },
                          "& .MuiInputBase-root": {
                            height: "48px !important",
                            width: "270px !important",
                            borderRadius: "150px !important",
                          },
                          "& .MuiInputBase-input": {
                            color: "#ffa500",

                          }
                        }}
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                      />

                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // gap: "13px",
                      gap: "10px",
                      // fontSize: "18px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      opacity: 1,
                    }}
                  >
                    {uploadedFile
                      ? "Upload Document Thumbnail"
                      : "*Upload Icon"}
                    <div>
                      {uploadedFile ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <div style={{ fontSize: "18px" }}>
                            <span>
                              <img
                                src={uploadedFile}
                                alt="Thumb"
                                width="75"
                                height="75"
                              />
                            </span>
                          </div>
                          <div
                            onClick={() => setUploadedFile("")}
                            style={{
                              display: "flex",
                              fontSize: "15px",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            <span style={{ borderBottom: "1px solid blue" }}>
                              Remove
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <UploadButton
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            style={{
                              textTransform: "capitalize",
                              fontSize: "14px",
                            }}
                          >
                            {/* <UploadFileRoundedIcon /> */}
                            <img
                              src={C_img}
                              alt="no-img"
                              style={{ marginRight: "2px" }}
                            />
                            Choose File
                            <input
                              type="file"
                              accept={"image/*"}
                              onChange={(e) => uploadedFileHandler(e)}
                              hidden
                            />
                          </UploadButton>
                          {validation && uploadedFile.length == 0 && (
                            <span style={{ color: "red", padding: "5px" }}>
                              Thumbnail is Required
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => closeDocumentCategory()}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton
                        onClick={() => {
                          editHandler(selectedItem.id);
                        }}
                      >
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton
                        onClick={() => {
                          addDocumentHandler();
                        }}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <ArticleRoundedIcon style={{ fontSize: "2.2vw" }} />
                <div
                  style={{
                    fontSize: "1.1vw",
                    fontWeight: "600",
                  }}
                >
                  Document Category List
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                name="search"
                Value={search.search}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addDocumentCategory && (
                <GreenAddButton onClick={() => openDocumentCategory()}>
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add Document Category
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper>

            <TableContainer
              sx={{
                maxHeight: addDocumentCategory ? 410 : 640,
                overflow: "auto",
              }}
            >
              <Table
                style={{ marginBottom: "90px" }}
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Document Category
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "2px 0 4px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("documentType");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Role
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="14.5"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "2px 0 4px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("role");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Short Name
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "2px 0 4px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("shortName");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                        paddingRight: 60,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            // "&:last-child td, &:last-child th": { border: 0 },

                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                            "&:last-child td, &:last-child th": {
                              borderBottom: "none !important",
                            },

                            "&:hover": {

                              "& td, & th": {
                                borderTop: "1px solid #ff9702",
                                borderBottom: "1px solid #ff9702",
                              },
                              " & th:first-of-type": {
                                borderLeft: "1px solid #ff9702",
                              },

                              "& td:first-of-type": {
                                borderLeft: "0",

                              },

                              "&:last-child td, &:last-child th": {
                                borderBottom: "1px solid #ff9702 !important",
                              },

                              "& td:last-of-type": {
                                borderRight: "1px solid #ff9702",

                              },

                              "& th:last-of-type": {
                                borderRight: "0",
                              },
                            },

                            "& td, & th": {
                              border: "1px solid transparent",
                              borderTop: "1px solid transparent",
                              borderBottom: "1px solid transparent",
                              "&:not(:last-child)": {
                                borderRight: "1px solid transparent",
                              },
                            },
                          }}

                          // hover={true}
                          // style={{
                          //   background: "#f9f8f8",
                          // }}
                          className={dashboardContentStyles.table_cell_name}
                          draggable
                          onDragStart={(e) => handleDragStart1(e, index)}
                          onDragOver={(e) => handleDragOver1(e, index)}
                          onDrop={handleDrop}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              // fontSize: "15px"
                              fontSize: "13px",
                              fontWeight: "600",
                              opacity: "0.8",
                            }}
                          >
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              // fontSize: "15px"
                              fontSize: "12px",
                              fontWeight: "600",
                              opacity: "0.8",
                            }}
                          >
                            {item?.documentType}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              // fontSize: "15px"
                              fontSize: "12px",
                              fontWeight: "600",
                              opacity: "0.8",
                            }}
                          >
                            {item.roles &&
                              item.roles.map((v, i) => {
                                return (
                                  <span key={v?.role}>
                                    {(i ? ", " : "") + v?.role}
                                  </span>
                                );
                              })}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              // fontSize: "15px"
                              fontSize: "12px",
                              fontWeight: "600",
                              opacity: "0.8",
                            }}
                          >
                            {item.shortName}
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Switch
                                checked={item?.active}
                                onChange={(e) => switchHandler(item?.id, e)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <EditIconButton
                              onClick={() => {
                                editClickHandler(item);
                              }}
                            >
                              <CustomTooltip title="Edit" placement={"top"}>
                                {/* <EditOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </CustomTooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => {
                                showDeleteModal(item);
                                setSelectedItem(item);
                              }}
                            >
                              <CustomTooltip title="Remove" placement={"top"}>
                                {/* <DeleteOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </CustomTooltip>
                            </DeleteIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              // style={{ display: "flex" }}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Document"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Document Category"}
          userName_Modal={
            SuccessDisplayName
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Document Category"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />

      </div>
    </>
  );
};
export default DocumentCategory;
