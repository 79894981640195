import React, { useEffect, useState } from "react";
import DashCss from "./SuperAdminDash.module.css";
import dashHome from "../../../src/img/dashHome.svg";
import axios from "axios";
import branchSvg from "../../../src/img/git-branch-svgrepo-com 1.png";
import schoolSvg from "../../../src/img/school-svgrepo-com 1 (1).png";
import student from "../../../src/img/Vector (1).png";
import vector from "../../../src/img/Vector (2).png";
import { Box, TextField ,Grid,Paper} from "@mui/material";
import Switch from '@mui/material/Switch';
import BoardModal from "./BoardModal";
import BranchModal from "./BranchModal";
import CityModal from "./CityModal";
import arrow from "../../img/Polygon 2.svg"
import searchIcon from "../../img/SuperAdminDashSearch.svg"
import SchoolAdminBreadcrumb from "../../../src/pages/js/SchoolAdminBreadcrumb";

const SuperAdminDash = () => {
  const token = sessionStorage.getItem("token");
  const [Boards, setBoards] = useState([]);
  const [cityData, setCityData] = useState();
  const [userCount, setUserCount] = useState({});
  const [countData, setCountData] = useState({});
  const [overviewData, setOverviewData] = useState([]);
  const [pageSize, setPageSize] = useState("5");
  const [page, setPage] = useState(1);
  const [branchOverview, setBranchOverview] = useState(false);
  const [openBoardModal, setOpenBoardModal] = useState(false);
  const [openBranchModal, setOpenBranchModal] = useState(false);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [topBranches, setTopBranches] = useState([]);
  const [boardDataPopup, setBoardDataPopup] = useState([]);
  const [branchPopupData, setBranchPopupData] = useState([]);
  const [openCityModal, setOpenCityModal] = useState(false)
  const [cityPopupData, setCityPopupData] = useState(false)
  useEffect(() => {
    schoolData();
    cityDataCall();
    // userCountData()
    schoolBranchCount();
    topBranchesApi();
  }, []);

  useEffect(() => {
    schoolBranchOverview(search);
  }, [page,search]);

  console.log(page, "page");
  const schoolData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/dashboard/board-school/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBoards(res.data.data);
      });
  };
  const cityDataCall = async () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/dashboard/cities-school/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCityData(res.data.data);
      });
  };

  const userCountData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/users-count`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserCount(res.data.data);
      });
  };

  const schoolBranchCount = async () => {
    axios
      .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/counts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCountData(res?.data.data);
      });
  };

  const schoolBranchOverview = async (search) => {
    axios
      .get(
        `${
          process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/schools/over-view/schools-branches?pageNumber=${
          page - 1
        }&pageSize=${pageSize}&sortBy=createdAt&order=true&search=${search? search : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOverviewData(res?.data.data.data);
        setTotalPages(res.data.data.totalPages);
      });
  };

  const topBranchesApi = async () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/top-branches/no-of-students`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopBranches(res?.data.data);
      });
  };

  const closeBoardModal = () => {
    setOpenBoardModal(false);
  };
  const closeBranchModal = () => {
    setOpenBranchModal(false);
  };
  const closeCityModal = () => {
    setOpenCityModal(false);
  };
  const handleSearch = (e) => {
   setSearch(e.target.value)
    schoolBranchOverview(search);
  };

  const handleArrow = () => {
    setPage(page + 1);
    if (page === totalPages) {
      setPage(1);
    }
  };

  const showBoardDetails = (v) => {
    console.log(v, "vv");
    setBoardDataPopup(v);
    setOpenBoardModal(true);
  };
  const showCityDetails = (v) => {
    console.log(v, "vv");
    setCityPopupData(v);
    setOpenCityModal(true);
  };
  const handleBranchModal = (v) => {
    console.log(v, "vv");
    setBranchPopupData(v);
    setOpenBranchModal(true);
  };

  const containerStyle = {
    fontSize: "48px",
    alignSelf: "center",
    color: "#c0d5e5",
    "@media (max-width: 1440px)": {
      fontSize: "48px",
      marginTop: "65px",
      color: "#c0d5e5",
    },
  };
  const items = [1, 2, 3, 4, 5, 6];
  console.log(topBranches, "board");
  return (
    <>
    <div className="az_home_navigation_section">
        <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}>
              <div style={{ display: "flex", alignItems: "flex-start"}}>
                  <p className="az_navigation_text"><span style={{position:"relative"}}>
                      <img style={{position: "absolute", top:"1px"}}
                        src={dashHome}
                        alt="no img"
                        width="18px"
                        height="18px"
                        
                      /> </span>
                      <span  className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                  </p>
              </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p><span style={{color: "#ffa500"}}>DashBoard</span></p>
        </div>
      </div>
      <div className={DashCss.mainContainer}>
        <Box className={DashCss.schoolContainer}
        sx={{
          marginTop:"40px !important"
        }}>
          <div className={DashCss.countBox}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {" "}
              <div
                style={{
                  borderRadius: "75%",
                  backgroundColor: "#31a0bc",
                  height: "40px",
                  width: "11%",
                  marginLeft:"10px",

                }}
              >
                <img
                  style={{ paddingTop: "7px", borderRadius: "10px" }}
                  src={schoolSvg}
                  alt="no img"
                />
              </div>
            </Box>
            <p className={DashCss.totalNoText}> Total number of schools </p>
            <div style={{marginRight:"125px",fontSize:"14px",color:"#6A6A6A"}}>{countData?.schoolCount}</div>
          </div>
          <div className={DashCss.countBox}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {" "}
              <div
                style={{
                  borderRadius: "75%",
                  backgroundColor: "#31a0bc",
                  height: "40px",
                  width: "11%",
                  marginLeft:"10px",
                }}
              >
                <img
                  style={{ paddingTop: "8px",paddingLeft:"4px" }}
                  src={branchSvg}
                  alt="no img"
                />
              </div>
            </Box>
            <p className={DashCss.totalNoText} style={{marginLeft:"20px"}}> Total number of Branches </p>
            <div style={{marginRight:"115px",fontSize:"14px",color:"#6A6A6A"}}>{countData?.branchCount}</div>
          </div>
          <div className={DashCss.countBox}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              {" "}
              <div
                style={{
                  borderRadius: "75%",
                  backgroundColor: "#31a0bc",
                  height: "40px",
                  width: "11%",
                  marginLeft:"10px",
                }}
              >
                <img style={{ paddingTop: "6px" }} src={student} alt="no img" />
                <img
                  style={{ paddingBottom: "13px" }}
                  src={vector}
                  alt="no img"
                />
              </div>
            </Box>
            <p className={DashCss.totalNoText}> Total number of Students </p>
            <div style={{marginRight:"130px",fontSize:"14px",color:"#6A6A6A"}}>{countData?.studentCount}</div>
          </div>
        </Box>

        <div className={DashCss.boardsContainer}>
          <div className={DashCss.boardBox}>
          <div
                style={{
                  display: "flex",
                  justifyContent:"space-around",
                  marginLeft: "8px",
                  // position: "sticky",
                  // top: "0",
                  marginBottom:"5px",
                }}
              >
                <h5 >Boards</h5>
                <p style={{color:"white"}}>{"----------------- "}</p>
                
                <h5  >Count</h5>
              </div>
            <div>           
              {Boards?.map((v, i) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <span
                      className={DashCss.schoolData}
                      onClick={() => showBoardDetails(v)}
                    ><p style={{marginLeft:"10px",marginTop:"2px",cursor:"pointer"}}>{v?.board}</p>
                      
                    </span>
                    <span className={DashCss.schoolCount}onClick={() => showBoardDetails(v)} >
                      
                      {v?.schoolCount}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={DashCss.cityBox}>
          <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginLeft: "8px",
                  // position: "sticky",
                  // top: "0",
                  marginBottom:"5px"
                }}
              >
                <h5 >City</h5>
                <p style={{color:"white"}}>{"----------------- "}</p>
                <h5 >Count</h5>
              </div>
            <div>
             
              {cityData?.map((v, i) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <span
                      onClick={() => showCityDetails(v)}
                      className={DashCss.cityData}
                    >
                      <p  style={{marginLeft:"10px",marginTop:"2px",cursor:"pointer"}}>{v?.city}</p>
                    </span>
                    <span className={DashCss.cityCount}  onClick={() => showCityDetails(v)}>{v?.schoolCount}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <Box className={DashCss.applicationBox1}>
            <p style={{ color: "#3268E7", padding: "5px" }}> Web Application</p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "10px" }}>40%</p>{" "}
                <div
                  style={{
                    backgroundColor: "blue",
                    height: "75px",
                    width: "25px",
                    marginLeft: "10px",
                  }}
                ></div>
                student
              </p>
              <p>
                {" "}
                <p style={{ fontSize: "10px", marginLeft: "10px" }}>40%</p>{" "}
                <div
                  style={{
                    backgroundColor: "#FA9825",
                    height: "75px",
                    width: "25px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                teacher
              </p>
            </div>
          </Box>
          <Box className={DashCss.applicationBox2}>
            <p style={{ color: "#3268E7", padding: "5px" }}>
              {" "}
              Mobile Application
            </p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "10px" }}>40%</p>{" "}
                <div
                  style={{
                    backgroundColor: "blue",
                    height: "75px",
                    width: "25px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                student
              </p>
              <p>
                <p style={{ fontSize: "10px", marginLeft: "10px" }}>40%</p>{" "}
                <div
                  style={{
                    backgroundColor: "#FA9825",
                    height: "75px",
                    width: "25px",
                    marginLeft: "10px",
                  }}
                ></div>{" "}
                teacher
              </p>
            </div>
          </Box>
        </div>

        <div className={DashCss.schoolOverviewContainer}>
          <div>
            <div className={DashCss.schoolRow}>
              <div style={{display:"flex"}}>
              <p style={{padding:"15px 5px 0px 25px",color: !branchOverview && "blue"}}> School overview</p>
              <Switch
                sx={{ marginTop: "10px", backgroundColor: "transparent"}}
                onClick={() => setBranchOverview(!branchOverview)}
              ></Switch>
              <p style={{padding:"15px 5px 0px 25px",color: branchOverview && "blue"}}> Branch overview</p>
              </div>
              <div style={{marginRight:"110px"}}>
              <TextField
                type={"text"}
                placeholder={"Search School..."}
                name="search"
                Value={search}
                InputProps={{
                  startAdornment: (
                
                      <img
                        src={searchIcon}
                        style={{
                          color:"#27334E",
                          verticalAlign: "top",
                          fontSize: "large",
                          marginRight:"5px",
                        }}
                        aria-hidden="true"
                      />
                 
                  ),
                }}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "350px !important",
                    height: "38px !important",
                    borderRadius: "10px !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    marginTop: "10px !important",
                    background: "#F3F2FF 0% 0% no-repeat padding-box",
                    border: "1px #FFFFFF",
                    opacity: 1,
                  },
                }}
                // onChange={onSearch}
                onChange={handleSearch}
              
              />
              </div>
            </div>

           

            <div className={DashCss.bottomSchoolFlex}>
              {!branchOverview ? (
                <div>
                  <div className={DashCss.school}>School:</div>

                  <div className={DashCss.branch}>Branch No:</div>
                </div>
              ) : (
                <div className={DashCss.schoolBranch1}>
                  <div className={DashCss.schoolBranchLabel}>
                    <p> School: </p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p>Branch: </p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p> Location: </p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p>grades: </p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p> Total Students: </p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p>Board:</p>
                  </div>
                  <div className={DashCss.schoolBranchLabel}>
                    <p> Plan Name:</p>
                  </div>
                </div>
              )}
              {overviewData?.map((school) => {
                return !branchOverview && (
                  <div className={DashCss.schoolBranch}>
                    <div className={DashCss.schoolBranchInsideBox}>
                      {school?.schoolName}
                    </div>
                    <div
                      className={DashCss.branchNo}
                      onClick={() => handleBranchModal(school)}
                    >
                      {school?.branchCount}
                    </div>
                  </div>
                ) 
              })}
              {topBranches?.map((item) => ( 
                branchOverview &&
                  <div className={DashCss.schoolBranch}>
                   
                      <>
                        {" "}
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p> {item?.school} </p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p> {item.name} </p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p> {item.locality} </p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p>{item.gradeCount} </p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p> {item.numberOfStudents} </p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p>{item.board}</p>
                        </div>
                        <div className={DashCss.schoolBranchInsideBox2}>
                          <p> {item.plan}</p>
                        </div>
                      </>
                    {" "}
                  </div>
                ))}
             {!branchOverview && 
             <img
             style={{alignSelf:"center",padding:"10px"}}
               src={arrow}
                onClick={handleArrow}
              />}
            </div>
          </div>
        </div>
        <BoardModal
          open={openBoardModal}
          close={closeBoardModal}
          boards={boardDataPopup}
        />

        <BranchModal
          open={openBranchModal}
          close={closeBranchModal}
          boards={branchPopupData}
        />
        <CityModal
          open={openCityModal}
          close={closeCityModal}
          boards={cityPopupData}
        />
      </div>
    </>
  );
};

export default SuperAdminDash;
